import { useState } from "react";
import { Modal, Button, Avatar } from "antd";
import { BigNumber } from "ethers";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { formatCelrDecimal } from "../../helpers/format";
import { getExlporerUrl } from "../../helpers/env";
import { RATE_BASE } from "../../constants";
import userIcon from "../../assets/images/userIcon.png";
import "./index.less";

import lockLoadingPng from "../../assets/images/lockLoading.png";
import successPng from "../../assets/images/success.png";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setRefreshMyDelegations } from "../../redux/transferSlice";

/* eslint-disable no-debugger */

export interface ITokenInputChangeEvent {
  value: string;
  error?: string;
}

interface IProps {
  visible: boolean;
  onClose: () => void;
  validatorInfo: {
    valAddr: string;
    commissionRate: BigNumber;
    desc?: {
      details: {
        icon: string | undefined;
      };
      moniker: string | undefined;
    };
  };
  delegatorInfo: {
    withdrawableTokens: BigNumber;
  };
}

enum WthdrawStatus {
  WITHDRAW,
  WITHDRAWING,
  SUCCESS,
  FAILED,
}

const WithdrawModal = (props: IProps) => {
  const { visible, onClose, validatorInfo, delegatorInfo } = props;
  const { contracts, transactor } = useContractsContext();
  const { Staking } = contracts;

  const commissionRate = validatorInfo.commissionRate.toNumber();
  const [withdrawStatus, setWithdrawStatus] = useState<WthdrawStatus>(WthdrawStatus.WITHDRAW);
  const [withdrawTxHash, setWithdrawTxHash] = useState<string>("");
  const explorerUrl = getExlporerUrl();
  const dispatch = useAppDispatch();
  const { transferInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;

  const withdrawMethod = async () => {
    if (!transactor || !Staking) {
      return;
    }
    setWithdrawStatus(WthdrawStatus.WITHDRAWING);
    try {
      const withdrawTx = await transactor(Staking?.completeUndelegate(validatorInfo?.valAddr));
      setWithdrawTxHash(withdrawTx.hash);
      setWithdrawStatus(WthdrawStatus.SUCCESS);
      dispatch(setRefreshMyDelegations(!refreshMyDelegations));
    } catch (e) {
      setWithdrawStatus(WthdrawStatus.FAILED);
    }
  };

  const successMethod = () => {
    setWithdrawStatus(WthdrawStatus.WITHDRAW);
    onClose();
  };

  const contentDom = () => {
    switch (withdrawStatus) {
      case WthdrawStatus.SUCCESS: {
        return (
          <div className="success">
            <div className="success-bolck">
              <div className="success-icon">
                <img src={successPng} alt="" />
              </div>
              <div className="success-text">Withdrawal Submitted</div>
              <div className="success-desc">
                Your request for withdrawing CELR token has been submitted. Please allow a few minutes for the
                transaction to complete.
              </div>
              <div className="success-link">
                <a href={`${explorerUrl}/tx/${withdrawTxHash}`} target="_blank" rel="noreferrer">
                  Check on Etherscan
                </a>
              </div>
            </div>
            <Button
              type="primary"
              block
              className="success-btn"
              onClick={() => {
                successMethod();
              }}
            >
              OK
            </Button>
          </div>
        );
      }
      default: {
        return (
          <div className="unbond">
            <div className="form-userInfo">
              <Avatar size={56} icon={<img src={validatorInfo?.desc?.details?.icon || userIcon} alt="userIcon" />} />
              <div className="candidateId">{validatorInfo?.desc?.moniker || "anonymous"}</div>
              <div className="commission-rate">
                <span className="rate-number">{`${commissionRate / RATE_BASE}% `}</span>
                <span className="rate">Commission</span>
              </div>
            </div>
            <div className="claimform-content">
              <div className="unlock-reward">
                <span className="text">Amount</span>
                <span className="amount">{formatCelrDecimal(delegatorInfo?.withdrawableTokens, 6)}</span>
              </div>
              <Button
                type="primary"
                block
                onClick={withdrawMethod}
                className={withdrawStatus === WthdrawStatus.WITHDRAWING ? "unbonding-btn" : "unbond-btn"}
                disabled={withdrawStatus === WthdrawStatus.WITHDRAWING}
              >
                {withdrawStatus === WthdrawStatus.WITHDRAWING ? <img src={lockLoadingPng} alt="" /> : "Withdraw"}
              </Button>
            </div>
          </div>
        );
      }
    }
  };

  const modalTitleDom = () => {
    return withdrawStatus === WthdrawStatus.FAILED ? (
      <>
        {/* <div className="transactionRejectDom">
                    <div className="text">
                        Transaction Reject
                    </div>
                    <div className="link" onClick={() => setWithdrawStatus(WthdrawStatus.WITHDRAW)}>
                        Dismiss
                    </div>
                </div>
                Withdraw */}
      </>
    ) : (
      "Withdraw"
    );
  };

  return (
    <Modal
      title={modalTitleDom()}
      className="withdraw-modal"
      width={401}
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {contentDom()}
    </Modal>
  );
};

export default WithdrawModal;
