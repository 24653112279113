import { Row, Col } from "antd";
import { formatUnits } from "@ethersproject/units";
import { isMobile } from "react-device-detect";
import "./candidate-tobal.less";
import { ValidatoAndDelegatorType } from "../../views/Dpos";

function getCandidateTotal(validators: Array<ValidatoAndDelegatorType>): number {
  /* eslint-disable no-else-return */
  if (validators && validators.length > 0) {
    return validators.reduce((prev, current) => {
      const celr = formatUnits(current.tokens, 18);
      return prev + Number(celr);
    }, 0);
  } else {
    return 0;
  }
}

const CandidateTotal = (props: { validators: Array<ValidatoAndDelegatorType> }): JSX.Element => {
  const { validators } = props;
  const totalValidators = validators.length;
  const totalStake = getCandidateTotal(validators);
  const totalStakeDom = `${
    totalStake && totalStake?.toFixed(0) ? Number(totalStake?.toFixed(0)).toLocaleString() + " CELR" : "--"
  }`;

  const renderMobileContent = () => {
    return (
      <div className="mobile-overview">
        <div className="mobile-overview-item">
          <div className="title">Number of Validators</div>
          <div className="desc">{totalValidators || "--"}</div>
        </div>
        <div className="line" />
        <div className="mobile-overview-item">
          <div className="title">Total Stake</div>
          <div className="desc">{totalStakeDom}</div>
        </div>
      </div>
    );
  };

  if (isMobile) {
    return renderMobileContent();
  }

  return (
    <Row className="Candidate-total">
      <Col span={12}>
        <div className="total-stake">
          <div className="total-stake-title">Number of Validators</div>
          <div className="total-stake-content">{totalValidators || "--"}</div>
        </div>
      </Col>
      <Col span={12}>
        <div className="total-stake">
          <div className="total-stake-title">Total Stake</div>
          <div className="total-stake-content">
            {`${
              totalStake && totalStake?.toFixed(0) ? Number(totalStake?.toFixed(0)).toLocaleString() + " CELR" : "--"
            }`}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default CandidateTotal;
