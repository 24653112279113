import { ChainTokenInfo, TokenInfo } from "../proto/gateway/gateway_pb";

const getTokenInfoByChainIdAndTokenSymbol = (
  chainTokenMap: Array<[number, ChainTokenInfo.AsObject]>,
  paramChainId: number,
  paramTokenSymbol,
): TokenInfo.AsObject => {
  let tokenInfo = new TokenInfo().toObject();
  if (chainTokenMap) {
    const chainTokenInfo = chainTokenMap.find(item => {
      return item[0] === paramChainId;
    });
    if (chainTokenInfo) {
      tokenInfo =
        chainTokenInfo[1].tokenList.find(item => {
          return item?.token?.symbol === paramTokenSymbol;
        }) || tokenInfo;
    }
  }
  return tokenInfo;
};

export default getTokenInfoByChainIdAndTokenSymbol;
