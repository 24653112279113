import { mainnetNetworks } from "./chains_mainnet";
import { partnermainnetNetworks } from "./chains_partner_mainnet";
import { stagingNetworks } from "./chains_staging_mainnet";
import { testNetworks } from "./chains_testnet";

type EnvType = "staging" | "partnermainnet" | "mainnet" | "test";

const getNetworkByEnv = (envType: EnvType) => {
  let newNetworks;
  switch (envType) {
    case "staging":
      newNetworks = stagingNetworks;
      break;
    case "partnermainnet":
      newNetworks = partnermainnetNetworks;
      break;
    case "mainnet":
      newNetworks = mainnetNetworks;
      break;
    case "test":
      newNetworks = testNetworks;
      break;
    default:
      newNetworks = mainnetNetworks;
      break;
  }
  return newNetworks;
};

export interface NetworkInfo {
  name: string;
  color: string;
  chainId: number;
  rpcUrl: string;
  blockTime: number;
  blockExplorerUrl: string;
  symbol: string;
}

export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const RPC_URL = process.env.REACT_APP_RPC_PROVIDER_URL;
export const ENV_TYPE = process.env.REACT_APP_ENV_TYPE as EnvType;

export const NETWORKS = getNetworkByEnv(ENV_TYPE);
export const CHAIN_LIST: NetworkInfo[] = Object.values(NETWORKS) as NetworkInfo[];
export const getNetworkById: (chainId: number) => NetworkInfo = (chainId: number) => {
  /* eslint-disable-next-line no-restricted-syntax */
  for (let i = 0; i < CHAIN_LIST.length; i++) {
    if (CHAIN_LIST[i]?.chainId === chainId || CHAIN_LIST[i].chainId === Number(chainId)) {
      return CHAIN_LIST[i];
    }
  }
  return NETWORKS.localhost;
};

export const USD_TOKENS = {
  BUSD: true,
  USDC: true,
  USDT: true,
  DAI: true,
};
