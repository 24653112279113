import { Button, Tooltip } from "antd";
import { useToggle } from "react-use";
import { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import {
  getFeeShareInfo,
  getPegFeeShareInfo,
  CBridgeFeeShareInfo,
  getTokenUsdPrice,
  getHistory,
  getPegHistory,
} from "../../api";
import {
  GetTokenUsdPriceRequest,
  ClaimHistoryRequest,
  ClaimStatus,
  PegClaimHistoryRequest,
} from "../../proto/gateway/gateway_pb";
import FeeShareModal from "./FeeShareModal";
import CanonicalFeeShareModal from "./CanonicalFeeShareModal";
import SingleChainWithdrawModal from "../singleChainWithdrawModal";
import HistoryModal from "./History/History";
import tooltipIcon from "../../assets/images/tooltipBold.png";
import rightIcon from "../../assets/images/rightIcon.png";
import "./feeShare.less";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setRefreshData } from "../../redux/transferSlice";
import { formatRemoveDecimals } from "../../helpers/format";
import { storageConstants } from "../../constants/const";
import { LPHistory } from "../../constants/claimType";
import { mergeLiquidityHistory } from "../../utils/mergeLiquidityHistory";
import getTokenInfoByChainIdAndTokenSymbol from "../../utils/getTokenInfoByChainIdAndTokenSymbol";

/* eslint-disable */
const minFeeShare = 0.000001;

const poolLearnMoreLink = "https://cbridge-docs.celer.network/introduction/canonical-token-bridge";
const caninicalLearnMoreLink = "https://cbridge-docs.celer.network/introduction/canonical-token-bridge";

const FeeShare = () => {
  const { address, provider, chainId } = useWeb3Context();
  const [poolbasedModalvisible, togglePoolbasedModalvisible] = useToggle(false);
  const [canonicalModalVisible, toogleCanonicalModalVisible] = useToggle(false);
  const [singleChainWithdrawModalVisible, toggleSingleChainWithdrawModalVisible] = useToggle(false);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [historyModalDotVisible, setHistoryModalDotVisible] = useState(false);
  const [feeShareList, setFeeShareList] = useState<Array<CBridgeFeeShareInfo>>([]);
  const [pegFeeShareList, setPegFeeShareList] = useState<Array<CBridgeFeeShareInfo>>([]);
  const { transferInfo } = useAppSelector(state => state);
  const { configs } = useAppSelector(state => state.globalInfo);
  const { chainTokenMap } = configs;
  const { refreshData, refreshMyDelegations } = transferInfo;

  const [feeSharetokenUsdMap, setFeeSharetokenUsdMap] = useState(new Map<string, number>());
  const [pegFeeSharetokenUsdMap, setPegFeeSharetokenUsdMap] = useState(new Map<string, number>());

  const cachePeggedFeeConfirmDataStr = localStorage.getItem(`cachePeggedFeeConfirmData-${address}`);
  let peggedFeeConfirmDataObj;
  if (cachePeggedFeeConfirmDataStr) {
    peggedFeeConfirmDataObj = JSON.parse(cachePeggedFeeConfirmDataStr);
  }

  const dispatch = useAppDispatch();

  const getSymbolAndChainId = (denom: string) => {
    const symbol = (denom.match(/CBF-(\S*)(\/)/) || [])[1];
    const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
    return {
      symbol,
      singleChainId: chainId,
    };
  };

  const getPegSymbolAndChainId = (denom: string) => {
    const symbol = (denom.match(/PBF-(\S*)(\/)/) || [])[1];
    const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
    return {
      symbol,
      singleChainId: chainId,
    };
  };

  const hasNeedToConfirmData = list => {
    return list?.find(item => item.status === ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION);
  };
  const getTxStatus = async (provider, link) => {
    const txid = link.split("/tx/")[1];
    if (txid) {
      const res = await provider?.getTransactionReceipt(txid);
      return res;
    }
    return "";
  };
  const getLpOnChainQueryPromiseList = (provider, chainId, localLpHistoryList: LPHistory[]) => {
    // eslint-disable-next-line
    const promiseList: Array<Promise<any>> = [];
    if (localLpHistoryList) {
      const newLocalLpList: LPHistory[] = [];
      localLpHistoryList?.forEach(localItem => {
        if (localItem && localItem.toString() !== "null") {
          newLocalLpList.push(localItem);
          if (
            localItem?.status === ClaimStatus.CLM_FAILED ||
            localItem?.txIsFailed ||
            Number(localItem.chain?.id) !== Number(chainId)
          ) {
            // Failed transactions filter
            const nullPromise = new Promise(resolve => {
              resolve(0);
            });
            promiseList.push(nullPromise);
          } else {
            const promistx = getTxStatus(provider, localItem.blockTxLink);
            promiseList.push(promistx);
          }
        }
      });
    }
    return promiseList;
  };
  const getLphistoryList = async () => {
    const request = new ClaimHistoryRequest();
    request.setPageSize(50);
    request.setNextPageToken("");
    request.setAddr(address);
    const res = await getHistory(request);
    let localLpList;
    const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
    if (localLpListStr) {
      localLpList = JSON.parse(localLpListStr)[address] as LPHistory[];
    }
    const promiseList = getLpOnChainQueryPromiseList(provider, chainId, localLpList);
    const lpPromiseList = Promise.all(promiseList).then(onChainResult => {
      const lpMergerdResult = mergeLiquidityHistory(
        address,
        new Date().getTime(),
        res.historyList,
        localLpList,
        onChainResult,
        50,
        "pool_bridge",
      );
      return lpMergerdResult;
    });

    const pegRequest = new ClaimHistoryRequest();
    pegRequest.setPageSize(50);
    pegRequest.setNextPageToken("");
    pegRequest.setAddr(address);
    const pegRes = await getPegHistory(pegRequest);
    let pegLocalLpList;
    const pegLocalLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
    if (pegLocalLpListStr) {
      pegLocalLpList = JSON.parse(pegLocalLpListStr)[address] as LPHistory[];
    }
    const pegPromiseList = getLpOnChainQueryPromiseList(provider, chainId, pegLocalLpList);
    const pegLpPromiseList = Promise.all(pegPromiseList).then(pegOnChainResult => {
      const pegLpMergerdResult = mergeLiquidityHistory(
        address,
        new Date().getTime(),
        pegRes.historyList,
        pegLocalLpList,
        pegOnChainResult,
        50,
        "canonical_bridge",
      );
      return pegLpMergerdResult;
    });
    Promise.all([lpPromiseList, pegLpPromiseList]).then(res => {
      if (hasNeedToConfirmData(res[0].mergedLpHistory) || hasNeedToConfirmData(res[1].mergedLpHistory)) {
        setHistoryModalDotVisible(true);
      } else {
        setHistoryModalDotVisible(false);
      }
    });
  };

  const refreshTransferAndLPHistory = () => {
    if (address) {
      getLphistoryList();
    }
  };

  const handleCloseHistoryModal = () => {
    setHistoryModalVisible(false);
    refreshTransferAndLPHistory();
  };

  useEffect(() => {
    if (!address) {
      return;
    }
    const request = new ClaimHistoryRequest();
    request.setPageSize(50);
    request.setNextPageToken("");
    request.setAddr(address);
    const getHistoryFetch = getHistory(request);
    const pegRequest = new PegClaimHistoryRequest();
    pegRequest.setPageSize(50);
    pegRequest.setNextPageToken("");
    pegRequest.setAddr(address);
    const getPegHistoryFetch = getPegHistory(pegRequest);
    Promise.all([getHistoryFetch, getPegHistoryFetch]).then(res => {
      if (hasNeedToConfirmData(res[0].historyList) || hasNeedToConfirmData(res[1].historyList)) {
        setHistoryModalDotVisible(true);
      } else {
        setHistoryModalDotVisible(false);
      }
    });
  }, [address, refreshData]);

  useEffect(() => {
    if (address && chainTokenMap) {
      getFeeShareInfo(address).then(res => {
        // filter fee share amount less than minFeeShare
        const result: Array<CBridgeFeeShareInfo> = [];
        for (let i = 0; i < res.length; i++) {
          res[i].amount = formatRemoveDecimals(res[i].amount);
          const { symbol, singleChainId } = getSymbolAndChainId(res[i].denom);
          if(Number(singleChainId) !== 1666600000 
            && !(Number(singleChainId) === 288 && symbol === "USDC")
            && !(Number(singleChainId) === 250 && symbol === "WETH")
            && !(Number(singleChainId) === 250 && symbol === "USDC")
            && !(Number(singleChainId) === 250 && symbol === "USDT")
            && !(Number(singleChainId) === 1313161554 && symbol === "USDC")
            && !(Number(singleChainId) === 1313161554 && symbol === "USDT")
            && !(Number(singleChainId) === 1313161554 && symbol === "ESW")) {
            const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
            const deciaml = tokenInfo?.token?.decimal || 18;
            const amount = formatUnits(res[i].amount, deciaml);
            if (Number(amount) >= minFeeShare) {
              result.push(res[i]);
            }
          }
        }
        setFeeShareList(result);
      });
      getPegFeeShareInfo(address).then(res => {
        // filter fee share amount less than minFeeShare
        const result: Array<CBridgeFeeShareInfo> = [];
        for (let i = 0; i < res.length; i++) {
          res[i].amount = formatRemoveDecimals(res[i].amount);
          const { symbol, singleChainId } = getPegSymbolAndChainId(res[i].denom);
          if(Number(singleChainId) !== 1666600000 && 
            !(Number(singleChainId) === 288 && symbol === "USDC")) {
            const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
            const deciaml = tokenInfo?.token?.decimal || 18;
            const amount = formatUnits(res[i].amount, deciaml);
            if (Number(amount) >= minFeeShare) {
              result.push(res[i]);
            }
          }
        }
        setPegFeeShareList(result);
      });
    }
  }, [address, chainTokenMap, refreshMyDelegations, refreshData]);

  useEffect(() => {
    const tokens: Array<string> = [];
    feeShareList.forEach(item => {
      const { symbol } = getSymbolAndChainId(item.denom);
      tokens.push(symbol);
    });
    const Request = new GetTokenUsdPriceRequest();
    Request.setTokenSymbolsList(tokens);
    getTokenUsdPrice(Request)
      .then(res => {
        const priceMap = new Map(res);
        setFeeSharetokenUsdMap(priceMap);
      })
      .catch(e => {
        console.log(e);
      });
  }, [feeShareList]);

  useEffect(() => {
    const tokens: Array<string> = [];
    pegFeeShareList.forEach(item => {
      const { symbol } = getPegSymbolAndChainId(item.denom);
      tokens.push(symbol);
    });
    const Request = new GetTokenUsdPriceRequest();
    Request.setTokenSymbolsList(tokens);
    getTokenUsdPrice(Request)
      .then(res => {
        const priceMap = new Map(res);
        setPegFeeSharetokenUsdMap(priceMap);
      })
      .catch(e => {
        console.log(e);
      });
  }, [pegFeeShareList]);

  const onClaimSuccess = () => {
    return getFeeShareInfo(address).then(res => {
      const result: Array<CBridgeFeeShareInfo> = [];
      for (let i = 0; i < res.length; i++) {
        res[i].amount = formatRemoveDecimals(res[i].amount);
        const { symbol, singleChainId } = getSymbolAndChainId(res[i].denom);
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
        const deciaml = tokenInfo?.token?.decimal || 18;
        const amount = formatUnits(res[i].amount, deciaml);
        if (Number(amount) >= minFeeShare) {
          result.push(res[i]);
        }
      }
      setFeeShareList(result);
      return result;
    });
  };

  const onPegClaimSuccess = () => {
    return getPegFeeShareInfo(address).then(res => {
      const result: Array<CBridgeFeeShareInfo> = [];
      for (let i = 0; i < res.length; i++) {
        res[i].amount = formatRemoveDecimals(res[i].amount);
        const { symbol, singleChainId } = getPegSymbolAndChainId(res[i].denom);
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
        const deciaml = tokenInfo?.token?.decimal || 18;
        const amount = formatUnits(res[i].amount, deciaml);
        if (Number(amount) >= minFeeShare) {
          result.push(res[i]);
        }
      }
      setPegFeeShareList(result);
      return result;
    });
  };

  const poolbasedClickMethod = () => {
    togglePoolbasedModalvisible();
  };

  return (
    <>
      <div className="cBridge-fee-rewards">
        <div className="cBridge-fee-rewards-info">
          <div className="cBridge-fee-rewards-info-title">
            <div className="cBridge-fee-rewards-info-title-text">cBridge v2 Transaction Fee</div>
            <div className="cBridge-fee-rewards-info-title-icon">
              <Tooltip
                title="These are your earnings from transaction fees in cBridge v2."
                placement="topLeft"
                color="#fff"
                overlayInnerStyle={{ color: "#000", borderRadius: "8px", textAlign: "center", width: 290 }}
              >
                <img src={tooltipIcon} alt="toolTip" />
              </Tooltip>
            </div>
          </div>
          <Button
            type="text"
            className="cBridge-fee-rewards-info-history"
            onClick={() => {
              setHistoryModalVisible(true);
            }}
          >
            <div className="cBridge-fee-rewards-info-history-text">View History</div>
            <div className="cBridge-fee-rewards-info-history-icon">
              <img src={rightIcon} alt="go history" />
              {historyModalDotVisible ? <div className="has-cta" /> : null}
            </div>
          </Button>
        </div>
        <div className="cBridge-fee-rewards-btns">
          <div className="cBridge-fee-rewards-btn">
            <div style={{ width: "100%", height: "100%" }}>
              <Button
                style={pegFeeShareList?.length === 0 && !peggedFeeConfirmDataObj ? { pointerEvents: "none" } : {}}
                type="text"
                className="btn-claim"
                onClick={() => {
                  toogleCanonicalModalVisible();
                }}
                disabled={pegFeeShareList?.length === 0 && !peggedFeeConfirmDataObj}
              >
                <div className="btn-title">Claim Fee Rewards</div>
                <div className="btn-sub-title">
                  canonical token bridge
                  <Tooltip
                    title={
                      <div
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        Claim the fee rewards generated by the canonical token bridges.{" "}
                        <a href={caninicalLearnMoreLink} target="_blank" rel="noreferrer">
                          Learn More
                        </a>{" "}
                        about canonical token bridges.
                      </div>
                    }
                    placement="bottomRight"
                    color="#fff"
                    overlayInnerStyle={{ color: "#000", borderRadius: "8px", textAlign: "center", width: 240 }}
                  >
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6 }} />
                    </div>
                  </Tooltip>
                </div>
              </Button>
            </div>
          </div>
          <div className="cBridge-fee-rewards-btn">
            <div style={{ width: "100%", height: "100%" }}>
              <Button
                style={feeShareList?.length === 0 ? { pointerEvents: "none" } : {}}
                type="text"
                className="btn-claim"
                onClick={poolbasedClickMethod}
                disabled={feeShareList?.length === 0}
              >
                <div className="btn-title">Claim Fee Rewards</div>
                <div className="btn-sub-title">
                  pool-based bridge
                  <Tooltip
                    title={
                      <div
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        Claim the fee rewards generated by the pool-based bridging transactions.{" "}
                        <a href={poolLearnMoreLink} target="_blank" rel="noreferrer">
                          Learn More
                        </a>{" "}
                        about pool-based bridges.
                      </div>
                    }
                    placement="bottomRight"
                    color="#fff"
                    overlayInnerStyle={{ color: "#000", borderRadius: "8px", textAlign: "center", width: 240 }}
                  >
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6 }} />
                    </div>
                  </Tooltip>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {poolbasedModalvisible ? (
        <FeeShareModal
          visible={poolbasedModalvisible}
          onClose={() => {
            togglePoolbasedModalvisible();
            dispatch(setRefreshData());
          }}
          onClaimSuccess={onClaimSuccess}
          feeShareList={feeShareList}
          singleChainToggleMethod={toggleSingleChainWithdrawModalVisible}
          feeSharetokenUsdMap={feeSharetokenUsdMap}
        />
      ) : null}
      {canonicalModalVisible ? (
        <CanonicalFeeShareModal
          visible={canonicalModalVisible}
          onClose={() => {
            toogleCanonicalModalVisible();
            dispatch(setRefreshData());
          }}
          onClaimSuccess={onPegClaimSuccess}
          feeShareList={pegFeeShareList}
          feeSharetokenUsdMap={pegFeeSharetokenUsdMap}
        />
      ) : null}
      {singleChainWithdrawModalVisible ? (
        <SingleChainWithdrawModal
          visible={singleChainWithdrawModalVisible}
          onClose={() => {
            toggleSingleChainWithdrawModalVisible();
            dispatch(setRefreshData());
          }}
          feeShareList={feeShareList}
          backToFeeShareModal={() => {
            toggleSingleChainWithdrawModalVisible();
            togglePoolbasedModalvisible();
          }}
        />
      ) : null}
      {historyModalVisible && (
        <HistoryModal
          visible={historyModalVisible}
          onClose={() => {
            handleCloseHistoryModal();
          }}
        />
      )}
    </>
  );
};

export default FeeShare;
