import axios, { AxiosResponse } from "axios";
import { TrackJS } from "trackjs";
import { getRpcUrl, getGatewayUrl, getGatewayOtherUrl } from "./env";

const rpcUrl = getRpcUrl();
const gatewayUrl = getGatewayUrl();
const gatewayOtherUrl = getGatewayOtherUrl();

const getErrorHandler =
<T>(path: string, body?: unknown) =>
(response: AxiosResponse<T>) => {
  if (response.status >= 400) {
    TrackJS.track(`
    response to ${path} failed with status code ${response.status}
    request body: ${body}
    response: ${response.data}
    `);
  }
  return response;
};

export const get = async <T>(path: string): Promise<T> => {
  const res = await axios.get(`${rpcUrl}${path}`).then(getErrorHandler<T>(path));
  return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const post = async <T>(path: string, body: any): Promise<T> => {
  const res = await axios.post(`${rpcUrl}${path}`, body).then(getErrorHandler<T>(path, body));
  return res.data;
}

export const gatewayGet = async <T>(path: string): Promise<T> => {
  const res = await axios.get(`${gatewayUrl}${path}`).then(getErrorHandler<T>(path));
  return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gatewayPost = async <T>(path: string, body: any): Promise<T> => {
  const res = await axios.post(`${gatewayUrl}${path}`, body).then(getErrorHandler<T>(path, body));
  return res.data;
};

export const gatewayOtherGet = async <T>(path: string): Promise<T> => {
  const res = await axios.get(`${gatewayOtherUrl}${path}`).then(getErrorHandler<T>(path));
  return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const gatewayOtherPost = async <T>(path: string, body: any): Promise<T> => {
  const res = await axios.post(`${gatewayOtherUrl}${path}`, body).then(getErrorHandler<T>(path, body));
  return res.data;
};
