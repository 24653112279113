import * as fcl from "@onflow/fcl";
import { createContext, ReactChild, ReactChildren, useCallback, useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";

// eslint-disable-next-line
/* eslint-disable */
export enum NonEVMMode {
  off, // Both from and to chains are EVM
  flowTest,
  flowMainnet,
}

interface NonEVMContextProps {
  nonEVMMode: NonEVMMode;
  nonEVMAddress: string;
  nonEVMConnected: boolean;
  // eslint-disable-next-line
  flowUser: any;
  flowConnected: boolean;
  flowAddress: string;
  loadNonEVMModal: (mode: NonEVMMode) => Promise<void>;
  logoutNonEVMModal: () => Promise<void>;
}

export const NonEVMContext = createContext<NonEVMContextProps>({
  nonEVMMode: NonEVMMode.off,
  nonEVMAddress: "",
  nonEVMConnected: false,
  flowUser: {},
  flowConnected: false,
  flowAddress: "",
  loadNonEVMModal: async (_: NonEVMMode) => {},
  logoutNonEVMModal: async () => {},
});

interface NonEVMContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const NonEVMContextProvider = ({ children }: NonEVMContextProviderProps): JSX.Element => {
  const { selectedChainId } = useAppSelector(state => state.globalInfo);
  const [nonEVMMode, setNonEVMMode] = useState<NonEVMMode>(NonEVMMode.off);
  const [nonEVMAddress, setNonEVMAddress] = useState("");
  const [nonEVMConnected, setNonEVMConnected] = useState(false);

  const [flowUser, setFlowUser] = useState({});
  const [flowConnected, setFlowConnected] = useState(false);
  const [flowAddress, setFlowAddress] = useState("");
  const [shouldSwitchToFlow, setShouldSwitchToFlow] = useState(false);

  const dispatch = useAppDispatch();

  const loadNonEVMModal = useCallback(
    async (mode: NonEVMMode) => {
      if (mode === NonEVMMode.flowMainnet || mode === NonEVMMode.flowTest) {
        if (!flowConnected) {
          setShouldSwitchToFlow(true);
          fcl.authenticate();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowConnected],
  );

  const logoutNonEVMModal = useCallback(async () => {
    if (nonEVMMode === NonEVMMode.flowMainnet || nonEVMMode === NonEVMMode.flowTest) {
      if (flowConnected) {
        fcl.unauthenticate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonEVMMode, flowConnected]);

  useEffect(() => {
    const chainMode = getNonEVMMode(selectedChainId ?? 0);

    if (chainMode !== NonEVMMode.off) {
      setNonEVMMode(chainMode);
    }
  }, [selectedChainId]);

  useEffect(() => {
    if (nonEVMMode === NonEVMMode.off) {
      setNonEVMConnected(false);
      // debugger;
      setNonEVMAddress("");
    } else if (nonEVMMode === NonEVMMode.flowMainnet || nonEVMMode === NonEVMMode.flowTest) {
      // debugger;
      setNonEVMConnected(flowConnected);
      setNonEVMAddress(flowAddress);
    } else {
      // debugger;
      setNonEVMConnected(false);
      setNonEVMAddress("");
    }
  }, [nonEVMMode, flowConnected, flowAddress]);

  useEffect(() => {
    /// FCL config

    if (process.env.REACT_APP_ENV_TYPE === "test") {
      fcl
        .config()
        .put("accessNode.api", "https://access-testnet.onflow.org")
        .put("discovery.wallet", "https://flow-wallet-testnet.blocto.app/authn"); // mainent:https://flow-wallet.blocto.app/authn
    } else {
      fcl
        .config()
        .put("accessNode.api", "https://flow-mainnet.g.alchemy.com")
        .put("discovery.wallet", "https://flow-wallet.blocto.app/authn")
        // eslint-disable-next-line
        .put("grpc.metadata", { api_key: "u59zhmfnv7s2mcubk0ppbdyo1lomvnfc" });
    }

    fcl.currentUser().subscribe(user => {
      const { loggedIn, addr } = user;

      if (loggedIn && loggedIn !== undefined) {
        setFlowConnected(loggedIn);
        if (loggedIn === true) {
          const fromChainId = selectedChainId ?? 0;
          if (shouldSwitchToFlow) {
            const targetChainIdForFlow = targetChainIdForNonEVMMode(
              process.env.REACT_APP_ENV_TYPE === "test" ? NonEVMMode.flowTest : NonEVMMode.flowMainnet,
            );
            if (fromChainId !== targetChainIdForFlow) {
              // todo: switch chain
              // const chain = transferConfig.chains.find(chainInfo => {
              //   return chainInfo.id === targetChainIdForFlow;
              // });
              // if (chain !== undefined &&) {
              //   dispatch(setFromChain(chain));
              // }
            }
          }
          setShouldSwitchToFlow(false);
        }
      } else {
        setFlowConnected(false);
      }
      if (addr && addr !== undefined) {
        setFlowAddress(addr);
      } else {
        setFlowAddress("");
      }
      setFlowUser({ ...user });
    });
  }, [shouldSwitchToFlow, dispatch, selectedChainId]);

  return (
    <NonEVMContext.Provider
      value={{
        nonEVMMode,
        nonEVMAddress,
        nonEVMConnected,
        flowUser,
        flowConnected,
        flowAddress,
        loadNonEVMModal,
        logoutNonEVMModal,
      }}
    >
      {children}
    </NonEVMContext.Provider>
  );
};
export const useNonEVMContext: () => NonEVMContextProps = () => useContext(NonEVMContext);

export const isNonEVMChain = (selectedChainId: number) => {
  return getNonEVMMode(selectedChainId) !== NonEVMMode.off;
};

export const getNonEVMMode = (targetChainId: number) => {
  if (targetChainId === 12340001) {
    return NonEVMMode.flowMainnet;
  }

  if (targetChainId === 12340002) {
    return NonEVMMode.flowTest;
  }

  return NonEVMMode.off;
};

const targetChainIdForNonEVMMode = (mode: NonEVMMode) => {
  if (mode === NonEVMMode.flowMainnet) {
    return 12340001;
  }

  if (mode === NonEVMMode.flowTest) {
    return 12340002;
  }

  console.log("Unexpect code path for nonEVMMode", mode);
  return 0;
};

export const convertNonEVMAddressToEVMCompatible = async (address: string, mode: NonEVMMode) => {
  if (mode === NonEVMMode.flowMainnet || mode === NonEVMMode.flowTest) {
    const addressWithoutOx = address.toLowerCase().replace("0x", "");
    return "0x" + addressWithoutOx.padStart(40, "0");
  }

  return address;
};
