import { Modal, Spin, Button } from "antd";
import careerPng from "../../assets/images/careerbanner.png";
import { UnlockType, UNLOCK_INIT, UNLOCK_WAITING } from "../../constants";
import "./claimform.less";

/* eslint-disable */
interface ClaimFormProps {
  visible: boolean;
  action: UnlockType;
  reward: string;
  unlockMethod: any;
  toggleClaimForm: any;
  loading: boolean;
}

export default function UnlockForm(props: ClaimFormProps): JSX.Element {
  const { visible, action, reward, unlockMethod, toggleClaimForm, loading } = props;
  const renderBtn = () => {
    switch (action) {
      case UNLOCK_INIT:
        return (
          <Button type="text" onClick={unlockMethod} loading={loading}>
            Unlock Reward
          </Button>
        );
      case UNLOCK_WAITING:
        return (
          <Button type="text" className="waiting" disabled>
            <Spin />
          </Button>
        );
      default:
        return null;
    }
  };

  const renderContent = () => (
    <div className="claimform-container">
      <div className="claimform-img">
        <img src={careerPng} alt=" " />
      </div>
      <div className="claimform-content">
        <div className="unlock-reward">
          <span>Unlock Reward</span>
          <span className="celr-amount">{reward}</span>
        </div>
        {action === UNLOCK_WAITING ? (
          <div className="sub-title">Your staking reward is being unlocked on SGN, which might take a few minutes.</div>
        ) : null}
        {renderBtn()}
      </div>
    </div>
  );

  const renderModalTitle = () => {
    switch (action) {
      case UNLOCK_INIT:
        return "Unlock Reward";
      case UNLOCK_WAITING:
        return "Unlocking Your Reward";
      default:
        return "";
    }
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={visible}
      footer={null}
      width={401}
      className="claimForm-modal"
      onCancel={toggleClaimForm}
    >
      <div className="claimform-container">{renderContent()}</div>
    </Modal>
  );
}
