import { RightOutlined } from "@ant-design/icons";
import { parseEther } from "@ethersproject/units";
import { message, Result, Space, Spin } from "antd";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import { useState } from "react";
import { useEthBalance } from "../../hooks";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useAppSelector } from "../../redux/store";
import ActionTitle from "../../components/modals/common/ActionTitle";
import "./index.less";

/* eslint-disable */

interface FaucetModalProps {
  onClose: () => void;
}
const FAUCET_URL = {
  5: "https://faucet.goerli.mudit.blog",
};
function Entry({ text, onClick }) {
  return (
    <div className="entry" onClick={onClick}>
      <Space className="entryInfo">
        <Text>{text}</Text>
      </Space>
      <RightOutlined className="arrow"/>
    </div>
  );
}

function FaucetResultModal({ resultText, onCancel }) {
  return (
    <Modal
      className="modal"
      closable
      maskClosable={false}
      visible
      width={360}
      onCancel={onCancel}
      footer={null}
    >
      <Result className="result" status="success" title={resultText} />
    </Modal>
  );
}

export default function FaucetModal({ onClose }: FaucetModalProps): JSX.Element {
  const {
    contracts: { faucet },
    transactor,
  } = useContractsContext();
  const { provider, address } = useWeb3Context();
  const { celerAddress } = useAppSelector(state => state.globalInfo);
  const [ethBalance] = useEthBalance(provider, address);
  const [resultMsg, setResultMsg] = useState("");
  const [loading, setLoadig] = useState(false);
  const tokenSymbolName = "ETH";

  const drip = async () => {
    if (!transactor || !faucet ) {
      message.error("It looks like something went wrong");
      return;
    }
    setLoadig(true);

    if (!ethBalance || parseEther(ethBalance.toString()).eq(0)) {
      message.error(`Insufficient ${tokenSymbolName}. Please get some test ${tokenSymbolName} first.`);
      setLoadig(false);
      return;
    }

    try {
      const dripTx = await transactor(faucet.drip([celerAddress]));
      await dripTx.wait();
      setResultMsg("Received Tokens");
      setLoadig(false);
    } catch (e) {
      setLoadig(false);
    }
  };

  const tokensText = "Test Tokens ( CELR )";

  if (resultMsg) {
    return <FaucetResultModal resultText={resultMsg} onCancel={onClose} />;
  }

  return (
    <Modal
      closable
      visible
      width={500}
      title={<ActionTitle title="Get From Faucets" token={undefined} />}
      onCancel={onClose}
      footer={null}
      className="modal"
    >
      <Spin spinning={loading}>
        <Entry
          text={`Test ${tokenSymbolName}`}
          onClick={(e: Event) => {
            e.preventDefault();
            const faucetUrl = FAUCET_URL[5];
            if (faucetUrl) {
            window.open(faucetUrl, "_blank");
            }
          }}
        />
        <Entry text={tokensText} onClick={() => drip()} />
      </Spin>
    </Modal>
  );
}
