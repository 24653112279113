import { ethers } from "ethers";
import { createContext, ReactChild, ReactChildren, useContext, useEffect, useState } from "react";
import { transactorWithNotifier } from "../helpers";
import { Transactor } from "../helpers/transactorWithNotifier";
import { useContractLoaders } from "../hooks";
import { SGNContracts, sgnContracts } from "../hooks/contractLoaders";
import { useAppSelector } from "../redux/store";
import { useWeb3Context } from "./Web3ContextProvider";

/* eslint-disable no-debugger */

interface ContractsContextProps {
  contracts: SGNContracts;
  transactor: Transactor<ethers.ContractTransaction> | undefined;
}

interface ContractsContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const ContractsContext = createContext<ContractsContextProps>({
  contracts: sgnContracts,
  transactor: undefined,
});

export const ContractsContextProvider = ({ children }: ContractsContextProviderProps): JSX.Element => {
  const { provider } = useWeb3Context();
  const [addresses, setAddresses] = useState<Record<string, string>>({});
  const { viewerContractAddress, stakingContractAddress, stakingRewardContractAddress, bridgeContractAddress, faucetAddresses, oTContractAddr } = useAppSelector(state => state.globalInfo);

  useEffect(() => {
    // if (sgnAddress && dposChainAddress && viewerContractAddress && stakingContractAddress && stakingRewardContractAddress) {
      setAddresses({
        Viewer: viewerContractAddress,
        Staking: stakingContractAddress,
        StakingReward: stakingRewardContractAddress,
        Bridge: bridgeContractAddress,
        faucet: faucetAddresses,
        originalTokenVault: oTContractAddr,
      });
    // }
  }, [viewerContractAddress, stakingContractAddress, stakingRewardContractAddress, bridgeContractAddress, faucetAddresses, oTContractAddr]);

  const contracts = useContractLoaders(provider, addresses);
  const transactor = transactorWithNotifier<ethers.ContractTransaction>(provider);

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        transactor,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export const useContractsContext: () => ContractsContextProps = () => useContext(ContractsContext);
