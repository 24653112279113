import { useState } from "react";
import { Button, Card, Spin } from "antd";
import { useAsync } from "react-use";
import { utils } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { ReloadOutlined } from "@ant-design/icons";
import { JsonRpcProvider } from "@ethersproject/providers";
import CandidateTotal from "../newComponents/dpos/Candidate-total";
import CandidateTable from "../newComponents/dpos/Candidate-table";
import { getValsInfo } from "../api";
import "./dpos.less";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { setRefreshData, setRefreshMyDelegations } from "../redux/transferSlice";
import earnRewardIcon from "../assets/images/earn-reward.png";
import { useConfigContext } from "../providers/ConfigContextProvider";
import useReadOnlyCustomContractLoader from "../hooks/customReadyOnlyContractLoader";
import { Viewer__factory } from "../typechain/factories/Viewer__factory";
import { ArrowSGV } from "../assets/customIcons";

export type ValidatoAndDelegatorType = {
  valAddr: string;
  status: number;
  tokens: BigNumber;
  commissionRate: BigNumber;
  description: {
    contact: string | undefined;
    details: {
      icon: string | undefined;
    };
    moniker: string | undefined;
    website: string | undefined;
  };
};

export default function Dpos(): JSX.Element | null {
  const dispatch = useAppDispatch();
  const [validators, setValidators] = useState<Array<ValidatoAndDelegatorType>>([]);
  const [loading, setLoading] = useState(false);
  const { transferInfo } = useAppSelector(state => state);
  const { refreshData, refreshMyDelegations } = transferInfo;
  const { getRpcUrlByChainId } = useConfigContext();
  const { viewerContractAddress } = useAppSelector(state => state.globalInfo);
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const viewerContract = useReadOnlyCustomContractLoader(jprovider, viewerContractAddress, Viewer__factory);
  useAsync(async () => {
    setLoading(true);
    requestValidatorDatas();
  }, [viewerContract, refreshData]);

  const requestValidatorDatas = async () => {
    if (viewerContract) {
      try {
        const allValidatorsInfo = await viewerContract.getValidatorInfos();
        const valsInfo = await getValsInfo();
        const validatorAndDelegatorInfos = allValidatorsInfo.map(validator => {
          const delegator = valsInfo.find(
            item => utils.getAddress(item.eth_address) === utils.getAddress(validator.valAddr),
          );
          return {
            valAddr: validator.valAddr,
            status: validator.status,
            tokens: validator.tokens,
            commissionRate: validator.commissionRate,
            description: {
              contact: delegator?.description?.contact,
              details: {
                icon: delegator?.description?.details?.icon,
              },
              moniker: delegator?.description?.moniker,
              website: delegator?.description?.website,
            },
          };
        });
        setValidators(validatorAndDelegatorInfos);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      const valsInfo = await getValsInfo();
      const validatorAndDelegatorInfos = valsInfo.map(validator => {
        setLoading(false);
        return {
          valAddr: validator.eth_address,
          status: 3,
          tokens: BigNumber.from(validator.tokens),
          commissionRate: BigNumber.from(Number(validator.commission_rate) * 10000),
          description: {
            contact: validator?.description?.contact,
            details: {
              icon: validator?.description?.details?.icon,
            },
            moniker: validator?.description?.moniker,
            website: validator?.description?.website,
          },
        };
      });
      setValidators(validatorAndDelegatorInfos);
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="earn-reward-head">
        <img src={earnRewardIcon} className="earn-reward-icon" alt="" />
        <span className="earn-reward-title"> Help Secure Cross-chain Interoperability by staking CELR with SGN </span>
        <div className="earn-guide-container">
          <a
            className="earn-guide-link"
            href="https://cbridge-docs.celer.network/tutorial/sgn-v2-staking-guide"
            target="_blank"
            rel="noreferrer"
          >
            View SGN v2 tutorial
          </a>
          <ArrowSGV
            onClick={() => {
              window.open("https://cbridge-docs.celer.network/tutorial/sgn-v2-staking-guide", "_blank");
            }}
          />
        </div>
      </div>
      <Card
        title="Network Overview"
        className="candidate-total"
        extra={
          <Button
            type="primary"
            className="rebutton"
            onClick={() => {
              dispatch(setRefreshData());
              dispatch(setRefreshMyDelegations(!refreshMyDelegations));
            }}
            icon={<ReloadOutlined style={{ fontSize: 20 }} />}
          />
        }
      >
        <CandidateTotal validators={validators} />
      </Card>
      <Card
        title="All Validators"
        className="candidate-table"
        style={{
          marginTop: "24px",
          borderRadius: "20px",
          minHeight: 560,
        }}
      >
        <CandidateTable validators={validators} />
      </Card>
    </Spin>
  );
}
