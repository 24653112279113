export const stagingNetworks = {
  883: {
    name: "883",
    color: "#3099f2",
    chainId: 883,
    rpcUrl: "http://localhost:8545",
    blockTime: 5000,
    iconUrl: "./noChain.png",
    blockDelay: 15,
    symbol: "",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
    lqMintTokenSymbolBlackList: [],
  },
  884: {
    name: "884",
    color: "#3099f2",
    chainId: 884,
    rpcUrl: "http://localhost:8547",
    blockTime: 5000,
    iconUrl: "./noChain.png",
    blockDelay: 15,
    symbol: "",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
    lqMintTokenSymbolBlackList: [],
  },
  localhost: {
    name: "--",
    color: "#666666",
    chainId: 31337,
    rpcUrl: "http://localhost:8545",
    blockTime: 3000,
    iconUrl: "./defaultIcon.svg",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
    lqMintTokenSymbolBlackList: [],
  },
  bscTest: {
    name: "BSC Testnet",
    color: "#7057ff",
    chainId: 97,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    blockTime: 4000,
    iconUrl: "./bsc.png",
    blockDelay: 80,
    symbol: "BNB",
    blockExplorerUrl: "https://testnet.bscscan.com",
    tokenSymbolList: ["CELR", "USDT", "WETH", "DOMI", "MCB", "PSP", "PCE", "MELI"],
    lqMintTokenSymbolBlackList: ["DOMI", "PSP", "MELI"],
  },
  ropsten: {
    name: "Ropsten Testnet",
    color: "#ff4a8d",
    chainId: 3,
    rpcUrl: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "https://ropsten.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH"],
    lqMintTokenSymbolBlackList: [],
  },
  goerli: {
    name: "Goerli Testnet",
    color: "#3099f2",
    chainId: 5,
    rpcUrl: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "https://goerli.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH", "TCELR", "USDC", "DOMI", "MCB", "cUSD", "PSP"],
    contractAddr: "0x358234B325EF9eA8115291A8b81b7d33A2Fa762D",
    lqMintTokenSymbolBlackList: ["DOMI", "cUSD", "PSP"],
  },
  mainnet: {
    name: "Ethereum Mainnet",
    color: "#29b6af",
    chainId: 1,
    rpcUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://etherscan.io",
    tokenSymbolList: [
      "USDC",
      "USDT",
      "WETH",
      "BUSD",
      "DODO",
      "LYRA",
      "DOMI",
      "MCB",
      "IMX",
      "oneDODO",
      "OLO",
      "BOBA",
      "XTK",
      "xXTKa",
      "PSP",
      "FRAX",
      "FXS",
      "CVP",
      "CEC",
      "Metis",
      "WXT",
      "PEOPLE",
      "STND",
      "SOS",
      "WBTC",
      "CELR",
      "WOO",
      "DF",
      "USX",
      "AMY",
      "DAI",
      "GHX",
      "KROM",
      "PERP",
      "TCR",
      "SAFLE",
      "PKEX",
      "CONV",
      "PERL",
      "TSD",
      "AAVE",
      "CRV",
      "SYS",
      "AVG",
      "BMI",
      "AELIN",
      "JPEG",
      "CGG",
      "REVA",
      "MASK",
      "LUSD",
      "WAGMIV1",
    ],
    lqMintTokenSymbolBlackList: [
      "DOMI",
      "oneDODO",
      "OLO",
      "PSP",
      "FRAX",
      "FXS",
      "WXT",
      "PEOPLE",
      "SOS",
      "WBTC",
      "CELR",
      "AMY",
      "DAI",
      "GHX",
      "SAFLE",
      "PKEX",
      "CONV",
      "TSD",
      "AAVE",
      "CRV",
      "SYS",
      "AVG",
      "AELIN",
      "CGG",
      "REVA",
      "LUSD",
      "WAGMIV1",
    ],
  },
  bsc: {
    name: "BNB Chain",
    color: "#7057ff",
    chainId: 56,
    rpcUrl: "https://bsc-dataseed.binance.org",
    blockTime: 4000,
    iconUrl: "./bnbchain.png",
    blockDelay: 8,
    symbol: "BNB",
    blockExplorerUrl: "https://bscscan.com",
    tokenSymbolList: [
      "USDC",
      "USDT",
      "WETH",
      "BUSD",
      "DODO",
      "DOMI",
      "MCB",
      "cUSD",
      "oneDODO",
      "OLO",
      "PSP",
      "FRAX",
      "FXS",
      "CVP",
      "CEC",
      "ATL",
      "PEOPLE",
      "SOS",
      "BNB",
      "WOO",
      "DF",
      "USX",
      "GHX",
      "PERP",
      "SAFLE",
      "PERL",
      "WBNB",
      "TSD",
      "SYS",
      "AVG",
      "BMI",
      "JPEG",
      "REVA",
      "MASK",
      "LUSD",
    ],
    lqMintTokenSymbolBlackList: [
      "cUSD",
      "oneDODO",
      "OLO",
      "PSP",
      "FRAX",
      "FXS",
      "ATL",
      "PEOPLE",
      "SOS",
      "BNB",
      "GHX",
      "SAFLE",
      "WBNB",
      "TSD",
      "SYS",
      "AVG",
      "REVA",
      "LUSD",
    ],
  },

  polygon: {
    name: "Polygon (Matic)",
    color: "#3099f2",
    chainId: 137,
    rpcUrl: "https://polygon-rpc.com",
    blockTime: 4000,
    iconUrl: "./polygon.png",
    blockDelay: 80,
    symbol: "MATIC",
    blockExplorerUrl: "https://polygonscan.com",
    tokenSymbolList: [
      "USDC",
      "USDT",
      "WETH",
      "IMX",
      "FRAX",
      "FXS",
      "WXT",
      "ATL",
      "MAI",
      "WOO",
      "SAFLE",
      "MATIC",
      "KROM",
      "BMI",
      "REVA",
      "JONES",
    ],
    lqMintTokenSymbolBlackList: ["FRAX", "FXS", "WXT", "ATL", "MAI", "SAFLE", "MATIC", "REVA", "JONES"],
  },
  arbitrum: {
    name: "Arbitrum",
    color: "#ff4a8d",
    chainId: 42161,
    rpcUrl: "https://arb1.arbitrum.io/rpc",
    blockTime: 4000,
    iconUrl: "./arbitrum.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://arbiscan.io",
    tokenSymbolList: [
      "USDC",
      "USDT",
      "WETH",
      "DODO",
      "MCB",
      "IMX",
      "XTK",
      "xXTKa",
      "FRAX",
      "FXS",
      "WOO",
      "DF",
      "USX",
      "AMY",
      "KROM",
      "PERP",
      "TCR",
      "REVA",
      "JONES",
    ],
    lqMintTokenSymbolBlackList: ["FRAX", "FXS", "AMY", "REVA", "JONES"],
  },
  Avalanche: {
    name: "Avalanche",
    color: "#3099f2",
    chainId: 43114,
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockTime: 5000,
    iconUrl: "./avalanche.png",
    blockDelay: 15,
    symbol: "AVAX",
    blockExplorerUrl: "https://snowtrace.io",
    tokenSymbolList: [
      "USDC",
      "USDT",
      "WETH",
      "IMX",
      "FRAX",
      "FXS",
      "WXT",
      "PEOPLE",
      "MAI",
      "SOS",
      "AVAX",
      "WOO",
      "ATL",
      "TSD",
      "DOMI",
      "CGG",
      "REVA",
      "LUSD",
      "JONES",
    ],
    lqMintTokenSymbolBlackList: [
      "FRAX",
      "FXS",
      "WXT",
      "PEOPLE",
      "MAI",
      "SOS",
      "AVAX",
      "ATL",
      "TSD",
      "CGG",
      "REVA",
      "LUSD",
      "JONES",
    ],
  },
  Optimism: {
    name: "Optimism",
    color: "#3099f2",
    chainId: 10,
    rpcUrl: "https://mainnet.optimism.io",
    blockTime: 5000,
    iconUrl: "./optimism.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://optimistic.etherscan.io",
    tokenSymbolList: ["USDC", "USDT", "WETH", "LYRA", "DF", "USX", "KROM", "PERP", "FRAX", "FXS", "AELIN", "REVA"],
    lqMintTokenSymbolBlackList: ["FRAX", "FXS", "AELIN", "REVA"],
  },
  OptimisticKovan: {
    name: "Optimistic Kovan",
    color: "#3099f2",
    chainId: 69,
    rpcUrl: "https://kovan.optimism.io",
    blockTime: 5000,
    iconUrl: "./optimism.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://kovan-optimistic.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH"],
    lqMintTokenSymbolBlackList: [],
  },
  Fantom: {
    name: "Fantom",
    color: "#3099f2",
    chainId: 250,
    rpcUrl: "https://rpc.ftm.tools",
    blockTime: 5000,
    iconUrl: "./fantom.png",
    blockDelay: 6,
    symbol: "FTM",
    blockExplorerUrl: "https://ftmscan.com",
    tokenSymbolList: ["USDC", "USDT", "WETH", "FRAX", "FXS", "PEOPLE", "SOS", "WOO", "FTM", "REVA", "IMX", "LUSD"],
    lqMintTokenSymbolBlackList: ["FRAX", "FXS", "PEOPLE", "SOS", "FTM", "REVA", "LUSD"],
  },
  FantomTestnet: {
    name: "Fantom Testnet",
    color: "#3099f2",
    chainId: 4002,
    rpcUrl: "https://rpc.testnet.fantom.network",
    blockTime: 5000,
    iconUrl: "./fantom.png",
    blockDelay: 6,
    symbol: "FTM",
    blockExplorerUrl: "https://testnet.ftmscan.com",
    tokenSymbolList: ["CELR", "USDT", "TCELR"],
    lqMintTokenSymbolBlackList: [],
  },

  Harmony: {
    name: "Harmony",
    color: "#3099f2",
    chainId: 1666600000,
    rpcUrl: "https://api.harmony.one",
    blockTime: 5000,
    iconUrl: "./harmony.png",
    blockDelay: 20,
    symbol: "ONE",
    blockExplorerUrl: "https://explorer.harmony.one",
    tokenSymbolList: ["USDC", "WETH", "FRAX", "FXS", "PEOPLE", "MAI", "SOS", "IMX", "REVA"],
    lqMintTokenSymbolBlackList: ["FRAX", "FXS", "PEOPLE", "MAI", "SOS", "REVA"],
  },

  Moonriver: {
    name: "Moonriver",
    color: "#3099f2",
    chainId: 1285,
    rpcUrl: "https://rpc.moonriver.moonbeam.network",
    blockTime: 5000,
    iconUrl: "./moonriver.png",
    blockDelay: 20,
    symbol: "MOVR",
    blockExplorerUrl: "https://moonriver.moonscan.io",
    tokenSymbolList: ["SOS", "FRAX", "FXS", "PEOPLE", "USDC", "USDT", "WETH", "IMX", "MAI", "ZLK", "DODO"],
    lqMintTokenSymbolBlackList: ["SOS", "FRAX", "FXS", "PEOPLE", "USDC", "USDT", "WETH", "MAI", "DODO"],
  },

  BoBa: {
    name: "Boba Network",
    color: "#3099f2",
    chainId: 288,
    rpcUrl: "https://mainnet.boba.network",
    blockTime: 10000,
    iconUrl: "./boba.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://blockexplorer.boba.network",
    tokenSymbolList: ["USDC", "OLO", "BOBA", "FRAX", "FXS", "WAGMIV1"],
    lqMintTokenSymbolBlackList: ["OLO", "FRAX", "FXS", "WAGMIV1"],
  },

  CeloTestnet: {
    name: "Celo Testnet",
    color: "#3099f2",
    chainId: 44787,
    rpcUrl: "https://alfajores-forno.celo-testnet.org",
    blockTime: 10000,
    iconUrl: "./celo.png",
    blockDelay: 8,
    symbol: "CELO",
    blockExplorerUrl: "https://alfajores-blockscout.celo-testnet.org",
    tokenSymbolList: ["USDC", "cUSD"],
    lqMintTokenSymbolBlackList: ["USDC", "cUSD"],
  },

  Celo: {
    name: "Celo",
    color: "#3099f2",
    chainId: 42220,
    rpcUrl: "https://forno.celo.org",
    blockTime: 10000,
    iconUrl: "./celo.png",
    blockDelay: 8,
    symbol: "CELO",
    blockExplorerUrl: "https://explorer.celo.org",
    tokenSymbolList: ["USDC", "USDT", "cUSD", "WETH", "PEOPLE", "SOS"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "cUSD", "WETH", "PEOPLE", "SOS"],
  },

  OKExChainTestnet: {
    name: "OKExChain Testnet",
    color: "#3099f2",
    chainId: 65,
    rpcUrl: "https://exchaintestrpc.okex.org",
    blockTime: 10000,
    iconUrl: "./OKchain.png",
    blockDelay: 8,
    symbol: "PCE",
    blockExplorerUrl: "https://explorer.celo.org",
    tokenSymbolList: ["PCE", "MELI"],
    lqMintTokenSymbolBlackList: ["MELI"],
  },

  Aurora: {
    name: "Aurora",
    color: "#3099f2",
    chainId: 1313161554,
    rpcUrl: "https://mainnet.aurora.dev",
    blockTime: 10000,
    iconUrl: "./Aurora.png",
    blockDelay: 8,
    symbol: "aETH",
    blockExplorerUrl: "https://explorer.mainnet.aurora.dev/",
    tokenSymbolList: ["USDC", "BNB", "AVAX", "FTM", "USDT", "FRAX", "FXS", "BUSD"],
    lqMintTokenSymbolBlackList: ["BNB", "AVAX", "FTM", "FRAX", "FXS", "BUSD"],
  },

  Metis: {
    name: "Metis",
    color: "#3099f2",
    chainId: 1088,
    rpcUrl: "https://andromeda.metis.io/?owner=1088",
    blockTime: 10000,
    iconUrl: "./metis_chain.png",
    blockDelay: 8,
    symbol: "Metis",
    blockExplorerUrl: "https://andromeda-explorer.metis.io/",
    tokenSymbolList: ["Metis", "STND", "PEOPLE", "WBTC", "CELR", "SOS"],
    lqMintTokenSymbolBlackList: ["PEOPLE", "WBTC", "CELR", "SOS"],
  },

  OKExChain: {
    name: "OKExChain Mainnet",
    color: "#3099f2",
    chainId: 66,
    rpcUrl: "https://exchainrpc.okex.org",
    blockTime: 10000,
    iconUrl: "./oec.png",
    blockDelay: 8,
    symbol: "OKT",
    blockExplorerUrl: "https://www.oklink.com/en/oec",
    tokenSymbolList: ["PEOPLE", "SOS", "MARK", "PLATO"],
    lqMintTokenSymbolBlackList: ["PEOPLE", "SOS", "MARK", "PLATO"],
  },

  xDai: {
    name: "Gnosis Chain",
    color: "#3099f2",
    chainId: 100,
    rpcUrl: "https://rpc.xdaichain.com",
    blockTime: 10000,
    iconUrl: "./gnosis.png",
    blockDelay: 8,
    symbol: "xDai",
    blockExplorerUrl: "https://blockscout.com/xdai/mainnet",
    tokenSymbolList: ["PEOPLE", "SOS"],
    lqMintTokenSymbolBlackList: ["PEOPLE", "SOS"],
  },

  Heco: {
    name: "Heco",
    color: "#3099f2",
    chainId: 128,
    rpcUrl: "https://http-mainnet.hecochain.com",
    blockTime: 10000,
    iconUrl: "./heco.png",
    blockDelay: 8,
    symbol: "HT",
    blockExplorerUrl: "https://hecoinfo.com",
    tokenSymbolList: ["PEOPLE", "SOS", "MARK", "PLATO"],
    lqMintTokenSymbolBlackList: ["PEOPLE", "SOS", "MARK", "PLATO"],
  },

  Oasis: {
    name: "Oasis Emerald",
    color: "#3099f2",
    chainId: 42262,
    rpcUrl: "https://emerald.oasis.dev",
    blockTime: 10000,
    iconUrl: "./oasis.png",
    blockDelay: 8,
    symbol: "ROSE",
    blockExplorerUrl: "https://www.oasisscan.com",
    tokenSymbolList: ["USDC", "USDT", "WETH", "BNB", "AVAX", "FTM", "AMY", "DAI", "CELR"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "WETH", "BNB", "AVAX", "FTM", "AMY", "DAI", "CELR"],
  },

  Moonbeam: {
    name: "Moonbeam",
    color: "#3099f2",
    chainId: 1284,
    rpcUrl: "https://rpc.api.moonbeam.network",
    blockTime: 5000,
    iconUrl: "./moonbeam.png",
    blockDelay: 20,
    symbol: "GLMR",
    blockExplorerUrl: "https://moonbeam.moonscan.io",
    tokenSymbolList: ["USDC", "USDT", "CELR", "PEOPLE", "FRAX", "FXS", "CONV", "ZLK", "WETH", "WBTC", "BUSD"],
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "CELR", "PEOPLE", "FRAX", "FXS", "CONV", "WETH", "WBTC", "BUSD"],
  },

  Astar: {
    name: "Astar",
    color: "#3099f2",
    chainId: 592,
    rpcUrl: "https://rpc.astar.network:8545",
    blockTime: 5000,
    iconUrl: "./astar.png",
    blockDelay: 20,
    symbol: "ASTR",
    blockExplorerUrl: "https://blockscout.com/astar",
    tokenSymbolList: ["WETH", "USDT", "USDC", "DAI", "BNB", "BUSD", "PKEX", "WBTC", "SDN", "MATIC", "AAVE", "CRV"],
    lqMintTokenSymbolBlackList: ["WETH", "USDT", "USDC", "DAI", "BNB", "BUSD", "WBTC", "SDN", "MATIC", "AAVE", "CRV"],
  },
  Syscoin: {
    name: "Syscoin",
    color: "#3099f2",
    chainId: 57,
    rpcUrl: "https://rpc.syscoin.org",
    blockTime: 5000,
    iconUrl: "./syscoin.png",
    blockDelay: 20,
    symbol: "SYS",
    blockExplorerUrl: "https://explorer.syscoin.org",
    tokenSymbolList: ["USDT", "USDC", "WETH", "WBTC", "BNB", "SYS", "DAI"],
    lqMintTokenSymbolBlackList: ["USDT", "USDC", "WETH", "WBTC", "BNB", "SYS", "DAI"],
  },
  Shiden: {
    name: "Shiden",
    color: "#3099f2",
    chainId: 336,
    rpcUrl: "https://rpc.shiden.astar.network:8545",
    blockTime: 5000,
    iconUrl: "./shiden.png",
    blockDelay: 20,
    symbol: "SDN",
    blockExplorerUrl: "https://shiden.subscan.io",
    tokenSymbolList: ["SDN", "PKEX"],
    lqMintTokenSymbolBlackList: ["SDN"],
  },
  Milkomeda: {
    name: "Milkomeda Cardano",
    color: "#3099f2",
    chainId: 2001,
    rpcUrl: "https://rpc-mainnet-cardano-evm.c1.milkomeda.com",
    blockTime: 5000,
    iconUrl: "./milkomeda.png",
    blockDelay: 20,
    symbol: "ADA",
    blockExplorerUrl: "https://explorer-mainnet-cardano-evm.c1.milkomeda.com",
    tokenSymbolList: ["PKEX", "USDT", "USDC", "WETH", "WBTC", "DAI", "BNB", "BUSD", "AVAX", "FTM"],
    lqMintTokenSymbolBlackList: ["USDT", "USDC", "WETH", "WBTC", "DAI", "BNB", "BUSD", "AVAX", "FTM"],
  },

  Evmos: {
    name: "Evmos",
    color: "#3099f2",
    chainId: 9001,
    rpcUrl: "https://eth.bd.evmos.org:8545",
    blockTime: 5000,
    iconUrl: "./evmos.png",
    blockDelay: 20,
    symbol: "EVMOS",
    blockExplorerUrl: "https://evm.evmos.org/",
    tokenSymbolList: ["USDT", "USDC", "DAI", "WETH", "WBTC", "CELR"],
    lqMintTokenSymbolBlackList: ["USDT", "USDC", "DAI", "WETH", "WBTC", "CELR"],
  },
};
