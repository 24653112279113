import { useState, useEffect } from "react";
import { Modal, Button, Tooltip } from "antd";
import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { useAsync, useToggle } from "react-use";
import { ethers } from "ethers";
import { isMobile } from "react-device-detect";
import { BigNumber } from "@ethersproject/bignumber";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { convertUSD, formatDecimal, formatBalance, formatDecimalWithSeparation } from "celer-web-utils/lib/format";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import selectChainDownPng from "../../assets/images/selectChainDown.png";
import selectChainUpPng from "../../assets/images/selectChainUp.png";
import warningImg from "../../assets/images/warning.png";
import loadingImg from "../../assets/images/loading.png";
import successImg from "../../assets/images/success.png";
import getTokenNameByTokenSymbol from "../../helpers/getTokenNameBySymbolAndChainId";
import {
  delegateSplitThousandSeparator,
  formatCelrWithoutSymbol,
  formatDecimal as oldFormatDecimal,
} from "../../helpers/format";
import { setRefreshData } from "../../redux/transferSlice";
import { estimateWithdrawAmt, withdrawLiquidity } from "../../redux/gateway";
import { getNetworkById } from "../../constants/network";
import {
  WithdrawLiquidityRequest,
  EstimateWithdrawAmtRequest,
  EstimateWithdrawAmtResponse,
  WithdrawInfo,
  WithdrawMethodType,
  EstimateWithdrawAmt,
  GetTokenUsdPriceRequest,
  Chain,
  TokenInfo,
  ClaimStatus,
  SignAgainRequest,
  SignAgainType,
} from "../../proto/gateway/gateway_pb";

import {
  WithdrawReq as WithdrawReqProto,
  WithdrawLq as WithdrawLqProto,
  WithdrawType,
} from "../../proto/sgn/cbridge/v1/tx_pb";
import {
  CBridgeFeeShareInfo,
  queryLiquidityStatus,
  LPType,
  LPHistoryStatus,
  WithdrawDetail,
  getTokenUsdPrice,
  signAgain,
} from "../../api";
import useGasFee from "../../hooks/useGasFee";
import getTokenInfoByChainIdAndTokenSymbol from "../../utils/getTokenInfoByChainIdAndTokenSymbol";
import { LPHistory } from "../../constants/claimType";
import { storageConstants } from "../../constants/const";
import { formatBlockExplorerUrlWithTxHash } from "../../utils/formatUrl";
import "./index.less";

/* eslint-disable no-debugger */
/* eslint-disable */
interface IProps {
  visible: boolean;
  onClose: () => void;
  feeShareList: Array<CBridgeFeeShareInfo>;
  backToFeeShareModal: () => void;
}

interface SelectedTokenInfo {
  amount: BigNumber;
  amountUsd?: number;
  singleChainId?: string;
  token: TokenInfo.AsObject;
  tokenSpecialName?: string;
}

enum Status {
  INIT,
  CLAIMING,
  CONFIRM_CLAIM,
  CACHE_CONFIRM_CLAIM,
  SUCCESS,
  SWITCH_CHAIN,
}

const getSymbolAndChainId = (denom: string) => {
  const symbol = (denom.match(/CBF-(\S*)(\/)/) || [])[1];
  const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
  return {
    symbol,
    singleChainId: chainId,
  };
};

const SingleChainWithdrawModal = (props: IProps) => {
  const { visible, onClose, feeShareList } = props;
  const {
    contracts: { Bridge },
    transactor,
  } = useContractsContext();
  const { chainId, address, signer } = useWeb3Context();
  const { configs } = useAppSelector(state => state.globalInfo);
  const dispatch = useAppDispatch();
  const { chainsList, chainTokenMap } = configs;
  const targetChainId = Number(process.env.REACT_APP_NETWORK_ID);
  let initStatus = Status.INIT;

  const [singleChainStatus, setSingleChainStatus] = useState<Status>(initStatus);
  const [chainList, setChainList] = useState<Array<CBridgeFeeShareInfo>>([]);
  const [tokenList, setTokenList] = useState<Array<SelectedTokenInfo>>([]);
  const [selectedChainInfo, setSelectedChainInfo] = useState<Chain.AsObject>();
  const [selectedTokenInfo, setSelectedTokenInfo] = useState<SelectedTokenInfo>();
  const [slippage, setSlippage] = useState<number>(0);
  const [fee, setFee] = useState<string>("0");
  const [getestimateEnd, setGetestimateEnd] = useState(false);
  const [receiveAmounts, setReceiveAmounts] = useState<string>("0");
  const [chainListVisible, toggleChainListVisible] = useToggle(false);
  const [tokenListVisible, toggleTokenListVisible] = useToggle(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [viewInExploreLink, setViewInExploreLink] = useState<string>("");
  const [withdrawDetail, setWithdrawDetail] = useState<WithdrawDetail>();
  const [estimateResponse, setEstimateResponse] = useState<EstimateWithdrawAmtResponse>();
  const [seqNum, setSeqNum] = useState<string>("");
  const [priceMap, setPriceMap] = useState(new Map());
  const { tokenGas, usdGas, setGasChainId } = useGasFee();

  let removeStatusInterval;

  useEffect(() => {
    const tokens: Array<string> = [];
    feeShareList.forEach(item => {
      const { symbol } = getSymbolAndChainId(item.denom);
      tokens.push(symbol);
    });
    console.log(tokens);
    const Request = new GetTokenUsdPriceRequest();
    Request.setTokenSymbolsList(tokens);
    getTokenUsdPrice(Request)
      .then(priceMap => {
        const map = new Map(priceMap);
        setPriceMap(map);
      })
      .catch(e => {
        console.log(e);
      });
  }, [feeShareList]);

  useAsync(async () => {}, [visible]);

  const getChainInfoByChainId = paramChainId => {
    return chainsList?.find(item => item.id === Number(paramChainId));
  };

  useEffect(() => {
    const totalChainList: CBridgeFeeShareInfo[] = [];
    const chainListMap = {};
    feeShareList.forEach((item, i) => {
      const { singleChainId } = getSymbolAndChainId(item.denom);
      if (!(chainListMap[singleChainId] && chainListMap[singleChainId] === 1)) {
        chainListMap[singleChainId] = 1;
        totalChainList.push(item);
      }
      setChainList(totalChainList);
    });
  }, [feeShareList]);

  useEffect(() => {
    const totalTokenList: SelectedTokenInfo[] = [];
    if (!selectedChainInfo) {
      setTokenList(totalTokenList);
      return;
    }
    /**
     * 应该包括amount信息和tokenInfo
     * tokenInfo中需要替换name
     */
    feeShareList.forEach((item, i) => {
      const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
      if (selectedChainInfo && `${selectedChainInfo.id}` === singleChainId) {
        const amountBigNumber = BigNumber.from(item.amount);
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
        const tokenName = getTokenNameByTokenSymbol(tokenInfo?.token?.symbol || "", Number(singleChainId));
        const usdPrice = priceMap.get(symbol) || 0;
        const amountUsd = Number(formatDecimal(item.amount, 6, tokenInfo?.token?.decimal)) * Number(usdPrice) || 0;
        totalTokenList.push({
          amount: amountBigNumber,
          amountUsd,
          token: tokenInfo,
          tokenSpecialName: tokenName,
        });
      }
      setTokenList(totalTokenList);
    });

    if (selectedTokenInfo) {
      const list = totalTokenList.filter(item => {
        return selectedTokenInfo?.token.token?.symbol === item?.token.token?.symbol;
      });
      if (list.length == 0) {
        setSelectedTokenInfo(undefined);
      } else {
        getestimate();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainInfo]);

  useEffect(() => {
    if (selectedTokenInfo) {
      getestimate();
    }
  }, [selectedTokenInfo]);

  const getestimate = async () => {
    setGetestimateEnd(false);
    setReceiveAmounts("");
    const src_withdrawsList: WithdrawInfo[] = [];
    console.log(feeShareList);
    let origiAmount = 0;
    feeShareList.map(item => {
      const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
      const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
      const chainInfo = getChainInfoByChainId(singleChainId);
      if (tokenInfo?.token?.symbol === selectedTokenInfo?.token.token?.symbol) {
        // const amountBigNumber = BigNumber.from(item.amount);
        const amountBigNumber = delegateSplitThousandSeparator(
          formatCelrWithoutSymbol(item.amount, 6, tokenInfo?.token?.decimal),
        );
        origiAmount = origiAmount + amountBigNumber;
        const infoJson = new WithdrawInfo();
        infoJson.setChainId(chainInfo?.id ?? 0);
        infoJson.setAmount(item.amount);
        infoJson.setSlippageTolerance(5000);
        src_withdrawsList.push(infoJson);
      }
    });
    const req = new EstimateWithdrawAmtRequest();
    req.setSrcWithdrawsList(src_withdrawsList);
    req.setDstChainId(selectedChainInfo?.id ?? 0);
    req.setTokenSymbol(selectedTokenInfo?.token.token?.symbol ?? "");
    req.setUsrAddr(address);
    console.log("estimateReq:", req);
    const res: EstimateWithdrawAmtResponse = await estimateWithdrawAmt(req);
    const resObject = res.toObject();
    console.log(resObject);
    if (resObject.err && resObject.err !== undefined) {
      console.log(resObject.err);
      return;
    }
    setEstimateResponse(res);
    if (JSON.stringify(res.getReqAmtMap()) !== "{}") {
      let totalReceiveAmounts = BigNumber.from("0");
      let totalFee = BigNumber.from("0");
      const resMap = res.getReqAmtMap();
      resMap.forEach((entry: EstimateWithdrawAmt, key: number) => {
        const eqValueTokenAmtBigNum = BigNumber.from(entry.getEqValueTokenAmt());
        const feeBigNum = BigNumber.from(entry.getBaseFee() !== "" ? entry.getBaseFee() : "0").add(
          BigNumber.from(entry.getPercFee() !== "" ? entry.getPercFee() : "0"),
        );
        const targetReceiveAmounts = eqValueTokenAmtBigNum.sub(feeBigNum);
        totalReceiveAmounts = totalReceiveAmounts.add(targetReceiveAmounts);
        totalFee = totalFee.add(feeBigNum);
      });
      setReceiveAmounts(totalReceiveAmounts.toString());
      setFee(totalFee.toString());
      setGetestimateEnd(true);
      const tempReceive = delegateSplitThousandSeparator(
        oldFormatDecimal(
          totalReceiveAmounts.add(totalFee).toString(),
          getTokenInfoByChainIdAndTokenSymbol(
            chainTokenMap,
            Number(selectedChainInfo?.id),
            selectedTokenInfo?.token.token?.symbol,
          )?.token?.decimal,
        ),
      );

      const tempOrigin = origiAmount;
      const slippagenum = ((tempReceive - tempOrigin) / tempOrigin) * 100;
      // "0x783c8a438cb6111a66e680",
      // const tempOrigin = formatDecimal(
      //   origiAmount.toString(),
      //   getTokenInfoByChainIdAndTokenSymbol(Number(selectedChainInfo?.chainId), selectedTokenInfo?.symbol)?.token
      //     ?.decimal,
      // );
      // const slippagenum = ((Number(tempReceive) - Number(tempOrigin)) / Number(tempOrigin)) * 100;
      setSlippage(slippagenum);
    }
  };

  const onCloseMethod = () => {
    setSingleChainStatus(Status.INIT);

    setSelectedChainInfo(undefined);
    setSelectedTokenInfo(undefined);
    if (chainListVisible) {
      toggleChainListVisible();
    }
    if (tokenListVisible) {
      toggleTokenListVisible();
    }
    if (chainId !== targetChainId) {
      dispatch(setRefreshData());
    }
    onClose();
  };

  const claimMethod = async () => {
    if (selectedChainInfo?.id !== chainId) {
      setSingleChainStatus(Status.SWITCH_CHAIN);
    } else {
      claimSingleMethod();
    }
  };

  const switchChainMethod = async claimChainId => {
    const inId = Number(claimChainId);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${inId.toString(16)}` }],
      });
      setTimeout(() => {
        claimSingleMethod();
      }, 500);
    } catch (e) {
      console.log("switch fails");
      if (isMobile) {
        onClose();
      }
    }
  };

  const claimSingleMethod = async () => {
    if (!Bridge || !selectedTokenInfo || !signer) {
      return;
    }
    setLoading(true);
    // 计算reqid, sig
    const timestamp = Math.floor(Date.now() / 1000);
    const WithdrawLqProtoList: WithdrawLqProto[] = [];
    const resMap = estimateResponse?.getReqAmtMap();
    feeShareList.map(item => {
      const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
      const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
      const chainInfo = getChainInfoByChainId(singleChainId);
      if (tokenInfo?.token?.symbol === selectedTokenInfo?.token.token?.symbol) {
        const withdrawLqProto = new WithdrawLqProto();
        withdrawLqProto.setFromChainId(chainInfo?.id ?? 0);
        withdrawLqProto.setTokenAddr(tokenInfo?.token?.address || "");
        withdrawLqProto.setRatio(100000000); //100000000  //此处不能传小数。
        resMap.forEach((entry: EstimateWithdrawAmt, key: number) => {
          if (key === chainInfo?.id) {
            withdrawLqProto.setMaxSlippage(entry.getMaxSlippage());
          }
        });
        WithdrawLqProtoList.push(withdrawLqProto);
      }
    });

    const withdrawReqProto = new WithdrawReqProto();
    withdrawReqProto.setWithdrawsList(WithdrawLqProtoList);
    withdrawReqProto.setExitChainId(selectedChainInfo?.id ?? 0);
    withdrawReqProto.setReqId(timestamp);
    withdrawReqProto.setWithdrawType(WithdrawType.WITHDRAW_TYPE_CLAIM_FEE_SHARE);

    console.log(withdrawReqProto.toObject());
    let sig;
    try {
      sig = await signer.signMessage(ethers.utils.arrayify(ethers.utils.keccak256(withdrawReqProto.serializeBinary())));
      if (sig) {
        setSingleChainStatus(Status.CLAIMING);
      }
    } catch (error) {
      setLoading(false);
      return;
    }
    const bytes = ethers.utils.arrayify(sig);
    const req = new WithdrawLiquidityRequest();
    req.setWithdrawReq(withdrawReqProto.serializeBinary());
    req.setSig(bytes);
    req.setEstimatedReceivedAmt(receiveAmounts);
    req.setMethodType(WithdrawMethodType.WD_METHOD_TYPE_AGGREGATE_STAKING_CLAIM);

    const withdrawResult = await withdrawLiquidity(req);

    const seq_num = withdrawResult.getSeqNum().toString() || "";
    setSeqNum(seq_num);

    removeStatusInterval = setInterval(async () => {
      const res = await queryLiquidityStatus({
        seq_num,
        lp_addr: address,
        chain_id: selectedChainInfo?.id,
        type: LPType.LP_TYPE_REMOVE,
      });
      if (res?.status) {
        const status = res.status;
        console.log(LPHistoryStatus[LPHistoryStatus.LP_WAITING_FOR_LP]);
        if (status === LPHistoryStatus.LP_FAILED) {
          setLoading(false);
          clearInterval(removeStatusInterval);
        } else if (status === LPHistoryStatus.LP_WAITING_FOR_LP) {
          setSingleChainStatus(Status.CONFIRM_CLAIM);
          const { wd_onchain, sorted_sigs, signers, powers } = res;
          setWithdrawDetail({
            _wdmsg: wd_onchain,
            _sigs: sorted_sigs,
            _signers: signers,
            _powers: powers,
          });
          setLoading(false);
          clearInterval(removeStatusInterval);
        }
      } else {
        setLoading(false);
        clearInterval(removeStatusInterval);
      }
    }, 5000);
  };

  const isRightBridge = () => {
    const bridgetArrd = Bridge?.address;
    const targetBridgeVaultArrd = chainsList?.find(item => item.id === Number(selectedChainInfo?.id))?.contractAddr;
    return bridgetArrd === targetBridgeVaultArrd;
  };

  const signAgainMethod = async ({ chainId, seqNum, addr }) => {
    const signAgainRequest = new SignAgainRequest();
    signAgainRequest.setType(SignAgainType.SAT_LIQUIDITY);
    signAgainRequest.setUsrAddr(addr);
    signAgainRequest.setChainId(chainId);
    signAgainRequest.setSeqNum(seqNum);
    return signAgain(signAgainRequest);
  };

  const afterSignAgainMthod = async () => {
    if (!Bridge || !transactor || !selectedTokenInfo || !selectedChainInfo) {
      return;
    }
    const res = await queryLiquidityStatus({
      seq_num: seqNum,
      lp_addr: address,
      chain_id: selectedChainInfo?.id,
      type: LPType.LP_TYPE_REMOVE,
    });
    const wdmsg = base64.decode(res.wd_onchain);
    const signers = res.signers.map(item => {
      const decodeSigners = base64.decode(item);
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    const sigs = res.sorted_sigs.map(item => {
      return base64.decode(item);
    });
    const powers = res.powers.map(item => {
      const decodeNum = base64.decode(item);
      return BigNumber.from(decodeNum);
    });
    let gasLimit = await Bridge.estimateGas.withdraw(wdmsg, sigs, signers, powers);
    gasLimit = gasLimit.mul(13).div(10);
    const resultTx = await transactor(Bridge.withdraw(wdmsg, sigs, signers, powers, { gasLimit: gasLimit }));
    if (resultTx) {
      const newtxStr = JSON.stringify(resultTx);
      const newtx = JSON.parse(newtxStr);
      if (resultTx instanceof Error || newtx.code) {
        console.log("get before error", resultTx);
        setLoading(false);
      } else {
        const blockTxLink = formatBlockExplorerUrlWithTxHash({
          chainId: selectedChainInfo.id,
          txHash: resultTx.hash,
        });
        const newLPJson: LPHistory = {
          chain: selectedChainInfo,
          token: selectedTokenInfo.token,
          amount: selectedTokenInfo.amount.toString(),
          ts: new Date().getTime(),
          blockTxLink,
          status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
          methodType: WithdrawMethodType.WD_METHOD_TYPE_AGGREGATE_STAKING_CLAIM,
          seqNum: Number(seqNum),
          nonce: resultTx.nonce,
          isLocal: true,
          updateTime: new Date().getTime(),
          txIsFailed: false,
          withdrawId: "",
        };
        const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
        let localLpList: LPHistory[] = [];
        if (localLpListStr) {
          localLpList = JSON.parse(localLpListStr)[address] || [];
        }
        localLpList.unshift(newLPJson);
        const newJson = { [address]: localLpList };
        localStorage.setItem(storageConstants.KEY_LP_LIST, JSON.stringify(newJson));
        setViewInExploreLink(blockTxLink);
        setSingleChainStatus(Status.SUCCESS);
        setLoading(false);
      }
    }
  };

  const confirmClaimMethod = async () => {
    if (!Bridge || !withdrawDetail || !transactor || !selectedTokenInfo || !selectedChainInfo) {
      return;
    }
    if (!isRightBridge()) {
      console.log("error: wrong Bridge addr");
      return;
    }
    setLoading(true);
    const { _wdmsg, _signers, _sigs, _powers } = withdrawDetail;
    const wdmsg = base64.decode(_wdmsg);
    const signers = _signers.map(item => {
      const decodeSigners = base64.decode(item);
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    const sigs = _sigs.map(item => {
      return base64.decode(item);
    });
    const powers = _powers.map(item => {
      const decodeNum = base64.decode(item);
      return BigNumber.from(decodeNum);
    });
    try {
      setLoading(true);
      try {
        let gasLimit = await Bridge.estimateGas.withdraw(wdmsg, sigs, signers, powers);
        gasLimit = gasLimit.mul(13).div(10);
        const resultTx = await transactor(Bridge.withdraw(wdmsg, sigs, signers, powers, { gasLimit: gasLimit }));
        if (resultTx) {
          const newtxStr = JSON.stringify(resultTx);
          const newtx = JSON.parse(newtxStr);
          if (resultTx instanceof Error || newtx.code) {
            console.log("get before error", resultTx);
            setLoading(false);
          } else {
            const blockTxLink = formatBlockExplorerUrlWithTxHash({
              chainId: selectedChainInfo.id,
              txHash: resultTx.hash,
            });
            const newLPJson: LPHistory = {
              chain: selectedChainInfo,
              token: selectedTokenInfo.token,
              amount: selectedTokenInfo.amount.toString(),
              ts: new Date().getTime(),
              blockTxLink,
              status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
              methodType: WithdrawMethodType.WD_METHOD_TYPE_AGGREGATE_STAKING_CLAIM,
              seqNum: Number(seqNum),
              nonce: resultTx.nonce,
              isLocal: true,
              updateTime: new Date().getTime(),
              txIsFailed: false,
              withdrawId: "",
            };
            const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
            let localLpList: LPHistory[] = [];
            if (localLpListStr) {
              localLpList = JSON.parse(localLpListStr)[address] || [];
            }
            localLpList.unshift(newLPJson);
            const newJson = { [address]: localLpList };
            localStorage.setItem(storageConstants.KEY_LP_LIST, JSON.stringify(newJson));
            setViewInExploreLink(blockTxLink);
            setSingleChainStatus(Status.SUCCESS);
            setLoading(false);
          }
        }
      } catch (e) {
        if (needToSignAgain(e)) {
          const signAgainRes = await signAgainMethod({
            addr: address,
            chainId: selectedChainInfo.id,
            seqNum,
          });
          console.log({ signAgainRes });
          return afterSignAgainMthod();
        } else {
          console.log("catch tx error", e);
          setSingleChainStatus(Status.CONFIRM_CLAIM);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
    } finally {
    }
  };

  const claimSuccessMethod = async () => {
    onCloseMethod();
  };

  const chainDom = () => {
    return (
      <div className="chain-list">
        {chainList.map(item => {
          const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
          const chainInfo = getChainInfoByChainId(singleChainId);
          return (
            <div
              className="item"
              key={`${singleChainId}-${symbol}`}
              onClick={() => {
                setSelectedChainInfo(chainInfo);
                setGasChainId(Number(singleChainId));
                toggleChainListVisible();
              }}
            >
              <div className="chain-info">
                <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                <span className="chain-info-name">{chainInfo?.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const tokensDom = () => {
    return (
      <div className="chain-list">
        {tokenList.map(item => {
          const { amount, amountUsd, singleChainId, token } = item;
          return (
            <div
              className="item"
              key={`${singleChainId}-${token.token?.symbol}`}
              onClick={() => {
                setSelectedTokenInfo({
                  amount,
                  amountUsd,
                  token,
                });
                toggleTokenListVisible();
              }}
            >
              <div className="chain-info">
                <img className="chain-info-icon" src={token.icon} alt="" />
                <span className="chain-info-name">{item.tokenSpecialName}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderNote = () => {
    if (selectedChainInfo && selectedTokenInfo) {
      const nativeToken = getNetworkById(selectedChainInfo?.id || 1);
      return (
        <div className="note">
          Note: You will need to pay
          <span className="note-price">
            {" "}
            {formatBalance(tokenGas, 6, "floor", ",", true)} {nativeToken.symbol} ({convertUSD(usdGas)}) on-chain gas
            costs{" "}
          </span>
          to claim this fee rewards.
        </div>
      );
    }
    return (
      <div className="note note-price">
        Note: Aggregating your rewards from multiple chains may incur additional slippage and fees.
      </div>
    );
  };

  const renderFeeAmount = () => {
    const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
      chainTokenMap,
      Number(selectedChainInfo?.id),
      selectedTokenInfo?.token.token?.symbol,
    );
    const amount = formatDecimalWithSeparation(fee, 8, tokenInfo?.token?.decimal, "floor", ",", true);
    return amount;
  };

  const renderTokenAmount = () => {
    const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
      chainTokenMap,
      Number(selectedChainInfo?.id),
      selectedTokenInfo?.token.token?.symbol,
    );
    const amount = formatDecimalWithSeparation(receiveAmounts, 6, tokenInfo?.token?.decimal, "floor", ",", true);
    return amount;
  };

  const renderUsdAmount = () => {
    const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
      chainTokenMap,
      Number(selectedChainInfo?.id),
      selectedTokenInfo?.token.token?.symbol,
    );
    const amount = formatDecimal(receiveAmounts, 6, tokenInfo?.token?.decimal);
    const usdPrice = priceMap.get(selectedTokenInfo?.token.token?.symbol) || 0;
    const amountUsd = Number(amount) * Number(usdPrice);
    return convertUSD(amountUsd);
  };

  const initContentDom = () => {
    return (
      <div className="single-chain">
        <div className="model-top-white">
          <div className="single-title">
            Aggregate your fee rewards from different chainsList and claim the rewards on a single chain.
          </div>
          <div className="btn-group-title">Please select the chain you want to receive the fee rewards</div>
          <div className="btn-group">
            <div
              className="select-group"
              onClick={() => {
                toggleChainListVisible();
                if (tokenListVisible) {
                  toggleTokenListVisible();
                }
              }}
            >
              <div className="btn-left">
                {selectedChainInfo && <img src={selectedChainInfo?.icon} alt="select chain" />}
                <span>{selectedChainInfo ? selectedChainInfo.name : "Select chain"}</span>
              </div>
              <img
                className="btn-right"
                src={chainListVisible ? selectChainUpPng : selectChainDownPng}
                alt="select chain"
              />
            </div>
            {chainListVisible && chainDom()}
          </div>
          <div className="btn-group-title">Please select the reward token you want to claim</div>
          <div className="btn-group">
            <div
              className="select-group"
              onClick={() => {
                if (selectedChainInfo) {
                  toggleTokenListVisible();
                }
              }}
            >
              <div className="btn-left">
                {selectedTokenInfo && <img src={selectedTokenInfo.token.icon} alt="select token" />}
                <span>{selectedTokenInfo ? selectedTokenInfo?.token.token?.symbol : "Select token"}</span>
              </div>
              <img
                className="btn-right"
                src={tokenListVisible ? selectChainUpPng : selectChainDownPng}
                alt="select chain"
              />
            </div>
            <div className="token-desc">
              <div className="slippage">
                Slippage:{" "}
                {selectedTokenInfo && getestimateEnd ? (
                  <span className={slippage >= 0 ? "value-green" : "value-red"}>
                    {slippage >= 0 ? "+" : "-"}
                    {Math.abs(slippage).toFixed(2)}%
                  </span>
                ) : (
                  "--"
                )}
              </div>
              <div className="fee">
                Fee:{" "}
                {selectedTokenInfo && getestimateEnd && BigNumber.from(fee).gt(0) ? (
                  <span>
                    {renderFeeAmount()} {selectedTokenInfo?.token.token?.symbol}
                  </span>
                ) : (
                  "--"
                )}
              </div>
            </div>
            {tokenListVisible && tokensDom()}
          </div>
        </div>

        <div className="model-bottom-gray">
          {selectedTokenInfo && (
            <div>
              <div className="dst-chain">
                Received Rewards on {selectedChainInfo?.name}
                <Tooltip
                  placement="right"
                  title={
                    <div className="toolsDetail" style={{ textAlign: "left" }}>
                      This is the estimated rewards you will receive on {selectedChainInfo?.name}. The actually received
                      amount may vary when this transaction is executed.
                    </div>
                  }
                  color="#FFFFFF"
                  overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
                >
                  <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6 }} />
                </Tooltip>
              </div>

              <div className="dst-token">
                {renderTokenAmount()} <span>{selectedTokenInfo?.token.token?.symbol}</span>
                <span>({renderUsdAmount()})</span>
              </div>
            </div>
          )}

          {renderNote()}

          <Button
            type="primary"
            block
            onClick={() => {
              claimMethod();
            }}
            className="claim-btn"
            disabled={!selectedTokenInfo}
          >
            Aggregate & Claim
          </Button>
        </div>
      </div>
    );
  };

  const claimingContentDom = () => {
    return (
      <div className="claiming-block">
        <div className="loading-block">
          <img className="loadingimg" src={loadingImg} alt="" />
        </div>
        <div className="desc">
          <div className="desc-info1">
            Your request to aggregate & claim rewards is being confirmed by Celer State Guardian Network (SGN), which
            might take a few minutes.
          </div>
        </div>
      </div>
    );
  };

  const confrimClaimContentDom = () => {
    const targetConfirmMethod = confirmClaimMethod;
    return (
      <div className="confirm-block">
        <div className="confirm-message">
          Please click the button to complete the rewards aggregation and claim process
        </div>
        <div className="btn-block">
          <Button type="text" block className="confirm-btn" loading={loading} onClick={targetConfirmMethod}>
            Confirm Aggregate & Claim Rewards
          </Button>
        </div>
      </div>
    );
  };

  const successContentDom = () => {
    const switchInfo = {
      chainName: selectedChainInfo?.name,
      chainId: selectedChainInfo?.id,
    };
    return (
      <div className="success-block">
        <div className="success-icon">
          <img src={successImg} alt="" />
        </div>
        <div className="title">
          <div className="main-title">Success</div>
          <div className="sub-title">
            Successfully aggregate & claim rewards to {switchInfo?.chainName}. Please wait a few minutes to receive the
            fund.
          </div>
        </div>
        <div className="view-in-explorer">
          <a href={viewInExploreLink} target="_blank" rel="noreferrer">
            View in Explorer
          </a>
        </div>
        <div className="btn-block">
          <Button type="text" block className="ok-btn" loading={loading} onClick={claimSuccessMethod}>
            OK
          </Button>
        </div>
      </div>
    );
  };

  const switchChainContentDom = () => {
    const switchInfo = {
      chainName: selectedChainInfo?.name,
      chainId: selectedChainInfo?.id,
    };
    return (
      <div className="switch-block">
        <div className="warning">
          <div className="img-block">
            <img src={warningImg} alt="" />
          </div>
          <div className="warning-message">
            Please switch to {switchInfo?.chainName} before claiming your cBridge fee reward.
          </div>
        </div>
        <div className="btn-block">
          <Button type="text" block className="ok-btn" onClick={() => switchChainMethod(switchInfo?.chainId)}>
            OK
          </Button>
        </div>
      </div>
    );
  };

  const modalTitleDom = () => {
    switch (singleChainStatus) {
      case Status.INIT:
      case Status.SUCCESS:
        return (
          <div className="model-title">
            <div className="back" onClick={props.backToFeeShareModal}>
              <LeftOutlined style={{ fontSize: 22, marginLeft: 6 }} />
            </div>
            <div className="modal-title-info">
              <div className="main-title">Claim Fee Rewards</div>
              <div className="desc">(pool-based bridge)</div>
            </div>
          </div>
        );
      case Status.CLAIMING:
        return "Waiting for SGN confirmations";
      case Status.CONFIRM_CLAIM:
      case Status.CACHE_CONFIRM_CLAIM:
        return "Aggregate & Claim Rewards";
      case Status.SWITCH_CHAIN:
        return " ";
      default:
        return null;
    }
  };

  const contentDom = () => {
    switch (singleChainStatus) {
      case Status.INIT:
        return initContentDom();
      case Status.CLAIMING:
        return claimingContentDom();
      case Status.CONFIRM_CLAIM:
        return confrimClaimContentDom();
      case Status.SWITCH_CHAIN:
        return switchChainContentDom();
      case Status.SUCCESS:
        return successContentDom();
    }
  };

  return (
    <Modal
      title={modalTitleDom()}
      className="single-chain-modal"
      width={401}
      visible={visible}
      maskClosable={false}
      onCancel={() => {
        onCloseMethod();
      }}
      footer={null}
      destroyOnClose
    >
      {contentDom()}
    </Modal>
  );
};

export const needToSignAgain = err => {
  const tag = JSON.stringify(err).includes("Mismatch current signers") || JSON.stringify(err).includes("quorum not reached");
  return tag;
};

export default SingleChainWithdrawModal;
