import { Button, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useToggle } from "react-use";
import "./IMFeeRewards.less";
import { formatUnits } from "@ethersproject/units";
import tooltipIcon from "../../assets/images/tooltipBold.png";
import { getIMFeeShareInfo, CBridgeFeeShareInfo, getTokenUsdPrice } from "../../api";
import IMFeeShareModal from "./IMFeeShareModal";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setRefreshData } from "../../redux/transferSlice";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { formatRemoveDecimals } from "../../helpers/format";
import getTokenInfoByChainIdAndTokenSymbol from "../../utils/getTokenInfoByChainIdAndTokenSymbol";
import { GetTokenUsdPriceRequest } from "../../proto/gateway/gateway_pb";
/* eslint-disable */
const minFeeShare = 0.000001;
const celerIMMoreLink = "https://im-docs.celer.network/";

const IMFeeRewards = () => {
  const { address, provider, chainId } = useWeb3Context();
  const { transferInfo } = useAppSelector(state => state);
  const { configs } = useAppSelector(state => state.globalInfo);
  const { refreshData, refreshMyDelegations } = transferInfo;
  const { chainTokenMap } = configs;
  const [canonicalModalVisible, toogleCanonicalModalVisible] = useToggle(false);
  const [imFeeShareList, setImFeeShareList] = useState<Array<CBridgeFeeShareInfo>>([]);
  const [imFeeSharetokenUsdMap, setImFeeSharetokenUsdMap] = useState(new Map<string, number>());

  const dispatch = useAppDispatch();

  const getSymbolAndChainId = (denom: string) => {
    const symbol = (denom.match(/MBF-(\S*)(\/)/) || [])[1];
    const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
    return {
      symbol,
      singleChainId: chainId,
    };
  };

  useEffect(() => {
    if (address && chainTokenMap) {
      getIMFeeShareInfo(address).then(res => {
        // getPegFeeShareInfo(address).then(res => {
        // filter fee share amount less than minFeeShare
        const result: Array<CBridgeFeeShareInfo> = [];
        for (let i = 0; i < res.length; i++) {
          res[i].amount = formatRemoveDecimals(res[i].amount);
          const { symbol, singleChainId } = getSymbolAndChainId(res[i].denom);
          const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
          const deciaml = tokenInfo?.token?.decimal || 18;
          const amount = formatUnits(res[i].amount, deciaml);
          if (Number(amount) >= minFeeShare) {
            result.push(res[i]);
          }
        }
        setImFeeShareList(result);
      });
    }
  }, [address, chainTokenMap, refreshMyDelegations, refreshData]);

  useEffect(() => {
    const tokens: Array<string> = [];
    imFeeShareList.forEach(item => {
      const { symbol } = getSymbolAndChainId(item.denom);
      tokens.push(symbol);
    });
    const Request = new GetTokenUsdPriceRequest();
    Request.setTokenSymbolsList(tokens);
    getTokenUsdPrice(Request)
      .then(res => {
        const priceMap = new Map(res);
        setImFeeSharetokenUsdMap(priceMap);
      })
      .catch(e => {
        console.log(e);
      });
  }, [imFeeShareList]);

  const onImClaimSuccess = () => {
    return getIMFeeShareInfo(address).then(res => {
      const result: Array<CBridgeFeeShareInfo> = [];
      for (let i = 0; i < res.length; i++) {
        res[i].amount = formatRemoveDecimals(res[i].amount);
        const { symbol, singleChainId } = getSymbolAndChainId(res[i].denom);
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
        const deciaml = tokenInfo?.token?.decimal || 18;
        const amount = formatUnits(res[i].amount, deciaml);
        if (Number(amount) >= minFeeShare) {
          result.push(res[i]);
        }
      }

      setImFeeShareList(result);
      return result;
    });
  };

  return (
    <>
      <div className="IM-fee-rewards">
        <div className="IM-fee-rewards-info">
          <div className="IM-fee-rewards-info-title">
            <div className="IM-fee-rewards-info-title-text">Celer Inter-chain Message (IM) Fee</div>
            <div className="IM-fee-rewards-info-title-icon">
              <Tooltip
                title={
                  <div>
                    Claim the fee rewards generated by Celer Inter-chain Message (Celer IM) framework.{" "}
                    <a href={celerIMMoreLink} target="_blank" rel="noreferrer">
                      Learn More
                    </a>{" "}
                    about Celer IM.
                  </div>
                }
                placement="topLeft"
                color="#fff"
                overlayInnerStyle={{ color: "#000", borderRadius: "8px", textAlign: "center", width: 290 }}
              >
                <img src={tooltipIcon} alt="tooltip" />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="IM-fee-rewards-btns">
          <div className="IM-fee-rewards-btn">
            <Button
              // style={imFeeShareList?.length === 0 ? { pointerEvents: "none" } : {}}
              type="text"
              className="btn-claim"
              onClick={() => {
                toogleCanonicalModalVisible();
              }}
              disabled={true}
              // disabled={imFeeShareList?.length === 0}
            >
              Claim Fee Rewards
            </Button>
          </div>
        </div>
      </div>
      {canonicalModalVisible ? (
        <IMFeeShareModal
          visible={canonicalModalVisible}
          onClose={() => {
            toogleCanonicalModalVisible();
            dispatch(setRefreshData());
          }}
          onClaimSuccess={onImClaimSuccess}
          feeShareList={imFeeShareList}
          feeSharetokenUsdMap={imFeeSharetokenUsdMap}
        />
      ) : null}
    </>
  );
};

export default IMFeeRewards;
