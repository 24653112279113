import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetTransferConfigsResponse } from "../proto/gateway/gateway_pb";

/* eslint-disable camelcase */

export interface IFarmingState {
  viewerContractAddress: string;
  stakingContractAddress: string;
  stakingRewardContractAddress: string;
  celerAddress: string;
  bridgeContractAddress: string;
  configs: GetTransferConfigsResponse.AsObject;
  faucetAddresses: string;
  unbondingBlockNum: number;
  pTContractAddr: string;
  oTContractAddr: string;
  flowTokenPathConfigs: Array<FlowTokenPathConfig>;
  selectedChainId: number;
}

interface FlowTokenPathConfig {
  TokenName: string;
  FullAddress: string;
  TokenAddress: string;
  StoragePath: string;
  BalancePath: string;
  ReceiverPath: string;
  Symbol: string;
}

interface globalType {
  viewerContractAddress: string;
  stakingContractAddress: string;
  stakingRewardContractAddress: string;
  celerAddress: string;
}

interface bridgeType {
  bridgeContractAddress: string;
}

const initialState: IFarmingState = {
  celerAddress: process.env.REACT_APP_CELER_ADDRESS || "",
  viewerContractAddress: "",
  stakingContractAddress: "",
  stakingRewardContractAddress: "",
  bridgeContractAddress: "",
  faucetAddresses: "0x50B96c4374EFeEA0C183D06679A14e951E33B4Dd",
  pTContractAddr: "",
  oTContractAddr: "",
  configs: new GetTransferConfigsResponse().toObject(),
  unbondingBlockNum: 0,
  flowTokenPathConfigs: [],
  selectedChainId: 0,
};

const globalInfoSlice = createSlice({
  name: "globalInfo",
  initialState,
  reducers: {
    save: (state, { payload }: PayloadAction<globalType>) => {
      const { viewerContractAddress, stakingContractAddress, stakingRewardContractAddress, celerAddress } = payload;
      state.viewerContractAddress = viewerContractAddress;
      state.stakingContractAddress = stakingContractAddress;
      state.stakingRewardContractAddress = stakingRewardContractAddress;
      state.celerAddress = celerAddress;
    },
    setBridgeContractAddress: (state, { payload }: PayloadAction<bridgeType>) => {
      const { bridgeContractAddress } = payload;
      state.bridgeContractAddress = bridgeContractAddress;
    },
    setConfigs: (state, { payload }: PayloadAction<GetTransferConfigsResponse.AsObject>) => {
      state.configs = payload;
    },
    setUnbondingBlockNum: (state, { payload }: PayloadAction<number>) => {
      state.unbondingBlockNum = payload;
    },
    setPTContractAddr: (state, { payload }: PayloadAction<string>) => {
      state.pTContractAddr = payload;
    },
    setOTContractAddr: (state, { payload }: PayloadAction<string>) => {
      state.oTContractAddr = payload;
    },
    setFlowTokenPathConfigs: (state, { payload }: PayloadAction<Array<FlowTokenPathConfig>>) => {
      state.flowTokenPathConfigs = payload;
    },
    setSelectedChainId: (state, { payload }: PayloadAction<number>) => {
      state.selectedChainId = payload;
    },
  },
});

export const {
  save,
  setBridgeContractAddress,
  setConfigs,
  setUnbondingBlockNum,
  setPTContractAddr,
  setOTContractAddr,
  setFlowTokenPathConfigs,
  setSelectedChainId,
} = globalInfoSlice.actions;

export default globalInfoSlice.reducer;
