import { useEffect, useState } from "react";
import { Modal, Button, Spin, Tooltip, message } from "antd";
import { LeftOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ethers } from "ethers";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { BytesLike } from "@ethersproject/bytes";
import { convertUSD, formatDecimal, formatBalance, formatDecimalWithSeparation } from "celer-web-utils/lib/format";
import { isMobile } from "react-device-detect";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useNonEVMContext, NonEVMMode, getNonEVMMode, isNonEVMChain } from "../../providers/NonEVMContextProvider";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setOTContractAddr, setSelectedChainId } from "../../redux/globalInfoSlice";
import {
  CBridgeFeeShareInfo,
  getTokenInfo,
  claimPegBridgeFeeMethod,
  getFeeClaimInfo,
  getWithdrawInfo,
  getChainSigners,
  signAgain,
} from "../../api";
import {
  withdrawFlowFeeReward,
  checkTokenReceivabilityForFlowAccount,
  setupTokenVaultForFlowAccount,
} from "../../redux/NonEVMAPIs/flowAPIs";

import {
  ClaimPegBridgeFeeRequest,
  GetTokenInfoRequest,
  Chain,
  ClaimStatus,
  WithdrawMethodType,
  SignAgainRequest,
  SignAgainType,
} from "../../proto/gateway/gateway_pb";
import { QueryFeeClaimInfoRequest, QueryWithdrawInfoRequest } from "../../proto/sgn/pegbridge/v1/query_pb";
import { QueryChainSignersRequest } from "../../proto/sgn/cbridge/v1/query_pb";
import { MsgClaimFee } from "../../proto/sgn/pegbridge/v1/tx_pb";

import "./canonicalFeeShareModal.less";
import Canonicalmg from "../../assets/images/canonical.png";
import warningImg from "../../assets/images/warning.png";
import loadingImg from "../../assets/images/loading.png";
import successImg from "../../assets/images/success.png";
import { formatCelrWithoutSymbol } from "../../helpers/format";
import getTokenNameByTokenSymbol from "../../helpers/getTokenNameBySymbolAndChainId";
import { setRefreshData } from "../../redux/transferSlice";
import { getNetworkById } from "../../constants/network";
import { LPHistory } from "../../constants/claimType";
import { storageConstants } from "../../constants/const";
import useGasFee from "../../hooks/useGasFee";
import getTokenInfoByChainIdAndTokenSymbol from "../../utils/getTokenInfoByChainIdAndTokenSymbol";
import { formatBlockExplorerUrlWithTxHash } from "../../utils/formatUrl";
import ProviderModal from "../providerModal";
import { needToSignAgain } from "../singleChainWithdrawModal";

/* eslint-disable */

/**
 * 1. gateway ClaimFee
 * 2. wait 30 seconds
 * 3. SGN QueryFeeClaimInfo
 * 4. SGN QueryWithdrawInfo
 * 5. OriginalTokenVault.withdraw(…)
 */

interface IProps {
  visible: boolean;
  onClose: () => void;
  feeShareList: Array<CBridgeFeeShareInfo>;
  onClaimSuccess: () => Promise<Array<CBridgeFeeShareInfo>>;
  feeSharetokenUsdMap: Map<string, number>;
}

interface SelectedInfo {
  amount: BigNumber;
  chainInfo?: Chain.AsObject;
  symbol: string;
  feeShareAmount: string;
  amountUsd: number;
  tokenName?: string;
  tokenAddress?: string;
  singleChainId?: string;
}

type FlowWithdrawInfoType = {
  _request: Uint8Array;
  _sigs: Uint8Array[];
};

type WithDrawInfoType = {
  _request: BytesLike;
  _sigs: BytesLike[];
  _signers: string[];
  _powers: BigNumberish[];
};

enum Status {
  INIT,
  CLAIM_SINGLE_FEE_SHARE,
  CLAIMING,
  CONFIRM_CLAIM,
  SUCCESS,
  SWITCH_CHAIN,
}

const getSymbolAndChainId = (denom: string) => {
  const symbol = (denom.match(/PBF-(\S*)(\/)/) || [])[1];
  const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
  return {
    symbol,
    singleChainId: chainId,
  };
};

const CanonicalFeeShareModal = (props: IProps) => {
  const { visible, onClose, feeShareList, onClaimSuccess, feeSharetokenUsdMap } = props;
  const { chainId, address, signer } = useWeb3Context();
  const { nonEVMMode, flowConnected, nonEVMConnected, nonEVMAddress, logoutNonEVMModal } = useNonEVMContext();
  const {
    transactor,
    contracts: { originalTokenVault },
  } = useContractsContext();
  const dispatch = useAppDispatch();
  const { configs, flowTokenPathConfigs } = useAppSelector(state => state.globalInfo);
  const { chainsList, chainTokenMap } = configs;
  let initStatus = Status.INIT;
  const [feeShareStatus, setFeeShareStatus] = useState<Status>(initStatus);
  const [switchChianId, setSwitchChainId] = useState<number>();
  const targetChainId = Number(process.env.REACT_APP_NETWORK_ID);
  const [selectedInfo, setSelectedInfo] = useState<SelectedInfo>();
  const [tokenList, setTokenList] = useState<Array<SelectedInfo>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [withdrawInfo, setWithdrawInfo] = useState<WithDrawInfoType>();
  const [flowWithdrawInfo, setFlowWithdrawInfo] = useState<FlowWithdrawInfoType>();
  const [viewInExploreLink, setViewInExploreLink] = useState<string>("");
  const [totalReward, setTotalReward] = useState(0);
  const { tokenGas, usdGas } = useGasFee();
  const [withdrawId, setWithdrawId] = useState("");
  const [realNonce, setRealNonce] = useState<number>(0);
  const [nonEVMRecipientAddress, setNonEVMRecipientAddress] = useState<string>("");
  const [flowAccountInitialized, setFlowAccountInitialized] = useState(false);
  const [providerVisible, setProviderVisible] = useState(false);
  const [createFlowBtnLoading, setCreateFlowBtnLoading] = useState(false);
  const [checkInitialzed, setCheckInitialzed] = useState(false);

  const tempNeedShowCreateFlowVaultBtn =
    flowConnected &&
    !flowAccountInitialized &&
    checkInitialzed &&
    (nonEVMMode === NonEVMMode.flowMainnet || nonEVMMode === NonEVMMode.flowTest);

  useEffect(() => {
    if (flowConnected && isNonEVMChain(selectedInfo?.chainInfo?.id ?? 0) && nonEVMAddress.length > 0) {
      setNonEVMRecipientAddress(nonEVMAddress);
    }
  }, [flowConnected, selectedInfo, nonEVMAddress]);

  useEffect(() => {
    const check = async () => {
      setFlowAccountInitialized(false);
      setCheckInitialzed(false);
      const chainMode = getNonEVMMode(selectedInfo?.chainInfo?.id ?? 0);
      const chainIsNotFlow = chainMode != NonEVMMode.flowTest && chainMode != NonEVMMode.flowMainnet;
      if (chainIsNotFlow || nonEVMRecipientAddress.length === 0) {
        return;
      }

      const flowTokenPath = flowTokenPathConfigs.find(config => {
        return config.Symbol === selectedInfo?.symbol;
      });

      const initialized = await checkTokenReceivabilityForFlowAccount(
        nonEVMRecipientAddress,
        flowTokenPath?.ReceiverPath ?? "",
      );
      setFlowAccountInitialized(initialized);
      setCheckInitialzed(true);
    };

    check();
  }, [nonEVMRecipientAddress, selectedInfo]);

  useEffect(() => {
    const newWokenList: Array<SelectedInfo> = [];
    let totalRewards = 0;
    feeShareList.forEach(item => {
      const amountBigNumber = BigNumber.from(item.amount);
      const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
      const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
      const tokenName = getTokenNameByTokenSymbol(tokenInfo?.token?.symbol || "", Number(singleChainId));
      const feeShareAmount = `${formatCelrWithoutSymbol(item.amount, 6, tokenInfo?.token?.decimal)} ${tokenName}`;
      const chainInfo = getChainInfoByChainId(singleChainId);
      const usdPrice = feeSharetokenUsdMap.get(symbol) || 0;
      const amountUsd = Number(formatDecimal(item.amount, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);
      totalRewards += amountUsd;
      newWokenList.push({
        amount: amountBigNumber,
        amountUsd,
        symbol,
        chainInfo,
        feeShareAmount,
        singleChainId,
        tokenName,
        tokenAddress: tokenInfo.token?.address,
      });
    });
    console.log({ newWokenList });
    const sortedTokenList: SelectedInfo[] = newWokenList.sort((a, b) => {
      if (a?.amountUsd > b?.amountUsd) {
        return -1;
      }
      if (a?.amountUsd < b?.amountUsd) {
        return 1;
      }
      return 0;
    });
    // console.log({ newWokenList, sortedTokenList });
    setTokenList(newWokenList);
    setTotalReward(totalRewards);
  }, [feeSharetokenUsdMap, feeShareList]);

  useEffect(() => {
    let targetChainId, targetSymbol;
    targetChainId = selectedInfo?.chainInfo?.id;
    targetSymbol = selectedInfo?.symbol;
    const nonEvmMode = getNonEVMMode(selectedInfo?.chainInfo?.id || -1);
    if (nonEvmMode !== NonEVMMode.off) {
      return;
    }
    const { peggedPairConfigsList } = configs;
    const config = peggedPairConfigsList.find(
      item => item.orgChainId === targetChainId && item.peggedToken?.token?.symbol === targetSymbol,
    );
    dispatch(setOTContractAddr(config?.peggedDepositContractAddr || ""));
  }, [configs, selectedInfo]);

  const getChainInfoByChainId = paramChainId => {
    return chainsList?.find(item => item.id === Number(paramChainId));
  };

  const closeMethod = async () => {
    onClose();
    setFeeShareStatus(Status.INIT);
    if (switchChianId && switchChianId !== targetChainId) {
      setSwitchChainId(targetChainId);
      dispatch(setRefreshData());
    }
  };

  const claimMethod = async (info: SelectedInfo) => {
    setSelectedInfo(info);
    dispatch(setSelectedChainId(info?.chainInfo?.id || 0));
    const nonEvmMode = getNonEVMMode(info?.chainInfo?.id || -1);
    if (nonEvmMode !== NonEVMMode.off) {
      setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
      return;
    }
    if (info.chainInfo?.id !== chainId) {
      setFeeShareStatus(Status.SWITCH_CHAIN);
    } else {
      setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
    }
  };

  const switchChainMethod = async claimChainId => {
    const inId = Number(claimChainId);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${inId.toString(16)}` }],
      });
      setSwitchChainId(inId);
      setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
    } catch (e) {
      console.log("switch fails");
      if (isMobile) {
        onClose();
      }
    }
  };

  const claimSingleMethod = async () => {
    if (!selectedInfo || !signer) {
      return;
    }
    setLoading(true);
    const getTokenInfoRequest = new GetTokenInfoRequest();
    getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
    getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
    const tokenInfo = await getTokenInfo(getTokenInfoRequest);
    const req = new ClaimPegBridgeFeeRequest();

    if (selectedInfo.amount.eq(BigNumber.from(0))) {
      console.log("error: wrong amount: ", selectedInfo.amount.toString());
      return;
    }
    req.setAmt(selectedInfo.amount.toString());
    const msg = new MsgClaimFee();
    msg.setDelegatorAddress(address);
    msg.setChainId(selectedInfo.chainInfo?.id || chainId);
    msg.setTokenAddress(tokenInfo?.toObject()?.token?.address || "");
    const nonce = new Date().getTime();
    msg.setNonce(nonce);
    const hash = ethers.utils.solidityKeccak256(
      ["string", "address", "uint64", "address", "uint64"],
      [
        "PegClaimFee",
        address,
        selectedInfo.chainInfo?.id.toString(),
        tokenInfo?.toObject()?.token?.address || "",
        nonce.toString(),
      ],
    );
    let sig;
    try {
      sig = await signer.signMessage(ethers.utils.arrayify(ethers.utils.keccak256(hash)));
      if (sig) {
        const bytes = ethers.utils.arrayify(sig);
        setFeeShareStatus(Status.CLAIMING);
        msg.setSignature(bytes);
        req.setMsg(msg);
      }
    } catch (error) {
      setLoading(false);
      return;
    }

    try {
      const res = await claimPegBridgeFeeMethod(req);
      console.log(res.toObject());
    } catch (error) {
      setLoading(false);
      return;
    }

    setTimeout(async () => {
      try {
        const getFeeClaimInfoReq = new QueryFeeClaimInfoRequest();
        getFeeClaimInfoReq.setNonce(nonce);
        getFeeClaimInfoReq.setAddress(address);
        const feeClaimInfo = await getFeeClaimInfo(getFeeClaimInfoReq);
        const resWithdrawId = feeClaimInfo.getFeeClaimInfo()?.getWithdrawId();
        if (resWithdrawId) {
          setWithdrawId(hexlify(resWithdrawId));
          setRealNonce(nonce);
          const getWithdrawInfoReq = new QueryWithdrawInfoRequest();
          getWithdrawInfoReq.setWithdrawId(hexlify(resWithdrawId));
          const withdrawInfo = await getWithdrawInfo(getWithdrawInfoReq);
          const withdrawProtoBytes = withdrawInfo.getWithdrawInfo()?.getWithdrawProtoBytes();
          const signatures = withdrawInfo.getWithdrawInfo()?.getSignaturesList();
          const sigs = signatures!.map(item => {
            const sig = item.getSigBytes();
            return sig;
          });
          const getChainSignersReq = new QueryChainSignersRequest();
          getChainSignersReq.setChainId(selectedInfo.chainInfo?.id || chainId);
          const chainSignersRes = await getChainSigners(getChainSignersReq);
          const sortedSignersList = chainSignersRes.getChainSigners()?.getSortedSignersList();
          if (!sortedSignersList) {
            return;
          }
          const powers = sortedSignersList.map(item => {
            const power = item.getPower();
            return BigNumber.from(power);
          });
          const signers = sortedSignersList!.map(item => {
            const decodeSigners = base64.decode(item.getAddr_asB64());
            const hexlifyObj = hexlify(decodeSigners);
            return getAddress(hexlifyObj);
          });
          setWithdrawInfo({
            _request: withdrawProtoBytes || "",
            _sigs: sigs,
            _powers: powers,
            _signers: signers,
          });
          setFeeShareStatus(Status.CONFIRM_CLAIM);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
        return;
      }
    }, 30000);
  };

  const claimFlowFee = async () => {
    if (!selectedInfo || !signer) {
      return;
    }
    setLoading(true);
    const getTokenInfoRequest = new GetTokenInfoRequest();
    getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
    getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
    const tokenInfo = await getTokenInfo(getTokenInfoRequest);
    console.log({ selectedInfo });
    const req = new ClaimPegBridgeFeeRequest();

    if (selectedInfo.amount.eq(BigNumber.from(0))) {
      console.log("error: wrong amount: ", selectedInfo.amount.toString());
      return;
    }
    req.setAmt(selectedInfo.amount.toString());
    const msg = new MsgClaimFee();
    msg.setDelegatorAddress(address);
    msg.setChainId(selectedInfo.chainInfo?.id || chainId);
    msg.setTokenAddress(tokenInfo?.toObject()?.token?.address || "");
    const nonce = new Date().getTime();
    msg.setNonce(nonce);
    msg.setReceiver(nonEVMAddress);
    req.setMsg(msg);
    const hash = ethers.utils.solidityKeccak256(
      ["string", "address", "uint64", "string", "uint64", "string"],
      [
        "PegClaimFee",
        address,
        selectedInfo.chainInfo?.id.toString(),
        tokenInfo?.toObject()?.token?.address || "",
        nonce.toString(),
        nonEVMAddress,
      ],
    );
    let sig;
    try {
      sig = await signer.signMessage(ethers.utils.arrayify(ethers.utils.keccak256(hash)));
      if (sig) {
        const bytes = ethers.utils.arrayify(sig);
        setFeeShareStatus(Status.CLAIMING);
        msg.setSignature(bytes);
        req.setMsg(msg);
      }
    } catch (error) {
      setLoading(false);
      return;
    }

    try {
      const res = await claimPegBridgeFeeMethod(req);
    } catch (error) {
      setLoading(false);
      return;
    }

    setTimeout(async () => {
      try {
        const getFeeClaimInfoReq = new QueryFeeClaimInfoRequest();
        getFeeClaimInfoReq.setNonce(nonce);
        getFeeClaimInfoReq.setAddress(address);
        const feeClaimInfo = await getFeeClaimInfo(getFeeClaimInfoReq);
        const resWithdrawId = feeClaimInfo.getFeeClaimInfo()?.getWithdrawId();
        if (resWithdrawId) {
          setWithdrawId(hexlify(resWithdrawId));
          setRealNonce(nonce);
          const getWithdrawInfoReq = new QueryWithdrawInfoRequest();
          getWithdrawInfoReq.setWithdrawId(hexlify(resWithdrawId));
          const withdrawInfo = await getWithdrawInfo(getWithdrawInfoReq);
          const withdrawProtoBytes = withdrawInfo.getWithdrawInfo()?.getWithdrawProtoBytes();
          const signatures = withdrawInfo.getWithdrawInfo()?.getSignaturesList();
          const sigs = signatures!.map(item => {
            const sig = item.getSigBytes();
            return sig;
          });
          setFlowWithdrawInfo({
            _request: withdrawProtoBytes as Uint8Array,
            _sigs: sigs as Uint8Array[],
          });
          setFeeShareStatus(Status.CONFIRM_CLAIM);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
        return;
      }
    }, 30000);
  };

  const connectFlowMethod = async () => {
    setProviderVisible(true);
  };

  const isRightOriginalTokenVault = () => {
    const originalTokenVaultArrd = originalTokenVault?.address;
    const targetOriginalTokenVaultArrd = configs.peggedPairConfigsList.find(
      item =>
        item.orgChainId === selectedInfo?.chainInfo?.id && item.peggedToken?.token?.symbol === selectedInfo?.symbol,
    )?.peggedDepositContractAddr;
    if (originalTokenVaultArrd && targetOriginalTokenVaultArrd) {
      return getAddress(originalTokenVaultArrd) === getAddress(targetOriginalTokenVaultArrd);
    }
    return false;
  };

  const signAgainMethod = async ({ chainId, nonce, addr }) => {
    const signAgainRequest = new SignAgainRequest();
    signAgainRequest.setType(SignAgainType.SAT_CANONICAL);
    signAgainRequest.setUsrAddr(addr);
    signAgainRequest.setChainId(chainId);
    signAgainRequest.setNonce(nonce);
    return signAgain(signAgainRequest);
  };

  const afterSignAgainMthod = async () => {
    if (!originalTokenVault || !transactor || !selectedInfo) {
      return;
    }
    const getWithdrawInfoReq = new QueryWithdrawInfoRequest();
    getWithdrawInfoReq.setWithdrawId(hexlify(withdrawId));
    const withdrawInfo = await getWithdrawInfo(getWithdrawInfoReq);
    const withdrawProtoBytes = withdrawInfo.getWithdrawInfo()?.getWithdrawProtoBytes();
    const signatures = withdrawInfo.getWithdrawInfo()?.getSignaturesList();
    const sigs = signatures!.map(item => {
      const sig = item.getSigBytes();
      return sig;
    });
    const getChainSignersReq = new QueryChainSignersRequest();
    getChainSignersReq.setChainId(selectedInfo.chainInfo?.id || chainId);
    const chainSignersRes = await getChainSigners(getChainSignersReq);
    const sortedSignersList = chainSignersRes.getChainSigners()?.getSortedSignersList();
    if (!sortedSignersList) {
      return;
    }
    const powers = sortedSignersList.map(item => {
      const power = item.getPower();
      return BigNumber.from(power);
    });
    const signers = sortedSignersList.map(item => {
      const decodeSigners = base64.decode(item.getAddr_asB64());
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    let gasLimit = await originalTokenVault?.estimateGas.withdraw(withdrawProtoBytes || "", sigs, signers, powers);
    gasLimit = gasLimit.mul(13).div(10);
    const resultTx = await transactor(
      originalTokenVault?.withdraw(withdrawProtoBytes || "", sigs, signers, powers, { gasLimit: gasLimit }),
    );
    if (resultTx) {
      const newtxStr = JSON.stringify(resultTx);
      const newtx = JSON.parse(newtxStr);
      const getTokenInfoRequest = new GetTokenInfoRequest();
      getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
      getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
      const tokenInfo = await getTokenInfo(getTokenInfoRequest);
      if (resultTx instanceof Error || newtx.code) {
        console.log("get before error", resultTx);
        setLoading(false);
      } else {
        const blockTxLink = formatBlockExplorerUrlWithTxHash({
          chainId: selectedInfo.chainInfo?.id || chainId,
          txHash: resultTx.hash,
        });
        const newLPJson: LPHistory = {
          chain: selectedInfo.chainInfo,
          token: tokenInfo?.toObject(),
          amount: selectedInfo.amount.toString(),
          ts: new Date().getTime(),
          blockTxLink,
          status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
          methodType: WithdrawMethodType.WD_METHOD_TYPE_STAKING_CLAIM,
          seqNum: 0,
          // nonce: resultTx.nonce,
          nonce: realNonce,
          isLocal: true,
          updateTime: new Date().getTime(),
          txIsFailed: false,
          withdrawId,
        };
        const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
        let localLpList: LPHistory[] = [];
        if (localLpListStr) {
          localLpList = JSON.parse(localLpListStr)[address] || [];
        }
        localLpList.unshift(newLPJson);
        const newJson = { [address]: localLpList };
        localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
        setViewInExploreLink(blockTxLink);
        setFeeShareStatus(Status.SUCCESS);
        setLoading(false);
      }
    }
  };

  const confirmClaimMethod = async () => {
    if (!originalTokenVault || !withdrawInfo || !transactor || !selectedInfo) {
      return;
    }

    if (!isRightOriginalTokenVault) {
      console.log("error: wrong originalTokenVault addr");
      return;
    }

    setLoading(true);

    const { _request, _sigs, _signers, _powers } = withdrawInfo;
    try {
      setLoading(true);
      try {
        let gasLimit = await originalTokenVault?.estimateGas.withdraw(_request, _sigs, _signers, _powers);
        gasLimit = gasLimit.mul(13).div(10);
        const resultTx = await transactor(
          originalTokenVault?.withdraw(_request, _sigs, _signers, _powers, { gasLimit: gasLimit }),
        );
        if (resultTx) {
          const newtxStr = JSON.stringify(resultTx);
          const newtx = JSON.parse(newtxStr);
          const getTokenInfoRequest = new GetTokenInfoRequest();
          getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
          getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
          const tokenInfo = await getTokenInfo(getTokenInfoRequest);
          if (resultTx instanceof Error || newtx.code) {
            console.log("get before error", resultTx);
            setLoading(false);
          } else {
            const blockTxLink = formatBlockExplorerUrlWithTxHash({
              chainId: selectedInfo.chainInfo?.id || chainId,
              txHash: resultTx.hash,
            });
            const newLPJson: LPHistory = {
              chain: selectedInfo.chainInfo,
              token: tokenInfo?.toObject(),
              amount: selectedInfo.amount.toString(),
              ts: new Date().getTime(),
              blockTxLink,
              status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
              methodType: WithdrawMethodType.WD_METHOD_TYPE_STAKING_CLAIM,
              seqNum: 0,
              // nonce: resultTx.nonce,
              nonce: realNonce,
              isLocal: true,
              updateTime: new Date().getTime(),
              txIsFailed: false,
              withdrawId,
            };
            const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
            let localLpList: LPHistory[] = [];
            if (localLpListStr) {
              localLpList = JSON.parse(localLpListStr)[address] || [];
            }
            localLpList.unshift(newLPJson);
            const newJson = { [address]: localLpList };
            localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
            setViewInExploreLink(blockTxLink);
            setFeeShareStatus(Status.SUCCESS);
            setLoading(false);
          }
        }
      } catch (e) {
        if (needToSignAgain(e)) {
          const signAgainRes = await signAgainMethod({
            addr: address,
            chainId: selectedInfo.chainInfo?.id || chainId,
            nonce: realNonce,
          });
          console.log({ signAgainRes });
          try {
            await afterSignAgainMthod();
            setLoading(false);
          } catch (err) {
            console.log("catch afterSignAgainMthod error", err);
            setFeeShareStatus(Status.CONFIRM_CLAIM);
            setLoading(false);
          }
        } else {
          console.log("catch tx error", e);
          setFeeShareStatus(Status.CONFIRM_CLAIM);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
    } finally {
      //   setLoading(false);
    }
  };

  const confirmClaimFlowFee = async () => {
    if (!flowWithdrawInfo || !transactor || !selectedInfo) {
      return;
    }

    setLoading(true);

    const { _request, _sigs } = flowWithdrawInfo;
    try {
      setLoading(true);
      try {
        const targetSymbol = selectedInfo?.symbol;
        const targetChainIdForFlow = selectedInfo.chainInfo?.id;

        const { peggedPairConfigsList } = configs;

        const depositContractAddress =
          peggedPairConfigsList.find(
            item => item.orgChainId === targetChainIdForFlow && item.peggedToken?.token?.symbol === targetSymbol,
          )?.peggedDepositContractAddr ?? "";
        const wdmsg = _request;
        const sigs = _sigs;
        const resultTx = await withdrawFlowFeeReward(
          depositContractAddress,
          selectedInfo.tokenAddress || "",
          wdmsg,
          sigs,
        );
        console.log("txHash", resultTx);
        if (resultTx.length > 0) {
          const getTokenInfoRequest = new GetTokenInfoRequest();
          getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
          getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
          const tokenInfo = await getTokenInfo(getTokenInfoRequest);
          const blockTxLink = formatBlockExplorerUrlWithTxHash({
            chainId: selectedInfo.chainInfo?.id || chainId,
            txHash: resultTx,
            isFlow: true,
          });
          const newLPJson: LPHistory = {
            chain: selectedInfo.chainInfo,
            token: tokenInfo?.toObject(),
            amount: selectedInfo.amount.toString(),
            ts: new Date().getTime(),
            blockTxLink,
            status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
            methodType: WithdrawMethodType.WD_METHOD_TYPE_STAKING_CLAIM,
            seqNum: 0,
            // nonce: resultTx.nonce,
            nonce: realNonce,
            isLocal: true,
            updateTime: new Date().getTime(),
            txIsFailed: false,
            withdrawId,
          };
          const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
          let localLpList: LPHistory[] = [];
          if (localLpListStr) {
            localLpList = JSON.parse(localLpListStr)[address] || [];
          }
          localLpList.unshift(newLPJson);
          const newJson = { [address]: localLpList };
          localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
          setViewInExploreLink(blockTxLink);
          setFeeShareStatus(Status.SUCCESS);
          setLoading(false);
        } else {
          setFeeShareStatus(Status.CONFIRM_CLAIM);
          setLoading(false);
        }
      } catch (e) {
        console.log("flow catch tx error", e);
        setFeeShareStatus(Status.CONFIRM_CLAIM);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const claimSuccessMethod = async () => {
    setFeeShareStatus(Status.INIT);
    setSpinning(true);
    try {
      const newFeeShareList = await onClaimSuccess();
      setSpinning(false);
      if (newFeeShareList.length <= 0) {
        closeMethod();
      }
    } catch (e) {
      console.log(e);
      setSpinning(false);
    }
  };

  const changeWallet = async () => {
    await logoutNonEVMModal();
    connectFlowMethod();
  };

  const renderRecipient = () => {
    if (!nonEVMConnected) {
      return null;
    }
    return (
      <div className="recipient">
        <div className="recipient-header">
          <div className="recipient-header-title">Recipient address on Flow</div>
          <Button type="text" className="recipient-header-action" onClick={changeWallet}>
            change wallet
          </Button>
        </div>
        <div className="recipient-content">{nonEVMAddress}</div>
      </div>
    );
  };

  const setupFlowAccountWithLoadingSign = () => {
    if (!flowConnected || flowAccountInitialized) {
      return;
    }

    setCreateFlowBtnLoading(true);

    const flowTokenPath = flowTokenPathConfigs.find(config => {
      return config.Symbol === selectedInfo?.symbol;
    });

    if (flowTokenPath) {
      setupTokenVaultForFlowAccount(flowTokenPath, nonEVMAddress)
        .then(initialized => {
          setFlowAccountInitialized(initialized);
          setCreateFlowBtnLoading(false);
        })
        .catch(e => {
          message.error(`set token vault err: ${e}`);
          setCreateFlowBtnLoading(false);
        });
    } else {
      setCreateFlowBtnLoading(false);
    }
  };

  const renderCreateVaultBtn = () => {
    console.log({ selectedInfo });
    return (
      <Button
        type="text"
        block
        onClick={setupFlowAccountWithLoadingSign}
        loading={createFlowBtnLoading}
        className="create-vault-btn"
      >
        <span className="create-vault-btn-text">
          Create {selectedInfo?.tokenName} vault to receive the fee rewards
          <Tooltip
            title={`In order to receive ${selectedInfo?.tokenName} on Flow, you will need to create a ${selectedInfo?.tokenName}
          vault in your Flow wallet. This does not consume any gas and only needs to be done once per token.`}
            placement="right"
            arrowPointAtCenter
            color="#fff"
            overlayInnerStyle={{ color: "#000", width: 265, fontSize: 12, borderRadius: 8 }}
          >
            <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6 }} />
          </Tooltip>
        </span>
      </Button>
    );
  };

  const renderSingleBtn = type => {
    switch (type) {
      case NonEVMMode.off: {
        return (
          <Button type="text" block loading={loading} className="claim-btn" onClick={claimSingleMethod}>
            Claim
          </Button>
        );
      }
      case NonEVMMode.flowTest:
      case NonEVMMode.flowMainnet: {
        if (!nonEVMConnected) {
          return (
            <Button type="text" block className="connect-flow-btn" onClick={connectFlowMethod}>
              <div className="btn-main-text">Connect Flow wallet</div>
              <div className="btn-sub-text">to claim fee rewards</div>
            </Button>
          );
        } else if (checkInitialzed) {
          return (
            <>
              {tempNeedShowCreateFlowVaultBtn ? renderCreateVaultBtn() : null}
              <Button
                type="text"
                block
                loading={loading}
                disabled={tempNeedShowCreateFlowVaultBtn}
                className="claim-btn"
                onClick={claimFlowFee}
              >
                Claim
              </Button>
            </>
          );
        }
        return null;
      }
    }
  };

  const renderNote = (itemInfo: SelectedInfo) => {
    const chainInfo = getChainInfoByChainId(itemInfo?.chainInfo?.id || chainId);
    const nativeToken = getNetworkById(chainInfo?.id || 1);
    return (
      <div className="feeShare-desc">
        Note: You will need to pay{" "}
        <span className="gas-fee-price">
          {formatBalance(tokenGas, 6, "floor", ",", true)} {nativeToken.symbol} ({convertUSD(usdGas)}) on-chain gas
          costs
        </span>{" "}
        to claim this fee rewards.
      </div>
    );
  };

  const contentDom = () => {
    const chainInfo = getChainInfoByChainId(selectedInfo?.chainInfo?.id || chainId);
    const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
      chainTokenMap,
      Number(selectedInfo?.chainInfo?.id || chainId),
      selectedInfo?.symbol,
    );
    const tokenName = getTokenNameByTokenSymbol(tokenInfo?.token?.symbol || "", Number(selectedInfo?.chainInfo?.id));
    const feeShareAmount = `${formatDecimalWithSeparation(
      selectedInfo?.amount || 0,
      6,
      tokenInfo?.token?.decimal,
      "floor",
      ",",
      true,
    )} ${tokenName}`;
    switch (feeShareStatus) {
      case Status.INIT:
        return (
          <Spin spinning={spinning}>
            <div className="init-block">
              <div className="total-rewards">Total claimable fee rewards: {convertUSD(totalReward)}</div>
              <div className="c-img">
                <img src={Canonicalmg} alt="" />
              </div>
              <div className="feeshare-list">
                {tokenList.map(item => {
                  const {
                    amount,
                    amountUsd,
                    symbol,
                    chainInfo,
                    feeShareAmount,
                    singleChainId,
                    tokenAddress,
                    tokenName,
                  } = item;
                  return (
                    <div className="item" key={`${singleChainId}-${symbol}`}>
                      <div className="item-content">
                        <div className="chain-info">
                          <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                          <span className="chain-info-name">{chainInfo?.name}</span>
                        </div>
                        <div className="fee-amount">
                          <div className="fee-amount-title">Claimable Fee Reward</div>
                          <div className="fee-amount-block">
                            <div className="fee-amount-token">{feeShareAmount} </div>
                            <div className="fee-amount-usd">({convertUSD(amountUsd)})</div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-block">
                        <Button
                          type="text"
                          block
                          className="claim-btn"
                          onClick={() => {
                            claimMethod({
                              amount,
                              amountUsd,
                              symbol,
                              chainInfo,
                              feeShareAmount,
                              tokenAddress,
                              tokenName,
                            });
                          }}
                        >
                          Claim
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Spin>
        );
      case Status.CLAIM_SINGLE_FEE_SHARE: {
        const nonEvmMode = getNonEVMMode(selectedInfo?.chainInfo?.id || -1);
        const isNonEvm = nonEvmMode === NonEVMMode.flowTest || nonEvmMode === NonEVMMode.flowMainnet;
        return (
          <div className="single-block">
            <div className="c-img">
              <img src={Canonicalmg} alt="" />
            </div>
            <div className="content">
              <div className="feeShare-info">
                <div className="chain-info">
                  <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                  <span>{selectedInfo?.chainInfo?.name}</span>
                </div>
                <div className="fee-amount">
                  <div className="fee-amount-title">Claimable Fee Reward</div>
                  <div className="fee-amount-token">{feeShareAmount} </div>
                  <div className="fee-amount-usd">({convertUSD(selectedInfo?.amountUsd || 0)})</div>
                </div>
              </div>
              {selectedInfo && !isNonEvm ? renderNote(selectedInfo) : null}

              {isNonEvm ? renderRecipient() : null}
              <div className="btn-block">{renderSingleBtn(nonEvmMode)}</div>
            </div>
          </div>
        );
      }
      case Status.SWITCH_CHAIN:
        return (
          <div className="switch-block">
            <div className="warning">
              <div className="img-block">
                <img src={warningImg} alt="" />
              </div>
              <div className="warning-message">
                Please switch to {chainInfo?.name} before claiming your cBridge fee reward.
              </div>
            </div>
            <div className="btn-block">
              <Button
                type="text"
                block
                className="ok-btn"
                onClick={() => switchChainMethod(selectedInfo?.chainInfo?.id || chainId)}
              >
                OK
              </Button>
            </div>
          </div>
        );
      case Status.CLAIMING:
        return (
          <div className="claiming-block">
            <div className="loading-block">
              <img className="loadingimg" src={loadingImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Waiting for Celer SGN Confirmations...</div>
              <div className="sub-title">
                Claim {feeShareAmount} cBridge fee reward from {selectedInfo?.chainInfo?.name}.
              </div>
            </div>
            <div className="desc">
              <div className="desc-info">
                Your request to claim cBridge fee reward is being confirmed by Celer State Guardian Network (SGN), which
                might take a few minutes.
              </div>
            </div>
          </div>
        );
      case Status.CONFIRM_CLAIM: {
        const nonEvmMode = getNonEVMMode(selectedInfo?.chainInfo?.id || -1);
        const isNonEvm = nonEvmMode === NonEVMMode.flowTest || nonEvmMode === NonEVMMode.flowMainnet;
        return (
          <div className="confirm-block">
            <div className="confirm-message">
              Please click "Confirm Claim" button to complete the fee rewards claim process.
            </div>
            <div className="btn-block">
              <Button
                type="text"
                block
                className="confirm-btn"
                loading={loading}
                onClick={isNonEvm ? confirmClaimFlowFee : confirmClaimMethod}
              >
                Confirm Claim
              </Button>
            </div>
          </div>
        );
      }
      case Status.SUCCESS:
        return (
          <div className="success-block">
            <div className="success-icon">
              <img src={successImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Success</div>
              <div className="sub-title">
                Claim {feeShareAmount} cBridge fee reward from {selectedInfo?.chainInfo?.name}
              </div>
            </div>
            <div className="view-in-explorer">
              <a href={viewInExploreLink} target="_blank" rel="noreferrer">
                View in Explorer
              </a>
            </div>
            <div className="desc">Please allow a few minutes before the transaction completes.</div>
            <div className="btn-block">
              <Button type="text" block className="ok-btn" loading={loading} onClick={claimSuccessMethod}>
                OK
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        title={
          feeShareStatus === Status.SWITCH_CHAIN ? (
            " "
          ) : (
            <div className="modal-title-block">
              {feeShareStatus === Status.CLAIM_SINGLE_FEE_SHARE ? (
                <div
                  className="back"
                  onClick={() => {
                    setFeeShareStatus(Status.INIT);
                  }}
                >
                  <LeftOutlined style={{ fontSize: 22, marginLeft: 6 }} />
                </div>
              ) : null}
              <div className="main-title">Claim Fee Rewards</div>
              <div className="sub-title">(canonical token bridge)</div>
            </div>
          )
        }
        className={feeShareStatus === Status.INIT ? "canonical-feeShare-list-modal" : "canonical-feeShare-modal"}
        visible={visible}
        onCancel={closeMethod}
        maskClosable={false}
        footer={null}
        style={{ visibility: providerVisible ? "hidden" : "visible" }}
      >
        {contentDom()}
      </Modal>
      <ProviderModal visible={providerVisible} onCancel={() => setProviderVisible(false)} />
    </>
  );
};

export default CanonicalFeeShareModal;
