import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { Contract, ContractFactory } from "@ethersproject/contracts";
import { InfuraProvider, JsonRpcProvider } from "@ethersproject/providers";
import { useEffect, useState } from "react";
import { DPoS__factory } from "../typechain/factories/DPoS__factory";
import { DPoS } from "../typechain/DPoS";
import { SGN__factory } from "../typechain/factories/SGN__factory";
import { SGN } from "../typechain/SGN";

import { Viewer } from "../typechain/Viewer";
import { Viewer__factory } from "../typechain/factories/Viewer__factory";
import { Staking } from "../typechain/Staking";
import { Staking__factory } from "../typechain/factories/Staking__factory";
import { StakingReward } from "../typechain/StakingReward";
import { StakingReward__factory } from "../typechain/factories/StakingReward__factory";
import { Bridge } from "../typechain/Bridge";
import { Bridge__factory } from "../typechain/factories/Bridge__factory";
import { Faucet__factory } from "../typechain/factories/Faucet__factory";
import { Faucet } from "../typechain/Faucet";
import { OriginalTokenVault } from "../typechain/OriginalTokenVault";
import { OriginalTokenVault__factory } from "../typechain/factories/OriginalTokenVault__factory";

/* eslint-disable no-debugger */
export type SGNContracts = {
  SGN: SGN | undefined;
  DPoS: DPoS | undefined;
  Viewer: Viewer | undefined;
  Staking: Staking | undefined;
  StakingReward: StakingReward | undefined;
  Bridge: Bridge | undefined;
  faucet: Faucet | undefined;
  originalTokenVault: OriginalTokenVault | undefined;
};

export type SGNContractFactoryClasses = {
  [key: string]: { new (signer: Signer): ContractFactory };
};

export const SGNContractFactories: SGNContractFactoryClasses = {
  SGN: SGN__factory,
  DPoS: DPoS__factory,
  Viewer: Viewer__factory,
  Staking: Staking__factory,
  StakingReward: StakingReward__factory,
  Bridge: Bridge__factory,
  faucet: Faucet__factory,
  originalTokenVault: OriginalTokenVault__factory,
};

export const sgnContracts: SGNContracts = {
  SGN: undefined,
  DPoS: undefined,
  Viewer: undefined,
  Staking: undefined,
  StakingReward: undefined,
  Bridge: undefined,
  faucet: undefined,
  originalTokenVault: undefined,
};

function loadContract(keyName: string, signer: Signer, addresses: Record<string, string>): Contract | undefined {
  if (!(keyName in addresses) || !addresses[keyName]) {
    return undefined;
  }
  const newContract = new SGNContractFactories[keyName](signer).attach(addresses[keyName]);
  return newContract;
}

/**
 * Converts a Signer or Provider to a Signer.
 *
 * @param signerOrProvider A Signer or a Provider.
 * @returns A Signer.
 */
export async function ensureSigner(signerOrProvider: Signer | Provider): Promise<Signer | undefined> {
  let signer: Signer;
  let accounts: string[] = [];
  if (signerOrProvider && typeof (signerOrProvider as JsonRpcProvider).listAccounts === "function") {
    accounts = await (signerOrProvider as JsonRpcProvider).listAccounts();
  }

  if (accounts && accounts.length > 0) {
    signer = (signerOrProvider as JsonRpcProvider).getSigner();
  } else if (signerOrProvider instanceof InfuraProvider) {
    return undefined;
  } else {
    signer = signerOrProvider as Signer;
  }
  return signer;
}

/**
 * Loads pre-defined L2F contracts.
 *
 * @param signerOrProvider A Signer or a Provider.
 * @param addresses The contract address.
 * @returns The contracts.
 */
export default function useContractLoader(
  signerOrProvider: Signer | Provider | undefined,
  addresses: Record<string, string>,
): SGNContracts {
  const [contracts, setContracts] = useState<SGNContracts>(sgnContracts);
  useEffect(() => {
    async function loadContracts() {
      if (typeof signerOrProvider !== "undefined") {
        try {
          const signer = await ensureSigner(signerOrProvider);
          if (!signer) {
            return;
          }
          const newContracts = Object.keys(sgnContracts).reduce((accumulator, keyName) => {
            accumulator[keyName] = loadContract(keyName, signer, addresses);
            return accumulator;
          }, {}) as SGNContracts;
          setContracts(newContracts);
        } catch (e) {
          console.log("Error loading contracts", e);
        }
      }
    }
    loadContracts();
  }, [signerOrProvider, addresses]);
  return contracts;
}
