import logoIcon from "../../assets/images/logo.png";
import Account from "../Account";
import "./header.mobile.less";

const MobileHeader = () => {
  return (
    <>
      <div className="mobile-header">
        <div className="logo">
          <img src={logoIcon} className="icon-logo" alt="" />
        </div>
        <div className="actions">
          <Account />
        </div>
      </div>
    </>
  );
};

export default MobileHeader;
