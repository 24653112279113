import { useEffect, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { useToggle } from "react-use";
import logoIcon from "../../assets/images/logo.png";
import Account from "../Account";
import FaucetModal from "../FaucetModal";
import "./index.less";
import { ERC20 } from "../../typechain/ERC20";
import { ERC20__factory } from "../../typechain/factories/ERC20__factory";
import { useCustomContractLoader, useTokenBalance } from "../../hooks";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { ModalName, openModal } from "../../redux/modalSlice";
import { useAppSelector, useAppDispatch } from "../../redux/store";

import MobileHeader from "./Header.mobile";

export default function Header(): JSX.Element {
  const location = useLocation();
  const [route, setRoute] = useState("");
  const [showFaucet, toggleFaucet] = useToggle(false);
  const { provider, address, signer } = useWeb3Context();
  const { celerAddress } = useAppSelector(state => state.globalInfo);
  const tokenContract = useCustomContractLoader(provider, celerAddress || "", ERC20__factory) as ERC20 | undefined;
  const [, , , refreshBlance] = useTokenBalance(tokenContract, address);
  const dispatch = useAppDispatch();

  const showProviderModal = useCallback(() => {
    dispatch(openModal(ModalName.provider));
  }, [dispatch]);

  useEffect(() => {
    const segments = location.pathname.split("/").filter(p => p);
    setRoute(segments[0]);
  }, [location]);

  const closeFaucet = () => {
    refreshBlance();
    toggleFaucet();
  };

  if (isMobile) {
    return <MobileHeader />;
  }

  return (
    <header className="page-header">
      <div className="header-left">
        <div className="icon">
          <img src={logoIcon} className="icon-logo" alt="" />
        </div>
        <Menu mode="horizontal" selectedKeys={[route]}>
          <Menu.Item key="staking">
            <Link to="/staking">All Validators</Link>
          </Menu.Item>

          <Menu.Item key="rewards">
            {!signer ? <div onClick={showProviderModal}></div> : <Link to="/rewards">My Rewards</Link>}
          </Menu.Item>
        </Menu>
      </div>

      <div className="header-right">
        <div className="sgn-v1-div">
          <a href="https://form.typeform.com/to/ypkzHrEV" className="sgn-link" target="_blank" rel="noreferrer">
            Support
          </a>
        </div>
        <div className="sgn-v1-div">
          <a href="https://sgn-v1.celer.network/" className="sgn-link" target="_blank" rel="noreferrer">
            SGN v1
          </a>
        </div>
        {/* <Button type="primary" className="faucet-block" onClick={toggleFaucet}>
          Faucet
        </Button> */}

        <Account />
      </div>
      {showFaucet && <FaucetModal onClose={closeFaucet} />}
    </header>
  );
}
