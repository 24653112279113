/**
 * @fileoverview gRPC-Web generated client stub for sgn.cbridge.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as sgn_cbridge_v1_query_pb from '../../../sgn/cbridge/v1/query_pb';


export class QueryClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorQueryParams = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryParams',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.EmptyRequest,
    sgn_cbridge_v1_query_pb.QueryParamsResponse,
    (request: sgn_cbridge_v1_query_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryParamsResponse.deserializeBinary
  );

  queryParams(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryParamsResponse>;

  queryParams(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryParamsResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryParamsResponse>;

  queryParams(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryParamsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryParams',
        request,
        metadata || {},
        this.methodDescriptorQueryParams,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryParams',
    request,
    metadata || {},
    this.methodDescriptorQueryParams);
  }

  methodDescriptorQueryConfig = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryConfig',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.EmptyRequest,
    sgn_cbridge_v1_query_pb.QueryConfigResponse,
    (request: sgn_cbridge_v1_query_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryConfigResponse.deserializeBinary
  );

  queryConfig(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryConfigResponse>;

  queryConfig(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryConfigResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryConfigResponse>;

  queryConfig(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryConfig',
        request,
        metadata || {},
        this.methodDescriptorQueryConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryConfig',
    request,
    metadata || {},
    this.methodDescriptorQueryConfig);
  }

  methodDescriptorQueryRelay = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryRelay',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryRelayRequest,
    sgn_cbridge_v1_query_pb.QueryRelayResponse,
    (request: sgn_cbridge_v1_query_pb.QueryRelayRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryRelayResponse.deserializeBinary
  );

  queryRelay(
    request: sgn_cbridge_v1_query_pb.QueryRelayRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryRelayResponse>;

  queryRelay(
    request: sgn_cbridge_v1_query_pb.QueryRelayRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryRelayResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryRelayResponse>;

  queryRelay(
    request: sgn_cbridge_v1_query_pb.QueryRelayRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryRelayResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryRelay',
        request,
        metadata || {},
        this.methodDescriptorQueryRelay,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryRelay',
    request,
    metadata || {},
    this.methodDescriptorQueryRelay);
  }

  methodDescriptorQueryRefund = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryRefund',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryRefundRequest,
    sgn_cbridge_v1_query_pb.QueryRefundResponse,
    (request: sgn_cbridge_v1_query_pb.QueryRefundRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryRefundResponse.deserializeBinary
  );

  queryRefund(
    request: sgn_cbridge_v1_query_pb.QueryRefundRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryRefundResponse>;

  queryRefund(
    request: sgn_cbridge_v1_query_pb.QueryRefundRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryRefundResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryRefundResponse>;

  queryRefund(
    request: sgn_cbridge_v1_query_pb.QueryRefundRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryRefundResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryRefund',
        request,
        metadata || {},
        this.methodDescriptorQueryRefund,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryRefund',
    request,
    metadata || {},
    this.methodDescriptorQueryRefund);
  }

  methodDescriptorQueryChainSigners = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryChainSigners',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryChainSignersRequest,
    sgn_cbridge_v1_query_pb.QueryChainSignersResponse,
    (request: sgn_cbridge_v1_query_pb.QueryChainSignersRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryChainSignersResponse.deserializeBinary
  );

  queryChainSigners(
    request: sgn_cbridge_v1_query_pb.QueryChainSignersRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryChainSignersResponse>;

  queryChainSigners(
    request: sgn_cbridge_v1_query_pb.QueryChainSignersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryChainSignersResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryChainSignersResponse>;

  queryChainSigners(
    request: sgn_cbridge_v1_query_pb.QueryChainSignersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryChainSignersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryChainSigners',
        request,
        metadata || {},
        this.methodDescriptorQueryChainSigners,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryChainSigners',
    request,
    metadata || {},
    this.methodDescriptorQueryChainSigners);
  }

  methodDescriptorQueryLatestSigners = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryLatestSigners',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.EmptyRequest,
    sgn_cbridge_v1_query_pb.QueryLatestSignersResponse,
    (request: sgn_cbridge_v1_query_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryLatestSignersResponse.deserializeBinary
  );

  queryLatestSigners(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryLatestSignersResponse>;

  queryLatestSigners(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLatestSignersResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryLatestSignersResponse>;

  queryLatestSigners(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLatestSignersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryLatestSigners',
        request,
        metadata || {},
        this.methodDescriptorQueryLatestSigners,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryLatestSigners',
    request,
    metadata || {},
    this.methodDescriptorQueryLatestSigners);
  }

  methodDescriptorQueryCheckChainTokenValid = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryCheckChainTokenValid',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.CheckChainTokenValidRequest,
    sgn_cbridge_v1_query_pb.CheckChainTokenValidResponse,
    (request: sgn_cbridge_v1_query_pb.CheckChainTokenValidRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.CheckChainTokenValidResponse.deserializeBinary
  );

  queryCheckChainTokenValid(
    request: sgn_cbridge_v1_query_pb.CheckChainTokenValidRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.CheckChainTokenValidResponse>;

  queryCheckChainTokenValid(
    request: sgn_cbridge_v1_query_pb.CheckChainTokenValidRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.CheckChainTokenValidResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.CheckChainTokenValidResponse>;

  queryCheckChainTokenValid(
    request: sgn_cbridge_v1_query_pb.CheckChainTokenValidRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.CheckChainTokenValidResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryCheckChainTokenValid',
        request,
        metadata || {},
        this.methodDescriptorQueryCheckChainTokenValid,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryCheckChainTokenValid',
    request,
    metadata || {},
    this.methodDescriptorQueryCheckChainTokenValid);
  }

  methodDescriptorQueryChkLiqSum = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryChkLiqSum',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.CheckLiqSumRequest,
    sgn_cbridge_v1_query_pb.CheckLiqSumResponse,
    (request: sgn_cbridge_v1_query_pb.CheckLiqSumRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.CheckLiqSumResponse.deserializeBinary
  );

  queryChkLiqSum(
    request: sgn_cbridge_v1_query_pb.CheckLiqSumRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.CheckLiqSumResponse>;

  queryChkLiqSum(
    request: sgn_cbridge_v1_query_pb.CheckLiqSumRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.CheckLiqSumResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.CheckLiqSumResponse>;

  queryChkLiqSum(
    request: sgn_cbridge_v1_query_pb.CheckLiqSumRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.CheckLiqSumResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryChkLiqSum',
        request,
        metadata || {},
        this.methodDescriptorQueryChkLiqSum,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryChkLiqSum',
    request,
    metadata || {},
    this.methodDescriptorQueryChkLiqSum);
  }

  methodDescriptorQueryDebugAny = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryDebugAny',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryDebugAnyRequest,
    sgn_cbridge_v1_query_pb.QueryDebugAnyResponse,
    (request: sgn_cbridge_v1_query_pb.QueryDebugAnyRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryDebugAnyResponse.deserializeBinary
  );

  queryDebugAny(
    request: sgn_cbridge_v1_query_pb.QueryDebugAnyRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryDebugAnyResponse>;

  queryDebugAny(
    request: sgn_cbridge_v1_query_pb.QueryDebugAnyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryDebugAnyResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryDebugAnyResponse>;

  queryDebugAny(
    request: sgn_cbridge_v1_query_pb.QueryDebugAnyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryDebugAnyResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryDebugAny',
        request,
        metadata || {},
        this.methodDescriptorQueryDebugAny,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryDebugAny',
    request,
    metadata || {},
    this.methodDescriptorQueryDebugAny);
  }

  methodDescriptorQueryAssets = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryAssets',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.EmptyRequest,
    sgn_cbridge_v1_query_pb.QueryAssetsResponse,
    (request: sgn_cbridge_v1_query_pb.EmptyRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryAssetsResponse.deserializeBinary
  );

  queryAssets(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryAssetsResponse>;

  queryAssets(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetsResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryAssetsResponse>;

  queryAssets(
    request: sgn_cbridge_v1_query_pb.EmptyRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryAssets',
        request,
        metadata || {},
        this.methodDescriptorQueryAssets,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryAssets',
    request,
    metadata || {},
    this.methodDescriptorQueryAssets);
  }

  methodDescriptorQueryAssetPrice = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryAssetPrice',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryAssetPriceRequest,
    sgn_cbridge_v1_query_pb.QueryAssetPriceResponse,
    (request: sgn_cbridge_v1_query_pb.QueryAssetPriceRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryAssetPriceResponse.deserializeBinary
  );

  queryAssetPrice(
    request: sgn_cbridge_v1_query_pb.QueryAssetPriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryAssetPriceResponse>;

  queryAssetPrice(
    request: sgn_cbridge_v1_query_pb.QueryAssetPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetPriceResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryAssetPriceResponse>;

  queryAssetPrice(
    request: sgn_cbridge_v1_query_pb.QueryAssetPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetPriceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryAssetPrice',
        request,
        metadata || {},
        this.methodDescriptorQueryAssetPrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryAssetPrice',
    request,
    metadata || {},
    this.methodDescriptorQueryAssetPrice);
  }

  methodDescriptorQueryAssetsSymbols = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryAssetsSymbols',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryAssetsSymbolsRequest,
    sgn_cbridge_v1_query_pb.QueryAssetsSymbolsResponse,
    (request: sgn_cbridge_v1_query_pb.QueryAssetsSymbolsRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryAssetsSymbolsResponse.deserializeBinary
  );

  queryAssetsSymbols(
    request: sgn_cbridge_v1_query_pb.QueryAssetsSymbolsRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryAssetsSymbolsResponse>;

  queryAssetsSymbols(
    request: sgn_cbridge_v1_query_pb.QueryAssetsSymbolsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetsSymbolsResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryAssetsSymbolsResponse>;

  queryAssetsSymbols(
    request: sgn_cbridge_v1_query_pb.QueryAssetsSymbolsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetsSymbolsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryAssetsSymbols',
        request,
        metadata || {},
        this.methodDescriptorQueryAssetsSymbols,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryAssetsSymbols',
    request,
    metadata || {},
    this.methodDescriptorQueryAssetsSymbols);
  }

  methodDescriptorQueryAssetsInfos = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryAssetsInfos',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryAssetsInfosRequest,
    sgn_cbridge_v1_query_pb.QueryAssetsInfosResponse,
    (request: sgn_cbridge_v1_query_pb.QueryAssetsInfosRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryAssetsInfosResponse.deserializeBinary
  );

  queryAssetsInfos(
    request: sgn_cbridge_v1_query_pb.QueryAssetsInfosRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryAssetsInfosResponse>;

  queryAssetsInfos(
    request: sgn_cbridge_v1_query_pb.QueryAssetsInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetsInfosResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryAssetsInfosResponse>;

  queryAssetsInfos(
    request: sgn_cbridge_v1_query_pb.QueryAssetsInfosRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryAssetsInfosResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryAssetsInfos',
        request,
        metadata || {},
        this.methodDescriptorQueryAssetsInfos,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryAssetsInfos',
    request,
    metadata || {},
    this.methodDescriptorQueryAssetsInfos);
  }

  methodDescriptorQueryGasPrice = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryGasPrice',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryGasPriceRequest,
    sgn_cbridge_v1_query_pb.QueryGasPriceResponse,
    (request: sgn_cbridge_v1_query_pb.QueryGasPriceRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryGasPriceResponse.deserializeBinary
  );

  queryGasPrice(
    request: sgn_cbridge_v1_query_pb.QueryGasPriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryGasPriceResponse>;

  queryGasPrice(
    request: sgn_cbridge_v1_query_pb.QueryGasPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryGasPriceResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryGasPriceResponse>;

  queryGasPrice(
    request: sgn_cbridge_v1_query_pb.QueryGasPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryGasPriceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryGasPrice',
        request,
        metadata || {},
        this.methodDescriptorQueryGasPrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryGasPrice',
    request,
    metadata || {},
    this.methodDescriptorQueryGasPrice);
  }

  methodDescriptorChainTokensConfig = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/ChainTokensConfig',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.ChainTokensConfigRequest,
    sgn_cbridge_v1_query_pb.ChainTokensConfigResponse,
    (request: sgn_cbridge_v1_query_pb.ChainTokensConfigRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.ChainTokensConfigResponse.deserializeBinary
  );

  chainTokensConfig(
    request: sgn_cbridge_v1_query_pb.ChainTokensConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.ChainTokensConfigResponse>;

  chainTokensConfig(
    request: sgn_cbridge_v1_query_pb.ChainTokensConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.ChainTokensConfigResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.ChainTokensConfigResponse>;

  chainTokensConfig(
    request: sgn_cbridge_v1_query_pb.ChainTokensConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.ChainTokensConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/ChainTokensConfig',
        request,
        metadata || {},
        this.methodDescriptorChainTokensConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/ChainTokensConfig',
    request,
    metadata || {},
    this.methodDescriptorChainTokensConfig);
  }

  methodDescriptorGetFee = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/GetFee',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.GetFeeRequest,
    sgn_cbridge_v1_query_pb.GetFeeResponse,
    (request: sgn_cbridge_v1_query_pb.GetFeeRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.GetFeeResponse.deserializeBinary
  );

  getFee(
    request: sgn_cbridge_v1_query_pb.GetFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.GetFeeResponse>;

  getFee(
    request: sgn_cbridge_v1_query_pb.GetFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.GetFeeResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.GetFeeResponse>;

  getFee(
    request: sgn_cbridge_v1_query_pb.GetFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.GetFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/GetFee',
        request,
        metadata || {},
        this.methodDescriptorGetFee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/GetFee',
    request,
    metadata || {},
    this.methodDescriptorGetFee);
  }

  methodDescriptorGetFeePercentage = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/GetFeePercentage',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.GetFeePercentageRequest,
    sgn_cbridge_v1_query_pb.GetFeePercentageResponse,
    (request: sgn_cbridge_v1_query_pb.GetFeePercentageRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.GetFeePercentageResponse.deserializeBinary
  );

  getFeePercentage(
    request: sgn_cbridge_v1_query_pb.GetFeePercentageRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.GetFeePercentageResponse>;

  getFeePercentage(
    request: sgn_cbridge_v1_query_pb.GetFeePercentageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.GetFeePercentageResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.GetFeePercentageResponse>;

  getFeePercentage(
    request: sgn_cbridge_v1_query_pb.GetFeePercentageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.GetFeePercentageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/GetFeePercentage',
        request,
        metadata || {},
        this.methodDescriptorGetFeePercentage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/GetFeePercentage',
    request,
    metadata || {},
    this.methodDescriptorGetFeePercentage);
  }

  methodDescriptorQueryTransferStatus = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryTransferStatus',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryTransferStatusRequest,
    sgn_cbridge_v1_query_pb.QueryTransferStatusResponse,
    (request: sgn_cbridge_v1_query_pb.QueryTransferStatusRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryTransferStatusResponse.deserializeBinary
  );

  queryTransferStatus(
    request: sgn_cbridge_v1_query_pb.QueryTransferStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryTransferStatusResponse>;

  queryTransferStatus(
    request: sgn_cbridge_v1_query_pb.QueryTransferStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryTransferStatusResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryTransferStatusResponse>;

  queryTransferStatus(
    request: sgn_cbridge_v1_query_pb.QueryTransferStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryTransferStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryTransferStatus',
        request,
        metadata || {},
        this.methodDescriptorQueryTransferStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryTransferStatus',
    request,
    metadata || {},
    this.methodDescriptorQueryTransferStatus);
  }

  methodDescriptorLiquidityDetailList = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/LiquidityDetailList',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.LiquidityDetailListRequest,
    sgn_cbridge_v1_query_pb.LiquidityDetailListResponse,
    (request: sgn_cbridge_v1_query_pb.LiquidityDetailListRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.LiquidityDetailListResponse.deserializeBinary
  );

  liquidityDetailList(
    request: sgn_cbridge_v1_query_pb.LiquidityDetailListRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.LiquidityDetailListResponse>;

  liquidityDetailList(
    request: sgn_cbridge_v1_query_pb.LiquidityDetailListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.LiquidityDetailListResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.LiquidityDetailListResponse>;

  liquidityDetailList(
    request: sgn_cbridge_v1_query_pb.LiquidityDetailListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.LiquidityDetailListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/LiquidityDetailList',
        request,
        metadata || {},
        this.methodDescriptorLiquidityDetailList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/LiquidityDetailList',
    request,
    metadata || {},
    this.methodDescriptorLiquidityDetailList);
  }

  methodDescriptorQueryTotalLiquidity = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryTotalLiquidity',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryTotalLiquidityRequest,
    sgn_cbridge_v1_query_pb.QueryTotalLiquidityResponse,
    (request: sgn_cbridge_v1_query_pb.QueryTotalLiquidityRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryTotalLiquidityResponse.deserializeBinary
  );

  queryTotalLiquidity(
    request: sgn_cbridge_v1_query_pb.QueryTotalLiquidityRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryTotalLiquidityResponse>;

  queryTotalLiquidity(
    request: sgn_cbridge_v1_query_pb.QueryTotalLiquidityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryTotalLiquidityResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryTotalLiquidityResponse>;

  queryTotalLiquidity(
    request: sgn_cbridge_v1_query_pb.QueryTotalLiquidityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryTotalLiquidityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryTotalLiquidity',
        request,
        metadata || {},
        this.methodDescriptorQueryTotalLiquidity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryTotalLiquidity',
    request,
    metadata || {},
    this.methodDescriptorQueryTotalLiquidity);
  }

  methodDescriptorQueryAddLiquidityStatus = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryAddLiquidityStatus',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryAddLiquidityStatusRequest,
    sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse,
    (request: sgn_cbridge_v1_query_pb.QueryAddLiquidityStatusRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse.deserializeBinary
  );

  queryAddLiquidityStatus(
    request: sgn_cbridge_v1_query_pb.QueryAddLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse>;

  queryAddLiquidityStatus(
    request: sgn_cbridge_v1_query_pb.QueryAddLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse>;

  queryAddLiquidityStatus(
    request: sgn_cbridge_v1_query_pb.QueryAddLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryAddLiquidityStatus',
        request,
        metadata || {},
        this.methodDescriptorQueryAddLiquidityStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryAddLiquidityStatus',
    request,
    metadata || {},
    this.methodDescriptorQueryAddLiquidityStatus);
  }

  methodDescriptorQueryWithdrawLiquidityStatus = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryWithdrawLiquidityStatus',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryWithdrawLiquidityStatusRequest,
    sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse,
    (request: sgn_cbridge_v1_query_pb.QueryWithdrawLiquidityStatusRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse.deserializeBinary
  );

  queryWithdrawLiquidityStatus(
    request: sgn_cbridge_v1_query_pb.QueryWithdrawLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse>;

  queryWithdrawLiquidityStatus(
    request: sgn_cbridge_v1_query_pb.QueryWithdrawLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse>;

  queryWithdrawLiquidityStatus(
    request: sgn_cbridge_v1_query_pb.QueryWithdrawLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLiquidityStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryWithdrawLiquidityStatus',
        request,
        metadata || {},
        this.methodDescriptorQueryWithdrawLiquidityStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryWithdrawLiquidityStatus',
    request,
    metadata || {},
    this.methodDescriptorQueryWithdrawLiquidityStatus);
  }

  methodDescriptorQueryLPOrigin = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryLPOrigin',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryLPOriginRequest,
    sgn_cbridge_v1_query_pb.QueryLPOriginResponse,
    (request: sgn_cbridge_v1_query_pb.QueryLPOriginRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryLPOriginResponse.deserializeBinary
  );

  queryLPOrigin(
    request: sgn_cbridge_v1_query_pb.QueryLPOriginRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryLPOriginResponse>;

  queryLPOrigin(
    request: sgn_cbridge_v1_query_pb.QueryLPOriginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLPOriginResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryLPOriginResponse>;

  queryLPOrigin(
    request: sgn_cbridge_v1_query_pb.QueryLPOriginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLPOriginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryLPOrigin',
        request,
        metadata || {},
        this.methodDescriptorQueryLPOrigin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryLPOrigin',
    request,
    metadata || {},
    this.methodDescriptorQueryLPOrigin);
  }

  methodDescriptorQueryLiquidity = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryLiquidity',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryLiquidityRequest,
    sgn_cbridge_v1_query_pb.QueryLiquidityResponse,
    (request: sgn_cbridge_v1_query_pb.QueryLiquidityRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryLiquidityResponse.deserializeBinary
  );

  queryLiquidity(
    request: sgn_cbridge_v1_query_pb.QueryLiquidityRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryLiquidityResponse>;

  queryLiquidity(
    request: sgn_cbridge_v1_query_pb.QueryLiquidityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLiquidityResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryLiquidityResponse>;

  queryLiquidity(
    request: sgn_cbridge_v1_query_pb.QueryLiquidityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLiquidityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryLiquidity',
        request,
        metadata || {},
        this.methodDescriptorQueryLiquidity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryLiquidity',
    request,
    metadata || {},
    this.methodDescriptorQueryLiquidity);
  }

  methodDescriptorQueryLPs = new grpcWeb.MethodDescriptor(
    '/sgn.cbridge.v1.Query/QueryLPs',
    grpcWeb.MethodType.UNARY,
    sgn_cbridge_v1_query_pb.QueryLPsRequest,
    sgn_cbridge_v1_query_pb.QueryLPsResponse,
    (request: sgn_cbridge_v1_query_pb.QueryLPsRequest) => {
      return request.serializeBinary();
    },
    sgn_cbridge_v1_query_pb.QueryLPsResponse.deserializeBinary
  );

  queryLPs(
    request: sgn_cbridge_v1_query_pb.QueryLPsRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_cbridge_v1_query_pb.QueryLPsResponse>;

  queryLPs(
    request: sgn_cbridge_v1_query_pb.QueryLPsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLPsResponse) => void): grpcWeb.ClientReadableStream<sgn_cbridge_v1_query_pb.QueryLPsResponse>;

  queryLPs(
    request: sgn_cbridge_v1_query_pb.QueryLPsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_cbridge_v1_query_pb.QueryLPsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.cbridge.v1.Query/QueryLPs',
        request,
        metadata || {},
        this.methodDescriptorQueryLPs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.cbridge.v1.Query/QueryLPs',
    request,
    metadata || {},
    this.methodDescriptorQueryLPs);
  }

}

