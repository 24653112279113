import { GithubFilled, TwitterCircleFilled } from "@ant-design/icons";
import { DiscordCircleFilled, TelegramCircleFilled } from "../../icons";
import "./footer.less";

export default function Footer(): JSX.Element {
  return (
    <div className="footer">
      <span className="title">Powered by Celer Network</span>
      <div className="contact-us">
        <DiscordCircleFilled onClick={() => window.open("https://discord.gg/uGx4fjQ", "_blank")} />
        <TelegramCircleFilled onClick={() => window.open("https://t.me/celernetwork", "_blank")} />
        <TwitterCircleFilled onClick={() => window.open("https://twitter.com/CelerNetwork", "_blank")} />
        <GithubFilled onClick={() => window.open("https://github.com/celer-network", "_blank")} />
      </div>
    </div>
  );
}
