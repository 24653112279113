import { getNetworkById } from "../constants/network";

const STORAGE_KEY_NAMES = {
  rpcUrl: "rpcUrl",
};
const chainId = process.env.REACT_APP_NETWORK_ID;
const sgnGateway = process.env.REACT_APP_SGN_GATEWAY;
const sgnGatewayOther = process.env.REACT_APP_SGN_GATEWAY_OTHER;
const url = getNetworkById(Number(chainId))?.rpcUrl;
const explorerUrl = getNetworkById(Number(chainId))?.blockExplorerUrl;

export const setRpcUrl = (rpcUrl: string): void => {
  localStorage.setItem(STORAGE_KEY_NAMES.rpcUrl, rpcUrl);
};

export const getRpcUrl = (): string | undefined => {
  return localStorage.getItem(STORAGE_KEY_NAMES.rpcUrl) || url;
};

export const getGatewayUrl = (): string | undefined => {
  return sgnGateway;
}

export const getGatewayOtherUrl = (): string | undefined => {
  return sgnGatewayOther;
}

export const getExlporerUrl = (): string | undefined => {
  return explorerUrl;
}