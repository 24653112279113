import { useState } from "react";
import { Modal, Button } from "antd";
import { BigNumber } from "@ethersproject/bignumber";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { formatDecimalWithSeparation } from "celer-web-utils/lib/format";
import { useContractsContext } from "../../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { useNonEVMContext, NonEVMMode, getNonEVMMode, isNonEVMChain } from "../../../providers/NonEVMContextProvider";
import { getWithdrawInfo, getChainSigners, signAgain } from "../../../api";
import { storageConstants } from "../../../constants/const";
import { LPHistory } from "../../../constants/claimType";
import { withdrawFlowFeeReward } from "../../../redux/NonEVMAPIs/flowAPIs";
import { setRefreshData } from "../../../redux/transferSlice";
import { setSelectedChainId } from "../../../redux/globalInfoSlice";

import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { formatBlockExplorerUrlWithTxHash } from "../../../utils/formatUrl";
import getTokenNameByTokenSymbol from "../../../helpers/getTokenNameBySymbolAndChainId";
import { ClaimHistory, ClaimStatus, SignAgainRequest, SignAgainType } from "../../../proto/gateway/gateway_pb";
import { QueryWithdrawInfoRequest } from "../../../proto/sgn/pegbridge/v1/query_pb";
import { QueryChainSignersRequest } from "../../../proto/sgn/cbridge/v1/query_pb";
import "./historyModal.less";
import successImg from "../../../assets/images/success.png";
import SwitchChainModal from "../../switchChainModal";
import ProviderModal from "../../providerModal";
import { needToSignAgain } from "../../singleChainWithdrawModal";

const INIT = "INIT";
const SUCCESS = "SUCCESS";
type ConfirmStatus = "INIT" | "SUCCESS";

/* eslint-disable no-debugger */
/* eslint-disable camelcase */

interface ConfirmIProps {
  info: ClaimHistory.AsObject | undefined;
  visible: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const PegHistoryConfirmModal = (props: ConfirmIProps) => {
  const { visible, onClose, onOpen, info } = props;
  const { address, chainId } = useWeb3Context();
  const {
    contracts: { originalTokenVault },
    transactor,
  } = useContractsContext();
  const { nonEVMConnected } = useNonEVMContext();
  const { configs } = useAppSelector(state => state.globalInfo);
  const dispatch = useAppDispatch();
  const [confirmStatus, setConfirmStatus] = useState<ConfirmStatus>(INIT);
  const [switchChainId, setSwitchChainId] = useState<number>(chainId);
  const [switchChainModalVisible, setSwitchChainModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewInExploreLink, setViewInExploreLink] = useState<string>("");
  const [providerVisible, setProviderVisible] = useState(false);

  // useEffect(() => {
  //   if (!info) {
  //     return;
  //   }
  //   const targetChainId = info.chain?.id;
  //   const targetSymbol = info.token?.token?.symbol;

  //   const { peggedPairConfigsList } = configs;
  //   const config = peggedPairConfigsList.find(
  //     item => item.orgChainId === targetChainId && item.peggedToken?.token?.symbol === targetSymbol,
  //   );
  //   // console.log({ peggedDepositContractAddr: config?.peggedDepositContractAddr });
  //   dispatch(setOTContractAddr(config?.peggedDepositContractAddr || ""));
  // }, [configs, info, dispatch]);

  if (!info) {
    return null;
  }

  const amountStr = formatDecimalWithSeparation(info.amount, 6, info.token?.token?.decimal);

  const claimSuccessMethod = () => {
    setConfirmStatus(INIT);
    onClose();
  };

  const isRightOriginalTokenVault = () => {
    const originalTokenVaultArrd = originalTokenVault?.address;
    const targetOriginalTokenVaultArrd = configs.peggedPairConfigsList.find(
      item => item.orgChainId === info.chain?.id && item.peggedToken?.token?.symbol === info.token?.token?.symbol,
    )?.peggedDepositContractAddr;
    return originalTokenVaultArrd === targetOriginalTokenVaultArrd;
  };

  const signAgainMethod = async ({ paramChainId, nonce, addr }) => {
    const signAgainRequest = new SignAgainRequest();
    signAgainRequest.setType(SignAgainType.SAT_CANONICAL);
    signAgainRequest.setUsrAddr(addr);
    signAgainRequest.setChainId(paramChainId);
    signAgainRequest.setNonce(nonce);
    return signAgain(signAgainRequest);
  };

  const afterSignAgainMthod = async () => {
    if (!transactor || !originalTokenVault || !info) {
      return;
    }
    const getWithdrawInfoReq = new QueryWithdrawInfoRequest();
    getWithdrawInfoReq.setWithdrawId(hexlify(info.withdrawId));
    const withdrawInfo = await getWithdrawInfo(getWithdrawInfoReq);
    const withdrawProtoBytes = withdrawInfo.getWithdrawInfo()?.getWithdrawProtoBytes();
    const signatures = withdrawInfo.getWithdrawInfo()?.getSignaturesList();

    if (!signatures) {
      return;
    }
    const sigs = signatures.map(item => {
      const sig = item.getSigBytes();
      return sig;
    });

    const getChainSignersReq = new QueryChainSignersRequest();
    getChainSignersReq.setChainId(info.chain?.id || chainId);
    const chainSignersRes = await getChainSigners(getChainSignersReq);
    const sortedSignersList = chainSignersRes.getChainSigners()?.getSortedSignersList();
    if (!sortedSignersList) {
      return;
    }
    const powers = sortedSignersList.map(item => {
      const power = item.getPower();
      return BigNumber.from(power);
    });

    const signers = sortedSignersList.map(item => {
      const decodeSigners = base64.decode(item.getAddr_asB64());
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    let gasLimit = await originalTokenVault?.estimateGas.withdraw(withdrawProtoBytes || "", sigs, signers, powers);
    gasLimit = gasLimit.mul(13).div(10);
    const resultTx = await transactor(
      originalTokenVault?.withdraw(withdrawProtoBytes || "", sigs, signers, powers, { gasLimit }),
    );
    if (resultTx) {
      const newtxStr = JSON.stringify(resultTx);
      const newtx = JSON.parse(newtxStr);
      if (resultTx instanceof Error || newtx.code) {
        console.log("get before error", resultTx);
        setLoading(false);
      } else {
        const blockTxLink = formatBlockExplorerUrlWithTxHash({
          chainId: info.chain?.id || chainId,
          txHash: resultTx.hash,
        });
        setViewInExploreLink(blockTxLink);
        setConfirmStatus(SUCCESS);
        setLoading(false);
        const newLPJson: LPHistory = {
          chain: info?.chain,
          token: info?.token,
          amount: info?.amount,
          ts: info?.ts,
          blockTxLink,
          status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
          methodType: info?.methodType,
          seqNum: Number(info?.seqNum),
          nonce: info.nonce,
          // nonce: resultTx.nonce,
          isLocal: true,
          updateTime: new Date().getTime(),
          txIsFailed: false,
          withdrawId: info.withdrawId,
        };
        const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
        let localLpList: LPHistory[] = [];
        if (localLpListStr) {
          localLpList = JSON.parse(localLpListStr)[address] || [];
        }
        let isHave = false;
        localLpList.map(item => {
          if (Number(item.withdrawId) === Number(info.withdrawId)) {
            isHave = true;
            item.updateTime = new Date().getTime();
            item.txIsFailed = false;
            item.blockTxLink = formatBlockExplorerUrlWithTxHash({
              chainId: info?.chain?.id || chainId,
              txHash: resultTx.hash,
            });
          }
          return item;
        });
        if (!isHave) {
          localLpList.unshift(newLPJson);
        }
        const newJson = { [address]: localLpList };
        localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
      }
    }
  };

  const confirmMethod = async () => {
    dispatch(setSelectedChainId(info.chain?.id || -1));
    if (info.chain?.id !== chainId) {
      setSwitchChainId(info.chain?.id || chainId);
      setSwitchChainModalVisible(true);
      props.onClose();
    } else {
      if (!transactor || !originalTokenVault) {
        return;
      }
      if (!isRightOriginalTokenVault()) {
        console.log("error: wrong originalTokenVault addr");
        return;
      }
      const getWithdrawInfoReq = new QueryWithdrawInfoRequest();
      getWithdrawInfoReq.setWithdrawId(hexlify(info.withdrawId));
      const withdrawInfo = await getWithdrawInfo(getWithdrawInfoReq);
      const withdrawProtoBytes = withdrawInfo.getWithdrawInfo()?.getWithdrawProtoBytes();
      const signatures = withdrawInfo.getWithdrawInfo()?.getSignaturesList();

      if (!signatures) {
        return;
      }
      const sigs = signatures.map(item => {
        const sig = item.getSigBytes();
        return sig;
      });

      const getChainSignersReq = new QueryChainSignersRequest();
      getChainSignersReq.setChainId(info.chain.id);
      const chainSignersRes = await getChainSigners(getChainSignersReq);
      const sortedSignersList = chainSignersRes.getChainSigners()?.getSortedSignersList();
      if (!sortedSignersList) {
        return;
      }
      const powers = sortedSignersList.map(item => {
        const power = item.getPower();
        return BigNumber.from(power);
      });

      const signers = sortedSignersList.map(item => {
        const decodeSigners = base64.decode(item.getAddr_asB64());
        const hexlifyObj = hexlify(decodeSigners);
        return getAddress(hexlifyObj);
      });
      const nonEvmMode = getNonEVMMode(info.chain.id);
      if (nonEvmMode === NonEVMMode.flowTest || nonEvmMode === NonEVMMode.flowMainnet) {
        try {
          setLoading(true);
          const targetSymbol = info.token?.token?.symbol;
          const { peggedPairConfigsList } = configs;
          const depositContractAddress =
            peggedPairConfigsList.find(
              item => item.orgChainId === info.chain?.id && item.peggedToken?.token?.symbol === targetSymbol,
            )?.peggedDepositContractAddr ?? "";
          const _wdmsg = base64.decode((withdrawProtoBytes || "").toString());
          const _sigs = sigs.map(item => {
            return base64.decode(item.toString());
          });
          const resultTx = await withdrawFlowFeeReward(
            depositContractAddress,
            info.token?.token?.address || "",
            _wdmsg,
            _sigs,
          );
          console.log("txHash", resultTx);
          if (resultTx.length > 0) {
            const blockTxLink = formatBlockExplorerUrlWithTxHash({ chainId: info?.chain.id, txHash: resultTx });
            setViewInExploreLink(blockTxLink);
            setConfirmStatus(SUCCESS);
            setLoading(false);
            const newLPJson: LPHistory = {
              chain: info?.chain,
              token: info?.token,
              amount: info?.amount,
              ts: info?.ts,
              blockTxLink,
              status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
              methodType: info?.methodType,
              seqNum: Number(info?.seqNum),
              nonce: info.nonce,
              // nonce: resultTx.nonce,
              isLocal: true,
              updateTime: new Date().getTime(),
              txIsFailed: false,
              withdrawId: info.withdrawId,
            };
            const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
            let localLpList: LPHistory[] = [];
            if (localLpListStr) {
              localLpList = JSON.parse(localLpListStr)[address] || [];
            }
            let isHave = false;
            localLpList.map(item => {
              if (Number(item.withdrawId) === Number(info.withdrawId)) {
                isHave = true;
                item.updateTime = new Date().getTime();
                item.txIsFailed = false;
                item.blockTxLink = formatBlockExplorerUrlWithTxHash({
                  chainId: info?.chain?.id || chainId,
                  txHash: resultTx,
                });
              }
              return item;
            });
            if (!isHave) {
              localLpList.unshift(newLPJson);
            }
            const newJson = { [address]: localLpList };
            localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
          }
        } catch (e) {
          if (needToSignAgain(e)) {
            const signAgainRes = await signAgainMethod({
              addr: address,
              paramChainId: info.chain.id,
              nonce: info.nonce,
            });
            console.log({ signAgainRes });
            try {
              await afterSignAgainMthod();
              setLoading(false);
            } catch (err) {
              console.log("catch afterSignAgainMthod error", err);
              setConfirmStatus(INIT);
              setLoading(false);
            }
          } else {
            console.log("catch tx error", e);
            setConfirmStatus(INIT);
            setLoading(false);
          }
        }
      } else {
        try {
          setLoading(true);
          let gasLimit = await originalTokenVault?.estimateGas.withdraw(
            withdrawProtoBytes || "",
            sigs,
            signers,
            powers,
          );
          gasLimit = gasLimit.mul(13).div(10);
          const resultTx = await transactor(
            originalTokenVault?.withdraw(withdrawProtoBytes || "", sigs, signers, powers, { gasLimit }),
          );
          if (resultTx) {
            const newtxStr = JSON.stringify(resultTx);
            const newtx = JSON.parse(newtxStr);
            if (resultTx instanceof Error || newtx.code) {
              console.log("get before error", resultTx);
              setLoading(false);
            } else {
              const blockTxLink = formatBlockExplorerUrlWithTxHash({ chainId: info?.chain.id, txHash: resultTx.hash });
              setViewInExploreLink(blockTxLink);
              setConfirmStatus(SUCCESS);
              setLoading(false);
              const newLPJson: LPHistory = {
                chain: info?.chain,
                token: info?.token,
                amount: info?.amount,
                ts: info?.ts,
                blockTxLink,
                status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
                methodType: info?.methodType,
                seqNum: Number(info?.seqNum),
                nonce: info.nonce,
                // nonce: resultTx.nonce,
                isLocal: true,
                updateTime: new Date().getTime(),
                txIsFailed: false,
                withdrawId: info.withdrawId,
              };
              const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
              let localLpList: LPHistory[] = [];
              if (localLpListStr) {
                localLpList = JSON.parse(localLpListStr)[address] || [];
              }
              let isHave = false;
              localLpList.map(item => {
                if (Number(item.withdrawId) === Number(info.withdrawId)) {
                  isHave = true;
                  item.updateTime = new Date().getTime();
                  item.txIsFailed = false;
                  item.blockTxLink = formatBlockExplorerUrlWithTxHash({
                    chainId: info?.chain?.id || chainId,
                    txHash: resultTx.hash,
                  });
                }
                return item;
              });
              if (!isHave) {
                localLpList.unshift(newLPJson);
              }
              const newJson = { [address]: localLpList };
              localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
            }
          }
        } catch (e) {
          if (needToSignAgain(e)) {
            const signAgainRes = await signAgainMethod({
              addr: address,
              paramChainId: info.chain.id,
              nonce: info.nonce,
            });
            console.log({ signAgainRes });
            try {
              await afterSignAgainMthod();
              setLoading(false);
            } catch (err) {
              console.log("catch afterSignAgainMthod error", err);
              setConfirmStatus(INIT);
              setLoading(false);
            }
          } else {
            console.log("catch tx error", e);
            setConfirmStatus(INIT);
            setLoading(false);
          }
        }
      }
    }
  };

  const confirmClaimFlowFee = async () => {
    if (!info) {
      return;
    }

    setLoading(true);
    const getWithdrawInfoReq = new QueryWithdrawInfoRequest();
    getWithdrawInfoReq.setWithdrawId(hexlify(info.withdrawId));
    const withdrawInfo = await getWithdrawInfo(getWithdrawInfoReq);
    const withdrawProtoBytes = withdrawInfo.getWithdrawInfo()?.getWithdrawProtoBytes() as Uint8Array;
    const signatures = withdrawInfo.getWithdrawInfo()?.getSignaturesList();
    if (!signatures) {
      return;
    }
    const sigs = signatures.map(item => {
      const sig = item.getSigBytes();
      return sig;
    }) as Uint8Array[];
    try {
      setLoading(true);
      try {
        const targetSymbol = info.token?.token?.symbol;
        const { peggedPairConfigsList } = configs;
        const depositContractAddress =
          peggedPairConfigsList.find(
            item => item.orgChainId === info.chain?.id && item.peggedToken?.token?.symbol === targetSymbol,
          )?.peggedDepositContractAddr ?? "";
        const resultTx = await withdrawFlowFeeReward(
          depositContractAddress,
          info.token?.token?.address || "",
          withdrawProtoBytes,
          sigs,
        );
        console.log("txHash", resultTx);
        if (resultTx.length > 0) {
          const blockTxLink = formatBlockExplorerUrlWithTxHash({
            chainId: info?.chain?.id || 0,
            txHash: resultTx,
            isFlow: true,
          });
          setViewInExploreLink(blockTxLink);
          setConfirmStatus(SUCCESS);
          setLoading(false);
          const newLPJson: LPHistory = {
            chain: info?.chain,
            token: info?.token,
            amount: info?.amount,
            ts: info?.ts,
            blockTxLink,
            status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
            methodType: info?.methodType,
            seqNum: Number(info?.seqNum),
            nonce: info.nonce,
            // nonce: resultTx.nonce,
            isLocal: true,
            updateTime: new Date().getTime(),
            txIsFailed: false,
            withdrawId: info.withdrawId,
          };
          const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
          let localLpList: LPHistory[] = [];
          if (localLpListStr) {
            localLpList = JSON.parse(localLpListStr)[address] || [];
          }
          let isHave = false;
          localLpList.map(item => {
            if (Number(item.withdrawId) === Number(info.withdrawId)) {
              isHave = true;
              item.updateTime = new Date().getTime();
              item.txIsFailed = false;
              item.blockTxLink = formatBlockExplorerUrlWithTxHash({
                chainId: info?.chain?.id || chainId,
                txHash: resultTx,
                isFlow: true,
              });
            }
            return item;
          });
          if (!isHave) {
            localLpList.unshift(newLPJson);
          }
          const newJson = { [address]: localLpList };
          localStorage.setItem(storageConstants.KEY_PEG_LP_LIST, JSON.stringify(newJson));
        }
      } catch (e) {
        setConfirmStatus(INIT);
        setLoading(false);
      }
    } catch (e) {
      setConfirmStatus(INIT);
      setLoading(false);
    } finally {
      //   setLoading(false);
    }
  };

  const renderDesc = () => {
    if (!nonEVMConnected) {
      return (
        <div className="claim-history-confirm-tooltip">
          You have pending rewards claim to be confirmed. Please connect Flow Wallet to complete the fee rewards claim
          process.
        </div>
      );
    }
    return (
      <div className="claim-history-confirm-tooltip">
        You have pending rewards claim to be confirmed. Please click &quot;Confirm Claim&quot; button to complete the
        fee rewards claim process.
      </div>
    );
  };

  const renderBtn = () => {
    const isNonEvm = isNonEVMChain(info.chain?.id || -1);
    if (isNonEvm) {
      if (!nonEVMConnected) {
        return (
          <Button
            type="text"
            block
            className="confirm-btn flow-btn"
            onClick={() => {
              dispatch(setSelectedChainId(info.chain?.id || -1));
              setProviderVisible(true);
            }}
          >
            <div className="btn-main-text">Connect Flow wallet</div>
            <div className="btn-sub-text">to claim fee rewards</div>
          </Button>
        );
      }
      return (
        <Button type="text" block className="confirm-btn" loading={loading} onClick={confirmClaimFlowFee}>
          Confirm Rewards Claim
        </Button>
      );
    }
    return (
      <Button type="text" block className="confirm-btn" loading={loading} onClick={confirmMethod}>
        Confirm Rewards Claim
      </Button>
    );
  };

  const renderContent = () => {
    const tokenName = getTokenNameByTokenSymbol(info.token?.token?.symbol || "", Number(info.chain?.id));
    switch (confirmStatus) {
      case INIT: {
        return (
          <div className="claim-history-confirm">
            <div className="claim-history-confirm-info">
              <div className="chain-info">
                <img src={info.chain?.icon} alt="" className="chain-info-icon" />
                <span className="chain-info-name">{info.chain?.name}</span>{" "}
              </div>
              <div className="token-info">
                <div className="token-info-amount">
                  {amountStr} {tokenName}
                </div>
              </div>
            </div>
            {renderDesc()}
            <div className="claim-history-confirm-btn">{renderBtn()}</div>
          </div>
        );
      }
      case SUCCESS:
        return (
          <div className="claim-history-success">
            <div className="success-icon">
              <img src={successImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Success</div>
              <div className="sub-title">
                Claim {amountStr} {tokenName} cBridge fee reward from {info.chain?.name}
              </div>
            </div>
            <div className="view-in-explorer">
              <a href={viewInExploreLink} target="_blank" rel="noreferrer">
                View in Explorer
              </a>
            </div>
            <div className="desc">Please allow a few minutes before the transaction completes.</div>
            <div className="btn-block">
              <Button type="text" block className="ok-btn" onClick={claimSuccessMethod}>
                OK
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        className="history-confirm-modal"
        title="History"
        visible={visible}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          onClose();
          dispatch(setRefreshData());
        }}
        style={{ visibility: providerVisible ? "hidden" : "visible" }}
      >
        {renderContent()}
      </Modal>
      <SwitchChainModal
        chainId={switchChainId}
        visible={switchChainModalVisible}
        closeMethod={() => {
          setSwitchChainModalVisible(false);
        }}
        afterSwitchMethod={() => {
          onOpen();
        }}
      />
      <ProviderModal visible={providerVisible} onCancel={() => setProviderVisible(false)} />
    </>
  );
};

export default PegHistoryConfirmModal;
