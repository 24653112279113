import { Modal, Button } from "antd";
import { isMobile } from "react-device-detect";
import { closeModal, ModalName } from "../../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import "./switchChainModal.less";
import warningImg from "../../assets/images/warning.png";
import { setRefreshData } from "../../redux/transferSlice";

const SwitchChainModal = () => {
  const { configs } = useAppSelector(state => state.globalInfo);
  const { chainsList } = configs;
  const { showSwitchChainModal } = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();
  const targetChainId = Number(process.env.REACT_APP_NETWORK_ID) || 883;

  const switchChainMethod = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${targetChainId.toString(16)}` }],
      });
      closeMethod();
      // window.location.reload();
    } catch (e) {
      console.log("switch fails");
      if (isMobile) {
        closeMethod();
      }
    }
  };

  const closeMethod = () => {
    dispatch(closeModal(ModalName.switchChain));
    dispatch(setRefreshData());
  };

  return (
    <Modal title=" " className="feeShare-modal" visible={showSwitchChainModal} onCancel={closeMethod} footer={null}>
      <div className="switch-block">
        <div className="warning">
          <div className="img-block">
            <img src={warningImg} alt="" />
          </div>
          <div className="warning-message">
            Please switch to {chainsList?.find(item => item.id === Number(targetChainId))?.name} before interacting with
            Celer SGN.
          </div>
        </div>
        <div className="btn-block">
          <Button type="primary" block className="ok-btn" onClick={switchChainMethod}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchChainModal;
