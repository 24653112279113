import { useEffect, useState } from "react";
import { Modal, Button, Spin, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { BytesLike } from "@ethersproject/bytes";
import { convertUSD, formatDecimal, formatDecimalWithSeparation } from "celer-web-utils/lib/format";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  CBridgeFeeShareInfo,
  getChainSigners,
  getMessageBusInfo,
  claimMsgFee,
  getImFeeClaimInfo,
  FeeClaimDetail,
} from "../../api";

import { Chain, ClaimMsgFeeRequest } from "../../proto/gateway/gateway_pb";
import { QueryChainSignersRequest } from "../../proto/sgn/cbridge/v1/query_pb";

import "./IMFeeShareModal.less";
import celerIM from "../../assets/images/celerIM.png";
import warningImg from "../../assets/images/warning.png";
import successImg from "../../assets/images/success.png";
import { delegateSplitThousandSeparator, formatCelrWithoutSymbol, formatRemoveDecimals } from "../../helpers/format";
import getTokenNameByTokenSymbol from "../../helpers/getTokenNameBySymbolAndChainId";
import { setRefreshData } from "../../redux/transferSlice";
import { getNetworkById } from "../../constants/network";
import getTokenInfoByChainIdAndTokenSymbol from "../../utils/getTokenInfoByChainIdAndTokenSymbol";
import { MessageBusSender } from "../../typechain/MessageBusSender";
import { MessageBusSender__factory } from "../../typechain/factories/MessageBusSender__factory";
import lockLoadingPng from "../../assets/images/lockLoading.png";
import arrowDownPng from "../../assets/images/arrowDown.png";
import { ensureSigner } from "../../hooks/contractLoaders";

/* eslint-disable */

/**
 * 1. gateway ClaimFee
 * 2. wait 30 seconds
 * 3. SGN QueryFeeClaimInfo
 * 4. SGN QueryWithdrawInfo
 * 5. OriginalTokenVault.withdraw(…)
 */

interface IProps {
  visible: boolean;
  onClose: () => void;
  feeShareList: Array<CBridgeFeeShareInfo>;
  onClaimSuccess: () => Promise<Array<CBridgeFeeShareInfo>>;
  feeSharetokenUsdMap: Map<string, number>;
}

interface SelectedInfo {
  amount: BigNumber;
  lockedAmount: BigNumber;
  chainInfo?: Chain.AsObject;
  symbol: string;
  feeShareAmount: string;
  lockedFeeShareAmount: string;
  amountUsd: number;
  lockedAmountUsd: number;
  tokenName?: string;
  singleChainId?: string;
}

type WithDrawInfoType = {
  _cumulativeFee: BigNumberish;
  _sigs: BytesLike[];
  _signers: string[];
  _powers: BigNumberish[];
};

enum Status {
  INIT,
  CALIM_LOCK_FEE_SHARE,
  CALIM_UNLOCKING_FEE_SHARE,
  CLAIM_SINGLE_FEE_SHARE,
  SUCCESS,
  SWITCH_CHAIN,
  FAILD,
}

const getSymbolAndChainId = (denom: string) => {
  const symbol = (denom.match(/MBF-(\S*)(\/)/) || [])[1];
  const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
  return {
    symbol,
    singleChainId: chainId,
  };
};

const IMFeeShareModal = (props: IProps) => {
  const { visible, onClose, feeShareList, onClaimSuccess, feeSharetokenUsdMap } = props;
  const { chainId, address, signer } = useWeb3Context();
  const {
    transactor,
    contracts: { originalTokenVault },
  } = useContractsContext();
  const dispatch = useAppDispatch();
  const { configs } = useAppSelector(state => state.globalInfo);
  const { chainsList, chainTokenMap } = configs;
  let initStatus = Status.INIT;
  const [feeShareStatus, setFeeShareStatus] = useState<Status>(initStatus);
  const [switchChianId, setSwitchChainId] = useState<number>();
  const targetChainId = Number(process.env.REACT_APP_NETWORK_ID);
  const [selectedInfo, setSelectedInfo] = useState<SelectedInfo>();
  const [tokenList, setTokenList] = useState<Array<SelectedInfo>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [withdrawInfo, setWithdrawInfo] = useState<WithDrawInfoType>();
  const [feeClaimDetailInfo, setFeeClaimDetailInfo] = useState<FeeClaimDetail>();
  const [feeClaimDetailInfoList, setFeeClaimDetailInfoList] = useState<Array<FeeClaimDetail>>([]);
  const [claimedRewardAmount, setClaimedRewardAmount] = useState<BigNumber>();
  const [viewInExploreLink, setViewInExploreLink] = useState<string>("");
  const [totalReward, setTotalReward] = useState(0);
  const [messageBus, setMessageBus] = useState<MessageBusSender | undefined>();
  const [hadLoadClaimedAmount, setHadLoadClaimedAmount] = useState<boolean>(false);

  useEffect(() => {
    loadMessageBusContract();
  }, [chainId]);

  const loadMessageBusContract = async () => {
    try {
      const messageInfoRes = await getMessageBusInfo(chainId);
      const messageBusAddress = messageInfoRes.message_bus?.contract_info?.address;
      if (typeof signer !== "undefined") {
        const signerTemp = await ensureSigner(signer);
        if (!signer) {
          return;
        }
        /* eslint-disable-next-line new-cap */
        const tempMessageBus = new MessageBusSender__factory(signerTemp).attach(messageBusAddress ?? "");
        setMessageBus(tempMessageBus);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    initData();
  }, [feeSharetokenUsdMap, feeShareList]);

  useEffect(() => {
    if (messageBus) {
      getClaimedAmount();
    }
  }, [messageBus]);

  const initData = async () => {
    const feeClaimInfoRes = await getImFeeClaimInfo(address);
    const feeClaimDetailsListList = feeClaimInfoRes.fee_claim_details_list ?? [];
    setFeeClaimDetailInfoList(feeClaimDetailsListList);

    const newWokenList: Array<SelectedInfo> = [];
    let totalRewards = 0;
    feeShareList.forEach(item => {
      const amountBigNumber = BigNumber.from(item.amount);
      const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
      const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
      const tokenName = getTokenNameByTokenSymbol(tokenInfo?.token?.symbol || "", Number(singleChainId));
      const feeShareAmount = `${formatCelrWithoutSymbol(item.amount, 6, tokenInfo?.token?.decimal)} ${tokenName}`;
      const chainInfo = getChainInfoByChainId(singleChainId);
      const usdPrice = feeSharetokenUsdMap.get(symbol) || 0;
      const amountUsd = Number(formatDecimal(item.amount, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);

      const feeDetail = getFeeClaimDetailInfoByChainId(Number(singleChainId), feeClaimDetailsListList);
      let lockedAmount = amountBigNumber;
      let lockedAmountUsd = amountUsd;
      if (feeDetail) {
        const feeClaimDetailAmount = BigNumber.from(
          formatRemoveDecimals(feeDetail?.cumulative_fee_amount?.amount ?? "0"),
        );
        lockedAmount = amountBigNumber.sub(feeClaimDetailAmount);
        lockedAmountUsd = Number(formatDecimal(lockedAmount, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);
      }
      const lockedFeeShareAmount = `${formatCelrWithoutSymbol(
        lockedAmount,
        6,
        tokenInfo?.token?.decimal,
      )} ${tokenName}`;

      totalRewards += lockedAmountUsd;
      newWokenList.push({
        amount: amountBigNumber,
        lockedAmount,
        amountUsd,
        lockedAmountUsd,
        symbol,
        chainInfo,
        feeShareAmount,
        lockedFeeShareAmount,
        singleChainId,
        tokenName,
      });
    });
    // console.log({ newWokenList, sortedTokenList });
    setTokenList(newWokenList);
    setTotalReward(totalRewards);
  };

  const getFeeClaimDetailInfoByChainId = (chainId: number, detailList: Array<FeeClaimDetail>) => {
    const list = detailList.filter(item => Number(item.chain_id) === chainId);
    if (list && list.length > 0) {
      return list[0];
    }
    return undefined;
  };

  useEffect(() => {
    if (selectedInfo) {
      getClaimedAmount();
    }
  }, [selectedInfo]);

  const getFeeClaimDetailInfo = async () => {
    setLoading(true);
    const feeClaimInfoRes = await getImFeeClaimInfo(address);
    const feeClaimDetailsListList = feeClaimInfoRes.fee_claim_details_list ?? [];
    const list = feeClaimDetailsListList.filter(item => Number(item.chain_id) === selectedInfo?.chainInfo?.id);

    setLoading(false);
    if (list && list.length > 0) {
      const feeClaimDetail = list[0];
      setFeeClaimDetailInfo(feeClaimDetail);
    }
  };

  const getClaimedAmount = async () => {
    if (!messageBus || !address) {
      return;
    }
    setLoading(true);
    try {
      const result = await messageBus?.withdrawnFees(address);
      setClaimedRewardAmount(result);
      if (selectedInfo && selectedInfo.chainInfo?.id === chainId) {
        setHadLoadClaimedAmount(true);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log("err messageBus?.withdrawnFees:  ", e);
    }
  };

  const getChainInfoByChainId = paramChainId => {
    return chainsList?.find(item => item.id === Number(paramChainId));
  };

  const closeMethod = async () => {
    onClose();
    setFeeShareStatus(Status.INIT);
    if (switchChianId && switchChianId !== targetChainId) {
      setSwitchChainId(targetChainId);
      dispatch(setRefreshData());
    }
  };

  const itemClaimMethod = async (info: SelectedInfo) => {
    setSelectedInfo(info);
    if (info.chainInfo?.id !== chainId) {
      setFeeShareStatus(Status.SWITCH_CHAIN);
    } else {
      setFeeShareStatus(Status.CALIM_LOCK_FEE_SHARE);
    }
  };

  const claimMethod = async () => {
    setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
  };

  const unlockMethod = async () => {
    setFeeShareStatus(Status.CALIM_UNLOCKING_FEE_SHARE);
    const claimMsgFeeReq = new ClaimMsgFeeRequest();
    claimMsgFeeReq.setUserAddr(address);
    try {
      const allFees = await claimMsgFee(claimMsgFeeReq);
      console.log(allFees);
      if (allFees.err) {
        message.error(allFees.err.msg);
        initData();
        setFeeShareStatus(Status.CALIM_LOCK_FEE_SHARE);
      } else {
        const unclockTime = new Date().getTime();
        sessionStorage.setItem("unlock_time_" + address, `${unclockTime}`);
        setTimeout(() => {
          getFeeClaimDetailInfo();
          setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
        }, 30000);
      }
    } catch (error) {
      setFeeShareStatus(Status.CALIM_LOCK_FEE_SHARE);
      return;
    }
  };

  const switchChainMethod = async claimChainId => {
    const inId = Number(claimChainId);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${inId.toString(16)}` }],
      });
      setSwitchChainId(inId);
      setFeeShareStatus(Status.CALIM_LOCK_FEE_SHARE);
    } catch (e) {
      console.log("switch fails");
    }
  };

  const claimSingleMethod = async () => {
    if (!selectedInfo || !signer) {
      return;
    }
    setLoading(true);
    const timeOut = feeClaimDetailInfo && feeClaimDetailInfo.signatures.length > 0 ? 3000 : 30000;
    setTimeout(async () => {
      try {
        const feeClaimInfoRes = await getImFeeClaimInfo(address);
        const feeClaimDetailsListList = feeClaimInfoRes.fee_claim_details_list ?? [];
        const list = feeClaimDetailsListList.filter(item => Number(item.chain_id) === selectedInfo.chainInfo?.id);

        if (list && list.length > 0) {
          const feeClaimDetail = list[0];
          const signatures = feeClaimDetail.signatures;
          const sigs = signatures!.map(item => {
            const sig = base64.decode(item.sig_bytes as string);
            return sig;
          });
          const getChainSignersReq = new QueryChainSignersRequest();
          getChainSignersReq.setChainId(selectedInfo.chainInfo?.id || chainId);
          const chainSignersRes = await getChainSigners(getChainSignersReq);
          const sortedSignersList = chainSignersRes.getChainSigners()?.getSortedSignersList();
          if (!sortedSignersList) {
            return;
          }
          const powers = sortedSignersList.map(item => {
            const power = item.getPower();
            return BigNumber.from(power);
          });
          const signers = sortedSignersList!.map(item => {
            const decodeSigners = base64.decode(item.getAddr_asB64());
            const hexlifyObj = hexlify(decodeSigners);
            return getAddress(hexlifyObj);
          });
          const withdrawInfo = {
            _cumulativeFee: selectedInfo.amount,
            _sigs: sigs,
            _powers: powers,
            _signers: signers,
          };
          setWithdrawInfo(withdrawInfo);
          setLoading(false);
          confirmClaimMethod(withdrawInfo);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
        return;
      }
    }, timeOut);
  };

  const isRightOriginalTokenVault = () => {
    const originalTokenVaultArrd = originalTokenVault?.address;
    const targetOriginalTokenVaultArrd = configs.peggedPairConfigsList.find(
      item =>
        item.orgChainId === selectedInfo?.chainInfo?.id && item.peggedToken?.token?.symbol === selectedInfo.symbol,
    )?.peggedDepositContractAddr;
    if (originalTokenVaultArrd && targetOriginalTokenVaultArrd) {
      return getAddress(originalTokenVaultArrd) === getAddress(targetOriginalTokenVaultArrd);
    }
    return false;
  };

  const confirmClaimMethod = async withdrawInfo => {
    if (!messageBus || !withdrawInfo || !transactor || !selectedInfo) {
      return;
    }

    if (!isRightOriginalTokenVault) {
      console.log("error: wrong originalTokenVault addr");
      return;
    }

    setLoading(true);

    const { _cumulativeFee, _sigs, _signers, _powers } = withdrawInfo;
    try {
      setLoading(true);
      let gasLimit = await messageBus?.estimateGas.withdrawFee(address, _cumulativeFee, _sigs, _signers, _powers);
      gasLimit = gasLimit.mul(13).div(10);
      const resultTx = await transactor(
        messageBus?.withdrawFee(address, _cumulativeFee, _sigs, _signers, _powers, { gasLimit: gasLimit }),
      ).catch(e => {
        console.log("catch tx error", e);
        if (e.code === 4001) {
          setFeeShareStatus(Status.FAILD);
        } else {
          setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
        }

        setLoading(false);
      });
      if (resultTx) {
        const newtxStr = JSON.stringify(resultTx);
        const newtx = JSON.parse(newtxStr);

        if (resultTx instanceof Error || newtx.code) {
          console.log("get before error", resultTx);
          setLoading(false);
          setFeeShareStatus(Status.FAILD);
        } else {
          setViewInExploreLink(
            `${getNetworkById(Number(selectedInfo?.chainInfo?.id || chainId)).blockExplorerUrl}/tx/${resultTx.hash}`,
          );
          setFeeShareStatus(Status.SUCCESS);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      setFeeShareStatus(Status.FAILD);
    } finally {
      //   setLoading(false);
    }
  };

  const claimSuccessMethod = async () => {
    setFeeShareStatus(Status.INIT);
    setSpinning(true);
    try {
      const newFeeShareList = await onClaimSuccess();
      setSpinning(false);
      if (newFeeShareList.length <= 0) {
        closeMethod();
      }
    } catch (e) {
      console.log(e);
      setSpinning(false);
    }
  };

  const contentDom = () => {
    const chainInfo = getChainInfoByChainId(selectedInfo?.chainInfo?.id || chainId);
    const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
      chainTokenMap,
      Number(selectedInfo?.chainInfo?.id || chainId),
      selectedInfo?.symbol,
    );
    const feeShareAmount = `${formatDecimalWithSeparation(
      selectedInfo?.amount || 0,
      6,
      tokenInfo?.token?.decimal,
      "floor",
      ",",
      true,
    )} ${tokenInfo?.token?.symbol}`;
    switch (feeShareStatus) {
      case Status.INIT:
        return (
          <Spin spinning={spinning}>
            <div className="init-block">
              <div className="total-rewards">Total locked fee rewards: {convertUSD(totalReward)}</div>
              <div className="c-img">
                <img src={celerIM} alt="" />
              </div>
              <div className="feeshare-list">
                {tokenList.map(item => {
                  const {
                    amount,
                    lockedAmount,
                    amountUsd,
                    lockedAmountUsd,
                    symbol,
                    chainInfo,
                    feeShareAmount,
                    lockedFeeShareAmount,
                    singleChainId,
                    tokenName,
                  } = item;
                  return (
                    <div className="item" key={`${singleChainId}-${symbol}`}>
                      <div className="item-content">
                        <div className="chain-info">
                          <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                          <span className="chain-info-name">{chainInfo?.name}</span>
                        </div>
                        <div className="fee-amount">
                          <div className="fee-amount-title">Locked Fee Reward</div>
                          <div className="fee-amount-block">
                            <div className="fee-amount-token">{lockedFeeShareAmount} </div>
                            <div className="fee-amount-usd">({convertUSD(lockedAmountUsd)})</div>
                          </div>
                        </div>
                      </div>
                      <div className="btn-block">
                        <Button
                          type="text"
                          block
                          className="claim-btn"
                          onClick={() => {
                            itemClaimMethod({
                              amount,
                              lockedAmount,
                              amountUsd,
                              lockedAmountUsd,
                              symbol,
                              chainInfo,
                              feeShareAmount,
                              lockedFeeShareAmount,
                              tokenName,
                            });
                          }}
                        >
                          Claim
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Spin>
        );
      case Status.CALIM_LOCK_FEE_SHARE: {
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
          chainTokenMap,
          Number(selectedInfo?.singleChainId),
          selectedInfo?.symbol,
        );
        const usdPrice = feeSharetokenUsdMap.get(selectedInfo?.symbol ?? "") || 0;
        const feeDetail = getFeeClaimDetailInfoByChainId(Number(selectedInfo?.chainInfo?.id), feeClaimDetailInfoList);
        const feeClaimDetailAmount = BigNumber.from(
          formatRemoveDecimals(feeDetail?.cumulative_fee_amount?.amount ?? "0"),
        );

        const lockedReward = `${selectedInfo?.lockedAmount}`;
        const lockedRewardUsd = selectedInfo?.lockedAmountUsd;

        const calimableReward = `${feeClaimDetailAmount.sub(claimedRewardAmount ?? BigNumber.from("0"))}`;
        const calimableRewardUsd =
          Number(formatDecimal(calimableReward, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);

        const currentTime = new Date().getTime();
        const lockTime = sessionStorage.getItem("unlock_time_" + address);
        const timeDisbleUnLock = currentTime - Number(lockTime ?? "0") < 30000;

        const unlockNum = delegateSplitThousandSeparator(formatCelrWithoutSymbol(lockedReward, 6));
        const claimbleNum = delegateSplitThousandSeparator(formatCelrWithoutSymbol(calimableReward, 6));
        const claimButtonDisabled = claimbleNum < 0.000001;
        const unlockButtonDisabled = unlockNum < 0.000001 || timeDisbleUnLock;
        return (
          <div className="lock-block">
            <div className="c-img">
              <img src={celerIM} alt="" />
            </div>
            <div className="chain-info">
              <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
              <span>{selectedInfo?.chainInfo?.name}</span>
            </div>
            <Spin spinning={loading}>
              <div className="content">
                {hadLoadClaimedAmount && (
                  <>
                    <div className="feeShare-info">
                      <div className="fee-amount">
                        <div className="fee-amount-title">Locked Fee Rewards</div>
                        <div className="fee-amount-token">
                          {formatCelrWithoutSymbol(lockedReward, 6, tokenInfo?.token?.decimal)}{" "}
                          {selectedInfo?.tokenName}{" "}
                        </div>
                        <div className="fee-amount-usd">({convertUSD(lockedRewardUsd || 0)})</div>
                      </div>
                      <div className="btn-block">
                        <Button
                          type="text"
                          block
                          className="claim-btn"
                          disabled={unlockButtonDisabled}
                          onClick={() => {
                            unlockMethod();
                          }}
                        >
                          Unlock
                        </Button>
                      </div>
                    </div>
                    <div className="feeShare-info">
                      <div className="fee-amount">
                        <div className="fee-amount-title">Claimable Fee Reward</div>
                        <div className="fee-amount-token">
                          {formatCelrWithoutSymbol(calimableReward, 6, tokenInfo?.token?.decimal)}{" "}
                          {selectedInfo?.tokenName}{" "}
                        </div>
                        <div className="fee-amount-usd">({convertUSD(calimableRewardUsd || 0)})</div>
                      </div>
                      <div className="btn-block">
                        <Button
                          type="text"
                          block
                          className="claim-btn"
                          disabled={claimButtonDisabled}
                          onClick={() => {
                            claimMethod();
                          }}
                        >
                          Claim
                        </Button>
                      </div>
                    </div>
                    <div className="img-block">
                      <img className="img-arrow" src={arrowDownPng} alt="" />
                    </div>
                  </>
                )}
              </div>
            </Spin>
          </div>
        );
      }
      case Status.CALIM_UNLOCKING_FEE_SHARE: {
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
          chainTokenMap,
          Number(selectedInfo?.singleChainId),
          selectedInfo?.symbol,
        );
        const usdPrice = feeSharetokenUsdMap.get(selectedInfo?.symbol ?? "") || 0;
        const lockedReward = `${selectedInfo?.lockedAmount}`;
        const lockedRewardUsd = Number(formatDecimal(lockedReward, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);

        return (
          <div className="unlocking-block">
            <div className="c-img">
              <img src={celerIM} alt="" />
            </div>
            <div className="content">
              <div className="feeShare-info">
                <div className="chain-info">
                  <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                  <span>{selectedInfo?.chainInfo?.name}</span>
                </div>
                <div className="fee-amount">
                  <div className="fee-amount-title">Locked Fee Rewards</div>
                  <div className="fee-amount-token">
                    {formatCelrWithoutSymbol(lockedReward, 6, tokenInfo?.token?.decimal)} {selectedInfo?.tokenName}{" "}
                  </div>
                  <div className="fee-amount-usd">({convertUSD(lockedRewardUsd || 0)})</div>
                </div>
              </div>
              <div className="feeShare-desc">
                Your staking reward is being unlocked on SGN, which might take a few minutes.
              </div>
              <div className="btn-block">
                <Button type="primary" block className={"unbonding-btn"} disabled>
                  <img src={lockLoadingPng} alt="" />
                </Button>
              </div>
            </div>
          </div>
        );
      }
      case Status.FAILD:
      case Status.CLAIM_SINGLE_FEE_SHARE: {
        const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
          chainTokenMap,
          Number(selectedInfo?.singleChainId),
          selectedInfo?.symbol,
        );
        const usdPrice = feeSharetokenUsdMap.get(selectedInfo?.symbol ?? "") || 0;
        const feeDetail = getFeeClaimDetailInfoByChainId(Number(selectedInfo?.chainInfo?.id), feeClaimDetailInfoList);
        const feeClaimDetailAmount = BigNumber.from(
          formatRemoveDecimals(feeDetail?.cumulative_fee_amount?.amount ?? "0"),
        );
        const calimableReward = `${feeClaimDetailAmount.sub(claimedRewardAmount ?? BigNumber.from("0"))}`;
        const calimableRewardUsd =
          Number(formatDecimal(calimableReward, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);
        return (
          <div className="single-block">
            <div className="c-img">
              <img src={celerIM} alt="" />
            </div>
            <div className="content">
              <div className="feeShare-info">
                <div className="chain-info">
                  <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                  <span>{selectedInfo?.chainInfo?.name}</span>
                </div>
                <div className="fee-amount">
                  <div className="fee-amount-title">Claimable Fee Reward</div>
                  <div className="fee-amount-token">
                    {formatCelrWithoutSymbol(calimableReward, 6, tokenInfo?.token?.decimal)} {selectedInfo?.tokenName}{" "}
                  </div>
                  <div className="fee-amount-usd">({convertUSD(calimableRewardUsd || 0)})</div>
                </div>
              </div>
              <div className="feeShare-desc"> </div>
              <div className="btn-block">
                <Button type="text" block loading={loading} className="claim-btn" onClick={claimSingleMethod}>
                  Claim
                </Button>
              </div>
            </div>
          </div>
        );
      }
      case Status.SWITCH_CHAIN:
        return (
          <div className="switch-block">
            <div className="warning">
              <div className="img-block">
                <img src={warningImg} alt="" />
              </div>
              <div className="warning-message">
                Please switch to {chainInfo?.name} before claiming your Celer IM fee reward.
              </div>
            </div>
            <div className="btn-block">
              <Button
                type="text"
                block
                className="ok-btn"
                onClick={() => switchChainMethod(selectedInfo?.chainInfo?.id || chainId)}
              >
                OK
              </Button>
            </div>
          </div>
        );
      case Status.SUCCESS:
        return (
          <div className="success-block">
            <div className="success-icon">
              <img src={successImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Claim Reward Submitted</div>
              <div className="sub-title">Please allow a few minutes for the transaction to complete.</div>
            </div>
            <div className="view-in-explorer">
              <a href={viewInExploreLink} target="_blank" rel="noreferrer">
                Check on Etherscan
              </a>
            </div>

            <div className="btn-block">
              <Button type="text" block className="ok-btn" loading={loading} onClick={claimSuccessMethod}>
                OK
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      title={
        feeShareStatus === Status.SWITCH_CHAIN ? (
          " "
        ) : (
          <div>
            {feeShareStatus === Status.FAILD ? (
              <div className="transactionRejectDom">
                <div className="text">Transaction Reject</div>
                <div className="link" onClick={() => setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE)}>
                  Dismiss
                </div>
              </div>
            ) : null}
            <div className="modal-title-block">
              {feeShareStatus === Status.CALIM_LOCK_FEE_SHARE ? (
                <div
                  className="back"
                  onClick={() => {
                    setFeeShareStatus(Status.INIT);
                  }}
                >
                  <LeftOutlined style={{ fontSize: 22, marginLeft: 6 }} />
                </div>
              ) : null}
              <div className="main-title">
                {feeShareStatus === Status.CALIM_UNLOCKING_FEE_SHARE ? "Unlocking Fee Rewards" : "Claim Fee Rewards"}
              </div>
              <div className="sub-title">(Celer IM)</div>
            </div>
          </div>
        )
      }
      className={feeShareStatus === Status.INIT ? "im-feeShare-list-modal" : "im-feeShare-modal"}
      visible={visible}
      onCancel={closeMethod}
      maskClosable={false}
      footer={null}
    >
      {contentDom()}
    </Modal>
  );
};

export default IMFeeShareModal;
