import { Input } from "antd";
import { ChangeEvent, FC } from "react";

export interface ITokenInputChangeEvent {
  value: string;
  error?: string;
}

interface IProps {
  value: string;
  disabled: boolean;
  onChange: (e: ITokenInputChangeEvent) => void;
}

const validFloatRegex = /([0-9]*[.])?[0-9]/;

const TokenInput: FC<IProps> = props => {
  const { value, onChange } = props;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;

    onChange({
      value: val,
      error: !validFloatRegex.test(val) || Number(val) <= 0 ? "Please enter a valid transfer amount." : "",
    });
  };

  return (
    <Input
      className="token-input"
      type="text"
      bordered={false}
      size="small"
      value={value}
      onChange={handleChange}
      placeholder=""
      disabled={props?.disabled}
      style={{ paddingLeft: 0 }}
      autoFocus
    />
  );
};

export default TokenInput;
