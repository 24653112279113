import { useState, useEffect } from "react";
import { Modal, Button, Avatar } from "antd";
import { isMobile } from "react-device-detect";
import { BigNumber } from "ethers";
import { parseEther } from "@ethersproject/units";
import moment from "moment";
import { useAsync } from "react-use";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { formatCelrWithoutSymbol, formatDeleteBalance, getTimeByBlockNum } from "../../helpers/format";
import { validFloatRegex } from "../../helpers/regex";
import { getExlporerUrl } from "../../helpers/env";
import { RATE_BASE, BLOCK_TIME } from "../../constants";
import userIcon from "../../assets/images/userIcon.png";
import "./index.less";
import TokenInput from "../common/tokenInut";
import lockLoadingPng from "../../assets/images/lockLoading.png";
import lockPng from "../../assets/images/lock.png";
import unbondSuccessPng from "../../assets/images/unbondSuccess.png";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setRefreshMyDelegations } from "../../redux/transferSlice";

/* eslint-disable no-debugger */

export interface ITokenInputChangeEvent {
  value: string;
  error?: string;
}

interface IProps {
  visible: boolean;
  onClose: () => void;
  validatorInfo: {
    valAddr: string;
    commissionRate: BigNumber;
    desc?: {
      details: {
        icon: string | undefined;
      };
      moniker: string | undefined;
    };
  };
  delegatorInfo: {
    tokens: BigNumber;
  };
}

enum UnbonStatus {
  UNBOND,
  UNBONDING,
  SUCCESS,
  FAILED,
}

const UnbondModal = (props: IProps) => {
  const { visible, onClose, validatorInfo, delegatorInfo } = props;
  const { contracts, transactor } = useContractsContext();
  const { Staking } = contracts;
  // const tokenContract = useCustomContractLoader(provider, celerAddress || "", ERC20__factory) as ERC20 | undefined;
  // const [tokenBalance] = useTokenBalance(tokenContract, address);

  const commissionRate = validatorInfo.commissionRate.toNumber();
  const minAmountNumber = 1;
  const [amount, setAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [unbondStatus, setUnbondStatus] = useState<UnbonStatus>(UnbonStatus.UNBOND);
  const [undelegateTxHash, setUndelegateTxHash] = useState<string>("");
  const explorerUrl = getExlporerUrl();
  const dispatch = useAppDispatch();
  const { transferInfo, globalInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;
  const { unbondingBlockNum } = globalInfo;

  useAsync(async () => {
    setUnbondStatus(UnbonStatus.UNBOND);
  }, [visible]);

  const unbondMethod = async () => {
    if (!transactor || !Staking) {
      return;
    }
    setUnbondStatus(UnbonStatus.UNBONDING);
    const amountWei = parseEther(formatDeleteBalance(amount));
    try {
      const undelegateTx = await Staking?.undelegateTokens(validatorInfo.valAddr, amountWei);
      setUndelegateTxHash(undelegateTx.hash);
      setUnbondStatus(UnbonStatus.SUCCESS);
      dispatch(setRefreshMyDelegations(!refreshMyDelegations));
    } catch (e) {
      setUnbondStatus(UnbonStatus.FAILED);
    }
  };

  const successMethod = () => {
    setUnbondStatus(UnbonStatus.UNBOND);
    onClose();
    setAmount("0");
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setErrorMessage(e.error || "");
    setAmount(e.value);
  };

  const setMaxAmount = () => {
    setAmount(formatCelrWithoutSymbol(delegatorInfo?.tokens, 2));
  };

  useEffect(() => {
    let err = "";
    const amountNum = Number(formatDeleteBalance(amount));
    console.log(formatDeleteBalance(formatCelrWithoutSymbol(delegatorInfo?.tokens, 2)));
    if (amountNum !== 0) {
      if (amountNum < 0 || !validFloatRegex.test(formatDeleteBalance(amount))) {
        err = "Please enter a valid amount.";
      } else if (amountNum < minAmountNumber) {
        err = `The min stake amount is ${minAmountNumber} CELR. Please enter a larger amount.`;
      } else if (amountNum > Number(formatDeleteBalance(formatCelrWithoutSymbol(delegatorInfo?.tokens, 2)))) {
        err = `Your max unbond amount is ${formatCelrWithoutSymbol(
          delegatorInfo?.tokens,
          2,
        )} CELR. Please enter a smaller amount.`;
      }
    }

    setErrorMessage(err);
  }, [amount, delegatorInfo?.tokens]);

  const getWithdrawableTime = () => {
    const nowTime = new Date().getTime();
    const unbondingTime = unbondingBlockNum * BLOCK_TIME * 1000;
    const withdrawableTime = nowTime + unbondingTime;
    return moment(withdrawableTime).format("YYYY-MM-DD HH:mm:ss");
  };

  const contentDom = () => {
    switch (unbondStatus) {
      case UnbonStatus.SUCCESS: {
        return (
          <div className="success">
            <div className="success-bolck">
              <div className="success-icon">
                <img src={unbondSuccessPng} alt="" />
              </div>
              <div className="success-text">Unbond In Progress</div>
              <div className="success-desc">
                Your CELR stake unbonding is in progress, which takes {getTimeByBlockNum(unbondingBlockNum)}. You can
                withdraw your assets after {getWithdrawableTime()}.
              </div>
              <div className="success-link">
                <a href={`${explorerUrl}/tx/${undelegateTxHash}`} target="_blank" rel="noreferrer">
                  Check on Etherscan
                </a>
              </div>
            </div>
            <Button
              type="primary"
              block
              className="success-btn"
              onClick={() => {
                successMethod();
              }}
            >
              OK
            </Button>
          </div>
        );
      }
      default: {
        return (
          <div className="unbond">
            <div className="form-userInfo">
              <Avatar size={56} icon={<img src={validatorInfo?.desc?.details?.icon || userIcon} alt="userIcon" />} />
              <div className="candidateId">{validatorInfo?.desc?.moniker || "anonymous"}</div>
              {!isMobile && errorMessage ? (
                <div className="error-info">
                  <span>{errorMessage}</span>
                </div>
              ) : (
                <div className="commission-rate">
                  <span className="rate-number">{`${commissionRate / RATE_BASE}% `}</span>
                  <span className="rate">Commission</span>
                </div>
              )}
            </div>
            <div className={`btn-group ${unbondStatus === UnbonStatus.UNBONDING ? "delegating" : null}`}>
              <TokenInput
                value={amount}
                onChange={handleTokenInputChange}
                disabled={unbondStatus === UnbonStatus.UNBONDING}
              />
              <div className="symbol-celer">CELR</div>
              {unbondStatus === UnbonStatus.UNBONDING ? (
                <div className="lock-img">
                  <img src={lockPng} alt="" />
                </div>
              ) : null}
              <Button block disabled={unbondStatus === UnbonStatus.UNBONDING} onClick={() => setMaxAmount()}>
                MAX
              </Button>
            </div>
            <div className="unbond-info">
              <div className="block">
                <div className="title">Available Amount</div>
                <div className="amount">
                  <span className="number">{formatCelrWithoutSymbol(delegatorInfo?.tokens, 2)}</span>
                  <span className="celr">CELR</span>
                </div>
              </div>
            </div>
            {isMobile && errorMessage ? (
              <div className="error-info">
                <span>{errorMessage}</span>
              </div>
            ) : null}
            <Button
              type="primary"
              block
              onClick={unbondMethod}
              className={unbondStatus === UnbonStatus.UNBONDING ? "unbonding-btn" : "unbond-btn"}
              disabled={!!errorMessage || amount === "" || unbondStatus === UnbonStatus.UNBONDING}
            >
              {unbondStatus === UnbonStatus.UNBONDING ? <img src={lockLoadingPng} alt="" /> : "Unbond"}
            </Button>
          </div>
        );
      }
    }
  };

  const modalTitleDom = () => {
    return unbondStatus === UnbonStatus.FAILED ? (
      <>
        {/* <div className="transactionRejectDom">
                    <div className="text">
                        Transaction Reject
                    </div>
                    <div className="link" onClick={() => setUnbondStatus(UnbonStatus.UNBOND)}>
                        Dismiss
                    </div>
                </div>
                Unbond */}
      </>
    ) : (
      "Unbond"
    );
  };

  return (
    <Modal
      title={modalTitleDom()}
      className="unbond-modal"
      width={401}
      visible={visible}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      {contentDom()}
    </Modal>
  );
};

export default UnbondModal;
