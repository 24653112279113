// source: sgn/pegbridge/v1/query.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var google_api_annotations_pb = require('../../../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var sgn_common_v1_common_pb = require('../../../sgn/common/v1/common_pb.js');
goog.object.extend(proto, sgn_common_v1_common_pb);
var sgn_pegbridge_v1_pegbridge_pb = require('../../../sgn/pegbridge/v1/pegbridge_pb.js');
goog.object.extend(proto, sgn_pegbridge_v1_pegbridge_pb);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryBurnInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryBurnInfoResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryConfigRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryDepositInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryDepositInfoResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryMintInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryMintInfoResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryParamsRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryParamsResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QuerySupplyInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QuerySupplyInfoResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryVaultMigrationRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryVaultMigrationResponse', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryParamsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryParamsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryParamsRequest.displayName = 'proto.sgn.pegbridge.v1.QueryParamsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryParamsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryParamsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryParamsResponse.displayName = 'proto.sgn.pegbridge.v1.QueryParamsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryConfigRequest.displayName = 'proto.sgn.pegbridge.v1.QueryConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.displayName = 'proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.repeatedFields_, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.displayName = 'proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.displayName = 'proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.displayName = 'proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryDepositInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryDepositInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QueryDepositInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryDepositInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryDepositInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QueryDepositInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryMintInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryMintInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QueryMintInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryMintInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryMintInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QueryMintInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryBurnInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryBurnInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QueryBurnInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryBurnInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryBurnInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QueryBurnInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QuerySupplyInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QuerySupplyInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QuerySupplyInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QuerySupplyInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.displayName = 'proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.displayName = 'proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryVaultMigrationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.displayName = 'proto.sgn.pegbridge.v1.QueryVaultMigrationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.repeatedFields_, null);
};
goog.inherits(proto.sgn.pegbridge.v1.QueryVaultMigrationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.displayName = 'proto.sgn.pegbridge.v1.QueryVaultMigrationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryParamsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryParamsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryParamsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryParamsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryParamsRequest}
 */
proto.sgn.pegbridge.v1.QueryParamsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryParamsRequest;
  return proto.sgn.pegbridge.v1.QueryParamsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryParamsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryParamsRequest}
 */
proto.sgn.pegbridge.v1.QueryParamsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryParamsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryParamsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryParamsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryParamsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryParamsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryParamsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    params: (f = msg.getParams()) && sgn_pegbridge_v1_pegbridge_pb.Params.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryParamsResponse}
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryParamsResponse;
  return proto.sgn.pegbridge.v1.QueryParamsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryParamsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryParamsResponse}
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.Params;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.Params.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryParamsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryParamsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.Params.serializeBinaryToWriter
    );
  }
};


/**
 * optional Params params = 1;
 * @return {?proto.sgn.pegbridge.v1.Params}
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.prototype.getParams = function() {
  return /** @type{?proto.sgn.pegbridge.v1.Params} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.Params, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.Params|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryParamsResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryParamsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryParamsResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryParamsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryConfigRequest}
 */
proto.sgn.pegbridge.v1.QueryConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryConfigRequest;
  return proto.sgn.pegbridge.v1.QueryConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryConfigRequest}
 */
proto.sgn.pegbridge.v1.QueryConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    orig: (f = msg.getOrig()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f),
    pegged: (f = msg.getPegged()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest;
  return proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setOrig(value);
      break;
    case 2:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setPegged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
  f = message.getPegged();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional sgn.common.v1.ContractInfo orig = 1;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.getOrig = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 1));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} returns this
*/
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.setOrig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.clearOrig = function() {
  return this.setOrig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.hasOrig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sgn.common.v1.ContractInfo pegged = 2;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.getPegged = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 2));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} returns this
*/
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.setPegged = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.clearPegged = function() {
  return this.setPegged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsRequest.prototype.hasPegged = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pairsList: jspb.Message.toObjectList(msg.getPairsList(),
    sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse;
  return proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair.deserializeBinaryFromReader);
      msg.addPairs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OrigPeggedPair pairs = 1;
 * @return {!Array<!proto.sgn.pegbridge.v1.OrigPeggedPair>}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.prototype.getPairsList = function() {
  return /** @type{!Array<!proto.sgn.pegbridge.v1.OrigPeggedPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair, 1));
};


/**
 * @param {!Array<!proto.sgn.pegbridge.v1.OrigPeggedPair>} value
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.prototype.setPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sgn.pegbridge.v1.OrigPeggedPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.prototype.addPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sgn.pegbridge.v1.OrigPeggedPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryOrigPeggedPairsResponse.prototype.clearPairsList = function() {
  return this.setPairsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pair: (f = msg.getPair()) && sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair.toObject(includeInstance, f),
    requestAmount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mint: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest;
  return proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair.deserializeBinaryFromReader);
      msg.setPair(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestAmount(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMint(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPair();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair.serializeBinaryToWriter
    );
  }
  f = message.getRequestAmount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMint();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional OrigPeggedPair pair = 1;
 * @return {?proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.getPair = function() {
  return /** @type{?proto.sgn.pegbridge.v1.OrigPeggedPair} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.OrigPeggedPair, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.OrigPeggedPair|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} returns this
*/
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.setPair = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.clearPair = function() {
  return this.setPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.hasPair = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string request_amount = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.getRequestAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.setRequestAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool mint = 3;
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.getMint = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesRequest.prototype.setMint = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiveAmount: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baseFee: jspb.Message.getFieldWithDefault(msg, 2, ""),
    percentageFee: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse;
  return proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceiveAmount(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseFee(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPercentageFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiveAmount();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaseFee();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPercentageFee();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string receive_amount = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.getReceiveAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.setReceiveAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string base_fee = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.getBaseFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.setBaseFee = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string percentage_fee = 3;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.getPercentageFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryEstimatedAmountFeesResponse.prototype.setPercentageFee = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryDepositInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryDepositInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    depositId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryDepositInfoRequest;
  return proto.sgn.pegbridge.v1.QueryDepositInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryDepositInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryDepositInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryDepositInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepositId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deposit_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.prototype.getDepositId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryDepositInfoRequest.prototype.setDepositId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryDepositInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    depositInfo: (f = msg.getDepositInfo()) && sgn_pegbridge_v1_pegbridge_pb.DepositInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryDepositInfoResponse;
  return proto.sgn.pegbridge.v1.QueryDepositInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.DepositInfo;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.DepositInfo.deserializeBinaryFromReader);
      msg.setDepositInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryDepositInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepositInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.DepositInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional DepositInfo deposit_info = 1;
 * @return {?proto.sgn.pegbridge.v1.DepositInfo}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.prototype.getDepositInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.DepositInfo} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.DepositInfo, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.DepositInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.prototype.setDepositInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryDepositInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.prototype.clearDepositInfo = function() {
  return this.setDepositInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryDepositInfoResponse.prototype.hasDepositInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest;
  return proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithdrawId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string withdraw_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.prototype.getWithdrawId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoRequest.prototype.setWithdrawId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawInfo: (f = msg.getWithdrawInfo()) && sgn_pegbridge_v1_pegbridge_pb.WithdrawInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse;
  return proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.WithdrawInfo;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.WithdrawInfo.deserializeBinaryFromReader);
      msg.setWithdrawInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.WithdrawInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional WithdrawInfo withdraw_info = 1;
 * @return {?proto.sgn.pegbridge.v1.WithdrawInfo}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.prototype.getWithdrawInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.WithdrawInfo} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.WithdrawInfo, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.WithdrawInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.prototype.setWithdrawInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.prototype.clearWithdrawInfo = function() {
  return this.setWithdrawInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryWithdrawInfoResponse.prototype.hasWithdrawInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryMintInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryMintInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mintId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryMintInfoRequest;
  return proto.sgn.pegbridge.v1.QueryMintInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryMintInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMintId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryMintInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryMintInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMintId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string mint_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.prototype.getMintId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryMintInfoRequest.prototype.setMintId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryMintInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryMintInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mintInfo: (f = msg.getMintInfo()) && sgn_pegbridge_v1_pegbridge_pb.MintInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryMintInfoResponse;
  return proto.sgn.pegbridge.v1.QueryMintInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryMintInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.MintInfo;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.MintInfo.deserializeBinaryFromReader);
      msg.setMintInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryMintInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryMintInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMintInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.MintInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional MintInfo mint_info = 1;
 * @return {?proto.sgn.pegbridge.v1.MintInfo}
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.prototype.getMintInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.MintInfo} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.MintInfo, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.MintInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryMintInfoResponse.prototype.setMintInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryMintInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.prototype.clearMintInfo = function() {
  return this.setMintInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryMintInfoResponse.prototype.hasMintInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryBurnInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryBurnInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    burnId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryBurnInfoRequest;
  return proto.sgn.pegbridge.v1.QueryBurnInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryBurnInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBurnId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryBurnInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryBurnInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBurnId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string burn_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.prototype.getBurnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryBurnInfoRequest.prototype.setBurnId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryBurnInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    burnInfo: (f = msg.getBurnInfo()) && sgn_pegbridge_v1_pegbridge_pb.BurnInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryBurnInfoResponse;
  return proto.sgn.pegbridge.v1.QueryBurnInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.BurnInfo;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.BurnInfo.deserializeBinaryFromReader);
      msg.setBurnInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryBurnInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBurnInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.BurnInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional BurnInfo burn_info = 1;
 * @return {?proto.sgn.pegbridge.v1.BurnInfo}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.prototype.getBurnInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.BurnInfo} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.BurnInfo, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.BurnInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.prototype.setBurnInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryBurnInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.prototype.clearBurnInfo = function() {
  return this.setBurnInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryBurnInfoResponse.prototype.hasBurnInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 1, ""),
    nonce: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest;
  return proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNonce(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string address = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 nonce = 2;
 * @return {number}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.prototype.getNonce = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoRequest.prototype.setNonce = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeClaimInfo: (f = msg.getFeeClaimInfo()) && sgn_pegbridge_v1_pegbridge_pb.FeeClaimInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse;
  return proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.FeeClaimInfo;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.FeeClaimInfo.deserializeBinaryFromReader);
      msg.setFeeClaimInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeClaimInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.FeeClaimInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional FeeClaimInfo fee_claim_info = 1;
 * @return {?proto.sgn.pegbridge.v1.FeeClaimInfo}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.prototype.getFeeClaimInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.FeeClaimInfo} */ (
    jspb.Message.getWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.FeeClaimInfo, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.FeeClaimInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.prototype.setFeeClaimInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.prototype.clearFeeClaimInfo = function() {
  return this.setFeeClaimInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.QueryFeeClaimInfoResponse.prototype.hasFeeClaimInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    peggedChainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    peggedAddress: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QuerySupplyInfoRequest;
  return proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setPeggedChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeggedAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeggedChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPeggedAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 pegged_chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.prototype.getPeggedChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.prototype.setPeggedChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string pegged_address = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.prototype.getPeggedAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoRequest.prototype.setPeggedAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cap: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QuerySupplyInfoResponse;
  return proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCap();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string total = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.prototype.getTotal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cap = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.prototype.getCap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QuerySupplyInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QuerySupplyInfoResponse.prototype.setCap = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    depositId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest;
  return proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepositId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDepositId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deposit_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.prototype.getDepositId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoRequest.prototype.setDepositId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse;
  return proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWithdrawId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string withdraw_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.prototype.getWithdrawId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryRefundClaimInfoResponse.prototype.setWithdrawId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryVaultMigrationRequest;
  return proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationRequest} returns this
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationRequest.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    vaultMigrationsList: jspb.Message.toObjectList(msg.getVaultMigrationsList(),
    sgn_pegbridge_v1_pegbridge_pb.VaultMigration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.QueryVaultMigrationResponse;
  return proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_pegbridge_v1_pegbridge_pb.VaultMigration;
      reader.readMessage(value,sgn_pegbridge_v1_pegbridge_pb.VaultMigration.deserializeBinaryFromReader);
      msg.addVaultMigrations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVaultMigrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sgn_pegbridge_v1_pegbridge_pb.VaultMigration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated VaultMigration vault_migrations = 1;
 * @return {!Array<!proto.sgn.pegbridge.v1.VaultMigration>}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.prototype.getVaultMigrationsList = function() {
  return /** @type{!Array<!proto.sgn.pegbridge.v1.VaultMigration>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_pegbridge_v1_pegbridge_pb.VaultMigration, 1));
};


/**
 * @param {!Array<!proto.sgn.pegbridge.v1.VaultMigration>} value
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse} returns this
*/
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.prototype.setVaultMigrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sgn.pegbridge.v1.VaultMigration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.pegbridge.v1.VaultMigration}
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.prototype.addVaultMigrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sgn.pegbridge.v1.VaultMigration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.QueryVaultMigrationResponse} returns this
 */
proto.sgn.pegbridge.v1.QueryVaultMigrationResponse.prototype.clearVaultMigrationsList = function() {
  return this.setVaultMigrationsList([]);
};


goog.object.extend(exports, proto.sgn.pegbridge.v1);
