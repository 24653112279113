/* eslint-disable camelcase */
/* eslint-disable no-debugger */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TransferIState {
    refreshData: boolean;
    refreshMyDelegations: boolean; 
}

const initialState: TransferIState = {
    refreshData: false,
    refreshMyDelegations: false, 
};

const transferSlice = createSlice({
    name: "lp",
    initialState,
    reducers: {
      setRefreshData: (state) => {
        state.refreshData = !state.refreshData;
      },
      setRefreshMyDelegations: (state, { payload }: PayloadAction<boolean>) => {
        state.refreshMyDelegations = payload;
      },
    },
});

export const {
    setRefreshData,
    setRefreshMyDelegations,
} = transferSlice.actions;
  
export default transferSlice.reducer;