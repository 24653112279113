import { useState } from "react";
import { Modal, Button } from "antd";
import { BigNumber } from "@ethersproject/bignumber";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { formatDecimalWithSeparation } from "celer-web-utils/lib/format";
import { useContractsContext } from "../../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { LPType, queryLiquidityStatus, signAgain } from "../../../api";
import { storageConstants } from "../../../constants/const";
import { LPHistory } from "../../../constants/claimType";
import { setRefreshData } from "../../../redux/transferSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { formatBlockExplorerUrlWithTxHash } from "../../../utils/formatUrl";
import { ClaimHistory, ClaimStatus, SignAgainRequest, SignAgainType } from "../../../proto/gateway/gateway_pb";
import "./historyModal.less";
import successImg from "../../../assets/images/success.png";
import SwitchChainModal from "../../switchChainModal";
import { needToSignAgain } from "../../singleChainWithdrawModal";

const INIT = "INIT";
const SUCCESS = "SUCCESS";
type ConfirmStatus = "INIT" | "SUCCESS";

/* eslint-disable no-debugger */
/* eslint-disable camelcase */

interface ConfirmIProps {
  info: ClaimHistory.AsObject | undefined;
  visible: boolean;
  onClose: () => void;
  onOpen: () => void;
}

const HistoryConfirmModal = (props: ConfirmIProps) => {
  const { visible, onClose, onOpen, info } = props;
  const { address, chainId } = useWeb3Context();
  const { configs } = useAppSelector(state => state.globalInfo);
  const {
    contracts: { Bridge },
    transactor,
  } = useContractsContext();
  const dispatch = useAppDispatch();
  const [confirmStatus, setConfirmStatus] = useState<ConfirmStatus>(INIT);
  const [switchChainId, setSwitchChainId] = useState<number>(chainId);
  const [switchChainModalVisible, setSwitchChainModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewInExploreLink, setViewInExploreLink] = useState<string>("");

  if (!info) {
    return null;
  }

  const amountStr = formatDecimalWithSeparation(info.amount, 6, info.token?.token?.decimal);

  const claimSuccessMethod = () => {
    setConfirmStatus(INIT);
    onClose();
  };

  const isRightBridge = () => {
    const bridgetArrd = Bridge?.address;
    const targetBridgeVaultArrd = configs?.chainsList?.find(item => item.id === Number(info.chain?.id))?.contractAddr;
    return bridgetArrd === targetBridgeVaultArrd;
  };

  const signAgainMethod = async ({ paramChainId, seqNum, addr }) => {
    const signAgainRequest = new SignAgainRequest();
    signAgainRequest.setType(SignAgainType.SAT_LIQUIDITY);
    signAgainRequest.setUsrAddr(addr);
    signAgainRequest.setChainId(paramChainId);
    signAgainRequest.setSeqNum(seqNum);
    return signAgain(signAgainRequest);
  };

  const afterSignAgainMthod = async () => {
    if (!Bridge || !transactor) {
      return;
    }
    const res = await queryLiquidityStatus({
      seq_num: info.seqNum,
      lp_addr: address,
      chain_id: info.chain?.id,
      type: LPType.LP_TYPE_REMOVE,
    });
    const { wd_onchain, sorted_sigs, signers, powers } = res;
    const _wdmsg = base64.decode(wd_onchain);
    const _signers = signers.map(item => {
      const decodeSigners = base64.decode(item);
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    const _sigs = sorted_sigs.map(item => {
      return base64.decode(item);
    });
    const _powers = powers.map(item => {
      const decodeNum = base64.decode(item);
      return BigNumber.from(decodeNum);
    });
    let gasLimit = await Bridge.estimateGas.withdraw(_wdmsg, _sigs, _signers, _powers);
    gasLimit = gasLimit.mul(13).div(10);
    const resultTx = await transactor(Bridge.withdraw(_wdmsg, _sigs, _signers, _powers, { gasLimit })).catch(e => {
      console.log("catch tx error", e);
      setConfirmStatus(INIT);
      setLoading(false);
    });
    if (resultTx) {
      const newtxStr = JSON.stringify(resultTx);
      const newtx = JSON.parse(newtxStr);
      if (resultTx instanceof Error || newtx.code) {
        console.log("get before error", resultTx);
        setLoading(false);
      } else {
        const blockTxLink = formatBlockExplorerUrlWithTxHash({
          chainId: info?.chain?.id || 0,
          txHash: resultTx.hash,
        });
        setViewInExploreLink(blockTxLink);
        setLoading(false);
        setConfirmStatus(SUCCESS);
        const newLPJson: LPHistory = {
          chain: info?.chain,
          token: info?.token,
          amount: info?.amount,
          ts: info?.ts,
          blockTxLink,
          status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
          methodType: info?.methodType,
          seqNum: Number(info?.seqNum),
          nonce: resultTx.nonce,
          isLocal: true,
          updateTime: new Date().getTime(),
          txIsFailed: false,
          withdrawId: "",
        };
        const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
        let localLpList: LPHistory[] = [];
        if (localLpListStr) {
          localLpList = JSON.parse(localLpListStr)[address] || [];
        }
        let isHave = false;
        localLpList.map(item => {
          if (Number(item.seqNum) === Number(info.seqNum)) {
            isHave = true;
            item.updateTime = new Date().getTime();
            item.txIsFailed = false;
            item.blockTxLink = formatBlockExplorerUrlWithTxHash({
              chainId: info?.chain?.id || chainId,
              txHash: resultTx.hash,
            });
          }
          return item;
        });
        if (!isHave) {
          localLpList.unshift(newLPJson);
        }
        const newJson = { [address]: localLpList };
        localStorage.setItem(storageConstants.KEY_LP_LIST, JSON.stringify(newJson));
      }
    }
  };

  // Todo: need to set confirming status
  const confirmMethod = async () => {
    if (info.chain?.id !== chainId) {
      setSwitchChainId(info.chain?.id || chainId);
      setSwitchChainModalVisible(true);
      props.onClose();
    } else {
      /**
       * 1. queryLiquidityStatus(), get withdraw detail
       * 2. Bridge.withdraw()
       */
      if (!Bridge || !transactor) {
        return;
      }
      if (!isRightBridge()) {
        console.log("error: wrong Bridge addr");
        return;
      }
      const res = await queryLiquidityStatus({
        seq_num: info.seqNum,
        lp_addr: address,
        chain_id: info.chain?.id,
        type: LPType.LP_TYPE_REMOVE,
      });
      const { wd_onchain, sorted_sigs, signers, powers } = res;
      const _wdmsg = base64.decode(wd_onchain);
      const _signers = signers.map(item => {
        const decodeSigners = base64.decode(item);
        const hexlifyObj = hexlify(decodeSigners);
        return getAddress(hexlifyObj);
      });
      const _sigs = sorted_sigs.map(item => {
        return base64.decode(item);
      });
      const _powers = powers.map(item => {
        const decodeNum = base64.decode(item);
        return BigNumber.from(decodeNum);
      });
      try {
        setLoading(true);
        try {
          let gasLimit = await Bridge.estimateGas.withdraw(_wdmsg, _sigs, _signers, _powers);
          gasLimit = gasLimit.mul(13).div(10);
          const resultTx = await transactor(Bridge.withdraw(_wdmsg, _sigs, _signers, _powers, { gasLimit })).catch(
            e => {
              console.log("catch tx error", e);
              setConfirmStatus(INIT);
              setLoading(false);
            },
          );
          if (resultTx) {
            const newtxStr = JSON.stringify(resultTx);
            const newtx = JSON.parse(newtxStr);
            if (resultTx instanceof Error || newtx.code) {
              console.log("get before error", resultTx);
              setLoading(false);
            } else {
              const blockTxLink = formatBlockExplorerUrlWithTxHash({
                chainId: info?.chain?.id || 0,
                txHash: resultTx.hash,
              });
              setViewInExploreLink(blockTxLink);
              setLoading(false);
              setConfirmStatus(SUCCESS);
              const newLPJson: LPHistory = {
                chain: info?.chain,
                token: info?.token,
                amount: info?.amount,
                ts: info?.ts,
                blockTxLink,
                status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
                methodType: info?.methodType,
                seqNum: Number(info?.seqNum),
                nonce: resultTx.nonce,
                isLocal: true,
                updateTime: new Date().getTime(),
                txIsFailed: false,
                withdrawId: "",
              };
              const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
              let localLpList: LPHistory[] = [];
              if (localLpListStr) {
                localLpList = JSON.parse(localLpListStr)[address] || [];
              }
              let isHave = false;
              localLpList.map(item => {
                if (Number(item.seqNum) === Number(info.seqNum)) {
                  isHave = true;
                  item.updateTime = new Date().getTime();
                  item.txIsFailed = false;
                  item.blockTxLink = formatBlockExplorerUrlWithTxHash({
                    chainId: info?.chain?.id || chainId,
                    txHash: resultTx.hash,
                  });
                }
                return item;
              });
              if (!isHave) {
                localLpList.unshift(newLPJson);
              }
              const newJson = { [address]: localLpList };
              localStorage.setItem(storageConstants.KEY_LP_LIST, JSON.stringify(newJson));
            }
          }
        } catch (e) {
          if (needToSignAgain(e)) {
            const signAgainRes = await signAgainMethod({
              addr: address,
              paramChainId: info.chain?.id,
              seqNum: info.seqNum,
            });
            console.log({ signAgainRes });
            try {
              await afterSignAgainMthod();
              setLoading(false);
            } catch (err) {
              console.log("catch afterSignAgainMthod error", err);
              setConfirmStatus(INIT);
              setLoading(false);
            }
          } else {
            console.log("catch tx error", e);
            setConfirmStatus(INIT);
            setLoading(false);
          }
        }
      } catch (e) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const renderContent = () => {
    switch (confirmStatus) {
      case INIT:
        return (
          <div className="claim-history-confirm">
            <div className="claim-history-confirm-info">
              <div className="chain-info">
                <img src={info.chain?.icon} alt="" className="chain-info-icon" />
                <span className="chain-info-name">{info.chain?.name}</span>{" "}
              </div>
              <div className="token-info">
                <div className="token-info-amount">
                  {amountStr} {info.token?.token?.symbol}
                </div>
              </div>
            </div>
            <div className="claim-history-confirm-tooltip">
              You have pending rewards claim to be confirmed. Please click &quot;Confirm Claim&quot; button to complete
              the fee rewards claim process.
            </div>
            <div className="claim-history-confirm-btn">
              <Button type="text" block className="confirm-btn" loading={loading} onClick={confirmMethod}>
                Confirm Rewards Claim
              </Button>
            </div>
          </div>
        );
      case SUCCESS:
        return (
          <div className="claim-history-success">
            <div className="success-icon">
              <img src={successImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Success</div>
              <div className="sub-title">
                Claim {amountStr} {info.token?.token?.symbol} cBridge fee reward from {info.chain?.name}
              </div>
            </div>
            <div className="view-in-explorer">
              <a href={viewInExploreLink} target="_blank" rel="noreferrer">
                View in Explorer
              </a>
            </div>
            <div className="desc">Please allow a few minutes before the transaction completes.</div>
            <div className="btn-block">
              <Button type="text" block className="ok-btn" onClick={claimSuccessMethod}>
                OK
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Modal
        className="history-confirm-modal"
        title="History"
        visible={visible}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          onClose();
          dispatch(setRefreshData());
        }}
      >
        {renderContent()}
      </Modal>
      <SwitchChainModal
        chainId={switchChainId}
        visible={switchChainModalVisible}
        closeMethod={() => {
          setSwitchChainModalVisible(false);
        }}
        afterSwitchMethod={() => {
          onOpen();
        }}
      />
    </>
  );
};

export default HistoryConfirmModal;
