import { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import { WarningFilled } from "@ant-design/icons";
import moment from "moment";
import { formatDecimalWithSeparation } from "celer-web-utils/lib/format";
import { isMobile } from "react-device-detect";
import { ClaimStatus } from "../../../proto/gateway/gateway_pb";
import "./historyModal.less";
import linkIcon from "../../../assets/images/link.png";
import yellowTooltipIcon from "../../../assets/images/yellow-tooltip.png";
import PegHistoryConfirmModal from "./PegHistoryConfirmModal";
import { useAppSelector, useAppDispatch } from "../../../redux/store";
import { setOTContractAddr } from "../../../redux/globalInfoSlice";
import { isConfirmToRemoveLq } from "../../../utils/mergeLiquidityHistory";
import getTokenNameByTokenSymbol from "../../../helpers/getTokenNameBySymbolAndChainId";
import { LPHistory } from "../../../constants/claimType";
import { NonEVMMode, getNonEVMMode } from "../../../providers/NonEVMContextProvider";
/* eslint-disable react-hooks/exhaustive-deps */

const tooltipShowTime = 15;

interface ItemProps {
  item: LPHistory;
  reloadHisList: () => void;
}
const PegHistoryModalItem = (props: ItemProps) => {
  const { item, reloadHisList } = props;
  const [itemInfo, setItemInfo] = useState(item);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const { configs } = useAppSelector(state => state.globalInfo);
  const dispatch = useAppDispatch();

  const openConfirmModal = () => {
    const targetChainId = item.chain?.id;
    const targetSymbol = item.token?.token?.symbol;
    const nonEvmMode = getNonEVMMode(targetChainId || -1);
    if (nonEvmMode !== NonEVMMode.off) {
      setConfirmModalVisible(true);
      return;
    }
    const { peggedPairConfigsList } = configs;
    const config = peggedPairConfigsList.find(
      it => it.orgChainId === targetChainId && it.peggedToken?.token?.symbol === targetSymbol,
    );
    dispatch(setOTContractAddr(config?.peggedDepositContractAddr || ""));
    setConfirmModalVisible(true);
  };

  useEffect(() => {
    setItemInfo(item);
  }, [item]);

  useEffect(() => {
    reloadHisList();
  }, [confirmModalVisible]);

  const renderHistoryItemDesc = () => {
    const amountStr = formatDecimalWithSeparation(itemInfo.amount, 6, itemInfo.token?.token?.decimal);
    const tokenName = getTokenNameByTokenSymbol(itemInfo.token?.token?.symbol || "", Number(itemInfo.chain?.id));
    return (
      <div className="history-item-desc">
        <span>Claim</span>
        <span className="item-token">
          {amountStr} {tokenName}
        </span>
        <span>on</span>
        {itemInfo.blockTxLink ? (
          <a href={itemInfo.blockTxLink} target="_blank" rel="noopener noreferrer">
            <img src={itemInfo.chain?.icon} alt="" className="item-chain-icon" />
            <span className="item-chain-name">{itemInfo.chain?.name}</span>{" "}
            <img src={linkIcon} alt="" className="item-link-icon" />
          </a>
        ) : (
          <div>
            <img src={itemInfo.chain?.icon} alt="" className="item-chain-icon" />
            <span className="item-chain-name">{itemInfo.chain?.name}</span>{" "}
          </div>
        )}
      </div>
    );
  };

  const renderHistoryItemStatus = () => {
    const time = moment(Number(itemInfo.ts)).format("YYYY-MM-DD HH:mm:ss");
    const nowDate = new Date().getTime();
    const showResult = nowDate - Number(itemInfo.updateTime || itemInfo.ts) <= tooltipShowTime * 60 * 1000;
    switch (itemInfo.status) {
      case ClaimStatus.CLM_WAITING_FOR_SGN_CONFIRMATIONS:
        return (
          <div className="history-item-action">
            <div className="action-title waiting-for-SGN-confirmations">
              Waiting for SGN confirmations
              <Tooltip
                title={
                  <span>
                    Your request for claiming fee rewards is being confirmed on SGN, which might take a few minutes.
                  </span>
                }
                placement={isMobile ? "bottomLeft" : "right"}
                color="#fff"
                overlayInnerStyle={{
                  color: "#3A3A3A",
                  borderRadius: "8px",
                  textAlign: "left",
                  width: 265,
                  fontWeight: 600,
                  fontSize: 12,
                  padding: "9px 12px",
                }}
              >
                <img src={yellowTooltipIcon} className="yellow-toltip-icon" alt="" />
              </Tooltip>
            </div>
            <div className="history-item-action-time">{time}</div>
          </div>
        );
      case ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION:
        return (
          <div className="history-item-action confirm-action">
            <Button type="text" block className="confirm-btn" onClick={() => openConfirmModal()}>
              Confirm Claim Fee Rewards
            </Button>
            <div className="history-item-action-time">{time}</div>
          </div>
        );
      case ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM: {
        if (isConfirmToRemoveLq(itemInfo)) {
          return (
            <div className="history-item-action confirm-action">
              <Tooltip
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <WarningFilled style={{ fontSize: 20, marginLeft: 4, marginRight: 10, color: "#ff8f00" }} />
                    </div>
                    <div>
                      It seems that your tx was failed or stuck on-chain. If you found the tx was failed, please
                      increase your gas limit and resubmit the transaction.
                    </div>
                  </div>
                }
                color="#fff"
                placement="right"
                overlayInnerStyle={{ color: "#000", textAlign: "left", borderRadius: 10, fontSize: 12, width: 290 }}
                getPopupContainer={() => {
                  return document.getElementById("modalpop") || document.body;
                }}
              >
                <Button type="text" block className="confirm-btn" onClick={() => openConfirmModal()}>
                  Confirm Claim Fee Rewards
                </Button>
              </Tooltip>
              <div className="history-item-action-time">{time}</div>
            </div>
          );
        }
        return (
          <div className="history-item-action">
            <div className="action-title confirming-fee-rewards-claim">
              Confirming Fee Rewards Claim
              <Tooltip
                title={
                  showResult ? (
                    <span>
                      Your fund is being processed on the {itemInfo.chain?.name}, which might take a few minutes.
                    </span>
                  ) : (
                    <div className="confirming-fee-rewards-claim-tooltip">
                      <div className="confirming-fee-rewards-claim-tooltip-title">
                        It seems that your transaction has been stuck for more than 15 minutes.
                      </div>
                      <ul>
                        <li>
                          If your on-chain tx has completed, please{" "}
                          <a
                            href={`https://form.typeform.com/to/Q4LMjUaK#srctx=${itemInfo.blockTxLink}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            contact support
                          </a>{" "}
                          for help.
                        </li>
                        <li>
                          If your on-chain tx is still pending, you may speed up your transaction by increasing the gas
                          price.
                        </li>
                        <li>
                          If your on-chain tx has failed, this is usually because the gas limit is set too low. Please{" "}
                          <span
                            style={{ color: "#1890ff", cursor: "pointer" }}
                            onClick={e => {
                              e.stopPropagation();
                              const newItem = itemInfo;
                              newItem.status = ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION;
                              setItemInfo(newItem);
                              openConfirmModal();
                            }}
                          >
                            click here
                          </span>{" "}
                          resubmit the tx.
                        </li>
                      </ul>
                    </div>
                  )
                }
                placement="right"
                color="#fff"
                overlayInnerStyle={{
                  color: "#000",
                  borderRadius: "8px",
                  textAlign: "left",
                  width: 265,
                  padding: "9px 12px",
                }}
              >
                <img src={yellowTooltipIcon} className="yellow-toltip-icon" alt="" />
              </Tooltip>
            </div>
            <div className="history-item-action-time">{time}</div>
          </div>
        );
      }
      case ClaimStatus.CLM_COMPLETED:
        return (
          <div className="history-item-action">
            <div className="action-title completed">Completed</div>
            <div className="history-item-action-time">{time}</div>
          </div>
        );
      case ClaimStatus.CLM_FAILED:
        return (
          <div className="history-item-action">
            <div className="action-title failed">Failed</div>
            <div className="history-item-action-time">{time}</div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div
        className={`history-item ${
          itemInfo.status === ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION ? "history-confirm-item" : ""
        }`}
        key={itemInfo.ts}
      >
        {renderHistoryItemDesc()}
        {renderHistoryItemStatus()}
      </div>
      <PegHistoryConfirmModal
        visible={confirmModalVisible}
        onClose={() => {
          setConfirmModalVisible(false);
        }}
        onOpen={() => {
          setConfirmModalVisible(true);
        }}
        info={itemInfo}
      />
    </>
  );
};

export default PegHistoryModalItem;
