import { useState, useEffect } from "react";
import { JsonRpcProvider } from "@ethersproject/providers";
import { formatUnits } from "ethers/lib/utils";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { useConfigContext } from "../providers/ConfigContextProvider";
import { GetTokenUsdPriceRequest } from "../proto/gateway/gateway_pb";
import { getTokenUsdPrice } from "../api";
import { getNetworkById } from "../constants/network";

const gasLimit = process.env.REACT_APP_GAS_LIMIT;
const gas2NativeToken = (gasNumber: number) => {
  return gasNumber * 0.000000001;
};

const useGasFee = () => {
  const { chainId } = useWeb3Context();
  const [gasChainId, setGasChainId] = useState(chainId);
  const { getRpcUrlByChainId } = useConfigContext();
  const [gas, setGas] = useState<number>();
  const [tokenUsdPrice, setTokenUsdPrice] = useState(0);
  const [tokenGas, setTokenGas] = useState(0);
  const [usdGas, setUsdGas] = useState(0);

  useEffect(() => {
    setGasChainId(chainId);
  }, [chainId]);

  useEffect(() => {
    const rpcUrl = getRpcUrlByChainId(gasChainId);
    const provider = new JsonRpcProvider(rpcUrl);
    if (provider) {
      provider.getGasPrice().then(res => {
        const gwei = formatUnits(res, "gwei");
        const gasNumber = Number(gwei) * Number(gasLimit);
        setGas(gasNumber);
      });
    }
  }, [gasChainId, getRpcUrlByChainId]);

  useEffect(() => {
    const nativeToken = getNetworkById(gasChainId);
    const tokens = [nativeToken.symbol];
    const quest = new GetTokenUsdPriceRequest();
    quest.setTokenSymbolsList(tokens);
    getTokenUsdPrice(quest).then(res => {
      const usdPrice = res[0][1];
      setTokenUsdPrice(usdPrice);
    });
  }, [gasChainId]);

  useEffect(() => {
    if (gas && tokenUsdPrice) {
      const tokenOfGas = gas2NativeToken(gas);
      const usdOfGas = tokenOfGas * tokenUsdPrice;
      setTokenGas(tokenOfGas);
      setUsdGas(usdOfGas);
    }
  }, [gas, tokenUsdPrice]);

  return {
    tokenGas,
    usdGas,
    setGasChainId,
  };
};

export default useGasFee;
