import { useState, useCallback, useEffect } from "react";
import { Table, Button, Statistic, Row, Col, Tooltip, ConfigProvider, Spin } from "antd";
import { BigNumber } from "ethers";
import { useToggle, useAsync } from "react-use";
import { isMobile } from "react-device-detect";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { ModalName, openModal } from "../../redux/modalSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { CANDIDATE_STATUS, RATE_BASE } from "../../constants";
import { getSimple, copyToClip } from "../../helpers/utils";
import { formatCelrDecimal, formatCelrWithoutSymbol, getTimeByBlockNum } from "../../helpers/format";
import DelegateModal from "../delegateModal";
import UnbondModal from "../unbondModal";
import WithdrawModal from "../withdrawModal";
import "./candidate-table.less";
import { DelegatorInfoType } from "../../helpers/subscribe";
import { ValidatoAndDelegatorType } from "../../views/Dpos";
import userIcon from "../../assets/images/userIcon.png";
import upIcon from "../../assets/images/up.png";
import downIcon from "../../assets/images/down.png";
import tipIcon from "../../assets/images/toolTip.png";
import copyIcon from "../../assets/images/copy.png";
import { setRefreshData } from "../../redux/transferSlice";
import { getNetworkById } from "../../constants/network";
import { useConfigContext } from "../../providers/ConfigContextProvider";
import useReadOnlyCustomContractLoader from "../../hooks/customReadyOnlyContractLoader";
import { Staking__factory } from "../../typechain/factories/Staking__factory";
import Sort, { SortType } from "../sort";
/* eslint-disable no-debugger */

const helpUrl = "https://cbridge-docs.celer.network/reference/faq#what-is-the-commission-rate-in-sgn";

const ExpandedDom = (props: { validator: ValidatoAndDelegatorType }) => {
  const validator = props.validator;
  const { address, chainId } = useWeb3Context();
  const [delegator, setDelegator] = useState<DelegatorInfoType>();
  const [delegateModalVisible, toggleDelegateModalVisible] = useToggle(false);
  const [unbondModalVisible, toggleUnbondModalVisible] = useToggle(false);
  const [withdrawModalVisible, toggleWithdrawModalVisible] = useToggle(false);
  const { transferInfo, globalInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;
  const { unbondingBlockNum } = globalInfo;
  const dispatch = useAppDispatch();
  const { getRpcUrlByChainId } = useConfigContext();
  const { stakingContractAddress } = useAppSelector(state => state.globalInfo);
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const stakingContract = useReadOnlyCustomContractLoader(jprovider, stakingContractAddress, Staking__factory);
  const [loading, setLoading] = useState(false);

  const clickWithCheckChainId = method => {
    if (chainId && chainId !== Number(process.env.REACT_APP_NETWORK_ID)) {
      return dispatch(openModal(ModalName.switchChain));
    }
    return method();
  };

  useAsync(async () => {
    if (!stakingContract) {
      return;
    }
    try {
      setLoading(true);
      const delegatorInfo = await stakingContract.getDelegatorInfo(validator?.valAddr, address);
      setDelegator(delegatorInfo);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [stakingContract, address]);

  useAsync(async () => {
    if (!stakingContract) {
      return;
    }
    try {
      const delegatorInfo = await stakingContract.getDelegatorInfo(validator?.valAddr, address);
      setDelegator(delegatorInfo);
    } catch (e) {
      console.log(e);
    }
  }, [refreshMyDelegations]);

  const commissionRateNumber = validator?.commissionRate.toNumber() || 0;
  const unbondingStake = delegator?.undelegationTokens?.sub(delegator?.withdrawableUndelegationTokens);
  return (
    <Spin spinning={loading}>
      <div className="validator-detail">
        <div className="detail-table">
          <Row>
            <Col span={6} style={{ borderRight: "1px solid #ECEBEE", borderBottom: "1px solid #ECEBEE" }}>
              <Statistic title="Staking Pool" value={formatCelrWithoutSymbol(validator?.tokens, 0)} />
            </Col>
            <Col span={6} style={{ borderRight: "1px solid #ECEBEE", borderBottom: "1px solid #ECEBEE" }}>
              <Statistic
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Your Delegated Stake
                    <Tooltip
                      placement="top"
                      title={
                        <div className="toolsDetail">
                          This is your stake currently delegated with this validator.
                          <br />
                          <br />
                          Note that your unbonding stake or withdrawable stake is not included.
                        </div>
                      }
                      color="#FFFFFF"
                      overlayInnerStyle={{
                        color: "#0A1E42",
                        width: 290,
                        // fontFamily: "SF Pro Text",
                        fontSize: 12,
                        lineHeight: "14px",
                      }}
                    >
                      <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                    </Tooltip>
                  </div>
                }
                value={formatCelrDecimal(delegator?.tokens, 6)}
              />
            </Col>
            <Col span={6} style={{ borderRight: "1px solid #ECEBEE", borderBottom: "1px solid #ECEBEE" }}>
              <Statistic
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Your Unbonding Stake
                    <Tooltip
                      placement="topLeft"
                      title={
                        <div className="toolsDetail">
                          This is your stake amount currently in the unbonding process. The unbonding process takes{" "}
                          {getTimeByBlockNum(unbondingBlockNum)}, after which it will become withdrawable.
                        </div>
                      }
                      color="#FFFFFF"
                      overlayInnerStyle={{
                        color: "#0A1E42",
                        width: 290,
                        // fontFamily: "SF Pro Text",
                        fontSize: 12,
                        lineHeight: "14px",
                      }}
                    >
                      <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                    </Tooltip>
                  </div>
                }
                value={formatCelrDecimal(unbondingStake, 6)}
              />
            </Col>
            <Col span={6} style={{ borderBottom: "1px solid #ECEBEE" }}>
              <Statistic
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Your Withdrawable Stake
                    <Tooltip
                      placement="topLeft"
                      title={
                        <div className="toolsDetail">
                          Withdrawable stake is ready to be claimed on {getNetworkById(chainId).name}.
                          <br />
                          <br />
                          Note: to increase your withdrawable stake, you first need to unbond your stake, which takes{" "}
                          {getTimeByBlockNum(unbondingBlockNum)}.
                        </div>
                      }
                      color="#FFFFFF"
                      overlayInnerStyle={{
                        color: "#0A1E42",
                        width: 290,
                        // fontFamily: "SF Pro Text",
                        fontSize: 12,
                        lineHeight: "14px",
                      }}
                    >
                      <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                    </Tooltip>
                  </div>
                }
                value={formatCelrDecimal(delegator?.withdrawableUndelegationTokens, 6)}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6} onClick={() => copyToClip(validator?.valAddr)} style={{ borderRight: "1px solid #ECEBEE" }}>
              <Statistic
                title="Address"
                value={getSimple(validator?.valAddr, 8, -6)}
                className="small-statistic"
                suffix={<img src={copyIcon} className="withTooltip" alt="copy icon" />}
              />
            </Col>
            <Col span={6} style={{ borderRight: "1px solid #ECEBEE" }}>
              <Statistic
                title={
                  <div style={{ display: "flex", alignItems: "center" }}>
                    Commission Rate
                    <Tooltip
                      placement="top"
                      title={
                        <div className="toolsDetail">
                          This is the percentage of your staking rewards taken by this validator.
                          <br />
                          <a href={`${helpUrl}`} target="_blank" rel="noreferrer">
                            Learn More
                          </a>
                        </div>
                      }
                      color="#FFFFFF"
                      overlayInnerStyle={{
                        color: "#0A1E42",
                        width: 290,
                        // fontFamily: "SF Pro Text",
                        fontSize: 12,
                        lineHeight: "14px",
                      }}
                    >
                      <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                    </Tooltip>
                  </div>
                }
                value={`${commissionRateNumber / RATE_BASE}%`}
              />
            </Col>
            <Col span={6} style={{ borderRight: "1px solid #ECEBEE" }}>
              <Statistic title="Contact" value={validator.description.contact || "--"} className="small-statistic" />
            </Col>
            <Col span={6}>
              <Statistic title="Website" value={validator.description.website || "--"} className="small-statistic" />
            </Col>
          </Row>
        </div>
        <div className="detail-btns">
          {!delegator || (delegator?.tokens.eq(0) && delegator?.withdrawableUndelegationTokens.eq(0)) ? (
            <Button
              key="stake"
              type="primary"
              className="extra-button btn-stake"
              onClick={() => {
                clickWithCheckChainId(() => toggleDelegateModalVisible());
              }}
            >
              Stake
            </Button>
          ) : (
            [
              <Button
                key="stake"
                type="text"
                className="extra-button btn-stake"
                onClick={() => {
                  clickWithCheckChainId(() => toggleDelegateModalVisible());
                }}
              >
                Stake More
              </Button>,
              <Button
                key="unbond"
                type="text"
                className="extra-button btn-unbond"
                onClick={() => {
                  clickWithCheckChainId(() => toggleUnbondModalVisible());
                }}
                disabled={delegator?.tokens?.eq(0)}
              >
                Unbond
              </Button>,
              <Button
                key="withdraw"
                type="text"
                className="extra-button btn-withdraw"
                onClick={() => {
                  clickWithCheckChainId(() => toggleWithdrawModalVisible());
                }}
                disabled={delegator?.withdrawableUndelegationTokens?.eq(0)}
              >
                Withdraw
              </Button>,
            ]
          )}
        </div>
        <DelegateModal
          visible={delegateModalVisible}
          onClose={() => {
            toggleDelegateModalVisible();
            dispatch(setRefreshData());
          }}
          validatorInfo={{
            valAddr: validator?.valAddr,
            commissionRate: validator?.commissionRate,
            desc: validator?.description,
          }}
        />
        <UnbondModal
          visible={unbondModalVisible}
          onClose={() => {
            toggleUnbondModalVisible();
            dispatch(setRefreshData());
          }}
          validatorInfo={{
            valAddr: validator?.valAddr,
            commissionRate: validator?.commissionRate,
            desc: validator?.description,
          }}
          delegatorInfo={{
            tokens: delegator?.tokens || BigNumber.from(0),
          }}
        />
        <WithdrawModal
          visible={withdrawModalVisible}
          onClose={() => {
            toggleWithdrawModalVisible();
            dispatch(setRefreshData());
          }}
          validatorInfo={{
            valAddr: validator?.valAddr,
            commissionRate: validator?.commissionRate,
            desc: validator?.description,
          }}
          delegatorInfo={{
            withdrawableTokens: delegator?.withdrawableUndelegationTokens || BigNumber.from(0),
          }}
        />
      </div>
    </Spin>
  );
};

interface MobileTableItemProps {
  validator: ValidatoAndDelegatorType;
}

const MobileTableItem = (props: MobileTableItemProps) => {
  const [expandedVisible, toggleExpandedVisible] = useToggle(false);
  const { validator } = props;
  const { address, chainId, signer } = useWeb3Context();
  const [delegator, setDelegator] = useState<DelegatorInfoType>();
  const [delegateModalVisible, toggleDelegateModalVisible] = useToggle(false);
  const [unbondModalVisible, toggleUnbondModalVisible] = useToggle(false);
  const [withdrawModalVisible, toggleWithdrawModalVisible] = useToggle(false);
  const { transferInfo, globalInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;
  const { unbondingBlockNum } = globalInfo;
  const dispatch = useAppDispatch();
  const { getRpcUrlByChainId } = useConfigContext();
  const { stakingContractAddress } = useAppSelector(state => state.globalInfo);
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const stakingContract = useReadOnlyCustomContractLoader(jprovider, stakingContractAddress, Staking__factory);
  const [loading, setLoading] = useState(false);

  const clickWithCheckChainId = method => {
    if (chainId && chainId !== Number(process.env.REACT_APP_NETWORK_ID)) {
      return dispatch(openModal(ModalName.switchChain));
    }
    return method();
  };

  useAsync(async () => {
    if (!stakingContract) {
      return;
    }
    try {
      setLoading(true);
      const delegatorInfo = await stakingContract.getDelegatorInfo(validator?.valAddr, address);
      setDelegator(delegatorInfo);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }, [stakingContract, address]);

  useAsync(async () => {
    if (!stakingContract) {
      return;
    }
    try {
      const delegatorInfo = await stakingContract.getDelegatorInfo(validator?.valAddr, address);
      setDelegator(delegatorInfo);
    } catch (e) {
      console.log(e);
    }
  }, [refreshMyDelegations]);

  const commissionRateNumber = validator?.commissionRate.toNumber() || 0;
  const unbondingStake = delegator?.undelegationTokens?.sub(delegator?.withdrawableUndelegationTokens);

  const expandedSwitch = () => {
    return expandedVisible ? (
      <div className="btn-more" onClick={toggleExpandedVisible}>
        <span>Hide</span>
        <img src={upIcon} alt="" className="expanded-icon" />
      </div>
    ) : (
      <div className="btn-more" onClick={toggleExpandedVisible}>
        <span>Details</span>
        <img src={downIcon} alt="" className="expanded-icon" />
      </div>
    );
  };

  return (
    <Spin spinning={loading}>
      <div className={`mobile-table-item ${expandedVisible ? "mobile-table-item-expanded" : ""}`}>
        <div className="item-info">
          <img src={validator.description.details.icon || userIcon} alt="" className="info-icon" />
          <div className="info-desc">
            <div className="info-desc-name">{validator.description.moniker || "Anonymous"}</div>
            <div className="info-desc-addr">{getSimple(validator.valAddr, 6, -4)}</div>
          </div>
        </div>
        <div className="item-content">
          <div className="content-item">
            <div className="content-item-title">Status</div>
            <div className="content-item-value">{`${CANDIDATE_STATUS[validator.status]}`}</div>
          </div>
          <div className="content-item">
            <div className="content-item-title">Stacking Pool</div>
            <div className="content-item-value">
              {`${formatCelrWithoutSymbol(validator.tokens, 0)}`}
              <span className="cell-text celr"> CELR</span>
            </div>
          </div>
          <div className="content-item">
            <div className="content-item-title">Commission Rate</div>
            <div className="content-item-value">{`${validator.commissionRate.toNumber() / RATE_BASE}%`}</div>
          </div>
        </div>
        <div className="item-btns">
          <Button
            disabled={signer === undefined}
            className="btn-delegate"
            onClick={e => {
              clickWithCheckChainId(() => toggleDelegateModalVisible());
              e.stopPropagation();
            }}
          >
            Delegate
          </Button>
          {signer ? expandedSwitch() : null}
        </div>
        {expandedVisible ? (
          <div className="mobile-expanded-block">
            <div className="expanded-info">
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">Number of Validators</div>
                <div className="expanded-info-item-value">100</div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">
                  Your Delegated Stake
                  <Tooltip
                    placement="topRight"
                    title={
                      <div className="toolsDetail">
                        This is your stake currently delegated with this validator.
                        <br />
                        <br />
                        Note that your unbonding stake or withdrawable stake is not included.
                      </div>
                    }
                    color="#FFFFFF"
                    overlayInnerStyle={{
                      color: "#0A1E42",
                      width: 200,
                      fontSize: 12,
                      lineHeight: "14px",
                    }}
                  >
                    <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                  </Tooltip>
                </div>
                <div className="expanded-info-item-value">{formatCelrDecimal(delegator?.tokens, 6)}</div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">
                  Your Unbonding Stake
                  <Tooltip
                    placement="top"
                    title={
                      <div className="toolsDetail">
                        This is your stake amount currently in the unbonding process. The unbonding process takes{" "}
                        {getTimeByBlockNum(unbondingBlockNum)}, after which it will become withdrawable.
                      </div>
                    }
                    color="#FFFFFF"
                    overlayInnerStyle={{
                      color: "#0A1E42",
                      width: 200,
                      fontSize: 12,
                      lineHeight: "14px",
                    }}
                  >
                    <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                  </Tooltip>
                </div>
                <div className="expanded-info-item-value">{formatCelrDecimal(unbondingStake, 6)}</div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">
                  Your Withdrawable Stake
                  <Tooltip
                    placement="top"
                    title={
                      <div className="toolsDetail">
                        Withdrawable stake is ready to be claimed on {getNetworkById(chainId).name}.
                        <br />
                        <br />
                        Note: to increase your withdrawable stake, you first need to unbond your stake, which takes{" "}
                        {getTimeByBlockNum(unbondingBlockNum)}.
                      </div>
                    }
                    color="#FFFFFF"
                    overlayInnerStyle={{
                      color: "#0A1E42",
                      width: 200,
                      fontSize: 12,
                      lineHeight: "14px",
                    }}
                  >
                    <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                  </Tooltip>
                </div>
                <div className="expanded-info-item-value">
                  {formatCelrDecimal(delegator?.withdrawableUndelegationTokens, 6)}
                </div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">Address</div>
                <div className="expanded-info-item-value">{getSimple(validator?.valAddr, 4, -4)}</div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">Commission Rate</div>
                <div className="expanded-info-item-value">{`${commissionRateNumber / RATE_BASE}%`}</div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">Contact</div>
                <div className="expanded-info-item-value">{validator.description.contact || "--"} </div>
              </div>
              <div className="expanded-info-item">
                <div className="expanded-info-item-title">Website</div>
                <div className="expanded-info-item-value">{validator.description.website || "--"}</div>
              </div>
            </div>
            <div className="expanded-btns">
              {!delegator || (delegator?.tokens.eq(0) && delegator?.withdrawableUndelegationTokens.eq(0)) ? (
                <Button
                  key="stake"
                  type="primary"
                  className="expanded-btn stake"
                  onClick={() => {
                    clickWithCheckChainId(() => toggleDelegateModalVisible());
                  }}
                >
                  Stake
                </Button>
              ) : (
                [
                  <Button
                    key="stake"
                    type="text"
                    className="expanded-btn stake"
                    onClick={() => {
                      clickWithCheckChainId(() => toggleDelegateModalVisible());
                    }}
                  >
                    Stake More
                  </Button>,
                  <Button
                    key="unbond"
                    type="text"
                    className="expanded-btn unbond"
                    onClick={() => {
                      clickWithCheckChainId(() => toggleUnbondModalVisible());
                    }}
                    disabled={delegator?.tokens?.eq(0)}
                  >
                    Unbond
                  </Button>,
                  <Button
                    key="withdraw"
                    type="text"
                    className="expanded-btn withdraw"
                    onClick={() => {
                      clickWithCheckChainId(() => toggleWithdrawModalVisible());
                    }}
                    disabled={delegator?.withdrawableUndelegationTokens?.eq(0)}
                  >
                    Withdraw
                  </Button>,
                ]
              )}
            </div>
          </div>
        ) : null}
        <DelegateModal
          visible={delegateModalVisible}
          onClose={() => {
            toggleDelegateModalVisible();
            dispatch(setRefreshData());
          }}
          validatorInfo={{
            valAddr: validator?.valAddr,
            commissionRate: validator?.commissionRate,
            desc: validator?.description,
          }}
        />
        <UnbondModal
          visible={unbondModalVisible}
          onClose={() => {
            toggleUnbondModalVisible();
            dispatch(setRefreshData());
          }}
          validatorInfo={{
            valAddr: validator?.valAddr,
            commissionRate: validator?.commissionRate,
            desc: validator?.description,
          }}
          delegatorInfo={{
            tokens: delegator?.tokens || BigNumber.from(0),
          }}
        />
        <WithdrawModal
          visible={withdrawModalVisible}
          onClose={() => {
            toggleWithdrawModalVisible();
            dispatch(setRefreshData());
          }}
          validatorInfo={{
            valAddr: validator?.valAddr,
            commissionRate: validator?.commissionRate,
            desc: validator?.description,
          }}
          delegatorInfo={{
            withdrawableTokens: delegator?.withdrawableUndelegationTokens || BigNumber.from(0),
          }}
        />
      </div>
    </Spin>
  );
};

export default function Dpos(props: { validators: Array<ValidatoAndDelegatorType> }): JSX.Element | null {
  const { signer, chainId } = useWeb3Context();
  const { validators } = props;
  const [delegateModalVisible, toggleDelegateModalVisible] = useToggle(false);
  const [selectedValidator, setSelectedValidator] = useState<ValidatoAndDelegatorType>();
  const [expandedValidator, setExpandedValidator] = useState<ValidatoAndDelegatorType>();
  const [tableExpanded, setTableExpanded] = useState(false);
  // const [switchStatus, toggleSwitchStatus] = useState(false);
  const [sortedValidators, setSortedValidators] = useState(validators);
  const dispatch = useAppDispatch();
  const [stackingPoolSortType, setStackingPoolSortType] = useState<SortType>("");
  const [commissionRateSortType, setCommissionRateSortType] = useState<SortType>("");

  // useEffect(() => {
  //   console.log("switch status to only show my delegated validators");
  // }, [switchStatus]);

  useEffect(() => {
    const tempSortedValidators = validators.sort((a, b) => {
      return b.status - a.status;
    });
    setSortedValidators(tempSortedValidators);
  }, [validators]);

  const sortValidatorsMethod = (sortType: SortType, sortKey) => {
    const tempSortedValidators = validators.sort((a, b) => {
      if (sortType === "up") {
        return a[sortKey] - b[sortKey];
      }
      return b[sortKey] - a[sortKey];
    });
    setSortedValidators(tempSortedValidators);
  };

  const showProviderModal = useCallback(() => {
    dispatch(openModal(ModalName.provider));
  }, [dispatch]);

  const clickWithCheckChainId = method => {
    if (chainId && chainId !== Number(process.env.REACT_APP_NETWORK_ID)) {
      return dispatch(openModal(ModalName.switchChain));
    }
    return method();
  };

  const toggleDelegateModal = (candidateId: string) => {
    toggleDelegateModalVisible();
    const targetValidator = validators.find(validator => validator.valAddr === candidateId);
    setSelectedValidator(targetValidator);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "valAddr",
      width: 350,
      render: (text, record) => (
        <div className="useracount">
          <img src={record.description.details.icon || userIcon} className="userhead" alt="" />
          <div className="userFont">
            <div className="username">{record.description.moniker || "Anonymous"}</div>
            <div className="useraddress">{getSimple(text, 6, -4)}</div>
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 170,
      render: text => <div className="cell-text status">{`${CANDIDATE_STATUS[text]}`}</div>,
    },
    {
      title: "Staking Pool",
      dataIndex: "tokens",
      width: 220,
      sorter: (a, b) => a.tokens - b.tokens,
      render: text => (
        <div className="cell-text staking-pool">
          {`${formatCelrWithoutSymbol(text, 0)}`}
          <span className="cell-text celr"> CELR</span>
        </div>
      ),
    },
    {
      title: () => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip
            placement="topLeft"
            title={
              <div className="toolsDetail">
                This is the percentage of your staking rewards taken by this validator.
                <br />
                <a href={`${helpUrl}`} target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </div>
            }
            color="#FFFFFF"
            overlayInnerStyle={{
              color: "#0A1E42",
              width: 290,
              // fontFamily: "SF Pro Text",
              fontSize: 12,
              lineHeight: "14px",
            }}
          >
            <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
          </Tooltip>
          Commission Rate
        </div>
      ),
      dataIndex: "commissionRate",
      width: 220,
      sorter: (a, b) => a.commissionRate - b.commissionRate,
      render: (text: BigNumber) => {
        const commissionRateNumber = text.toNumber();
        return <div className="DM-Mono">{`${commissionRateNumber / RATE_BASE}%`}</div>;
      },
    },
    {
      key: "action",
      width: 100,
      render: (text, record) => (
        <Button
          disabled={signer === undefined}
          className={signer ? "delegateButton" : "delegateDisableButton"}
          onClick={e => {
            clickWithCheckChainId(() => toggleDelegateModal(record.valAddr));
            e.stopPropagation();
          }}
        >
          Delegate
        </Button>
      ),
    },
  ];

  const customizeRenderEmpty = () => {
    return (
      <div style={{ paddingTop: 100, color: "#87828D", fontWeight: "bold", fontFamily: "Karla", fontStyle: "normal" }}>
        <p>👀</p>
        <p>No Data yet!</p>
        {signer ? null : (
          <Button type="primary" onClick={showProviderModal} className="connect-btn">
            CONNECT WALLET
          </Button>
        )}
      </div>
    );
  };

  const renderMobileTable = () => {
    return (
      <div className="mobile-table">
        {/* <div className="table-title">
          <div className="table-sub-title">
            <div className="table-sub-title-text">Only show my delegated validators</div>
            <div className="table-sub-title-check">
              <Switch checked={switchStatus} onChange={() => toggleSwitchStatus(!switchStatus)} />
            </div>
          </div>
        </div> */}
        <div className="table-sort">
          <div className="table-sort-item">
            Stacking Pool
            <Sort
              sortType={stackingPoolSortType}
              sortMeth={() => {
                setCommissionRateSortType("");
                if (stackingPoolSortType === "" || stackingPoolSortType === "down") {
                  setStackingPoolSortType("up");
                  sortValidatorsMethod("up", "tokens");
                } else {
                  setStackingPoolSortType("down");
                  sortValidatorsMethod("down", "tokens");
                }
              }}
            />
          </div>
          <div className="table-sort-item">
            Commission Rate
            <Sort
              sortType={commissionRateSortType}
              sortMeth={() => {
                setStackingPoolSortType("");
                if (commissionRateSortType === "" || commissionRateSortType === "down") {
                  setCommissionRateSortType("up");
                  sortValidatorsMethod("up", "commissionRate");
                } else {
                  setCommissionRateSortType("down");
                  sortValidatorsMethod("down", "commissionRate");
                }
              }}
            />
          </div>
        </div>
        <div className="table-items">
          {sortedValidators?.map(item => {
            return <MobileTableItem key={item.valAddr} validator={item} />;
          })}
        </div>
      </div>
    );
  };

  const renderWebTable = () => {
    return (
      <Table
        dataSource={sortedValidators}
        columns={columns}
        pagination={false}
        rowClassName={record => {
          if (tableExpanded && record.valAddr === expandedValidator?.valAddr) {
            return "table-cell-color-open";
          }
          return "table-cell-color-close";
        }}
        rowKey="valAddr"
        className="vals-table"
        showSorterTooltip={false}
        expandRowByClick={signer ? !false : false}
        expandable={
          signer
            ? {
                expandedRowRender: record => <ExpandedDom validator={record} />,
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <div
                      className="expanded-block"
                      onClick={e => {
                        onExpand(record, e);
                        setTableExpanded(false);
                        setExpandedValidator(record);
                      }}
                    >
                      <span>Hide</span>
                      <div className="expanded-icon">
                        <img src={upIcon} alt="" />
                      </div>
                    </div>
                  ) : (
                    <div
                      className="expanded-block"
                      onClick={e => {
                        onExpand(record, e);
                        setTableExpanded(true);
                        setExpandedValidator(record);
                      }}
                    >
                      <span>Details</span>
                      <div className="expanded-icon">
                        <img src={downIcon} alt="" />
                      </div>
                    </div>
                  ),
                fixed: false,
                expandIconColumnIndex: 5,
              }
            : {}
        }
      />
    );
  };

  return (
    <>
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        {isMobile ? renderMobileTable() : renderWebTable()}
        {selectedValidator ? (
          <DelegateModal
            visible={delegateModalVisible}
            onClose={() => toggleDelegateModalVisible()}
            validatorInfo={{
              valAddr: selectedValidator.valAddr,
              commissionRate: selectedValidator.commissionRate,
              desc: selectedValidator.description,
            }}
          />
        ) : null}
      </ConfigProvider>
    </>
  );
}
