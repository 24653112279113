import { BigNumberish } from "@ethersproject/bignumber";
import { formatUnits } from "@ethersproject/units";
import { components } from "../api/api";
import { CELR, BLOCK_TIME } from "../constants";

export function formatBalance(balance: string | number, sep = "", keepDecimals = 6): string {
  balance = balance.toString();
  const [integerPart, decimalPart] = balance.split(".");
  const str = integerPart.toString();
  const chunkSize = 3;
  const result: string[] = [];
  for (let i = 0; i < str.length; i += chunkSize) {
    const end = str.length - i;
    const start = end - chunkSize;
    result.unshift(str.substring(start, end));
  }

  let resultIntPart = result.join(sep);

  if (decimalPart) {
    let keptDecimals = decimalPart;
    if (decimalPart.length > keepDecimals) {
      const keptDecimalsArr = decimalPart.slice(0, keepDecimals + 1).split("");
      if (keptDecimalsArr[keptDecimals.length - 1] > "4") {
        keptDecimalsArr[keptDecimals.length - 2] += 1;
      }
      keptDecimals = keptDecimalsArr.slice(0, keepDecimals).join("");
    }
    if (!resultIntPart) {
      resultIntPart = "0";
    }
    if (keptDecimals) {
      return resultIntPart + "." + keptDecimals;
    }
  }

  return resultIntPart;
}

export function formatDeleteBalance(amount: string): string {
  const splitStrs = amount.split(",");
  const resultStr = splitStrs.join("");
  return resultStr;
}

export function formatDecimal(amount?: BigNumberish, decimalCount = 18, keepDecimals?: number): string {
  const unitFormateed = formatUnits(amount || 0, decimalCount);
  return formatBalance(unitFormateed, ",", keepDecimals);
}

export function formatMula(num: string | number, symbol: string): string {
  if (num === undefined || num === null) {
    return symbol + "0.00";
  }
  const formattedNum = formatBalance(num, ",", 2);
  return symbol + formattedNum;
}

export function formatUSD(num: string | number): string {
  return formatMula(num, "$");
}

export function formatPercentage(num: number | undefined, isAlreadyPercent = false): string {
  if (!num) {
    return "0.00%";
  }
  if (isAlreadyPercent) {
    return num.toFixed(2) + "%";
  }
  return (num * 100).toFixed(2) + "%";
}

export function formatToken(amount: string | undefined | BigNumberish, token: components["schemas"]["Asset"]) {
  return `${formatDecimal(amount, token.decimal)} ${token.symbol}`;
}

export function formatDate(timestamp: number) {
  if (!timestamp) {
    return "--";
  }
  return new Date(timestamp).toLocaleString();
}

export function formatCurrencyValue(value, unit, showDecimal) {
  if (!value) {
    return "";
  }

  if (showDecimal) {
    return `${formatUnits(value, 18)} ${unit}`;
  }

  return `${formatUnits(value, 18).split(".")[0]} ${unit}`;
}

export function formatCelrValue(value, showDecimal) {
  return formatCurrencyValue(value, CELR, showDecimal);
}

export function formatRemoveDecimals(value) {
  return `${value.split(".")[0]}`;
}

export function formatCelrDecimal(value, decimal) {
  return `${formatCelrWithoutSymbol(value, decimal)} ${CELR}`;
  // if (!value) {
  //   return "";
  // }

  // if (decimal > 0) {
  //   const celr = formatUnits(value, 18)
  //   const celrDecimalStr = celr.split('.')[1];
  //   if(celrDecimalStr.length>2){
  //     return `${celr.split('.')[0]}.${celrDecimalStr.slice(0, 2)} ${CELR}`;
  //   }
  //   return `${celr} ${CELR}`;
  // }

  // return `${formatUnits(value, 18).split('.')[0]} ${CELR}`;
}

export function formatCelrWithoutSymbol(value, decimal, decimals?: number) {
  if (!value) {
    return "0";
  }

  if (decimal > 0) {
    const celr = formatUnits(value, decimals || 18);
    const celrDecimalStr = celr.split(".")[1];
    if (celrDecimalStr.length >= decimal) {
      const decimalStr = `${celr.split(".")[0]}.${celrDecimalStr.slice(0, decimal)}`;
      return `${addSplitThousandSeparator(Number(decimalStr))}`;
    }
    return `${addSplitThousandSeparator(Number(celr))}`;
  }

  return `${addSplitThousandSeparator(Number(formatUnits(value, decimals || 18).split(".")[0]))}`;
}

export function formatTokenWithoutSymbol(value, decimal, decimals?: number) {
  if (!value) {
    return "0";
  }

  if (decimal > 0) {
    const celr = formatUnits(value, decimals || 18);
    const celrDecimalStr = celr.split(".")[1];
    if (celrDecimalStr.length >= decimal) {
      const decimalStr = `${celr.split(".")[0]}.${celrDecimalStr.slice(0, decimal)}`;
      return `${parseFloat(Number(decimalStr).toFixed(decimal)).toLocaleString()}`;
    }
    return `${parseFloat(Number(celr).toFixed(decimal)).toLocaleString()}`;
  }

  return `${Number(formatUnits(value, decimals || 18).split(".")[0]).toLocaleString()}`;
}

export function formatTokenDecimals(amount, decimal = 18, symbol) {
  if (!amount) {
    return `0 ${symbol}`;
  }

  if (decimal > 0) {
    const celr = formatUnits(amount, decimal);
    const celrDecimalStr = celr.split(".")[1];
    if (celrDecimalStr.length >= decimal) {
      const decimalStr = `${celr.split(".")[0]}.${celrDecimalStr.slice(0, decimal)}`;
      return `${parseFloat(Number(decimalStr).toFixed(decimal)).toLocaleString()} ${symbol}`;
    }
    return `${parseFloat(Number(celr).toFixed(decimal)).toLocaleString()} ${symbol}`;
  }

  return `${Number(formatUnits(amount, decimal).split(".")[0]).toLocaleString()} ${symbol}`;
}

export function delegateSplitThousandSeparator(num) {
  if (num !== undefined) {
    const numStr = `${num}`;
    const result = numStr.replace(/,/gi, "");
    return Number(result);
  }
  return 0;
}

export function addSplitThousandSeparator(num: number): string {
  const DIGIT_PATTERN = /(^|\s)\d+(?=\.?\d*($|\s))/g;
  const MILI_PATTERN = /(?=(?!\b)(\d{3})+\.?\b)/g;
  const str: string = num.toString().replace(DIGIT_PATTERN, m => m.replace(MILI_PATTERN, ","));
  return str;
}

function formatDot(num: number) {
  const str = num.toFixed(2);
  const dot = str.split(".")[1];
  if (dot === "00") {
    return str.split(".")[0];
  }
  return str;
}

// export function getTimeByBlockNum(blockNum: number) {
//   const seconds = blockNum * BLOCK_TIME;
//   const hours = (seconds / (60 * 60));
//   if(hours < 24) {
//     const hoursStr = formatDot(hours);
//     return `${hoursStr} hours`;
//   }
//   const days = hours / 24;
//   const daysStr = formatDot(days);
//   return `${daysStr} days`;
// }

export function getTimeByBlockNum(blockNum: number) {
  const seconds = blockNum * BLOCK_TIME;
  const hours = seconds / (60 * 60);
  if (hours < 24) {
    const hoursStr = formatDot(hours);
    return `${Number(hoursStr).toFixed(0)} hours`;
  }
  const days = hours / 24;
  const daysStr = formatDot(days);
  return `${Number(daysStr).toFixed(0)} days`;
}
