import { RightOutlined } from "@ant-design/icons";
import { Button, Modal, Space, Spin, Typography, Avatar } from "antd";
import { isMobile } from "react-device-detect";
import { injected, IProviderInfo } from "@celer-network/web3modal";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { NonEVMMode, useNonEVMContext, getNonEVMMode } from "../../providers/NonEVMContextProvider";
import { useAppSelector } from "../../redux/store";
import "./index.less";
// import dcentLogo from "./dcent.webp";

const { Text } = Typography;

interface IProps {
  visible: boolean;
  onCancel: () => void;
}

type NonEVMProvider = {
  logo: string;
  name: string;
};

interface ProviderProps {
  provider: IProviderInfo | NonEVMProvider;
  onClick: () => void;
}
const Provider = (props: ProviderProps) => {
  const { provider, onClick } = props;
  return (
    <div className="provider" onClick={onClick}>
      {/* {provider === "WalletConnect" ? (
        <Space>
          <img src="./connect.png" alt="" style={{ width: 32 }} />
          <Text>WalletConnect</Text>
        </Space>
      ) : (
        <Space>
          <Avatar src={provider.logo} shape="circle" />
          <Text>{provider.name}</Text>
        </Space>
      )} */}
      <Space>
        <Avatar src={provider.logo} shape="circle" />
        <Text>{provider.name}</Text>
      </Space>
      <RightOutlined className="arrow" />
    </div>
  );
};

const ProviderModal = (props: IProps): JSX.Element => {
  const { loadWeb3Modal, connecting } = useWeb3Context();
  const { visible, onCancel } = props;

  const { loadNonEVMModal } = useNonEVMContext();
  const { selectedChainId } = useAppSelector(state => state.globalInfo);
  const nonEVMMode = getNonEVMMode(selectedChainId ?? 0);
  const isNonEvmMode = nonEVMMode === NonEVMMode.flowMainnet || nonEVMMode === NonEVMMode.flowTest;

  const handleSelectProvider = async () => {
    if (isNonEvmMode) {
      await loadNonEVMModal(nonEVMMode);
    } else {
      await loadWeb3Modal("injected");
    }
    onCancel();
  };

  return (
    <Modal
      title="Connect Your Wallet"
      className="provider-modal"
      width={401}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div className="content">
        <Spin spinning={connecting}>
          <div className="provider-list">
            {isNonEvmMode ? (
              <Provider
                provider={{ logo: "./flowBlocktoWallet.png", name: "Blocto Wallet" }}
                onClick={handleSelectProvider}
              />
            ) : (
              <>
                <Provider provider={injected.METAMASK} onClick={handleSelectProvider} />
                {isMobile ? (
                  <>
                    <Provider provider={injected.IMTOKEN} onClick={handleSelectProvider} />
                    <Provider provider={injected.MATHWALLET} onClick={handleSelectProvider} />
                    <Provider provider={injected.TOKENPOCKET} onClick={handleSelectProvider} />
                    <Provider provider={injected.ONTOWALLET} onClick={handleSelectProvider} />
                    <Provider provider={injected.COIN98WALLET} onClick={handleSelectProvider} />
                    <Provider provider={injected.GOPOCKET} onClick={handleSelectProvider} />
                  </>
                ) : null}
              </>
            )}
          </div>
        </Spin>
        <div className="tooltip">*Beta software. Use at your own risk!</div>
        <div className="provider-footer">
          <div className="accept-text">By connecting, I accept Celer Network</div>
          <div className="action-text">
            <Button type="link" onClick={() => window.open("./terms-of-use.pdf")}>
              Terms of Use
            </Button>
            <div className="line" />
            <Button type="link" onClick={() => window.open("./privacy-policy.pdf")}>
              Privacy Policy
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProviderModal;
