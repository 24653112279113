import { Modal, Spin, Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import careerPng from "../../assets/images/careerbanner.png";
import { ClaimType, CLAIM_INIT, CLAIM_WAITING, CLAIM_SUCCESS } from "../../constants";
import "./claimform.less";
import { getExlporerUrl } from "../../helpers/env";

/* eslint-disable */
interface ClaimFormProps {
  visible: boolean;
  action: ClaimType;
  reward: string;
  redeemReward: any;
  claimSuccessMethod: any;
  toggleClaimForm: any;
  claimTxHash: string;
}

export default function ClaimForm(props: ClaimFormProps): JSX.Element {
  const { visible, action, reward, redeemReward, claimSuccessMethod, toggleClaimForm, claimTxHash } = props;
  const explorerUrl = getExlporerUrl();
  const renderBtn = () => {
    switch (action) {
      case CLAIM_INIT:
        return (
          <Button type="text" onClick={redeemReward}>
            Claim
          </Button>
        );
      case CLAIM_WAITING:
        return (
          <Button type="text" className="waiting" disabled>
            <Spin />
          </Button>
        );
      default:
        return null;
    }
  };

  const renderContent = () => (
    <div className="claimform-container">
      <div className="claimform-img">
        <img src={careerPng} alt=" " />
      </div>
      <div className="claimform-content">
        <div className="unlock-reward">
          <span>Claimable Reward</span>
          <span className="celr-amount">{reward}</span>
        </div>
        {renderBtn()}
      </div>
    </div>
  );

  const renderSuccess = () => {
    return (
      <div className="success-content">
        <CheckOutlined className="icon-success" />
        <div className="mian-title">Claim Reward Submitted</div>
        <div className="sub-title">Please allow a few minutes for the transaction to complete.</div>
        <div className="action-check">
          <a href={`${explorerUrl}/tx/${claimTxHash}`} target="_blank" rel="noreferrer">
            Check on Etherscan
          </a>
        </div>
        <Button type="primary" className="action-ok" onClick={claimSuccessMethod}>
          OK
        </Button>
      </div>
    );
  };

  const renderModalTitle = () => {
    switch (action) {
      case CLAIM_INIT:
      case CLAIM_SUCCESS:
        return "Claim reward";
      case CLAIM_WAITING:
        return "Claiming reward";
      default:
        return "";
    }
  };

  return (
    <Modal
      title={renderModalTitle()}
      visible={visible}
      footer={null}
      width={401}
      className="claimForm-modal"
      onCancel={action !== CLAIM_SUCCESS ? toggleClaimForm : claimSuccessMethod}
    >
      <div className="claimform-container">{action !== CLAIM_SUCCESS ? renderContent() : renderSuccess()}</div>
    </Modal>
  );
}
