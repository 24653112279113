/**
 * @fileoverview gRPC-Web generated client stub for sgn.gateway.v1
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as gateway_gateway_pb from '../gateway/gateway_pb';
import * as sgn_health_v1_health_pb from '../sgn/health/v1/health_pb';


export class WebClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname;
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorGetTransferConfigs = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTransferConfigs',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTransferConfigsRequest,
    gateway_gateway_pb.GetTransferConfigsResponse,
    (request: gateway_gateway_pb.GetTransferConfigsRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTransferConfigsResponse.deserializeBinary
  );

  getTransferConfigs(
    request: gateway_gateway_pb.GetTransferConfigsRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTransferConfigsResponse>;

  getTransferConfigs(
    request: gateway_gateway_pb.GetTransferConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferConfigsResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTransferConfigsResponse>;

  getTransferConfigs(
    request: gateway_gateway_pb.GetTransferConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferConfigsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTransferConfigs',
        request,
        metadata || {},
        this.methodDescriptorGetTransferConfigs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTransferConfigs',
    request,
    metadata || {},
    this.methodDescriptorGetTransferConfigs);
  }

  methodDescriptorGetTransferConfigsForAll = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTransferConfigsForAll',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTransferConfigsRequest,
    gateway_gateway_pb.GetTransferConfigsResponse,
    (request: gateway_gateway_pb.GetTransferConfigsRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTransferConfigsResponse.deserializeBinary
  );

  getTransferConfigsForAll(
    request: gateway_gateway_pb.GetTransferConfigsRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTransferConfigsResponse>;

  getTransferConfigsForAll(
    request: gateway_gateway_pb.GetTransferConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferConfigsResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTransferConfigsResponse>;

  getTransferConfigsForAll(
    request: gateway_gateway_pb.GetTransferConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferConfigsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTransferConfigsForAll',
        request,
        metadata || {},
        this.methodDescriptorGetTransferConfigsForAll,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTransferConfigsForAll',
    request,
    metadata || {},
    this.methodDescriptorGetTransferConfigsForAll);
  }

  methodDescriptorGetTokenInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTokenInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTokenInfoRequest,
    gateway_gateway_pb.GetTokenInfoResponse,
    (request: gateway_gateway_pb.GetTokenInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTokenInfoResponse.deserializeBinary
  );

  getTokenInfo(
    request: gateway_gateway_pb.GetTokenInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTokenInfoResponse>;

  getTokenInfo(
    request: gateway_gateway_pb.GetTokenInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTokenInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTokenInfoResponse>;

  getTokenInfo(
    request: gateway_gateway_pb.GetTokenInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTokenInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTokenInfo',
        request,
        metadata || {},
        this.methodDescriptorGetTokenInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTokenInfo',
    request,
    metadata || {},
    this.methodDescriptorGetTokenInfo);
  }

  methodDescriptorEstimateAmt = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/EstimateAmt',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.EstimateAmtRequest,
    gateway_gateway_pb.EstimateAmtResponse,
    (request: gateway_gateway_pb.EstimateAmtRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.EstimateAmtResponse.deserializeBinary
  );

  estimateAmt(
    request: gateway_gateway_pb.EstimateAmtRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.EstimateAmtResponse>;

  estimateAmt(
    request: gateway_gateway_pb.EstimateAmtRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.EstimateAmtResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.EstimateAmtResponse>;

  estimateAmt(
    request: gateway_gateway_pb.EstimateAmtRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.EstimateAmtResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/EstimateAmt',
        request,
        metadata || {},
        this.methodDescriptorEstimateAmt,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/EstimateAmt',
    request,
    metadata || {},
    this.methodDescriptorEstimateAmt);
  }

  methodDescriptorEstimateAmtForSVC = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/EstimateAmtForSVC',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.EstimateAmtRequest,
    gateway_gateway_pb.EstimateAmtResponse,
    (request: gateway_gateway_pb.EstimateAmtRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.EstimateAmtResponse.deserializeBinary
  );

  estimateAmtForSVC(
    request: gateway_gateway_pb.EstimateAmtRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.EstimateAmtResponse>;

  estimateAmtForSVC(
    request: gateway_gateway_pb.EstimateAmtRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.EstimateAmtResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.EstimateAmtResponse>;

  estimateAmtForSVC(
    request: gateway_gateway_pb.EstimateAmtRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.EstimateAmtResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/EstimateAmtForSVC',
        request,
        metadata || {},
        this.methodDescriptorEstimateAmtForSVC,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/EstimateAmtForSVC',
    request,
    metadata || {},
    this.methodDescriptorEstimateAmtForSVC);
  }

  methodDescriptorGetTransferStatus = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTransferStatus',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTransferStatusRequest,
    gateway_gateway_pb.GetTransferStatusResponse,
    (request: gateway_gateway_pb.GetTransferStatusRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTransferStatusResponse.deserializeBinary
  );

  getTransferStatus(
    request: gateway_gateway_pb.GetTransferStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTransferStatusResponse>;

  getTransferStatus(
    request: gateway_gateway_pb.GetTransferStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferStatusResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTransferStatusResponse>;

  getTransferStatus(
    request: gateway_gateway_pb.GetTransferStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTransferStatus',
        request,
        metadata || {},
        this.methodDescriptorGetTransferStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTransferStatus',
    request,
    metadata || {},
    this.methodDescriptorGetTransferStatus);
  }

  methodDescriptorGetLPInfoList = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetLPInfoList',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetLPInfoListRequest,
    gateway_gateway_pb.GetLPInfoListResponse,
    (request: gateway_gateway_pb.GetLPInfoListRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetLPInfoListResponse.deserializeBinary
  );

  getLPInfoList(
    request: gateway_gateway_pb.GetLPInfoListRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetLPInfoListResponse>;

  getLPInfoList(
    request: gateway_gateway_pb.GetLPInfoListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetLPInfoListResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetLPInfoListResponse>;

  getLPInfoList(
    request: gateway_gateway_pb.GetLPInfoListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetLPInfoListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetLPInfoList',
        request,
        metadata || {},
        this.methodDescriptorGetLPInfoList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetLPInfoList',
    request,
    metadata || {},
    this.methodDescriptorGetLPInfoList);
  }

  methodDescriptorWithdrawLiquidity = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/WithdrawLiquidity',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.WithdrawLiquidityRequest,
    gateway_gateway_pb.WithdrawLiquidityResponse,
    (request: gateway_gateway_pb.WithdrawLiquidityRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.WithdrawLiquidityResponse.deserializeBinary
  );

  withdrawLiquidity(
    request: gateway_gateway_pb.WithdrawLiquidityRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.WithdrawLiquidityResponse>;

  withdrawLiquidity(
    request: gateway_gateway_pb.WithdrawLiquidityRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.WithdrawLiquidityResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.WithdrawLiquidityResponse>;

  withdrawLiquidity(
    request: gateway_gateway_pb.WithdrawLiquidityRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.WithdrawLiquidityResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/WithdrawLiquidity',
        request,
        metadata || {},
        this.methodDescriptorWithdrawLiquidity,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/WithdrawLiquidity',
    request,
    metadata || {},
    this.methodDescriptorWithdrawLiquidity);
  }

  methodDescriptorEstimateWithdrawAmt = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/EstimateWithdrawAmt',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.EstimateWithdrawAmtRequest,
    gateway_gateway_pb.EstimateWithdrawAmtResponse,
    (request: gateway_gateway_pb.EstimateWithdrawAmtRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.EstimateWithdrawAmtResponse.deserializeBinary
  );

  estimateWithdrawAmt(
    request: gateway_gateway_pb.EstimateWithdrawAmtRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.EstimateWithdrawAmtResponse>;

  estimateWithdrawAmt(
    request: gateway_gateway_pb.EstimateWithdrawAmtRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.EstimateWithdrawAmtResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.EstimateWithdrawAmtResponse>;

  estimateWithdrawAmt(
    request: gateway_gateway_pb.EstimateWithdrawAmtRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.EstimateWithdrawAmtResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/EstimateWithdrawAmt',
        request,
        metadata || {},
        this.methodDescriptorEstimateWithdrawAmt,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/EstimateWithdrawAmt',
    request,
    metadata || {},
    this.methodDescriptorEstimateWithdrawAmt);
  }

  methodDescriptorQueryLiquidityStatus = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/QueryLiquidityStatus',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.QueryLiquidityStatusRequest,
    gateway_gateway_pb.QueryLiquidityStatusResponse,
    (request: gateway_gateway_pb.QueryLiquidityStatusRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.QueryLiquidityStatusResponse.deserializeBinary
  );

  queryLiquidityStatus(
    request: gateway_gateway_pb.QueryLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.QueryLiquidityStatusResponse>;

  queryLiquidityStatus(
    request: gateway_gateway_pb.QueryLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.QueryLiquidityStatusResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.QueryLiquidityStatusResponse>;

  queryLiquidityStatus(
    request: gateway_gateway_pb.QueryLiquidityStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.QueryLiquidityStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/QueryLiquidityStatus',
        request,
        metadata || {},
        this.methodDescriptorQueryLiquidityStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/QueryLiquidityStatus',
    request,
    metadata || {},
    this.methodDescriptorQueryLiquidityStatus);
  }

  methodDescriptorUnlockFarmingReward = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/UnlockFarmingReward',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.UnlockFarmingRewardRequest,
    gateway_gateway_pb.UnlockFarmingRewardResponse,
    (request: gateway_gateway_pb.UnlockFarmingRewardRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.UnlockFarmingRewardResponse.deserializeBinary
  );

  unlockFarmingReward(
    request: gateway_gateway_pb.UnlockFarmingRewardRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.UnlockFarmingRewardResponse>;

  unlockFarmingReward(
    request: gateway_gateway_pb.UnlockFarmingRewardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UnlockFarmingRewardResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.UnlockFarmingRewardResponse>;

  unlockFarmingReward(
    request: gateway_gateway_pb.UnlockFarmingRewardRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UnlockFarmingRewardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/UnlockFarmingReward',
        request,
        metadata || {},
        this.methodDescriptorUnlockFarmingReward,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/UnlockFarmingReward',
    request,
    metadata || {},
    this.methodDescriptorUnlockFarmingReward);
  }

  methodDescriptorGetFarmingRewardDetails = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetFarmingRewardDetails',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetFarmingRewardDetailsRequest,
    gateway_gateway_pb.GetFarmingRewardDetailsResponse,
    (request: gateway_gateway_pb.GetFarmingRewardDetailsRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetFarmingRewardDetailsResponse.deserializeBinary
  );

  getFarmingRewardDetails(
    request: gateway_gateway_pb.GetFarmingRewardDetailsRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetFarmingRewardDetailsResponse>;

  getFarmingRewardDetails(
    request: gateway_gateway_pb.GetFarmingRewardDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetFarmingRewardDetailsResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetFarmingRewardDetailsResponse>;

  getFarmingRewardDetails(
    request: gateway_gateway_pb.GetFarmingRewardDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetFarmingRewardDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetFarmingRewardDetails',
        request,
        metadata || {},
        this.methodDescriptorGetFarmingRewardDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetFarmingRewardDetails',
    request,
    metadata || {},
    this.methodDescriptorGetFarmingRewardDetails);
  }

  methodDescriptorTransferHistory = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/TransferHistory',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.TransferHistoryRequest,
    gateway_gateway_pb.TransferHistoryResponse,
    (request: gateway_gateway_pb.TransferHistoryRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.TransferHistoryResponse.deserializeBinary
  );

  transferHistory(
    request: gateway_gateway_pb.TransferHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.TransferHistoryResponse>;

  transferHistory(
    request: gateway_gateway_pb.TransferHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.TransferHistoryResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.TransferHistoryResponse>;

  transferHistory(
    request: gateway_gateway_pb.TransferHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.TransferHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/TransferHistory',
        request,
        metadata || {},
        this.methodDescriptorTransferHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/TransferHistory',
    request,
    metadata || {},
    this.methodDescriptorTransferHistory);
  }

  methodDescriptorGetTransfer = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTransfer',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTransferRequest,
    gateway_gateway_pb.GetTransferResponse,
    (request: gateway_gateway_pb.GetTransferRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTransferResponse.deserializeBinary
  );

  getTransfer(
    request: gateway_gateway_pb.GetTransferRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTransferResponse>;

  getTransfer(
    request: gateway_gateway_pb.GetTransferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTransferResponse>;

  getTransfer(
    request: gateway_gateway_pb.GetTransferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTransfer',
        request,
        metadata || {},
        this.methodDescriptorGetTransfer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTransfer',
    request,
    metadata || {},
    this.methodDescriptorGetTransfer);
  }

  methodDescriptorLPHistory = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/LPHistory',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.LPHistoryRequest,
    gateway_gateway_pb.LPHistoryResponse,
    (request: gateway_gateway_pb.LPHistoryRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.LPHistoryResponse.deserializeBinary
  );

  lPHistory(
    request: gateway_gateway_pb.LPHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.LPHistoryResponse>;

  lPHistory(
    request: gateway_gateway_pb.LPHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.LPHistoryResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.LPHistoryResponse>;

  lPHistory(
    request: gateway_gateway_pb.LPHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.LPHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/LPHistory',
        request,
        metadata || {},
        this.methodDescriptorLPHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/LPHistory',
    request,
    metadata || {},
    this.methodDescriptorLPHistory);
  }

  methodDescriptorClaimHistory = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ClaimHistory',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.ClaimHistoryRequest,
    gateway_gateway_pb.ClaimHistoryResponse,
    (request: gateway_gateway_pb.ClaimHistoryRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.ClaimHistoryResponse.deserializeBinary
  );

  claimHistory(
    request: gateway_gateway_pb.ClaimHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.ClaimHistoryResponse>;

  claimHistory(
    request: gateway_gateway_pb.ClaimHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimHistoryResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.ClaimHistoryResponse>;

  claimHistory(
    request: gateway_gateway_pb.ClaimHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ClaimHistory',
        request,
        metadata || {},
        this.methodDescriptorClaimHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ClaimHistory',
    request,
    metadata || {},
    this.methodDescriptorClaimHistory);
  }

  methodDescriptorPegClaimHistory = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/PegClaimHistory',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.PegClaimHistoryRequest,
    gateway_gateway_pb.PegClaimHistoryResponse,
    (request: gateway_gateway_pb.PegClaimHistoryRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.PegClaimHistoryResponse.deserializeBinary
  );

  pegClaimHistory(
    request: gateway_gateway_pb.PegClaimHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.PegClaimHistoryResponse>;

  pegClaimHistory(
    request: gateway_gateway_pb.PegClaimHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.PegClaimHistoryResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.PegClaimHistoryResponse>;

  pegClaimHistory(
    request: gateway_gateway_pb.PegClaimHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.PegClaimHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/PegClaimHistory',
        request,
        metadata || {},
        this.methodDescriptorPegClaimHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/PegClaimHistory',
    request,
    metadata || {},
    this.methodDescriptorPegClaimHistory);
  }

  methodDescriptorRewardingData = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/RewardingData',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.RewardingDataRequest,
    gateway_gateway_pb.RewardingDataResponse,
    (request: gateway_gateway_pb.RewardingDataRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.RewardingDataResponse.deserializeBinary
  );

  rewardingData(
    request: gateway_gateway_pb.RewardingDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.RewardingDataResponse>;

  rewardingData(
    request: gateway_gateway_pb.RewardingDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.RewardingDataResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.RewardingDataResponse>;

  rewardingData(
    request: gateway_gateway_pb.RewardingDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.RewardingDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/RewardingData',
        request,
        metadata || {},
        this.methodDescriptorRewardingData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/RewardingData',
    request,
    metadata || {},
    this.methodDescriptorRewardingData);
  }

  methodDescriptorStakingConfig = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/StakingConfig',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.StakingConfigRequest,
    gateway_gateway_pb.StakingConfigResponse,
    (request: gateway_gateway_pb.StakingConfigRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.StakingConfigResponse.deserializeBinary
  );

  stakingConfig(
    request: gateway_gateway_pb.StakingConfigRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.StakingConfigResponse>;

  stakingConfig(
    request: gateway_gateway_pb.StakingConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.StakingConfigResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.StakingConfigResponse>;

  stakingConfig(
    request: gateway_gateway_pb.StakingConfigRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.StakingConfigResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/StakingConfig',
        request,
        metadata || {},
        this.methodDescriptorStakingConfig,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/StakingConfig',
    request,
    metadata || {},
    this.methodDescriptorStakingConfig);
  }

  methodDescriptorUnlockStakingReward = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/UnlockStakingReward',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.UnlockStakingRewardRequest,
    gateway_gateway_pb.UnlockStakingRewardResponse,
    (request: gateway_gateway_pb.UnlockStakingRewardRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.UnlockStakingRewardResponse.deserializeBinary
  );

  unlockStakingReward(
    request: gateway_gateway_pb.UnlockStakingRewardRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.UnlockStakingRewardResponse>;

  unlockStakingReward(
    request: gateway_gateway_pb.UnlockStakingRewardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UnlockStakingRewardResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.UnlockStakingRewardResponse>;

  unlockStakingReward(
    request: gateway_gateway_pb.UnlockStakingRewardRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UnlockStakingRewardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/UnlockStakingReward',
        request,
        metadata || {},
        this.methodDescriptorUnlockStakingReward,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/UnlockStakingReward',
    request,
    metadata || {},
    this.methodDescriptorUnlockStakingReward);
  }

  methodDescriptorGetStakingRewardDetails = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetStakingRewardDetails',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetStakingRewardDetailsRequest,
    gateway_gateway_pb.GetStakingRewardDetailsResponse,
    (request: gateway_gateway_pb.GetStakingRewardDetailsRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetStakingRewardDetailsResponse.deserializeBinary
  );

  getStakingRewardDetails(
    request: gateway_gateway_pb.GetStakingRewardDetailsRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetStakingRewardDetailsResponse>;

  getStakingRewardDetails(
    request: gateway_gateway_pb.GetStakingRewardDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetStakingRewardDetailsResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetStakingRewardDetailsResponse>;

  getStakingRewardDetails(
    request: gateway_gateway_pb.GetStakingRewardDetailsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetStakingRewardDetailsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetStakingRewardDetails',
        request,
        metadata || {},
        this.methodDescriptorGetStakingRewardDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetStakingRewardDetails',
    request,
    metadata || {},
    this.methodDescriptorGetStakingRewardDetails);
  }

  methodDescriptorGetTotalLiquidityProviderTokenBalance = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTotalLiquidityProviderTokenBalance',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceRequest,
    gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceResponse,
    (request: gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceResponse.deserializeBinary
  );

  getTotalLiquidityProviderTokenBalance(
    request: gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceResponse>;

  getTotalLiquidityProviderTokenBalance(
    request: gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceResponse>;

  getTotalLiquidityProviderTokenBalance(
    request: gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTotalLiquidityProviderTokenBalanceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTotalLiquidityProviderTokenBalance',
        request,
        metadata || {},
        this.methodDescriptorGetTotalLiquidityProviderTokenBalance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTotalLiquidityProviderTokenBalance',
    request,
    metadata || {},
    this.methodDescriptorGetTotalLiquidityProviderTokenBalance);
  }

  methodDescriptorQueryLiquidityProviderTokenBalance = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/QueryLiquidityProviderTokenBalance',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.QueryLiquidityProviderTokenBalanceRequest,
    gateway_gateway_pb.QueryLiquidityProviderTokenBalanceResponse,
    (request: gateway_gateway_pb.QueryLiquidityProviderTokenBalanceRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.QueryLiquidityProviderTokenBalanceResponse.deserializeBinary
  );

  queryLiquidityProviderTokenBalance(
    request: gateway_gateway_pb.QueryLiquidityProviderTokenBalanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.QueryLiquidityProviderTokenBalanceResponse>;

  queryLiquidityProviderTokenBalance(
    request: gateway_gateway_pb.QueryLiquidityProviderTokenBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.QueryLiquidityProviderTokenBalanceResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.QueryLiquidityProviderTokenBalanceResponse>;

  queryLiquidityProviderTokenBalance(
    request: gateway_gateway_pb.QueryLiquidityProviderTokenBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.QueryLiquidityProviderTokenBalanceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/QueryLiquidityProviderTokenBalance',
        request,
        metadata || {},
        this.methodDescriptorQueryLiquidityProviderTokenBalance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/QueryLiquidityProviderTokenBalance',
    request,
    metadata || {},
    this.methodDescriptorQueryLiquidityProviderTokenBalance);
  }

  methodDescriptorUpdateChain = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/UpdateChain',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.UpdateChainRequest,
    gateway_gateway_pb.UpdateChainResponse,
    (request: gateway_gateway_pb.UpdateChainRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.UpdateChainResponse.deserializeBinary
  );

  updateChain(
    request: gateway_gateway_pb.UpdateChainRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.UpdateChainResponse>;

  updateChain(
    request: gateway_gateway_pb.UpdateChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UpdateChainResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.UpdateChainResponse>;

  updateChain(
    request: gateway_gateway_pb.UpdateChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UpdateChainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/UpdateChain',
        request,
        metadata || {},
        this.methodDescriptorUpdateChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/UpdateChain',
    request,
    metadata || {},
    this.methodDescriptorUpdateChain);
  }

  methodDescriptorUpdateToken = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/UpdateToken',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.UpdateTokenRequest,
    gateway_gateway_pb.UpdateTokenResponse,
    (request: gateway_gateway_pb.UpdateTokenRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.UpdateTokenResponse.deserializeBinary
  );

  updateToken(
    request: gateway_gateway_pb.UpdateTokenRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.UpdateTokenResponse>;

  updateToken(
    request: gateway_gateway_pb.UpdateTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UpdateTokenResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.UpdateTokenResponse>;

  updateToken(
    request: gateway_gateway_pb.UpdateTokenRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.UpdateTokenResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/UpdateToken',
        request,
        metadata || {},
        this.methodDescriptorUpdateToken,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/UpdateToken',
    request,
    metadata || {},
    this.methodDescriptorUpdateToken);
  }

  methodDescriptorGetCampaignScores = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetCampaignScores',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetCampaignScoresRequest,
    gateway_gateway_pb.GetCampaignScoresResponse,
    (request: gateway_gateway_pb.GetCampaignScoresRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetCampaignScoresResponse.deserializeBinary
  );

  getCampaignScores(
    request: gateway_gateway_pb.GetCampaignScoresRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetCampaignScoresResponse>;

  getCampaignScores(
    request: gateway_gateway_pb.GetCampaignScoresRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetCampaignScoresResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetCampaignScoresResponse>;

  getCampaignScores(
    request: gateway_gateway_pb.GetCampaignScoresRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetCampaignScoresResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetCampaignScores',
        request,
        metadata || {},
        this.methodDescriptorGetCampaignScores,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetCampaignScores',
    request,
    metadata || {},
    this.methodDescriptorGetCampaignScores);
  }

  methodDescriptorGetInfoByTxHash = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetInfoByTxHash',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetInfoByTxHashRequest,
    gateway_gateway_pb.GetInfoByTxHashResponse,
    (request: gateway_gateway_pb.GetInfoByTxHashRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetInfoByTxHashResponse.deserializeBinary
  );

  getInfoByTxHash(
    request: gateway_gateway_pb.GetInfoByTxHashRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetInfoByTxHashResponse>;

  getInfoByTxHash(
    request: gateway_gateway_pb.GetInfoByTxHashRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetInfoByTxHashResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetInfoByTxHashResponse>;

  getInfoByTxHash(
    request: gateway_gateway_pb.GetInfoByTxHashRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetInfoByTxHashResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetInfoByTxHash',
        request,
        metadata || {},
        this.methodDescriptorGetInfoByTxHash,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetInfoByTxHash',
    request,
    metadata || {},
    this.methodDescriptorGetInfoByTxHash);
  }

  methodDescriptorGetAbnormalStatusInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetAbnormalStatusInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetAbnormalStatusInfoRequest,
    gateway_gateway_pb.GetAbnormalStatusInfoResponse,
    (request: gateway_gateway_pb.GetAbnormalStatusInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetAbnormalStatusInfoResponse.deserializeBinary
  );

  getAbnormalStatusInfo(
    request: gateway_gateway_pb.GetAbnormalStatusInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetAbnormalStatusInfoResponse>;

  getAbnormalStatusInfo(
    request: gateway_gateway_pb.GetAbnormalStatusInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAbnormalStatusInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetAbnormalStatusInfoResponse>;

  getAbnormalStatusInfo(
    request: gateway_gateway_pb.GetAbnormalStatusInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAbnormalStatusInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetAbnormalStatusInfo',
        request,
        metadata || {},
        this.methodDescriptorGetAbnormalStatusInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetAbnormalStatusInfo',
    request,
    metadata || {},
    this.methodDescriptorGetAbnormalStatusInfo);
  }

  methodDescriptorGetAllLPInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetAllLPInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetAllLPInfoRequest,
    gateway_gateway_pb.GetAllLPInfoResponse,
    (request: gateway_gateway_pb.GetAllLPInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetAllLPInfoResponse.deserializeBinary
  );

  getAllLPInfo(
    request: gateway_gateway_pb.GetAllLPInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetAllLPInfoResponse>;

  getAllLPInfo(
    request: gateway_gateway_pb.GetAllLPInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAllLPInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetAllLPInfoResponse>;

  getAllLPInfo(
    request: gateway_gateway_pb.GetAllLPInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAllLPInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetAllLPInfo',
        request,
        metadata || {},
        this.methodDescriptorGetAllLPInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetAllLPInfo',
    request,
    metadata || {},
    this.methodDescriptorGetAllLPInfo);
  }

  methodDescriptorGetAllTXInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetAllTXInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetAllTXInfoRequest,
    gateway_gateway_pb.GetAllTXInfoResponse,
    (request: gateway_gateway_pb.GetAllTXInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetAllTXInfoResponse.deserializeBinary
  );

  getAllTXInfo(
    request: gateway_gateway_pb.GetAllTXInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetAllTXInfoResponse>;

  getAllTXInfo(
    request: gateway_gateway_pb.GetAllTXInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAllTXInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetAllTXInfoResponse>;

  getAllTXInfo(
    request: gateway_gateway_pb.GetAllTXInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAllTXInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetAllTXInfo',
        request,
        metadata || {},
        this.methodDescriptorGetAllTXInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetAllTXInfo',
    request,
    metadata || {},
    this.methodDescriptorGetAllTXInfo);
  }

  methodDescriptorFixEventMiss = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/FixEventMiss',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.FixEventMissRequest,
    gateway_gateway_pb.FixEventMissResponse,
    (request: gateway_gateway_pb.FixEventMissRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.FixEventMissResponse.deserializeBinary
  );

  fixEventMiss(
    request: gateway_gateway_pb.FixEventMissRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.FixEventMissResponse>;

  fixEventMiss(
    request: gateway_gateway_pb.FixEventMissRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.FixEventMissResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.FixEventMissResponse>;

  fixEventMiss(
    request: gateway_gateway_pb.FixEventMissRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.FixEventMissResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/FixEventMiss',
        request,
        metadata || {},
        this.methodDescriptorFixEventMiss,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/FixEventMiss',
    request,
    metadata || {},
    this.methodDescriptorFixEventMiss);
  }

  methodDescriptorGetUsrBalance = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetUsrBalance',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetUsrBalanceRequest,
    gateway_gateway_pb.GetUsrBalanceResponse,
    (request: gateway_gateway_pb.GetUsrBalanceRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetUsrBalanceResponse.deserializeBinary
  );

  getUsrBalance(
    request: gateway_gateway_pb.GetUsrBalanceRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetUsrBalanceResponse>;

  getUsrBalance(
    request: gateway_gateway_pb.GetUsrBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetUsrBalanceResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetUsrBalanceResponse>;

  getUsrBalance(
    request: gateway_gateway_pb.GetUsrBalanceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetUsrBalanceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetUsrBalance',
        request,
        metadata || {},
        this.methodDescriptorGetUsrBalance,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetUsrBalance',
    request,
    metadata || {},
    this.methodDescriptorGetUsrBalance);
  }

  methodDescriptorGetAllConfigs = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetAllConfigs',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetAllConfigsRequest,
    gateway_gateway_pb.GetAllConfigsResponse,
    (request: gateway_gateway_pb.GetAllConfigsRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetAllConfigsResponse.deserializeBinary
  );

  getAllConfigs(
    request: gateway_gateway_pb.GetAllConfigsRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetAllConfigsResponse>;

  getAllConfigs(
    request: gateway_gateway_pb.GetAllConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAllConfigsResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetAllConfigsResponse>;

  getAllConfigs(
    request: gateway_gateway_pb.GetAllConfigsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetAllConfigsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetAllConfigs',
        request,
        metadata || {},
        this.methodDescriptorGetAllConfigs,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetAllConfigs',
    request,
    metadata || {},
    this.methodDescriptorGetAllConfigs);
  }

  methodDescriptorGetCbrConfigsOnChain = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetCbrConfigsOnChain',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetCbrConfigsOnChainRequest,
    gateway_gateway_pb.GetCbrConfigsOnChainResponse,
    (request: gateway_gateway_pb.GetCbrConfigsOnChainRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetCbrConfigsOnChainResponse.deserializeBinary
  );

  getCbrConfigsOnChain(
    request: gateway_gateway_pb.GetCbrConfigsOnChainRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetCbrConfigsOnChainResponse>;

  getCbrConfigsOnChain(
    request: gateway_gateway_pb.GetCbrConfigsOnChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetCbrConfigsOnChainResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetCbrConfigsOnChainResponse>;

  getCbrConfigsOnChain(
    request: gateway_gateway_pb.GetCbrConfigsOnChainRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetCbrConfigsOnChainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetCbrConfigsOnChain',
        request,
        metadata || {},
        this.methodDescriptorGetCbrConfigsOnChain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetCbrConfigsOnChain',
    request,
    metadata || {},
    this.methodDescriptorGetCbrConfigsOnChain);
  }

  methodDescriptorReportCurrentBlockNumber = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ReportCurrentBlockNumber',
    grpcWeb.MethodType.UNARY,
    sgn_health_v1_health_pb.ReportSgnAnalyticsRequest,
    sgn_health_v1_health_pb.ReportSgnAnalyticsResponse,
    (request: sgn_health_v1_health_pb.ReportSgnAnalyticsRequest) => {
      return request.serializeBinary();
    },
    sgn_health_v1_health_pb.ReportSgnAnalyticsResponse.deserializeBinary
  );

  reportCurrentBlockNumber(
    request: sgn_health_v1_health_pb.ReportSgnAnalyticsRequest,
    metadata: grpcWeb.Metadata | null): Promise<sgn_health_v1_health_pb.ReportSgnAnalyticsResponse>;

  reportCurrentBlockNumber(
    request: sgn_health_v1_health_pb.ReportSgnAnalyticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_health_v1_health_pb.ReportSgnAnalyticsResponse) => void): grpcWeb.ClientReadableStream<sgn_health_v1_health_pb.ReportSgnAnalyticsResponse>;

  reportCurrentBlockNumber(
    request: sgn_health_v1_health_pb.ReportSgnAnalyticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_health_v1_health_pb.ReportSgnAnalyticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ReportCurrentBlockNumber',
        request,
        metadata || {},
        this.methodDescriptorReportCurrentBlockNumber,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ReportCurrentBlockNumber',
    request,
    metadata || {},
    this.methodDescriptorReportCurrentBlockNumber);
  }

  methodDescriptorReportSgnConsensusLog = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ReportSgnConsensusLog',
    grpcWeb.MethodType.UNARY,
    sgn_health_v1_health_pb.SgnConsensusLogReport,
    sgn_health_v1_health_pb.ReportSgnAnalyticsResponse,
    (request: sgn_health_v1_health_pb.SgnConsensusLogReport) => {
      return request.serializeBinary();
    },
    sgn_health_v1_health_pb.ReportSgnAnalyticsResponse.deserializeBinary
  );

  reportSgnConsensusLog(
    request: sgn_health_v1_health_pb.SgnConsensusLogReport,
    metadata: grpcWeb.Metadata | null): Promise<sgn_health_v1_health_pb.ReportSgnAnalyticsResponse>;

  reportSgnConsensusLog(
    request: sgn_health_v1_health_pb.SgnConsensusLogReport,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: sgn_health_v1_health_pb.ReportSgnAnalyticsResponse) => void): grpcWeb.ClientReadableStream<sgn_health_v1_health_pb.ReportSgnAnalyticsResponse>;

  reportSgnConsensusLog(
    request: sgn_health_v1_health_pb.SgnConsensusLogReport,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: sgn_health_v1_health_pb.ReportSgnAnalyticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ReportSgnConsensusLog',
        request,
        metadata || {},
        this.methodDescriptorReportSgnConsensusLog,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ReportSgnConsensusLog',
    request,
    metadata || {},
    this.methodDescriptorReportSgnConsensusLog);
  }

  methodDescriptorGetCurrentBlockNumberByNode = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetCurrentBlockNumberByNode',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetCurrentBlockNumberByNodeRequest,
    gateway_gateway_pb.GetCurrentBlockNumberByNodeResponse,
    (request: gateway_gateway_pb.GetCurrentBlockNumberByNodeRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetCurrentBlockNumberByNodeResponse.deserializeBinary
  );

  getCurrentBlockNumberByNode(
    request: gateway_gateway_pb.GetCurrentBlockNumberByNodeRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetCurrentBlockNumberByNodeResponse>;

  getCurrentBlockNumberByNode(
    request: gateway_gateway_pb.GetCurrentBlockNumberByNodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetCurrentBlockNumberByNodeResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetCurrentBlockNumberByNodeResponse>;

  getCurrentBlockNumberByNode(
    request: gateway_gateway_pb.GetCurrentBlockNumberByNodeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetCurrentBlockNumberByNodeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetCurrentBlockNumberByNode',
        request,
        metadata || {},
        this.methodDescriptorGetCurrentBlockNumberByNode,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetCurrentBlockNumberByNode',
    request,
    metadata || {},
    this.methodDescriptorGetCurrentBlockNumberByNode);
  }

  methodDescriptorGetRetentionRewardsInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetRetentionRewardsInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetRetentionRewardsInfoRequest,
    gateway_gateway_pb.GetRetentionRewardsInfoResponse,
    (request: gateway_gateway_pb.GetRetentionRewardsInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetRetentionRewardsInfoResponse.deserializeBinary
  );

  getRetentionRewardsInfo(
    request: gateway_gateway_pb.GetRetentionRewardsInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetRetentionRewardsInfoResponse>;

  getRetentionRewardsInfo(
    request: gateway_gateway_pb.GetRetentionRewardsInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetRetentionRewardsInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetRetentionRewardsInfoResponse>;

  getRetentionRewardsInfo(
    request: gateway_gateway_pb.GetRetentionRewardsInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetRetentionRewardsInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetRetentionRewardsInfo',
        request,
        metadata || {},
        this.methodDescriptorGetRetentionRewardsInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetRetentionRewardsInfo',
    request,
    metadata || {},
    this.methodDescriptorGetRetentionRewardsInfo);
  }

  methodDescriptorGetIncentiveCampaignCelrRank = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetIncentiveCampaignCelrRank',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetIncentiveCampaignCelrRankRequest,
    gateway_gateway_pb.GetIncentiveCampaignCelrRankResponse,
    (request: gateway_gateway_pb.GetIncentiveCampaignCelrRankRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetIncentiveCampaignCelrRankResponse.deserializeBinary
  );

  getIncentiveCampaignCelrRank(
    request: gateway_gateway_pb.GetIncentiveCampaignCelrRankRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetIncentiveCampaignCelrRankResponse>;

  getIncentiveCampaignCelrRank(
    request: gateway_gateway_pb.GetIncentiveCampaignCelrRankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetIncentiveCampaignCelrRankResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetIncentiveCampaignCelrRankResponse>;

  getIncentiveCampaignCelrRank(
    request: gateway_gateway_pb.GetIncentiveCampaignCelrRankRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetIncentiveCampaignCelrRankResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetIncentiveCampaignCelrRank',
        request,
        metadata || {},
        this.methodDescriptorGetIncentiveCampaignCelrRank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetIncentiveCampaignCelrRank',
    request,
    metadata || {},
    this.methodDescriptorGetIncentiveCampaignCelrRank);
  }

  methodDescriptorInIncentiveCampaignBnbWhiteList = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/InIncentiveCampaignBnbWhiteList',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.InIncentiveCampaignBnbWhiteListRequest,
    gateway_gateway_pb.InIncentiveCampaignBnbWhiteListResponse,
    (request: gateway_gateway_pb.InIncentiveCampaignBnbWhiteListRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.InIncentiveCampaignBnbWhiteListResponse.deserializeBinary
  );

  inIncentiveCampaignBnbWhiteList(
    request: gateway_gateway_pb.InIncentiveCampaignBnbWhiteListRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.InIncentiveCampaignBnbWhiteListResponse>;

  inIncentiveCampaignBnbWhiteList(
    request: gateway_gateway_pb.InIncentiveCampaignBnbWhiteListRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.InIncentiveCampaignBnbWhiteListResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.InIncentiveCampaignBnbWhiteListResponse>;

  inIncentiveCampaignBnbWhiteList(
    request: gateway_gateway_pb.InIncentiveCampaignBnbWhiteListRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.InIncentiveCampaignBnbWhiteListResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/InIncentiveCampaignBnbWhiteList',
        request,
        metadata || {},
        this.methodDescriptorInIncentiveCampaignBnbWhiteList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/InIncentiveCampaignBnbWhiteList',
    request,
    metadata || {},
    this.methodDescriptorInIncentiveCampaignBnbWhiteList);
  }

  methodDescriptorGetIncentiveCampaignBnbRank = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetIncentiveCampaignBnbRank',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetIncentiveCampaignBnbRankRequest,
    gateway_gateway_pb.GetIncentiveCampaignBnbRankResponse,
    (request: gateway_gateway_pb.GetIncentiveCampaignBnbRankRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetIncentiveCampaignBnbRankResponse.deserializeBinary
  );

  getIncentiveCampaignBnbRank(
    request: gateway_gateway_pb.GetIncentiveCampaignBnbRankRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetIncentiveCampaignBnbRankResponse>;

  getIncentiveCampaignBnbRank(
    request: gateway_gateway_pb.GetIncentiveCampaignBnbRankRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetIncentiveCampaignBnbRankResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetIncentiveCampaignBnbRankResponse>;

  getIncentiveCampaignBnbRank(
    request: gateway_gateway_pb.GetIncentiveCampaignBnbRankRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetIncentiveCampaignBnbRankResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetIncentiveCampaignBnbRank',
        request,
        metadata || {},
        this.methodDescriptorGetIncentiveCampaignBnbRank,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetIncentiveCampaignBnbRank',
    request,
    metadata || {},
    this.methodDescriptorGetIncentiveCampaignBnbRank);
  }

  methodDescriptorClaimRetentionRewards = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ClaimRetentionRewards',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.ClaimRetentionRewardsRequest,
    gateway_gateway_pb.ClaimRetentionRewardsResponse,
    (request: gateway_gateway_pb.ClaimRetentionRewardsRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.ClaimRetentionRewardsResponse.deserializeBinary
  );

  claimRetentionRewards(
    request: gateway_gateway_pb.ClaimRetentionRewardsRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.ClaimRetentionRewardsResponse>;

  claimRetentionRewards(
    request: gateway_gateway_pb.ClaimRetentionRewardsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimRetentionRewardsResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.ClaimRetentionRewardsResponse>;

  claimRetentionRewards(
    request: gateway_gateway_pb.ClaimRetentionRewardsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimRetentionRewardsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ClaimRetentionRewards',
        request,
        metadata || {},
        this.methodDescriptorClaimRetentionRewards,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ClaimRetentionRewards',
    request,
    metadata || {},
    this.methodDescriptorClaimRetentionRewards);
  }

  methodDescriptorGetBscCampaignInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetBscCampaignInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetBscCampaignInfoRequest,
    gateway_gateway_pb.GetBscCampaignInfoResponse,
    (request: gateway_gateway_pb.GetBscCampaignInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetBscCampaignInfoResponse.deserializeBinary
  );

  getBscCampaignInfo(
    request: gateway_gateway_pb.GetBscCampaignInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetBscCampaignInfoResponse>;

  getBscCampaignInfo(
    request: gateway_gateway_pb.GetBscCampaignInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetBscCampaignInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetBscCampaignInfoResponse>;

  getBscCampaignInfo(
    request: gateway_gateway_pb.GetBscCampaignInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetBscCampaignInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetBscCampaignInfo',
        request,
        metadata || {},
        this.methodDescriptorGetBscCampaignInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetBscCampaignInfo',
    request,
    metadata || {},
    this.methodDescriptorGetBscCampaignInfo);
  }

  methodDescriptorClaimGetBscCampaignReward = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ClaimGetBscCampaignReward',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.ClaimGetBscCampaignRewardRequest,
    gateway_gateway_pb.ClaimGetBscCampaignRewardResponse,
    (request: gateway_gateway_pb.ClaimGetBscCampaignRewardRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.ClaimGetBscCampaignRewardResponse.deserializeBinary
  );

  claimGetBscCampaignReward(
    request: gateway_gateway_pb.ClaimGetBscCampaignRewardRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.ClaimGetBscCampaignRewardResponse>;

  claimGetBscCampaignReward(
    request: gateway_gateway_pb.ClaimGetBscCampaignRewardRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimGetBscCampaignRewardResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.ClaimGetBscCampaignRewardResponse>;

  claimGetBscCampaignReward(
    request: gateway_gateway_pb.ClaimGetBscCampaignRewardRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimGetBscCampaignRewardResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ClaimGetBscCampaignReward',
        request,
        metadata || {},
        this.methodDescriptorClaimGetBscCampaignReward,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ClaimGetBscCampaignReward',
    request,
    metadata || {},
    this.methodDescriptorClaimGetBscCampaignReward);
  }

  methodDescriptorGetFeeRebateInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetFeeRebateInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetFeeRebateInfoRequest,
    gateway_gateway_pb.GetFeeRebateInfoResponse,
    (request: gateway_gateway_pb.GetFeeRebateInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetFeeRebateInfoResponse.deserializeBinary
  );

  getFeeRebateInfo(
    request: gateway_gateway_pb.GetFeeRebateInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetFeeRebateInfoResponse>;

  getFeeRebateInfo(
    request: gateway_gateway_pb.GetFeeRebateInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetFeeRebateInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetFeeRebateInfoResponse>;

  getFeeRebateInfo(
    request: gateway_gateway_pb.GetFeeRebateInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetFeeRebateInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetFeeRebateInfo',
        request,
        metadata || {},
        this.methodDescriptorGetFeeRebateInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetFeeRebateInfo',
    request,
    metadata || {},
    this.methodDescriptorGetFeeRebateInfo);
  }

  methodDescriptorClaimFeeRebate = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ClaimFeeRebate',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.ClaimFeeRebateRequest,
    gateway_gateway_pb.ClaimFeeRebateResponse,
    (request: gateway_gateway_pb.ClaimFeeRebateRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.ClaimFeeRebateResponse.deserializeBinary
  );

  claimFeeRebate(
    request: gateway_gateway_pb.ClaimFeeRebateRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.ClaimFeeRebateResponse>;

  claimFeeRebate(
    request: gateway_gateway_pb.ClaimFeeRebateRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimFeeRebateResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.ClaimFeeRebateResponse>;

  claimFeeRebate(
    request: gateway_gateway_pb.ClaimFeeRebateRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimFeeRebateResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ClaimFeeRebate',
        request,
        metadata || {},
        this.methodDescriptorClaimFeeRebate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ClaimFeeRebate',
    request,
    metadata || {},
    this.methodDescriptorClaimFeeRebate);
  }

  methodDescriptorClaimPegBridgeFee = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ClaimPegBridgeFee',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.ClaimPegBridgeFeeRequest,
    gateway_gateway_pb.ClaimPegBridgeFeeResponse,
    (request: gateway_gateway_pb.ClaimPegBridgeFeeRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.ClaimPegBridgeFeeResponse.deserializeBinary
  );

  claimPegBridgeFee(
    request: gateway_gateway_pb.ClaimPegBridgeFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.ClaimPegBridgeFeeResponse>;

  claimPegBridgeFee(
    request: gateway_gateway_pb.ClaimPegBridgeFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimPegBridgeFeeResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.ClaimPegBridgeFeeResponse>;

  claimPegBridgeFee(
    request: gateway_gateway_pb.ClaimPegBridgeFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimPegBridgeFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ClaimPegBridgeFee',
        request,
        metadata || {},
        this.methodDescriptorClaimPegBridgeFee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ClaimPegBridgeFee',
    request,
    metadata || {},
    this.methodDescriptorClaimPegBridgeFee);
  }

  methodDescriptorInitWithdraw = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/InitWithdraw',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.InitWithdrawRequest,
    gateway_gateway_pb.InitWithdrawResponse,
    (request: gateway_gateway_pb.InitWithdrawRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.InitWithdrawResponse.deserializeBinary
  );

  initWithdraw(
    request: gateway_gateway_pb.InitWithdrawRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.InitWithdrawResponse>;

  initWithdraw(
    request: gateway_gateway_pb.InitWithdrawRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.InitWithdrawResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.InitWithdrawResponse>;

  initWithdraw(
    request: gateway_gateway_pb.InitWithdrawRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.InitWithdrawResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/InitWithdraw',
        request,
        metadata || {},
        this.methodDescriptorInitWithdraw,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/InitWithdraw',
    request,
    metadata || {},
    this.methodDescriptorInitWithdraw);
  }

  methodDescriptorInitPegRefund = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/InitPegRefund',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.InitPegRefundRequest,
    gateway_gateway_pb.InitPegRefundResponse,
    (request: gateway_gateway_pb.InitPegRefundRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.InitPegRefundResponse.deserializeBinary
  );

  initPegRefund(
    request: gateway_gateway_pb.InitPegRefundRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.InitPegRefundResponse>;

  initPegRefund(
    request: gateway_gateway_pb.InitPegRefundRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.InitPegRefundResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.InitPegRefundResponse>;

  initPegRefund(
    request: gateway_gateway_pb.InitPegRefundRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.InitPegRefundResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/InitPegRefund',
        request,
        metadata || {},
        this.methodDescriptorInitPegRefund,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/InitPegRefund',
    request,
    metadata || {},
    this.methodDescriptorInitPegRefund);
  }

  methodDescriptorSignAgain = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/SignAgain',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.SignAgainRequest,
    gateway_gateway_pb.SignAgainResponse,
    (request: gateway_gateway_pb.SignAgainRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.SignAgainResponse.deserializeBinary
  );

  signAgain(
    request: gateway_gateway_pb.SignAgainRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.SignAgainResponse>;

  signAgain(
    request: gateway_gateway_pb.SignAgainRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.SignAgainResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.SignAgainResponse>;

  signAgain(
    request: gateway_gateway_pb.SignAgainRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.SignAgainResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/SignAgain',
        request,
        metadata || {},
        this.methodDescriptorSignAgain,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/SignAgain',
    request,
    metadata || {},
    this.methodDescriptorSignAgain);
  }

  methodDescriptorGetLPOrigin = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetLPOrigin',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetLPOriginRequest,
    gateway_gateway_pb.GetLPOriginResponse,
    (request: gateway_gateway_pb.GetLPOriginRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetLPOriginResponse.deserializeBinary
  );

  getLPOrigin(
    request: gateway_gateway_pb.GetLPOriginRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetLPOriginResponse>;

  getLPOrigin(
    request: gateway_gateway_pb.GetLPOriginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetLPOriginResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetLPOriginResponse>;

  getLPOrigin(
    request: gateway_gateway_pb.GetLPOriginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetLPOriginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetLPOrigin',
        request,
        metadata || {},
        this.methodDescriptorGetLPOrigin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetLPOrigin',
    request,
    metadata || {},
    this.methodDescriptorGetLPOrigin);
  }

  methodDescriptorGetTokenBound = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTokenBound',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTokenBoundRequest,
    gateway_gateway_pb.GetTokenBoundResponse,
    (request: gateway_gateway_pb.GetTokenBoundRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTokenBoundResponse.deserializeBinary
  );

  getTokenBound(
    request: gateway_gateway_pb.GetTokenBoundRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTokenBoundResponse>;

  getTokenBound(
    request: gateway_gateway_pb.GetTokenBoundRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTokenBoundResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTokenBoundResponse>;

  getTokenBound(
    request: gateway_gateway_pb.GetTokenBoundRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTokenBoundResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTokenBound',
        request,
        metadata || {},
        this.methodDescriptorGetTokenBound,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTokenBound',
    request,
    metadata || {},
    this.methodDescriptorGetTokenBound);
  }

  methodDescriptorGetTokenUsdPrice = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTokenUsdPrice',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTokenUsdPriceRequest,
    gateway_gateway_pb.GetTokenUsdPriceResponse,
    (request: gateway_gateway_pb.GetTokenUsdPriceRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTokenUsdPriceResponse.deserializeBinary
  );

  getTokenUsdPrice(
    request: gateway_gateway_pb.GetTokenUsdPriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTokenUsdPriceResponse>;

  getTokenUsdPrice(
    request: gateway_gateway_pb.GetTokenUsdPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTokenUsdPriceResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTokenUsdPriceResponse>;

  getTokenUsdPrice(
    request: gateway_gateway_pb.GetTokenUsdPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTokenUsdPriceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTokenUsdPrice',
        request,
        metadata || {},
        this.methodDescriptorGetTokenUsdPrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTokenUsdPrice',
    request,
    metadata || {},
    this.methodDescriptorGetTokenUsdPrice);
  }

  methodDescriptorGetTransferData = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetTransferData',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetTransferDataRequest,
    gateway_gateway_pb.GetTransferDataResponse,
    (request: gateway_gateway_pb.GetTransferDataRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetTransferDataResponse.deserializeBinary
  );

  getTransferData(
    request: gateway_gateway_pb.GetTransferDataRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetTransferDataResponse>;

  getTransferData(
    request: gateway_gateway_pb.GetTransferDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferDataResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetTransferDataResponse>;

  getTransferData(
    request: gateway_gateway_pb.GetTransferDataRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetTransferDataResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetTransferData',
        request,
        metadata || {},
        this.methodDescriptorGetTransferData,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetTransferData',
    request,
    metadata || {},
    this.methodDescriptorGetTransferData);
  }

  methodDescriptorClaimMsgFee = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/ClaimMsgFee',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.ClaimMsgFeeRequest,
    gateway_gateway_pb.ClaimMsgFeeResponse,
    (request: gateway_gateway_pb.ClaimMsgFeeRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.ClaimMsgFeeResponse.deserializeBinary
  );

  claimMsgFee(
    request: gateway_gateway_pb.ClaimMsgFeeRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.ClaimMsgFeeResponse>;

  claimMsgFee(
    request: gateway_gateway_pb.ClaimMsgFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimMsgFeeResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.ClaimMsgFeeResponse>;

  claimMsgFee(
    request: gateway_gateway_pb.ClaimMsgFeeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.ClaimMsgFeeResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/ClaimMsgFee',
        request,
        metadata || {},
        this.methodDescriptorClaimMsgFee,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/ClaimMsgFee',
    request,
    metadata || {},
    this.methodDescriptorClaimMsgFee);
  }

  methodDescriptorGetWithdrawInfo = new grpcWeb.MethodDescriptor(
    '/sgn.gateway.v1.Web/GetWithdrawInfo',
    grpcWeb.MethodType.UNARY,
    gateway_gateway_pb.GetWithdrawInfoRequest,
    gateway_gateway_pb.GetWithdrawInfoResponse,
    (request: gateway_gateway_pb.GetWithdrawInfoRequest) => {
      return request.serializeBinary();
    },
    gateway_gateway_pb.GetWithdrawInfoResponse.deserializeBinary
  );

  getWithdrawInfo(
    request: gateway_gateway_pb.GetWithdrawInfoRequest,
    metadata: grpcWeb.Metadata | null): Promise<gateway_gateway_pb.GetWithdrawInfoResponse>;

  getWithdrawInfo(
    request: gateway_gateway_pb.GetWithdrawInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetWithdrawInfoResponse) => void): grpcWeb.ClientReadableStream<gateway_gateway_pb.GetWithdrawInfoResponse>;

  getWithdrawInfo(
    request: gateway_gateway_pb.GetWithdrawInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: gateway_gateway_pb.GetWithdrawInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/sgn.gateway.v1.Web/GetWithdrawInfo',
        request,
        metadata || {},
        this.methodDescriptorGetWithdrawInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/sgn.gateway.v1.Web/GetWithdrawInfo',
    request,
    metadata || {},
    this.methodDescriptorGetWithdrawInfo);
  }

}

