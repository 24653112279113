import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { Card, Row, Col, Statistic, Button, Skeleton, message } from "antd";
import { isMobile } from "react-device-detect";
import { ArrowDownOutlined, ReloadOutlined } from "@ant-design/icons";
import { BigNumber } from "@ethersproject/bignumber";
import { base64 } from "ethers/lib/utils";
import { useHistory } from "react-router";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import {
  getStakingRewardClaimInfo,
  unlockStakingReward,
  StakingRewardClaimInfo,
  getStakingRewardDetails,
  StakingRewardInfo,
  getStakingRewardInfo,
} from "../../api/reward";
import UnlockForm from "../form/UnlockForm";
import ClaimForm from "../../newComponents/form/ClaimForm";
import {
  UnlockType,
  ClaimType,
  UNLOCK_INIT,
  CLAIM_INIT,
  UNLOCK_WAITING,
  CLAIM_WAITING,
  CLAIM_SUCCESS,
} from "../../constants";
import {
  delegateSplitThousandSeparator,
  formatCelrDecimal,
  formatCelrWithoutSymbol,
  formatRemoveDecimals,
} from "../../helpers/format";
import "./myReward.less";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { setRefreshData, setRefreshMyDelegations } from "../../redux/transferSlice";
import { ModalName, openModal } from "../../redux/modalSlice";
import { useConfigContext } from "../../providers/ConfigContextProvider";
import useReadOnlyCustomContractLoader from "../../hooks/customReadyOnlyContractLoader";
import { StakingReward__factory } from "../../typechain/factories/StakingReward__factory";

/* eslint-disable */
interface RewardProps {
  changeLoading: any;
}

export default function Reward(props: RewardProps): JSX.Element | null {
  const { changeLoading } = props;
  const [stakingRewardInfo, setStakingRewardInfo] = useState<StakingRewardInfo>();
  const [stakingRewardClaimInfo, setStakingRewardClaimInfo] = useState<StakingRewardClaimInfo>();
  const [redeemedStakingReward, setRedeemedStakingReward] = useState<BigNumber>();
  const [loading, setLoading] = useState(false);
  const { address, signer, chainId, provider } = useWeb3Context();
  const ref = useRef<any>();
  ref.current = signer;
  const dispatch = useAppDispatch();
  const { transferInfo } = useAppSelector(state => state);
  const { refreshData, refreshMyDelegations } = transferInfo;
  const [claimTxHash, setClaimTxHash] = useState<string>("");
  const history = useHistory();
  const { contracts } = useContractsContext();
  const { StakingReward } = contracts;
  const { getRpcUrlByChainId } = useConfigContext();
  const { stakingRewardContractAddress } = useAppSelector(state => state.globalInfo);
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const stakingRewardContract = useReadOnlyCustomContractLoader(
    jprovider,
    stakingRewardContractAddress,
    StakingReward__factory,
  );

  const [unlockFormVisible, setUnlockFormVisible] = useState(false);
  const [claimFormVisible, setClaimFormVisible] = useState(false);
  const [unlockStatus, setUnlockStatus] = useState<UnlockType>(UNLOCK_INIT);
  const [claimStatus, setClaimStatus] = useState<ClaimType>(CLAIM_INIT);
  const [claimLoading, setClaimLoading] = useState(false);

  let claimInterval;

  const initData = () => {
    changeLoading(true);
    getStakingRewardInfoData();
    getClaimedRewardAmountsData();
    getStakingRewardClaimInfoData();
  };

  useEffect(() => {
    initData();
  }, [stakingRewardContract, address, refreshData]);

  useEffect(() => {
    return () => {
      clearInterval(claimInterval);
    };
  }, []);

  useEffect(() => {
    if (!provider) {
      return;
    }
    const cacheTxHash = sessionStorage.getItem("cacheTxHash");
    if (cacheTxHash) {
      setClaimLoading(true);
      claimInterval = setInterval(async () => {
        const txStatusRes = await getTxStatus(cacheTxHash);
        if (txStatusRes) {
          sessionStorage.removeItem("cacheTxHash");
          setClaimLoading(false);
          initData();
          clearInterval(claimInterval);
        }
      }, 1000);
    }
  }, [provider]);

  useEffect(() => {
    if (_.isEmpty(stakingRewardInfo) || _.isEmpty(stakingRewardClaimInfo) || _.isEmpty(redeemedStakingReward)) {
    } else {
      changeLoading(false);
    }
  }, [stakingRewardInfo, stakingRewardClaimInfo, redeemedStakingReward]);

  const getStakingRewardInfoData = async () => {
    if (signer && chainId > 0) {
      // changeLoading(true);
      const reward = await getStakingRewardInfo(address);
      setStakingRewardInfo(reward);
      // changeLoading(false);
    } else {
      setTimeout(() => {
        if (!ref.current) {
          history.push("drop");
        }
      }, 500);
    }
  };

  // get claimable rewards info
  const getClaimedRewardAmountsData = async () => {
    if (!stakingRewardContract || !address) {
      return;
    }
    try {
      const result = await stakingRewardContract?.claimedRewardAmounts(address);
      setRedeemedStakingReward(result);
    } catch {
      dispatch(openModal(ModalName.switchChain));
    }
  };

  const getStakingRewardClaimInfoData = async () => {
    if (address) {
      const reward = await getStakingRewardClaimInfo(address);
      setStakingRewardClaimInfo(reward);
    }
  };

  const unlockMethod = async () => {
    /* eslint-disable camelcase */
    //setLoading(true);
    setUnlockStatus(UNLOCK_WAITING);
    await unlockStakingReward({ delegator_address: address });
    setTimeout(() => {
      getStakingRewardDetails({ delegator_address: address })
        .then(res => {
          if (res && res.cumulative_reward_amount && res.cumulative_reward_amount.length > 0) {
            setStakingRewardClaimInfo(res);
            waitSigs();
          } else {
            message.error("Something error!");
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          message.error("gateway error!");
        });
    }, 30000);
  };

  const waitSigs = () => {
    const timeout = setTimeout(() => {
      setUnlockStatus(UNLOCK_INIT);
      setUnlockFormVisible(false);
      setClaimFormVisible(true);
      setClaimStatus(CLAIM_INIT);
      clearTimeout(timeout);
      initData();
    });
  };

  const getTxStatus = async txHashStr => {
    if (txHashStr) {
      const res = await provider?.getTransactionReceipt(txHashStr);
      return res;
    }

    return "";
  };

  const redeemReward = async () => {
    if (!(stakingRewardClaimInfo && stakingRewardClaimInfo.signatures.length > 0)) {
      const result = await getStakingRewardDetails({ delegator_address: address });
      setStakingRewardClaimInfo(result);
    }

    if (stakingRewardClaimInfo) {
      setClaimLoading(true);
      const { reward_proto_bytes, signatures } = stakingRewardClaimInfo;
      const newSignatures = signatures.sort((a, b) => a.signer.localeCompare(b.signer));
      const sigs = newSignatures?.map(item => {
        return base64.decode(item.sig_bytes);
      });
      const rewardProtoBytes = base64.decode(reward_proto_bytes);
      try {
        setClaimStatus(CLAIM_WAITING);
        const claimTx = (await StakingReward?.claimReward(rewardProtoBytes, sigs))?.hash || "";
        setClaimTxHash(claimTx);
        setClaimStatus(CLAIM_SUCCESS);
        initData();
        sessionStorage.setItem("cacheTxHash", claimTx);
        claimInterval = setInterval(async () => {
          const txStatusRes = await getTxStatus(claimTx);
          if (txStatusRes) {
            sessionStorage.removeItem("cacheTxHash");
            setClaimLoading(false);
            initData();
            clearInterval(claimInterval);
          }
        }, 1000);
      } catch (e) {
        console.log(e);
        setClaimLoading(false);
        setClaimStatus(CLAIM_INIT);
      }
    }
  };

  const claimSuccessMethod = () => {
    setClaimStatus(CLAIM_INIT);
    setClaimFormVisible(false);
  };

  const clickWithCheckChainId = method => {
    if (chainId && chainId !== Number(process.env.REACT_APP_NETWORK_ID)) {
      return dispatch(openModal(ModalName.switchChain));
    }
    return method();
  };

  if (_.isEmpty(stakingRewardInfo) || _.isEmpty(stakingRewardClaimInfo) || _.isEmpty(redeemedStakingReward)) {
    return <Skeleton />;
  }

  const cumulativeStakingReward = `${BigNumber.from(
    formatRemoveDecimals(stakingRewardInfo?.cumulative_reward_amount),
  )}`;
  const lockedStakingReward = `${BigNumber.from(formatRemoveDecimals(stakingRewardInfo?.cumulative_reward_amount)).sub(
    BigNumber.from(formatRemoveDecimals(stakingRewardInfo?.claimed_reward_amount)),
  )}`;
  const calimableStakingReward = `${BigNumber.from(
    formatRemoveDecimals(stakingRewardClaimInfo?.cumulative_reward_amount),
  ).sub(BigNumber.from(redeemedStakingReward))}`;

  const unlockNum = delegateSplitThousandSeparator(formatCelrWithoutSymbol(lockedStakingReward, 6));
  const claimbleNum = delegateSplitThousandSeparator(formatCelrWithoutSymbol(calimableStakingReward, 6));
  const unlockButtonDisabled = unlockNum < 0.000001 || unlockStatus === UNLOCK_WAITING;
  const claimButtonDisabled = claimbleNum < 0.000001 || claimLoading;

  const renderMobileContent = () => {
    return (
      <div className="mobile-rewards-content">
        <div className="mobile-rewards-content-block">
          <div className="mobile-rewards-reward-info">
            <Statistic title="Cumulative Staking Reward" value={formatCelrDecimal(cumulativeStakingReward, 6)} />
            <Statistic title="Locked Staking Reward" value={formatCelrDecimal(lockedStakingReward, 6)} />
          </div>
          <Button
            className="mobile-rewards-reward-btn"
            disabled={unlockButtonDisabled}
            onClick={() => {
              clickWithCheckChainId(() => {
                setUnlockFormVisible(true);
              });
            }}
          >
            {unlockStatus === UNLOCK_WAITING ? "Unlocking.." : "Unlock to claim"}
          </Button>
        </div>
        <div className="mobile-rewards-conten-line">
          <ArrowDownOutlined
            style={{
              position: "absolute",
              top: "-0.12rem",
              right: "0.43rem",
              fontSize: "0.16rem",
              borderRadius: "12px",
              padding: "0.04rem",
              background: "#ECEBEE",
            }}
          />
        </div>
        <div className="mobile-rewards-content-block">
          <div className="mobile-rewards-reward-info">
            <Statistic title="Claimed Staking Reward" value={formatCelrDecimal(redeemedStakingReward, 6)} />
            <Statistic title="Claimable Staking Reward" value={formatCelrDecimal(calimableStakingReward, 6)} />
          </div>
          <Button
            className="mobile-rewards-reward-btn"
            disabled={claimButtonDisabled}
            onClick={() => {
              clickWithCheckChainId(() => {
                setClaimFormVisible(true);
              });
            }}
          >
            {claimLoading ? "Claiming" : "Claim"}
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Row style={{ marginTop: "10px" }}>
          <Col span={10}>
            <Statistic title="Cumulative Staking Reward" value={formatCelrDecimal(cumulativeStakingReward, 6)} />
          </Col>
          <Col span={10}>
            <Statistic title="Locked Staking Reward" value={formatCelrDecimal(lockedStakingReward, 6)} />
          </Col>
          <Col span={4}>
            <Button
              disabled={unlockButtonDisabled}
              onClick={() => {
                clickWithCheckChainId(() => {
                  setUnlockFormVisible(true);
                });
              }}
            >
              {unlockStatus === UNLOCK_WAITING ? "Unlocking.." : "Unlock to claim"}
            </Button>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid #ECEBEE", paddingTop: "40px", marginTop: "40px" }}>
          <Col span={10}>
            <Statistic title="Claimed Staking Reward" value={formatCelrDecimal(redeemedStakingReward, 6)} />
          </Col>
          <Col span={10}>
            <Statistic title="Claimable Staking Reward" value={formatCelrDecimal(calimableStakingReward, 6)} />
          </Col>
          <Col span={4}>
            <ArrowDownOutlined
              style={{
                position: "absolute",
                float: "right",
                top: "-52px",
                right: "74px",
                fontSize: "16px",
                borderRadius: "12px",
                padding: "4px",
                background: "#ECEBEE",
              }}
            />
            <Button
              disabled={claimButtonDisabled}
              onClick={() => {
                clickWithCheckChainId(() => {
                  setClaimFormVisible(true);
                });
              }}
            >
              {claimLoading ? "Claiming" : "Claim"}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Card
        title="My Reward"
        className="my-reward"
        style={{ marginBottom: "24px", borderRadius: "21px" }}
        extra={
          <Button
            type="primary"
            className="rebutton"
            onClick={() => {
              initData();
              dispatch(setRefreshMyDelegations(!refreshMyDelegations));
            }}
            icon={<ReloadOutlined style={{ fontSize: 20 }} />}
          />
        }
      >
        {isMobile ? renderMobileContent() : renderContent()}
        <UnlockForm
          visible={unlockFormVisible}
          reward={formatCelrDecimal(lockedStakingReward, 6)}
          action={unlockStatus}
          toggleClaimForm={() => {
            setUnlockFormVisible(!unlockFormVisible);
            dispatch(setRefreshData());
          }}
          unlockMethod={unlockMethod}
          loading={loading}
        />
        <ClaimForm
          visible={claimFormVisible}
          reward={formatCelrDecimal(calimableStakingReward, 6)}
          action={claimStatus}
          toggleClaimForm={() => {
            setClaimFormVisible(!claimFormVisible);
            dispatch(setRefreshData());
          }}
          redeemReward={redeemReward}
          claimSuccessMethod={claimSuccessMethod}
          claimTxHash={claimTxHash}
        />
      </Card>
    </>
  );
}
