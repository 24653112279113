const getTokenNameByTokenSymbol = (symbol: string, chId: number) => {
  let name = symbol;
  const chainId = Number(chId);
  if (chainId === 43114) {
    if (symbol === "USDT") {
      name = "USDT.e";
    }
    if (symbol === "DAI") {
      name = "DAI.e";
    }
    if (symbol === "USDC") {
      name = "USDC.e";
    }
    if (symbol === "WETH") {
      name = "WETH.e";
    }
  }
  if (chainId === 250) {
    if (symbol === "USDT") {
      name = "fUSDT";
    }
    if (symbol === "WETH") {
      name = "ETH";
    }
  }
  if (chainId === 56) {
    if (symbol === "WETH") {
      name = "ETH";
    }
  }

  if (chainId === 1 || chainId === 42161 || chainId === 10 || chainId === 5) {
    if (symbol === "WETH") {
      name = "ETH";
    }
  }

  if (chainId === 12340001 || chainId === 12340002) {
    if (symbol === "cfUSDC") {
      name = "USDC";
    }
    if (symbol === "celrWFLOW") {
      name = "FLOW";
    }
  }

  return name;
};

export default getTokenNameByTokenSymbol;
