import { useCallback } from "react";
import { Dropdown, Menu } from "antd";
import { isMobile } from "react-device-detect";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { ModalName, openModal } from "../redux/modalSlice";
import { useAppDispatch } from "../redux/store";
import { getSimple } from "../helpers/utils";
import connectPng from "../assets/images/connect.png";
import disconnectPng from "../assets/images/disconnect.png";
import "./account.less";

export default function Account(): JSX.Element {
  const { address, signer, logoutOfWeb3Modal } = useWeb3Context();
  const dispatch = useAppDispatch();

  const showWalletConnectionProviderModal = useCallback(() => {
    dispatch(openModal(ModalName.provider));
  }, [dispatch]);

  const walletConnectionLogout = () => {
    logoutOfWeb3Modal();
  };

  const renderAccount = () => {
    if (signer) {
      const menu = (
        <Menu className="dropDownMenu">
          <Menu.Item className="logoutBtn" key="logout" onClick={walletConnectionLogout}>
            <div className="disconnect">
              <div className="text">Disconnect</div>
              {isMobile ? null : (
                <div className="img-block">
                  <img src={disconnectPng} alt="" />
                </div>
              )}
            </div>
          </Menu.Item>
        </Menu>
      );
      return (
        <Dropdown overlay={menu}>
          <div className="account">
            <div className="address">{getSimple(address, 6, -4)}</div>
            {isMobile ? null : (
              <div className="img-block">
                <img src={connectPng} alt="" />
              </div>
            )}
          </div>
        </Dropdown>
      );
    }

    return (
      <div className="connect" onClick={showWalletConnectionProviderModal}>
        CONNECT WALLET
      </div>
    );
  };

  return renderAccount();
}
