import { useState, useEffect, useCallback } from "react";
import { Modal, Spin, Button, Menu } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { isMobile } from "react-device-detect";
import { useWeb3Context } from "../../../providers/Web3ContextProvider";
import { getHistory, getPegHistory } from "../../../api";
import { storageConstants } from "../../../constants/const";
import { LPHistory } from "../../../constants/claimType";
import { mergeLiquidityHistory } from "../../../utils/mergeLiquidityHistory";
import { ClaimHistoryRequest, ClaimStatus, PegClaimHistoryRequest } from "../../../proto/gateway/gateway_pb";
import "./historyModal.less";
import { dataClone } from "../../../helpers/dataClone";
import PageFlipper from "../../pageFlipper";
import HistoryModalItem from "./HistoryItem";
import PegHistoryModalItem from "./PegHistoryItem";
import mobileReload from "../../../assets/images/mobile-reload.png";
import mobileClose from "../../../assets/images/mobile-close.png";

const defaultPageSize = isMobile ? 5 : 6;

const getTxStatus = async (provider, link) => {
  const txid = link.split("/tx/")[1];
  if (txid) {
    const res = await provider?.getTransactionReceipt(txid);
    return res;
  }
  return "";
};

/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-shadow */

export type PageTokenMap = {
  [propName: number]: number;
};

export type Historykey = "pool_bridge" | "canonical_bridge" | "celer_IM";

interface IProps {
  visible: boolean;
  onClose: () => void;
}
const HistoryModal = (props: IProps) => {
  const { visible, onClose } = props;
  const { address, provider, chainId } = useWeb3Context();
  const [currentPage, setCurrentPage] = useState(0);
  const [pegCurrentPage, setPegCurrentPage] = useState(0);
  const [nexPageToken, setNexPageToken] = useState(0);
  const [pegNexPageToken, setPegNexPageToken] = useState(0);
  const [pageChanged, setPageChanged] = useState(false);
  const [size, setSize] = useState(defaultPageSize);
  const [pegSize, setPegSize] = useState(defaultPageSize);
  const [lpLoading, setLpLodaing] = useState(false);
  const now = new Date().getTime();
  const [pageMap, setPageMap] = useState<PageTokenMap>({ 0: now });
  const [pegPageMap, setPegPageMap] = useState<PageTokenMap>({ 0: now });
  const [mergedLpHistory, setMergedLpHistory] = useState<LPHistory[]>([]);
  const [pegMergedLpHistory, setPegMergedLpHistory] = useState<LPHistory[]>([]);
  const [historykey, setHistorykey] = useState<Historykey>("pool_bridge");
  const [poolActionNum, setPoolActionNum] = useState<number>(0);
  const [poolPendingNum, setPoolPendingNum] = useState<number>(0);
  const [pegActionNum, setPegActionNum] = useState<number>(0);
  const [pegPendingNum, setPegPendingNum] = useState<number>(0);

  useEffect(() => {
    switch (historykey) {
      case "pool_bridge":
        if (mergedLpHistory && mergedLpHistory.length > 0) {
          const renewPageToken = mergedLpHistory[mergedLpHistory.length - 1].ts.toString();
          const oldPageMap = dataClone(pageMap);
          oldPageMap[currentPage + 1] = renewPageToken;
          setPageMap(oldPageMap);
        }
        break;
      case "canonical_bridge":
        if (pegMergedLpHistory && pegMergedLpHistory.length > 0) {
          const renewPageToken = pegMergedLpHistory[pegMergedLpHistory.length - 1].ts.toString();
          const oldPegPageMap = dataClone(pegPageMap);
          oldPegPageMap[pegCurrentPage + 1] = renewPageToken;
          setPegPageMap(oldPegPageMap);
        }
        break;
      default:
        break;
    }
  }, [mergedLpHistory, currentPage, pegMergedLpHistory, pegCurrentPage, historykey]);

  const getLpOnChainQueryPromiseList = (paramProvider, paramChainId, localLpHistoryList: LPHistory[]) => {
    // eslint-disable-next-line
    const promiseList: Array<Promise<any>> = [];
    if (localLpHistoryList) {
      const newLocalLpList: LPHistory[] = [];
      localLpHistoryList?.forEach(localItem => {
        if (localItem && localItem.toString() !== "null") {
          newLocalLpList.push(localItem);
          if (
            localItem?.status === ClaimStatus.CLM_FAILED ||
            localItem?.txIsFailed ||
            Number(localItem.chain?.id) !== Number(paramChainId)
          ) {
            // Failed transactions filter
            const nullPromise = new Promise(resolve => {
              resolve(0);
            });
            promiseList.push(nullPromise);
          } else {
            const promistx = getTxStatus(paramProvider, localItem.blockTxLink);
            promiseList.push(promistx);
          }
        }
      });
    }
    return promiseList;
  };

  const onPageChange = page => {
    switch (historykey) {
      case "pool_bridge": {
        const oldPageMap = dataClone(pageMap);
        if (page === 0) {
          oldPageMap[0] = now;
          setNexPageToken(now);
        } else {
          setNexPageToken(oldPageMap[page]);
        }
        setCurrentPage(page);
        setPageMap(oldPageMap);
        setPageChanged(true);
        break;
      }
      case "canonical_bridge": {
        const oldPegPageMap = dataClone(pegPageMap);
        if (page === 0) {
          oldPegPageMap[0] = now;
          setPegNexPageToken(now);
        } else {
          setPegNexPageToken(oldPegPageMap[page]);
        }
        setPegCurrentPage(page);
        setPegPageMap(oldPegPageMap);
        setPageChanged(true);
        break;
      }
      default:
        break;
    }
  };

  const getLphistoryList = async nextPageToken => {
    setLpLodaing(true);
    const request = new ClaimHistoryRequest();
    request.setPageSize(defaultPageSize);
    request.setNextPageToken(nextPageToken);
    request.setAddr(address);
    const res = await getHistory(request);
    if (res) {
      setSize(res?.currentSize);
      let localLpList;
      const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
      if (localLpListStr) {
        localLpList = JSON.parse(localLpListStr)[address] as LPHistory[];
      }
      const promiseList = getLpOnChainQueryPromiseList(provider, chainId, localLpList);
      Promise.all(promiseList).then(onChainResult => {
        const lpMergerdResult = mergeLiquidityHistory(
          address,
          pageMap[currentPage],
          res.historyList,
          localLpList,
          onChainResult,
          6,
          "pool_bridge",
        );
        setPoolActionNum(lpMergerdResult.actionNum);
        setPoolPendingNum(lpMergerdResult.pendingNum);
        setMergedLpHistory(lpMergerdResult.mergedLpHistory);
      });
      setLpLodaing(false);
    }
  };

  const getPegLphistoryList = async nextPageToken => {
    setLpLodaing(true);
    const request = new PegClaimHistoryRequest();
    request.setPageSize(defaultPageSize);
    request.setNextPageToken(nextPageToken);
    request.setAddr(address);
    const res = await getPegHistory(request);
    if (res) {
      setPegSize(res?.currentSize);
      let localLpList;
      const localLpListStr = localStorage.getItem(storageConstants.KEY_PEG_LP_LIST);
      if (localLpListStr) {
        localLpList = JSON.parse(localLpListStr)[address] as LPHistory[];
      }
      const promiseList = getLpOnChainQueryPromiseList(provider, chainId, localLpList);
      Promise.all(promiseList).then(onChainResult => {
        const lpMergerdResult = mergeLiquidityHistory(
          address,
          pegPageMap[pegCurrentPage],
          res.historyList,
          localLpList,
          onChainResult,
          6,
          "canonical_bridge",
        );
        setPegActionNum(lpMergerdResult.actionNum);
        setPegPendingNum(lpMergerdResult.pendingNum);
        setPegMergedLpHistory(lpMergerdResult.mergedLpHistory);
      });
      setLpLodaing(false);
    }
  };

  const reloadHisList = () => {
    switch (historykey) {
      case "pool_bridge":
        getLphistoryList(currentPage === 0 ? now : pageMap[currentPage]);
        break;
      case "canonical_bridge":
        getPegLphistoryList(pegCurrentPage === 0 ? now : pegPageMap[pegCurrentPage]);
        break;
      default:
        break;
    }
  };

  const refreshTabMessageCallback = useCallback(
    (poolActionNum, poolPendingNum, pegActionNum, pegPendingNum, mergedLpHistory, pegMergedLpHistory, pageChanged) => {
      if (pageChanged) {
        return;
      }
      const hasActionInPool = poolActionNum > 0;
      const hasActionInPeg = pegActionNum > 0;
      const hasPendingInPool = poolPendingNum > 0;
      const hasPendingInPeg = pegPendingNum > 0;
      const hasHistoryInPool = mergedLpHistory.length > 0;
      const hasHistoryInLiquidity = pegMergedLpHistory.length > 0;

      // Action Required > No Action Required
      // Pending > No Pending
      // History > No History
      // Transfer > Liquidity
      const poolDefaultPriority = 1;
      const pegDefaultPriority = 0;
      const hasHistoryPriority = 10;
      const hasPendingPriority = 100;
      const hasActionPriority = 1000;
      let poolPriority = 0;
      let pegPriority = 0;

      poolPriority += poolDefaultPriority;
      pegPriority += pegDefaultPriority;
      if (hasHistoryInPool) {
        poolPriority += hasHistoryPriority;
      }
      if (hasHistoryInLiquidity) {
        pegPriority += hasHistoryPriority;
      }
      if (hasPendingInPool) {
        poolPriority += hasPendingPriority;
      }
      if (hasPendingInPeg) {
        pegPriority += hasPendingPriority;
      }
      if (hasActionInPool) {
        poolPriority += hasActionPriority;
      }
      if (hasActionInPeg) {
        pegPriority += hasActionPriority;
      }

      if (pegPriority > poolPriority) {
        setHistorykey("canonical_bridge");
      } else {
        setHistorykey("pool_bridge");
      }
    },
    [poolActionNum, poolPendingNum, pegActionNum, pegPendingNum, mergedLpHistory, pegMergedLpHistory, pageChanged],
  );

  const debouncedMessageNumberChangeHandler = useCallback(debounce(refreshTabMessageCallback, 300), []);

  useEffect(() => {
    debouncedMessageNumberChangeHandler(
      poolActionNum,
      poolPendingNum,
      pegActionNum,
      pegPendingNum,
      mergedLpHistory,
      pegMergedLpHistory,
      pageChanged,
    );
  }, [poolActionNum, poolPendingNum, pegActionNum, pegPendingNum, mergedLpHistory, pegMergedLpHistory, pageChanged]);

  useEffect(() => {
    reloadHisList();
  }, [visible]);

  useEffect(() => {
    setNexPageToken(now);
    const newpMap = { 0: now };
    setPageMap(newpMap);
    setCurrentPage(0);
    getLphistoryList(now.toString());

    setPegNexPageToken(now);
    const newPegpMap = { 0: now };
    setPegPageMap(newPegpMap);
    setPegCurrentPage(0);
    getPegLphistoryList(now.toString());
  }, [address, historykey]);

  useEffect(() => {
    switch (historykey) {
      case "pool_bridge":
        if (nexPageToken !== 0) {
          getLphistoryList(nexPageToken);
        }
        break;
      case "canonical_bridge":
        if (pegNexPageToken !== 0) {
          getPegLphistoryList(pegNexPageToken);
        }
        break;
      default:
        break;
    }
  }, [nexPageToken, pegNexPageToken, historykey]);

  const renderEmpty = () => {
    return (
      <div className="history-empty">
        <div>👀</div>
        <div>No Data yet!</div>
      </div>
    );
  };

  const renderContent = () => {
    switch (historykey) {
      case "pool_bridge":
        return mergedLpHistory && mergedLpHistory.length > 0 ? (
          <div className="claim-history">
            <Spin spinning={lpLoading}>
              <div className="history-list">
                {mergedLpHistory?.map(item => {
                  return <HistoryModalItem item={item} key={item.seqNum} reloadHisList={reloadHisList} />;
                })}
              </div>
            </Spin>
          </div>
        ) : (
          renderEmpty()
        );
      case "canonical_bridge":
        return pegMergedLpHistory && pegMergedLpHistory.length > 0 ? (
          <div className="claim-history">
            <Spin spinning={lpLoading}>
              <div className="history-list">
                {pegMergedLpHistory?.map(item => {
                  return <PegHistoryModalItem item={item} key={item.nonce} reloadHisList={reloadHisList} />;
                })}
              </div>
            </Spin>
          </div>
        ) : (
          renderEmpty()
        );
      default:
        return null;
    }
  };

  const renderPageFlipper = () => {
    switch (historykey) {
      case "pool_bridge":
        return currentPage !== undefined ? (
          <div className="history-pagination">
            <PageFlipper
              page={currentPage}
              hasMore={Number(size) === defaultPageSize}
              onPageChange={(toPage: number) => onPageChange(toPage)}
            />
          </div>
        ) : null;
      case "canonical_bridge":
        return !pegCurrentPage !== undefined ? (
          <div className="history-pagination">
            <PageFlipper
              page={pegCurrentPage}
              hasMore={Number(pegSize) === defaultPageSize}
              onPageChange={(toPage: number) => onPageChange(toPage)}
            />
          </div>
        ) : null;
      default:
        return null;
    }
  };

  const renderModalHeader = () => {
    return (
      <div className="history-modal-header">
        {isMobile ? (
          <div className="history-modal-header-actions">
            <Button
              type="text"
              className="mobile-reload"
              onClick={() => {
                reloadHisList();
              }}
            >
              <img src={mobileReload} alt="reload" className="reload-icon" />
            </Button>
            <Button
              type="text"
              className="mobile-close"
              onClick={() => {
                onClose();
              }}
            >
              <img src={mobileClose} alt="close" className="close-icon" />
            </Button>
          </div>
        ) : null}
        <Menu
          className="history-menu"
          selectedKeys={[historykey]}
          onClick={e => {
            setPageChanged(true);
            setHistorykey(e.key as Historykey);
          }}
          mode="horizontal"
        >
          <Menu.Item key="pool_bridge" className="pool-bridge">
            <div className="history-menu-item">
              Pool Bridge
              {poolActionNum !== 0 && <div className="has-cta" />}
            </div>
          </Menu.Item>
          <Menu.Item key="canonical_bridge" className="canonical-bridge">
            <div className="history-menu-item">
              Canonical Bridge
              {pegActionNum !== 0 && <div className="has-cta" />}
            </div>
          </Menu.Item>
        </Menu>
        {isMobile ? null : (
          <div className="history-actions">
            <Button
              type="text"
              className="rebutton"
              onClick={() => {
                reloadHisList();
              }}
              icon={<ReloadOutlined style={{ fontSize: 20, color: "#DAD7E0" }} />}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      className="history-modal"
      title={renderModalHeader()}
      visible={visible}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        onClose();
      }}
    >
      <div style={{ width: "100%" }}>
        {renderContent()}
        {renderPageFlipper()}
      </div>
    </Modal>
  );
};

export default HistoryModal;
