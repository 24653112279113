import { EstimateWithdrawAmtRequest } from "../proto-grpc/sgn/gateway/v1/gateway.pb";
import {
  ClaimPegBridgeFeeRequest,
  ClaimPegBridgeFeeResponse,
  GetTokenUsdPriceRequest,
  ClaimHistoryRequest,
  GetTokenInfoRequest,
  WithdrawLiquidityRequest,
  WithdrawLiquidityResponse,
  GetTransferConfigsRequest,
  PegClaimHistoryRequest,
  ClaimMsgFeeRequest,
  SignAgainRequest,
} from "../proto/gateway/gateway_pb";
import {
  QueryFeeClaimInfoRequest,
  QueryFeeClaimInfoResponse,
  QueryWithdrawInfoRequest,
  QueryWithdrawInfoResponse,
} from "../proto/sgn/pegbridge/v1/query_pb";

import { QueryChainSignersRequest, QueryChainSignersResponse } from "../proto/sgn/cbridge/v1/query_pb";
import { WebClient } from "../proto/gateway/GatewayServiceClientPb";
import { QueryClient } from "../proto/sgn/pegbridge/v1/QueryServiceClientPb";
import { QueryClient as CBridgeQueryClient } from "../proto/sgn/cbridge/v1/QueryServiceClientPb";
import { gatewayGet, gatewayPost, gatewayOtherGet } from "../helpers/request";
import { SignatureSGN } from "../constants/type";

// const preFix = { pathPrefix: process.env.REACT_APP_SERVER_URL };

const client = new WebClient(`${process.env.REACT_APP_GRPC_SERVER_URL}`, null, null);

const queryClient = new QueryClient(`${process.env.REACT_APP_GRPC_SERVER_URL}`, null, null);

const cBridgeQueryClient = new CBridgeQueryClient(`${process.env.REACT_APP_GRPC_SERVER_URL}`, null, null);

/* eslint-disable camelcase */
/* eslint-disable */
// export interface WithdrawDetail {
//     wd_onchain?: Uint8Array
//     sorted_sigs?: Uint8Array[]
//     signers?: Uint8Array[]
//     powers?: Uint8Array[]
// }
export interface WithdrawDetail {
  _wdmsg: string;
  _sigs: Array<string>;
  _signers: Array<string>;
  _powers: Array<string>;
}

export interface WithdrawInfo {
  _request: string;
  _sigs: Array<string>;
  _signers: Array<string>;
  _powers: Array<string>;
}

export enum LPType {
  LP_TYPE_UNKNOWN = 0,
  LP_TYPE_ADD = 1,
  LP_TYPE_REMOVE = 2,
}
// interface QueryLiquidityStatusRequest {
//     seq_num: string;
//     lp_addr: string;
//     chain_id: number;
//     type: LPType;
// }

export enum LPHistoryStatus {
  LP_UNKNOWN,
  LP_WAITING_FOR_SGN,
  LP_WAITING_FOR_LP,
  LP_SUBMITTING,
  LP_COMPLETED,
  LP_FAILED,
}
interface QueryLiquidityStatusResponse {
  status: LPHistoryStatus;
  wd_onchain: string;
  sorted_sigs: Array<string>;
  signers: Array<string>;
  powers: Array<string>;
  block_tx_link: string;
}

interface StakingConfig {
  viewer_contract: string;
  staking_contract: string;
  staking_reward_contract: string;
  celr_contract: string;
}

interface MarkLiquidityRequest {
  lp_addr: string;
  amt: string;
  token_addr: string;
  chain_id: number;
  seq_num: string;
  tx_hash?: string;
  type: LPType;
}

export interface ValDescInfo {
  identity: string;
  contact: string;
  website: string;
  moniker: string;
  details: {
    icon: string;
  };
}

interface ValInfo {
  eth_address: string;
  description: ValDescInfo;
  eth_signer: string;
  sgn_address: string;
  status: string;
  tokens: string;
  commission_rate: string;
}

export interface CBridgeFeeShareInfo {
  amount: string;
  denom: string;
}
interface CBridgeFeeShareType {
  claimable_fee_amounts: Array<CBridgeFeeShareInfo>;
}

export type FeeClaimInfo = {
  recipient: string;
  last_claim_time: string;
  fee_claim_details_list: Array<FeeClaimDetail>;
};

export type FeeClaimDetail = {
  chain_id: number;
  cumulative_fee_amount: CBridgeFeeShareInfo;
  signatures: Array<SignatureSGN>;
};

// get contract addr
const getContractsAddr = async () => {
  const res = await gatewayGet<StakingConfig>(`v1/staking/stakingConfig`);
  return res;
};

// get validators info
const getValsInfo = async () => {
  const res = await gatewayOtherGet<{ validators: Array<ValInfo> }>(`sgn/staking/v1/validators`);
  return res.validators;
};

// get single validators info
const getValInfo = async (valAddr: string) => {
  const res = await gatewayOtherGet<{ validator: ValInfo }>(`sgn/staking/v1/validators/${valAddr}`);
  return res.validator;
};

const getFeeShareInfo = async delAddr => {
  const res = await gatewayOtherGet<{ fee_share_info: CBridgeFeeShareType }>(
    `sgn/distribution/v1/cbridge_fee_share_info/${delAddr}`,
  );
  return res.fee_share_info.claimable_fee_amounts;
};

const getPegFeeShareInfo = async delAddr => {
  const res = await gatewayOtherGet<{ fees_info: CBridgeFeeShareType }>(
    `sgn/distribution/v1/pegbridge_fees_info/${delAddr}`,
  );
  return res.fees_info.claimable_fee_amounts;
};

const queryLiquidityStatus = async reqParams => {
  const res = await gatewayGet<QueryLiquidityStatusResponse>(
    `v1/queryLiquidityStatus?seq_num=${reqParams.seq_num}&lp_addr=${reqParams.lp_addr}&chain_id=${reqParams.chain_id}&type=${reqParams.type}`,
  );
  return res;
  // debugger;
  // return Web.QueryLiquidityStatus(reqParams, preFix);
};

const getFeeClaimInfo = (reqParams: QueryFeeClaimInfoRequest): Promise<QueryFeeClaimInfoResponse> => {
  return queryClient.feeClaimInfo(reqParams, null);
};

const getWithdrawInfo = (reqParams: QueryWithdrawInfoRequest): Promise<QueryWithdrawInfoResponse> => {
  return queryClient.withdrawInfo(reqParams, null);
};

const getChainSigners = (reqParams: QueryChainSignersRequest): Promise<QueryChainSignersResponse> => {
  return cBridgeQueryClient.queryChainSigners(reqParams, null);
};

const withdrawLiquidity = (reqParams: WithdrawLiquidityRequest): Promise<WithdrawLiquidityResponse> => {
  return client.withdrawLiquidity(reqParams, null);
};

const claimPegBridgeFeeMethod = (reqParams: ClaimPegBridgeFeeRequest): Promise<ClaimPegBridgeFeeResponse> => {
  return client.claimPegBridgeFee(reqParams, null);
};

const markLiquidity = async (params: MarkLiquidityRequest) => {
  const res = await gatewayPost<null>("v1/markLiquidity", params);
  return res;
};

const estimateWithdrawAmt = async (params: EstimateWithdrawAmtRequest) => {
  const res = await gatewayPost<null>("v1/estimateAmt", params);
  return res;
};

const getTransferConfigs = async (params: GetTransferConfigsRequest) => {
  const res = await client.getTransferConfigsForAll(params, null);
  return res;
};

const getTokenInfo = async (params: GetTokenInfoRequest) => {
  const res = await client.getTokenInfo(params, null);
  return res.getTokenInfo();
};

const getTokenUsdPrice = async (params: GetTokenUsdPriceRequest) => {
  const res = await client.getTokenUsdPrice(params, null);
  if (res.hasErr()) {
    console.log(res.getErr());
  }
  return res.toObject().priceMap;
};

const getHistory = async (params: ClaimHistoryRequest) => {
  const res = await client.claimHistory(params, null);
  if (res.hasErr()) {
    console.log(res.getErr());
  }
  return res.toObject();
};

const getPegHistory = async (params: PegClaimHistoryRequest) => {
  const res = await client.pegClaimHistory(params, null);
  if (res.hasErr()) {
    console.log(res.getErr());
  }
  return res.toObject();
};

const getIMFeeShareInfo = async delAddr => {
  const res = await gatewayOtherGet<any>(`sgn/distribution/v1/message_fees_info/${delAddr}`);
  return res.fees_info.claimable_fee_amounts;
};

const getMessageBusInfo = async chainId => {
  const res = await gatewayOtherGet<any>(`sgn/message/v1/message_bus/${chainId}`);
  return res;
};

const claimMsgFee = async (params: ClaimMsgFeeRequest) => {
  const res = await client.claimMsgFee(params, null);
  return res.toObject();
};

const getImFeeClaimInfo = async delAddr => {
  try {
    const res = await gatewayOtherGet<{ fee_claim_info: FeeClaimInfo }>(`sgn/message/v1/fee_claim_info/${delAddr}`);
    return res.fee_claim_info;
  } catch {
    const feeClaimInfo = {
      recipient: "",
      last_claim_time: "",
      fee_claim_details_list: [],
    };
    return feeClaimInfo;
  }
};

const signAgain = async (params: SignAgainRequest) => {
  const res = await client.signAgain(params, null);
  if (res.hasErr()) {
    console.log(res.getErr());
  }
  return res.toObject();
};

export {
  getContractsAddr,
  getValsInfo,
  getValInfo,
  getFeeShareInfo,
  getPegFeeShareInfo,
  getIMFeeShareInfo,
  queryLiquidityStatus,
  withdrawLiquidity,
  markLiquidity,
  getTransferConfigs,
  getTokenInfo,
  claimPegBridgeFeeMethod,
  getFeeClaimInfo,
  getWithdrawInfo,
  getChainSigners,
  estimateWithdrawAmt,
  getTokenUsdPrice,
  getHistory,
  getPegHistory,
  getMessageBusInfo,
  claimMsgFee,
  getImFeeClaimInfo,
  signAgain,
};
