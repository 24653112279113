
import { BigNumber } from "@ethersproject/bignumber";
import axios from "axios";
import { SignatureSGN } from "../constants/type";
import { getGatewayUrl } from "../helpers/env";
import { gatewayGet, gatewayOtherGet, gatewayPost } from "../helpers/request";

/* eslint-disable */

export type RewardInfo = {
  lastWithdrawTime: string;
  miningReward: string;
  receiver: string;
  rewardProtoBytes: string;
  serviceReward: string;
}

export type StakingRewardInfo = {
  cumulative_reward_amount: string;
  claimed_reward_amount: string;
}

export type StakingRewardClaimInfo = {
  recipient: string;
  last_claim_time: string;
  cumulative_reward_amount: string;
  reward_proto_bytes: string;
  signatures: Array<SignatureSGN>;
}

export type RewardRequest = {
  miningReward: BigNumber;
  serviceReward: BigNumber;
  rewardRequestProtoBytes: string;
}

type RewardRequestResult = {
  result: {
    mining_reward: BigNumber;
    service_reward: BigNumber;
    reward_request_proto_bytes: string;
  }
}

export async function rewardRequest(addr: string): Promise<RewardRequest> {
  const res = await gatewayGet<RewardRequestResult>(`validator/rewardRequest/${addr}`);
  const rewardRequestInfo = {
    miningReward: res.result.mining_reward,
    serviceReward: res.result.service_reward,
    rewardRequestProtoBytes: res.result.reward_request_proto_bytes,
  }
  return rewardRequestInfo;
}

export async function withdrawReward(params:any) {
  const res = await gatewayPost<any>("validator/withdrawReward", params);
  return res.result;
}

export async function getStakingRewardInfo(addr: string): Promise<StakingRewardInfo> {
  const res = await gatewayOtherGet<any>(`sgn/distribution/v1/staking_reward_info/${addr}`);
  const rewardInfo = {
    cumulative_reward_amount: res.reward_info.cumulative_reward_amount.amount,
    claimed_reward_amount: res.reward_info.claimed_reward_amount.amount,
  }
  return rewardInfo;
}

export async function getStakingRewardClaimInfo(addr: string): Promise<StakingRewardClaimInfo> {
  try{
    const res = await gatewayOtherGet<any>(`sgn/distribution/v1/staking_reward_claim_info/${addr}`);
    const rewardClaimInfo = res.reward_claim_info;
    const rewardInfo = {
      recipient: rewardClaimInfo.recipient,
      last_claim_time: rewardClaimInfo.last_claim_time,
      cumulative_reward_amount: rewardClaimInfo.cumulative_reward_amount.amount,
      reward_proto_bytes: rewardClaimInfo.reward_proto_bytes,
      signatures: rewardClaimInfo.signatures,
    }
    return rewardInfo;
  }
  catch{
    const rewardInfo = {
      recipient: '',
      last_claim_time: '',
      cumulative_reward_amount: '0',
      reward_proto_bytes: '',
      signatures: [],
    }
    return rewardInfo;
  }
  
}

export async function unlockStakingReward(params: any){
  const res = await gatewayPost<any>(`v1/staking/unlockStakingReward`, params);
  return res;
}

export const getStakingRewardDetails = (reqParams: any): Promise<StakingRewardClaimInfo> =>
  axios
    .get(`${getGatewayUrl()}v1/staking/getStakingRewardDetails`, {
      params: {
        ...reqParams,
      },
    })
    .then(res => {
      const rewardClaimInfo = res.data.detail;
      const rewardInfo = {
        recipient: rewardClaimInfo.recipient,
        last_claim_time: rewardClaimInfo.last_claim_time,
        cumulative_reward_amount: rewardClaimInfo.cumulative_reward_amount.amount,
        reward_proto_bytes: rewardClaimInfo.reward_proto_bytes,
        signatures: rewardClaimInfo.signatures,
      }
      return rewardInfo;
      return res.data;
    })
    .catch(e => {
      console.log("error=>", e);
    });