export const EMPTY_ADDRESS = "0x0000000000000000000000000000000000000000";
export const RATE_PRECISION = 2;
export const RATE_BASE = 10 ** RATE_PRECISION;

export const CANDIDATE_STATUS = ["Null", "Unbonded", "Unbonding", "Bonded"];

export const PARAM_NAMES = [
  "ProposalDeposit",
  "GovernVoteTimeout",
  "SlashTimeout",
  "MinValidatorNum",
  "MaxValidatorNum",
  "MinStakeInPool",
  "AdvanceNoticePeriod",
];

export const PROPOSAL_STATUS = { Voting: "1", Closed: "2" };

export const VOTE_TYPE = {
  Yes: 1,
  No: 2,
  Abstain: 3,
};

export const STAKE_TYPE = "Delegate";
export const UNBOND_TYPE = "Unbond";
export const WITHDRAW_TYPE = "Withdraw";

export type TRANS_MODAL_TYPE = "Delegate" | "Unbond" | "Withdraw";

export const CELR = "CELR";

export type UnlockType = "init" | "waiting";
export type ClaimType = "init" | "claiming" | "suceess";

export const UNLOCK_INIT = "init";
export const UNLOCK_WAITING = "waiting";
export const CLAIM_INIT = "init";
export const CLAIM_WAITING = "claiming";
export const CLAIM_SUCCESS = "suceess";

export const BLOCK_TIME = Number(process.env.REACT_APP_BLOCK_TIME || 0);
