import { Modal, Button } from "antd";
import { useAppSelector } from "../../redux/store";
import "./index.less";
import warningImg from "../../assets/images/warning.png";

interface IProps {
  chainId: number;
  visible: boolean;
  closeMethod: () => void;
  afterSwitchMethod: () => void;
}

const SwitchChainModal = (props: IProps) => {
  const { configs } = useAppSelector(state => state.globalInfo);
  const { chainsList } = configs;

  const switchChainMethod = async () => {
    try {
      console.log({ chainId: `0x${props.chainId.toString(16)}` });
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${props.chainId.toString(16)}` }],
      });
      props.closeMethod();
      if (props.afterSwitchMethod) {
        props.afterSwitchMethod();
      }
      // window.location.reload();
    } catch (e) {
      console.log("switch fails");
    }
  };

  return (
    <Modal title=" " className="switch-chain-modal" visible={props.visible} onCancel={props.closeMethod} footer={null}>
      <div className="switch-block">
        <div className="warning">
          <div className="img-block">
            <img src={warningImg} alt="" />
          </div>
          <div className="warning-message">
            Please switch to {chainsList?.find(item => item.id === Number(props.chainId))?.name} before interacting with
            Celer SGN.
          </div>
        </div>
        <div className="btn-block">
          <Button type="primary" block className="ok-btn" onClick={switchChainMethod}>
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchChainModal;
