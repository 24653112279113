// source: sgn/pegbridge/v1/pegbridge.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var cosmos_proto_cosmos_pb = require('../../../cosmos_proto/cosmos_pb.js');
goog.object.extend(proto, cosmos_proto_cosmos_pb);
var gogoproto_gogo_pb = require('../../../gogoproto/gogo_pb.js');
goog.object.extend(proto, gogoproto_gogo_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var sgn_common_v1_common_pb = require('../../../sgn/common/v1/common_pb.js');
goog.object.extend(proto, sgn_common_v1_common_pb);
goog.exportSymbol('proto.sgn.pegbridge.v1.BurnInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.ContractInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.DepositInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.FeeClaimInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.FeeSplit', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.MigrationInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.MintInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.MintOnChain', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.OrigPeggedPair', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.PairDeleteProposal', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.Params', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.PegConfig', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.PegProposal', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.PeggedOrigIndex', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.VaultMigration', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.VaultMigrationProposal', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.WithdrawInfo', null, global);
goog.exportSymbol('proto.sgn.pegbridge.v1.WithdrawOnChain', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.Params = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.Params.displayName = 'proto.sgn.pegbridge.v1.Params';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.ContractInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.ContractInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.ContractInfo.displayName = 'proto.sgn.pegbridge.v1.ContractInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.PegConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.pegbridge.v1.PegConfig.repeatedFields_, null);
};
goog.inherits(proto.sgn.pegbridge.v1.PegConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.PegConfig.displayName = 'proto.sgn.pegbridge.v1.PegConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.OrigPeggedPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.OrigPeggedPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.OrigPeggedPair.displayName = 'proto.sgn.pegbridge.v1.OrigPeggedPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.PeggedOrigIndex, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.PeggedOrigIndex.displayName = 'proto.sgn.pegbridge.v1.PeggedOrigIndex';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.FeeSplit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.FeeSplit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.FeeSplit.displayName = 'proto.sgn.pegbridge.v1.FeeSplit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.DepositInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.DepositInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.DepositInfo.displayName = 'proto.sgn.pegbridge.v1.DepositInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MintInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.pegbridge.v1.MintInfo.repeatedFields_, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MintInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MintInfo.displayName = 'proto.sgn.pegbridge.v1.MintInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.BurnInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.BurnInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.BurnInfo.displayName = 'proto.sgn.pegbridge.v1.BurnInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.WithdrawInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.pegbridge.v1.WithdrawInfo.repeatedFields_, null);
};
goog.inherits(proto.sgn.pegbridge.v1.WithdrawInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.WithdrawInfo.displayName = 'proto.sgn.pegbridge.v1.WithdrawInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.FeeClaimInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.FeeClaimInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.FeeClaimInfo.displayName = 'proto.sgn.pegbridge.v1.FeeClaimInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MintOnChain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MintOnChain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MintOnChain.displayName = 'proto.sgn.pegbridge.v1.MintOnChain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.WithdrawOnChain = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.WithdrawOnChain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.WithdrawOnChain.displayName = 'proto.sgn.pegbridge.v1.WithdrawOnChain';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MigrationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MigrationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MigrationInfo.displayName = 'proto.sgn.pegbridge.v1.MigrationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.VaultMigration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.pegbridge.v1.VaultMigration.repeatedFields_, null);
};
goog.inherits(proto.sgn.pegbridge.v1.VaultMigration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.VaultMigration.displayName = 'proto.sgn.pegbridge.v1.VaultMigration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.PegProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.PegProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.PegProposal.displayName = 'proto.sgn.pegbridge.v1.PegProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.PairDeleteProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.PairDeleteProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.PairDeleteProposal.displayName = 'proto.sgn.pegbridge.v1.PairDeleteProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.displayName = 'proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.displayName = 'proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.displayName = 'proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.VaultMigrationProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.VaultMigrationProposal.displayName = 'proto.sgn.pegbridge.v1.VaultMigrationProposal';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.Params.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.Params.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.Params} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.Params.toObject = function(includeInstance, msg) {
  var f, obj = {
    triggerSignCooldown: (f = msg.getTriggerSignCooldown()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.Params}
 */
proto.sgn.pegbridge.v1.Params.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.Params;
  return proto.sgn.pegbridge.v1.Params.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.Params}
 */
proto.sgn.pegbridge.v1.Params.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setTriggerSignCooldown(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.Params.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.Params.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriggerSignCooldown();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Duration trigger_sign_cooldown = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sgn.pegbridge.v1.Params.prototype.getTriggerSignCooldown = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sgn.pegbridge.v1.Params} returns this
*/
proto.sgn.pegbridge.v1.Params.prototype.setTriggerSignCooldown = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.Params} returns this
 */
proto.sgn.pegbridge.v1.Params.prototype.clearTriggerSignCooldown = function() {
  return this.setTriggerSignCooldown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.Params.prototype.hasTriggerSignCooldown = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.ContractInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.ContractInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.ContractInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    contract: (f = msg.getContract()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.ContractInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.ContractInfo;
  return proto.sgn.pegbridge.v1.ContractInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.ContractInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.ContractInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setContract(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.ContractInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.ContractInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.ContractInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContract();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional sgn.common.v1.ContractInfo contract = 1;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.getContract = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 1));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.ContractInfo} returns this
*/
proto.sgn.pegbridge.v1.ContractInfo.prototype.setContract = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.ContractInfo} returns this
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.clearContract = function() {
  return this.setContract(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.hasContract = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 version = 2;
 * @return {number}
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.ContractInfo} returns this
 */
proto.sgn.pegbridge.v1.ContractInfo.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.pegbridge.v1.PegConfig.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.PegConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.PegConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PegConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    peggedTokenBridgesList: jspb.Message.toObjectList(msg.getPeggedTokenBridgesList(),
    proto.sgn.pegbridge.v1.ContractInfo.toObject, includeInstance),
    originalTokenVaultsList: jspb.Message.toObjectList(msg.getOriginalTokenVaultsList(),
    proto.sgn.pegbridge.v1.ContractInfo.toObject, includeInstance),
    origPeggedPairsList: jspb.Message.toObjectList(msg.getOrigPeggedPairsList(),
    proto.sgn.pegbridge.v1.OrigPeggedPair.toObject, includeInstance),
    feeSplitGlobal: (f = msg.getFeeSplitGlobal()) && proto.sgn.pegbridge.v1.FeeSplit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.PegConfig}
 */
proto.sgn.pegbridge.v1.PegConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.PegConfig;
  return proto.sgn.pegbridge.v1.PegConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.PegConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.PegConfig}
 */
proto.sgn.pegbridge.v1.PegConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sgn.pegbridge.v1.ContractInfo;
      reader.readMessage(value,proto.sgn.pegbridge.v1.ContractInfo.deserializeBinaryFromReader);
      msg.addPeggedTokenBridges(value);
      break;
    case 2:
      var value = new proto.sgn.pegbridge.v1.ContractInfo;
      reader.readMessage(value,proto.sgn.pegbridge.v1.ContractInfo.deserializeBinaryFromReader);
      msg.addOriginalTokenVaults(value);
      break;
    case 3:
      var value = new proto.sgn.pegbridge.v1.OrigPeggedPair;
      reader.readMessage(value,proto.sgn.pegbridge.v1.OrigPeggedPair.deserializeBinaryFromReader);
      msg.addOrigPeggedPairs(value);
      break;
    case 4:
      var value = new proto.sgn.pegbridge.v1.FeeSplit;
      reader.readMessage(value,proto.sgn.pegbridge.v1.FeeSplit.deserializeBinaryFromReader);
      msg.setFeeSplitGlobal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.PegConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.PegConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PegConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeggedTokenBridgesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.sgn.pegbridge.v1.ContractInfo.serializeBinaryToWriter
    );
  }
  f = message.getOriginalTokenVaultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.sgn.pegbridge.v1.ContractInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrigPeggedPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.sgn.pegbridge.v1.OrigPeggedPair.serializeBinaryToWriter
    );
  }
  f = message.getFeeSplitGlobal();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.sgn.pegbridge.v1.FeeSplit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ContractInfo pegged_token_bridges = 1;
 * @return {!Array<!proto.sgn.pegbridge.v1.ContractInfo>}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.getPeggedTokenBridgesList = function() {
  return /** @type{!Array<!proto.sgn.pegbridge.v1.ContractInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.pegbridge.v1.ContractInfo, 1));
};


/**
 * @param {!Array<!proto.sgn.pegbridge.v1.ContractInfo>} value
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
*/
proto.sgn.pegbridge.v1.PegConfig.prototype.setPeggedTokenBridgesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.sgn.pegbridge.v1.ContractInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.pegbridge.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.addPeggedTokenBridges = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sgn.pegbridge.v1.ContractInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.clearPeggedTokenBridgesList = function() {
  return this.setPeggedTokenBridgesList([]);
};


/**
 * repeated ContractInfo original_token_vaults = 2;
 * @return {!Array<!proto.sgn.pegbridge.v1.ContractInfo>}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.getOriginalTokenVaultsList = function() {
  return /** @type{!Array<!proto.sgn.pegbridge.v1.ContractInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.pegbridge.v1.ContractInfo, 2));
};


/**
 * @param {!Array<!proto.sgn.pegbridge.v1.ContractInfo>} value
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
*/
proto.sgn.pegbridge.v1.PegConfig.prototype.setOriginalTokenVaultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.sgn.pegbridge.v1.ContractInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.pegbridge.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.addOriginalTokenVaults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sgn.pegbridge.v1.ContractInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.clearOriginalTokenVaultsList = function() {
  return this.setOriginalTokenVaultsList([]);
};


/**
 * repeated OrigPeggedPair orig_pegged_pairs = 3;
 * @return {!Array<!proto.sgn.pegbridge.v1.OrigPeggedPair>}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.getOrigPeggedPairsList = function() {
  return /** @type{!Array<!proto.sgn.pegbridge.v1.OrigPeggedPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.pegbridge.v1.OrigPeggedPair, 3));
};


/**
 * @param {!Array<!proto.sgn.pegbridge.v1.OrigPeggedPair>} value
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
*/
proto.sgn.pegbridge.v1.PegConfig.prototype.setOrigPeggedPairsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.pegbridge.v1.OrigPeggedPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.addOrigPeggedPairs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.pegbridge.v1.OrigPeggedPair, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.clearOrigPeggedPairsList = function() {
  return this.setOrigPeggedPairsList([]);
};


/**
 * optional FeeSplit fee_split_global = 4;
 * @return {?proto.sgn.pegbridge.v1.FeeSplit}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.getFeeSplitGlobal = function() {
  return /** @type{?proto.sgn.pegbridge.v1.FeeSplit} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.FeeSplit, 4));
};


/**
 * @param {?proto.sgn.pegbridge.v1.FeeSplit|undefined} value
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
*/
proto.sgn.pegbridge.v1.PegConfig.prototype.setFeeSplitGlobal = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.PegConfig} returns this
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.clearFeeSplitGlobal = function() {
  return this.setFeeSplitGlobal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.PegConfig.prototype.hasFeeSplitGlobal = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.OrigPeggedPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.OrigPeggedPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    orig: (f = msg.getOrig()) && sgn_common_v1_common_pb.ERC20Token.toObject(includeInstance, f),
    pegged: (f = msg.getPegged()) && sgn_common_v1_common_pb.ERC20Token.toObject(includeInstance, f),
    mintFeePips: jspb.Message.getFieldWithDefault(msg, 3, 0),
    maxMintFee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    burnFeePips: jspb.Message.getFieldWithDefault(msg, 5, 0),
    maxBurnFee: jspb.Message.getFieldWithDefault(msg, 6, ""),
    supplyCap: jspb.Message.getFieldWithDefault(msg, 7, ""),
    vaultVersion: jspb.Message.getFieldWithDefault(msg, 8, 0),
    bridgeVersion: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mintFeeSplitOverride: (f = msg.getMintFeeSplitOverride()) && proto.sgn.pegbridge.v1.FeeSplit.toObject(includeInstance, f),
    burnFeeSplitOverride: (f = msg.getBurnFeeSplitOverride()) && proto.sgn.pegbridge.v1.FeeSplit.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.OrigPeggedPair;
  return proto.sgn.pegbridge.v1.OrigPeggedPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.OrigPeggedPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_common_v1_common_pb.ERC20Token;
      reader.readMessage(value,sgn_common_v1_common_pb.ERC20Token.deserializeBinaryFromReader);
      msg.setOrig(value);
      break;
    case 2:
      var value = new sgn_common_v1_common_pb.ERC20Token;
      reader.readMessage(value,sgn_common_v1_common_pb.ERC20Token.deserializeBinaryFromReader);
      msg.setPegged(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMintFeePips(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxMintFee(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBurnFeePips(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaxBurnFee(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSupplyCap(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVaultVersion(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBridgeVersion(value);
      break;
    case 10:
      var value = new proto.sgn.pegbridge.v1.FeeSplit;
      reader.readMessage(value,proto.sgn.pegbridge.v1.FeeSplit.deserializeBinaryFromReader);
      msg.setMintFeeSplitOverride(value);
      break;
    case 11:
      var value = new proto.sgn.pegbridge.v1.FeeSplit;
      reader.readMessage(value,proto.sgn.pegbridge.v1.FeeSplit.deserializeBinaryFromReader);
      msg.setBurnFeeSplitOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.OrigPeggedPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.OrigPeggedPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrig();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_common_v1_common_pb.ERC20Token.serializeBinaryToWriter
    );
  }
  f = message.getPegged();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sgn_common_v1_common_pb.ERC20Token.serializeBinaryToWriter
    );
  }
  f = message.getMintFeePips();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMaxMintFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBurnFeePips();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMaxBurnFee();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSupplyCap();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getVaultVersion();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getBridgeVersion();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getMintFeeSplitOverride();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.sgn.pegbridge.v1.FeeSplit.serializeBinaryToWriter
    );
  }
  f = message.getBurnFeeSplitOverride();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.sgn.pegbridge.v1.FeeSplit.serializeBinaryToWriter
    );
  }
};


/**
 * optional sgn.common.v1.ERC20Token orig = 1;
 * @return {?proto.sgn.common.v1.ERC20Token}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getOrig = function() {
  return /** @type{?proto.sgn.common.v1.ERC20Token} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ERC20Token, 1));
};


/**
 * @param {?proto.sgn.common.v1.ERC20Token|undefined} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
*/
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setOrig = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.clearOrig = function() {
  return this.setOrig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.hasOrig = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sgn.common.v1.ERC20Token pegged = 2;
 * @return {?proto.sgn.common.v1.ERC20Token}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getPegged = function() {
  return /** @type{?proto.sgn.common.v1.ERC20Token} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ERC20Token, 2));
};


/**
 * @param {?proto.sgn.common.v1.ERC20Token|undefined} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
*/
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setPegged = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.clearPegged = function() {
  return this.setPegged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.hasPegged = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 mint_fee_pips = 3;
 * @return {number}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getMintFeePips = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setMintFeePips = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string max_mint_fee = 4;
 * @return {string}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getMaxMintFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setMaxMintFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint32 burn_fee_pips = 5;
 * @return {number}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getBurnFeePips = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setBurnFeePips = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string max_burn_fee = 6;
 * @return {string}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getMaxBurnFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setMaxBurnFee = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string supply_cap = 7;
 * @return {string}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getSupplyCap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setSupplyCap = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional uint32 vault_version = 8;
 * @return {number}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getVaultVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setVaultVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 bridge_version = 9;
 * @return {number}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getBridgeVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setBridgeVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional FeeSplit mint_fee_split_override = 10;
 * @return {?proto.sgn.pegbridge.v1.FeeSplit}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getMintFeeSplitOverride = function() {
  return /** @type{?proto.sgn.pegbridge.v1.FeeSplit} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.FeeSplit, 10));
};


/**
 * @param {?proto.sgn.pegbridge.v1.FeeSplit|undefined} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
*/
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setMintFeeSplitOverride = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.clearMintFeeSplitOverride = function() {
  return this.setMintFeeSplitOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.hasMintFeeSplitOverride = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional FeeSplit burn_fee_split_override = 11;
 * @return {?proto.sgn.pegbridge.v1.FeeSplit}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.getBurnFeeSplitOverride = function() {
  return /** @type{?proto.sgn.pegbridge.v1.FeeSplit} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.FeeSplit, 11));
};


/**
 * @param {?proto.sgn.pegbridge.v1.FeeSplit|undefined} value
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
*/
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.setBurnFeeSplitOverride = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.OrigPeggedPair} returns this
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.clearBurnFeeSplitOverride = function() {
  return this.setBurnFeeSplitOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.OrigPeggedPair.prototype.hasBurnFeeSplitOverride = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.PeggedOrigIndex.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.PeggedOrigIndex} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.toObject = function(includeInstance, msg) {
  var f, obj = {
    pegged: (f = msg.getPegged()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f),
    orig: (f = msg.getOrig()) && sgn_common_v1_common_pb.ContractInfo.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.PeggedOrigIndex}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.PeggedOrigIndex;
  return proto.sgn.pegbridge.v1.PeggedOrigIndex.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.PeggedOrigIndex} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.PeggedOrigIndex}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setPegged(value);
      break;
    case 2:
      var value = new sgn_common_v1_common_pb.ContractInfo;
      reader.readMessage(value,sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
      msg.setOrig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.PeggedOrigIndex.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.PeggedOrigIndex} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPegged();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
  f = message.getOrig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter
    );
  }
};


/**
 * optional sgn.common.v1.ContractInfo pegged = 1;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.getPegged = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 1));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.PeggedOrigIndex} returns this
*/
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.setPegged = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.PeggedOrigIndex} returns this
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.clearPegged = function() {
  return this.setPegged(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.hasPegged = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sgn.common.v1.ContractInfo orig = 2;
 * @return {?proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.getOrig = function() {
  return /** @type{?proto.sgn.common.v1.ContractInfo} */ (
    jspb.Message.getWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 2));
};


/**
 * @param {?proto.sgn.common.v1.ContractInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.PeggedOrigIndex} returns this
*/
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.setOrig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.PeggedOrigIndex} returns this
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.clearOrig = function() {
  return this.setOrig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.PeggedOrigIndex.prototype.hasOrig = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.FeeSplit.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.FeeSplit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.FeeSplit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.FeeSplit.toObject = function(includeInstance, msg) {
  var f, obj = {
    celerTreasuryFeePerc: jspb.Message.getFieldWithDefault(msg, 1, 0),
    celerTreasuryAddr: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.FeeSplit}
 */
proto.sgn.pegbridge.v1.FeeSplit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.FeeSplit;
  return proto.sgn.pegbridge.v1.FeeSplit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.FeeSplit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.FeeSplit}
 */
proto.sgn.pegbridge.v1.FeeSplit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCelerTreasuryFeePerc(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCelerTreasuryAddr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.FeeSplit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.FeeSplit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.FeeSplit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.FeeSplit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCelerTreasuryFeePerc();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCelerTreasuryAddr();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 celer_treasury_fee_perc = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.FeeSplit.prototype.getCelerTreasuryFeePerc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.FeeSplit} returns this
 */
proto.sgn.pegbridge.v1.FeeSplit.prototype.setCelerTreasuryFeePerc = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string celer_treasury_addr = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.FeeSplit.prototype.getCelerTreasuryAddr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.FeeSplit} returns this
 */
proto.sgn.pegbridge.v1.FeeSplit.prototype.setCelerTreasuryAddr = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.DepositInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.DepositInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.DepositInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    depositId: msg.getDepositId_asB64(),
    mintId: msg.getMintId_asB64(),
    vaultVersion: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.DepositInfo}
 */
proto.sgn.pegbridge.v1.DepositInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.DepositInfo;
  return proto.sgn.pegbridge.v1.DepositInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.DepositInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.DepositInfo}
 */
proto.sgn.pegbridge.v1.DepositInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDepositId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMintId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVaultVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.DepositInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.DepositInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.DepositInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDepositId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getMintId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getVaultVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.DepositInfo} returns this
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes deposit_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getDepositId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes deposit_id = 2;
 * This is a type-conversion wrapper around `getDepositId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getDepositId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDepositId()));
};


/**
 * optional bytes deposit_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDepositId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getDepositId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDepositId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.DepositInfo} returns this
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.setDepositId = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes mint_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getMintId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes mint_id = 3;
 * This is a type-conversion wrapper around `getMintId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getMintId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMintId()));
};


/**
 * optional bytes mint_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMintId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getMintId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMintId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.DepositInfo} returns this
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.setMintId = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional uint32 vault_version = 4;
 * @return {number}
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.getVaultVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.DepositInfo} returns this
 */
proto.sgn.pegbridge.v1.DepositInfo.prototype.setVaultVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.pegbridge.v1.MintInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.MintInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.MintInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MintInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    mintProtoBytes: msg.getMintProtoBytes_asB64(),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    sgn_common_v1_common_pb.Signature.toObject, includeInstance),
    baseFee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    percentageFee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    lastReqTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    bridgeVersion: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MintInfo}
 */
proto.sgn.pegbridge.v1.MintInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MintInfo;
  return proto.sgn.pegbridge.v1.MintInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MintInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MintInfo}
 */
proto.sgn.pegbridge.v1.MintInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMintProtoBytes(value);
      break;
    case 3:
      var value = new sgn_common_v1_common_pb.Signature;
      reader.readMessage(value,sgn_common_v1_common_pb.Signature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPercentageFee(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastReqTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBridgeVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MintInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MintInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MintInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getMintProtoBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      sgn_common_v1_common_pb.Signature.serializeBinaryToWriter
    );
  }
  f = message.getBaseFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPercentageFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLastReqTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getBridgeVersion();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes mint_proto_bytes = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getMintProtoBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes mint_proto_bytes = 2;
 * This is a type-conversion wrapper around `getMintProtoBytes()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getMintProtoBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMintProtoBytes()));
};


/**
 * optional bytes mint_proto_bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMintProtoBytes()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getMintProtoBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMintProtoBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setMintProtoBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated sgn.common.v1.Signature signatures = 3;
 * @return {!Array<!proto.sgn.common.v1.Signature>}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.sgn.common.v1.Signature>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_common_v1_common_pb.Signature, 3));
};


/**
 * @param {!Array<!proto.sgn.common.v1.Signature>} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
*/
proto.sgn.pegbridge.v1.MintInfo.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.common.v1.Signature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.common.v1.Signature}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.common.v1.Signature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};


/**
 * optional string base_fee = 4;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getBaseFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setBaseFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string percentage_fee = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getPercentageFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setPercentageFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool success = 6;
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 last_req_time = 7;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getLastReqTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setLastReqTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 bridge_version = 8;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.getBridgeVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MintInfo} returns this
 */
proto.sgn.pegbridge.v1.MintInfo.prototype.setBridgeVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.BurnInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.BurnInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.BurnInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    burnId: msg.getBurnId_asB64(),
    withdrawId: msg.getWithdrawId_asB64(),
    mintId: msg.getMintId_asB64(),
    bridgeVersion: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.BurnInfo}
 */
proto.sgn.pegbridge.v1.BurnInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.BurnInfo;
  return proto.sgn.pegbridge.v1.BurnInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.BurnInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.BurnInfo}
 */
proto.sgn.pegbridge.v1.BurnInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBurnId(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWithdrawId(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setMintId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBridgeVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.BurnInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.BurnInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.BurnInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getBurnId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getWithdrawId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getMintId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getBridgeVersion();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.BurnInfo} returns this
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes burn_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getBurnId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes burn_id = 2;
 * This is a type-conversion wrapper around `getBurnId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getBurnId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBurnId()));
};


/**
 * optional bytes burn_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBurnId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getBurnId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBurnId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.BurnInfo} returns this
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.setBurnId = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes withdraw_id = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getWithdrawId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes withdraw_id = 3;
 * This is a type-conversion wrapper around `getWithdrawId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getWithdrawId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWithdrawId()));
};


/**
 * optional bytes withdraw_id = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWithdrawId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getWithdrawId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWithdrawId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.BurnInfo} returns this
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.setWithdrawId = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes mint_id = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getMintId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes mint_id = 4;
 * This is a type-conversion wrapper around `getMintId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getMintId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getMintId()));
};


/**
 * optional bytes mint_id = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getMintId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getMintId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getMintId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.BurnInfo} returns this
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.setMintId = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional uint32 bridge_version = 5;
 * @return {number}
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.getBridgeVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.BurnInfo} returns this
 */
proto.sgn.pegbridge.v1.BurnInfo.prototype.setBridgeVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.pegbridge.v1.WithdrawInfo.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.WithdrawInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.WithdrawInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.WithdrawInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    withdrawProtoBytes: msg.getWithdrawProtoBytes_asB64(),
    signaturesList: jspb.Message.toObjectList(msg.getSignaturesList(),
    sgn_common_v1_common_pb.Signature.toObject, includeInstance),
    baseFee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    percentageFee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    success: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    lastReqTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    vaultVersion: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.WithdrawInfo;
  return proto.sgn.pegbridge.v1.WithdrawInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.WithdrawInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWithdrawProtoBytes(value);
      break;
    case 3:
      var value = new sgn_common_v1_common_pb.Signature;
      reader.readMessage(value,sgn_common_v1_common_pb.Signature.deserializeBinaryFromReader);
      msg.addSignatures(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPercentageFee(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastReqTime(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVaultVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.WithdrawInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.WithdrawInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.WithdrawInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getWithdrawProtoBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSignaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      sgn_common_v1_common_pb.Signature.serializeBinaryToWriter
    );
  }
  f = message.getBaseFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPercentageFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getLastReqTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getVaultVersion();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bytes withdraw_proto_bytes = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getWithdrawProtoBytes = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes withdraw_proto_bytes = 2;
 * This is a type-conversion wrapper around `getWithdrawProtoBytes()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getWithdrawProtoBytes_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWithdrawProtoBytes()));
};


/**
 * optional bytes withdraw_proto_bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWithdrawProtoBytes()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getWithdrawProtoBytes_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWithdrawProtoBytes()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setWithdrawProtoBytes = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated sgn.common.v1.Signature signatures = 3;
 * @return {!Array<!proto.sgn.common.v1.Signature>}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getSignaturesList = function() {
  return /** @type{!Array<!proto.sgn.common.v1.Signature>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_common_v1_common_pb.Signature, 3));
};


/**
 * @param {!Array<!proto.sgn.common.v1.Signature>} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
*/
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setSignaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.sgn.common.v1.Signature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.common.v1.Signature}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.addSignatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.common.v1.Signature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.clearSignaturesList = function() {
  return this.setSignaturesList([]);
};


/**
 * optional string base_fee = 4;
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getBaseFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setBaseFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string percentage_fee = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getPercentageFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setPercentageFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool success = 6;
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int64 last_req_time = 7;
 * @return {number}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getLastReqTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setLastReqTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 vault_version = 8;
 * @return {number}
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.getVaultVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawInfo} returns this
 */
proto.sgn.pegbridge.v1.WithdrawInfo.prototype.setVaultVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.FeeClaimInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.FeeClaimInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    withdrawId: msg.getWithdrawId_asB64(),
    vaultVersion: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.FeeClaimInfo}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.FeeClaimInfo;
  return proto.sgn.pegbridge.v1.FeeClaimInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.FeeClaimInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.FeeClaimInfo}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setWithdrawId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVaultVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.FeeClaimInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.FeeClaimInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWithdrawId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getVaultVersion();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional bytes withdraw_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.getWithdrawId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes withdraw_id = 1;
 * This is a type-conversion wrapper around `getWithdrawId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.getWithdrawId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getWithdrawId()));
};


/**
 * optional bytes withdraw_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWithdrawId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.getWithdrawId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getWithdrawId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.FeeClaimInfo} returns this
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.setWithdrawId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional uint32 vault_version = 2;
 * @return {number}
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.getVaultVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.FeeClaimInfo} returns this
 */
proto.sgn.pegbridge.v1.FeeClaimInfo.prototype.setVaultVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.MintOnChain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.MintOnChain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MintOnChain.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: msg.getToken_asB64(),
    account: msg.getAccount_asB64(),
    amount: msg.getAmount_asB64(),
    depositor: msg.getDepositor_asB64(),
    refChainId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    refId: msg.getRefId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MintOnChain}
 */
proto.sgn.pegbridge.v1.MintOnChain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MintOnChain;
  return proto.sgn.pegbridge.v1.MintOnChain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MintOnChain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MintOnChain}
 */
proto.sgn.pegbridge.v1.MintOnChain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAccount(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setDepositor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRefChainId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRefId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MintOnChain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MintOnChain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MintOnChain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAccount_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getAmount_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getDepositor_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getRefChainId();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getRefId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional bytes token = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getToken = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes token = 1;
 * This is a type-conversion wrapper around `getToken()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getToken_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getToken()));
};


/**
 * optional bytes token = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToken()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getToken_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getToken()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MintOnChain} returns this
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.setToken = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes account = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getAccount = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes account = 2;
 * This is a type-conversion wrapper around `getAccount()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getAccount_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAccount()));
};


/**
 * optional bytes account = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAccount()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getAccount_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAccount()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MintOnChain} returns this
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.setAccount = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes amount = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getAmount = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes amount = 3;
 * This is a type-conversion wrapper around `getAmount()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getAmount_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAmount()));
};


/**
 * optional bytes amount = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAmount()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getAmount_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAmount()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MintOnChain} returns this
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.setAmount = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes depositor = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getDepositor = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes depositor = 4;
 * This is a type-conversion wrapper around `getDepositor()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getDepositor_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getDepositor()));
};


/**
 * optional bytes depositor = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getDepositor()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getDepositor_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getDepositor()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MintOnChain} returns this
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.setDepositor = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional uint64 ref_chain_id = 5;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getRefChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MintOnChain} returns this
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.setRefChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bytes ref_id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getRefId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes ref_id = 6;
 * This is a type-conversion wrapper around `getRefId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getRefId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRefId()));
};


/**
 * optional bytes ref_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRefId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.getRefId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRefId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MintOnChain} returns this
 */
proto.sgn.pegbridge.v1.MintOnChain.prototype.setRefId = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.WithdrawOnChain.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.WithdrawOnChain} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: msg.getToken_asB64(),
    receiver: msg.getReceiver_asB64(),
    amount: msg.getAmount_asB64(),
    burnAccount: msg.getBurnAccount_asB64(),
    refChainId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    refId: msg.getRefId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.WithdrawOnChain;
  return proto.sgn.pegbridge.v1.WithdrawOnChain.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.WithdrawOnChain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setReceiver(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAmount(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBurnAccount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRefChainId(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setRefId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.WithdrawOnChain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.WithdrawOnChain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getReceiver_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getAmount_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
  f = message.getBurnAccount_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
  f = message.getRefChainId();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getRefId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      6,
      f
    );
  }
};


/**
 * optional bytes token = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getToken = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes token = 1;
 * This is a type-conversion wrapper around `getToken()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getToken_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getToken()));
};


/**
 * optional bytes token = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToken()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getToken_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getToken()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain} returns this
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.setToken = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes receiver = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getReceiver = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes receiver = 2;
 * This is a type-conversion wrapper around `getReceiver()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getReceiver_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getReceiver()));
};


/**
 * optional bytes receiver = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiver()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getReceiver_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getReceiver()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain} returns this
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.setReceiver = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional bytes amount = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getAmount = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes amount = 3;
 * This is a type-conversion wrapper around `getAmount()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getAmount_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAmount()));
};


/**
 * optional bytes amount = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAmount()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getAmount_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAmount()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain} returns this
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.setAmount = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * optional bytes burn_account = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getBurnAccount = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes burn_account = 4;
 * This is a type-conversion wrapper around `getBurnAccount()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getBurnAccount_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBurnAccount()));
};


/**
 * optional bytes burn_account = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBurnAccount()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getBurnAccount_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBurnAccount()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain} returns this
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.setBurnAccount = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};


/**
 * optional uint64 ref_chain_id = 5;
 * @return {number}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getRefChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain} returns this
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.setRefChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bytes ref_id = 6;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getRefId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes ref_id = 6;
 * This is a type-conversion wrapper around `getRefId()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getRefId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getRefId()));
};


/**
 * optional bytes ref_id = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRefId()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.getRefId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getRefId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.WithdrawOnChain} returns this
 */
proto.sgn.pegbridge.v1.WithdrawOnChain.prototype.setRefId = function(value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.MigrationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.MigrationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MigrationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    token: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromVersion: jspb.Message.getFieldWithDefault(msg, 3, 0),
    toVersion: jspb.Message.getFieldWithDefault(msg, 4, 0),
    toAddress: jspb.Message.getFieldWithDefault(msg, 5, ""),
    executor: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo}
 */
proto.sgn.pegbridge.v1.MigrationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MigrationInfo;
  return proto.sgn.pegbridge.v1.MigrationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MigrationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo}
 */
proto.sgn.pegbridge.v1.MigrationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFromVersion(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setToVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setToAddress(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MigrationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MigrationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MigrationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromVersion();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getToVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getToAddress();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getExecutor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo} returns this
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string token = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo} returns this
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 from_version = 3;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.getFromVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo} returns this
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.setFromVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 to_version = 4;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.getToVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo} returns this
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.setToVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string to_address = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.getToAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo} returns this
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.setToAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string executor = 6;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.getExecutor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MigrationInfo} returns this
 */
proto.sgn.pegbridge.v1.MigrationInfo.prototype.setExecutor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.pegbridge.v1.VaultMigration.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.VaultMigration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.VaultMigration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.VaultMigration.toObject = function(includeInstance, msg) {
  var f, obj = {
    migrationInfo: (f = msg.getMigrationInfo()) && proto.sgn.pegbridge.v1.MigrationInfo.toObject(includeInstance, f),
    deadline: jspb.Message.getFieldWithDefault(msg, 7, 0),
    withdrawIdsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.VaultMigration}
 */
proto.sgn.pegbridge.v1.VaultMigration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.VaultMigration;
  return proto.sgn.pegbridge.v1.VaultMigration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.VaultMigration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.VaultMigration}
 */
proto.sgn.pegbridge.v1.VaultMigration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.sgn.pegbridge.v1.MigrationInfo;
      reader.readMessage(value,proto.sgn.pegbridge.v1.MigrationInfo.deserializeBinaryFromReader);
      msg.setMigrationInfo(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeadline(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addWithdrawIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.VaultMigration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.VaultMigration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.VaultMigration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMigrationInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.sgn.pegbridge.v1.MigrationInfo.serializeBinaryToWriter
    );
  }
  f = message.getDeadline();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getWithdrawIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
};


/**
 * optional MigrationInfo migration_info = 1;
 * @return {?proto.sgn.pegbridge.v1.MigrationInfo}
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.getMigrationInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.MigrationInfo} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.MigrationInfo, 1));
};


/**
 * @param {?proto.sgn.pegbridge.v1.MigrationInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigration} returns this
*/
proto.sgn.pegbridge.v1.VaultMigration.prototype.setMigrationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.VaultMigration} returns this
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.clearMigrationInfo = function() {
  return this.setMigrationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.hasMigrationInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 deadline = 7;
 * @return {number}
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.getDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigration} returns this
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.setDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated string withdraw_ids = 8;
 * @return {!Array<string>}
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.getWithdrawIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigration} returns this
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.setWithdrawIdsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.sgn.pegbridge.v1.VaultMigration} returns this
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.addWithdrawIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.pegbridge.v1.VaultMigration} returns this
 */
proto.sgn.pegbridge.v1.VaultMigration.prototype.clearWithdrawIdsList = function() {
  return this.setWithdrawIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.PegProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.PegProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PegProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pegConfig: (f = msg.getPegConfig()) && proto.sgn.pegbridge.v1.PegConfig.toObject(includeInstance, f),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.PegProposal}
 */
proto.sgn.pegbridge.v1.PegProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.PegProposal;
  return proto.sgn.pegbridge.v1.PegProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.PegProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.PegProposal}
 */
proto.sgn.pegbridge.v1.PegProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.sgn.pegbridge.v1.PegConfig;
      reader.readMessage(value,proto.sgn.pegbridge.v1.PegConfig.deserializeBinaryFromReader);
      msg.setPegConfig(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.PegProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.PegProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PegProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPegConfig();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sgn.pegbridge.v1.PegConfig.serializeBinaryToWriter
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PegProposal} returns this
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PegProposal} returns this
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional PegConfig peg_config = 3;
 * @return {?proto.sgn.pegbridge.v1.PegConfig}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.getPegConfig = function() {
  return /** @type{?proto.sgn.pegbridge.v1.PegConfig} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.PegConfig, 3));
};


/**
 * @param {?proto.sgn.pegbridge.v1.PegConfig|undefined} value
 * @return {!proto.sgn.pegbridge.v1.PegProposal} returns this
*/
proto.sgn.pegbridge.v1.PegProposal.prototype.setPegConfig = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.PegProposal} returns this
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.clearPegConfig = function() {
  return this.setPegConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.hasPegConfig = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string deposit = 4;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PegProposal} returns this
 */
proto.sgn.pegbridge.v1.PegProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.PairDeleteProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.PairDeleteProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pairToDelete: (f = msg.getPairToDelete()) && proto.sgn.pegbridge.v1.OrigPeggedPair.toObject(includeInstance, f),
    deposit: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.PairDeleteProposal;
  return proto.sgn.pegbridge.v1.PairDeleteProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.PairDeleteProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.sgn.pegbridge.v1.OrigPeggedPair;
      reader.readMessage(value,proto.sgn.pegbridge.v1.OrigPeggedPair.deserializeBinaryFromReader);
      msg.setPairToDelete(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.PairDeleteProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.PairDeleteProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPairToDelete();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sgn.pegbridge.v1.OrigPeggedPair.serializeBinaryToWriter
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrigPeggedPair pair_to_delete = 3;
 * @return {?proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.getPairToDelete = function() {
  return /** @type{?proto.sgn.pegbridge.v1.OrigPeggedPair} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.OrigPeggedPair, 3));
};


/**
 * @param {?proto.sgn.pegbridge.v1.OrigPeggedPair|undefined} value
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal} returns this
*/
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.setPairToDelete = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.clearPairToDelete = function() {
  return this.setPairToDelete(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.hasPairToDelete = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string deposit = 4;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PairDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PairDeleteProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chainId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal;
  return proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 chain_id = 3;
 * @return {number}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string deposit = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.OriginalTokenVaultDeleteProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    chainId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    version: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal;
  return proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setChainId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setVersion(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getVersion();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint64 chain_id = 3;
 * @return {number}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.getChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.setChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 version = 4;
 * @return {number}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.getVersion = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.setVersion = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string deposit = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal} returns this
 */
proto.sgn.pegbridge.v1.PeggedTokenBridgeDeleteProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pair: (f = msg.getPair()) && proto.sgn.pegbridge.v1.OrigPeggedPair.toObject(includeInstance, f),
    totalSupply: jspb.Message.getFieldWithDefault(msg, 4, ""),
    deposit: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal;
  return proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.sgn.pegbridge.v1.OrigPeggedPair;
      reader.readMessage(value,proto.sgn.pegbridge.v1.OrigPeggedPair.deserializeBinaryFromReader);
      msg.setPair(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTotalSupply(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPair();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sgn.pegbridge.v1.OrigPeggedPair.serializeBinaryToWriter
    );
  }
  f = message.getTotalSupply();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} returns this
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} returns this
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional OrigPeggedPair pair = 3;
 * @return {?proto.sgn.pegbridge.v1.OrigPeggedPair}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.getPair = function() {
  return /** @type{?proto.sgn.pegbridge.v1.OrigPeggedPair} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.OrigPeggedPair, 3));
};


/**
 * @param {?proto.sgn.pegbridge.v1.OrigPeggedPair|undefined} value
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} returns this
*/
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.setPair = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} returns this
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.clearPair = function() {
  return this.setPair(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.hasPair = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string total_supply = 4;
 * @return {string}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.getTotalSupply = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} returns this
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.setTotalSupply = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string deposit = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal} returns this
 */
proto.sgn.pegbridge.v1.TotalSupplyUpdateProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.toObject = function(opt_includeInstance) {
  return proto.sgn.pegbridge.v1.VaultMigrationProposal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.sgn.pegbridge.v1.VaultMigrationProposal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    migrationInfo: (f = msg.getMigrationInfo()) && proto.sgn.pegbridge.v1.MigrationInfo.toObject(includeInstance, f),
    duration: jspb.Message.getFieldWithDefault(msg, 4, 0),
    deposit: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.VaultMigrationProposal;
  return proto.sgn.pegbridge.v1.VaultMigrationProposal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.VaultMigrationProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.sgn.pegbridge.v1.MigrationInfo;
      reader.readMessage(value,proto.sgn.pegbridge.v1.MigrationInfo.deserializeBinaryFromReader);
      msg.setMigrationInfo(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDuration(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeposit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.VaultMigrationProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.VaultMigrationProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMigrationInfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.sgn.pegbridge.v1.MigrationInfo.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal} returns this
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal} returns this
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional MigrationInfo migration_info = 3;
 * @return {?proto.sgn.pegbridge.v1.MigrationInfo}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.getMigrationInfo = function() {
  return /** @type{?proto.sgn.pegbridge.v1.MigrationInfo} */ (
    jspb.Message.getWrapperField(this, proto.sgn.pegbridge.v1.MigrationInfo, 3));
};


/**
 * @param {?proto.sgn.pegbridge.v1.MigrationInfo|undefined} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal} returns this
*/
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.setMigrationInfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal} returns this
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.clearMigrationInfo = function() {
  return this.setMigrationInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.hasMigrationInfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 duration = 4;
 * @return {number}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal} returns this
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string deposit = 5;
 * @return {string}
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.getDeposit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.VaultMigrationProposal} returns this
 */
proto.sgn.pegbridge.v1.VaultMigrationProposal.prototype.setDeposit = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.sgn.pegbridge.v1);
