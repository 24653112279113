import { useEffect, useState } from "react";
import { Card, Row, Col, Button, Avatar, Tooltip } from "antd";
import { useToggle, useAsync } from "react-use";
import { isMobile } from "react-device-detect";
import { BigNumber } from "ethers";
import { JsonRpcProvider } from "@ethersproject/providers";
import { RATE_BASE } from "../../constants";
import { ValidatorInfo, DelegatorInfoType } from "../../helpers/subscribe";
import { Viewer__factory } from "../../typechain/factories/Viewer__factory";
import { formatCelrDecimal, getTimeByBlockNum } from "../../helpers/format";
import userIcon from "../../assets/images/userIcon.png";
import DelegateModal from "../delegateModal";
import UnbondModal from "../unbondModal";
import WithdrawModal from "../withdrawModal";
import { getValInfo, ValDescInfo } from "../../api";
import useReadOnlyCustomContractLoader from "../../hooks/customReadyOnlyContractLoader";
import "./validator.less";
import tipIcon from "../../assets/images/toolTip.png";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { ModalName, openModal } from "../../redux/modalSlice";
import { setRefreshData } from "../../redux/transferSlice";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useConfigContext } from "../../providers/ConfigContextProvider";
import { getNetworkById } from "../../constants/network";

interface IProps {
  delegatorInfo: DelegatorInfoType;
}

export default function Validator(props: IProps): JSX.Element {
  const { valAddr, tokens, undelegationTokens, withdrawableUndelegationTokens } = props.delegatorInfo;
  const { viewerContractAddress } = useAppSelector(state => state.globalInfo);
  const { getRpcUrlByChainId } = useConfigContext();
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const viewerContract = useReadOnlyCustomContractLoader(jprovider, viewerContractAddress, Viewer__factory);
  const [delegateModalVisible, toggleDelegateModalVisible] = useToggle(false);
  const [unbondModalVisible, toggleUnbondModalVisible] = useToggle(false);
  const [withdrawModalVisible, toggleWithdrawModalVisible] = useToggle(false);
  const [validator, setValidator] = useState<ValidatorInfo>();
  const [valDescInfo, setValDescInfo] = useState<ValDescInfo>();
  const { transferInfo, globalInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;
  const { unbondingBlockNum } = globalInfo;
  const dispatch = useAppDispatch();
  const { chainId } = useWeb3Context();
  const helpUrl = "https://cbridge-docs.celer.network/reference/faq#what-is-the-commission-rate-in-sgn";

  const clickWithCheckChainId = method => {
    if (chainId && chainId !== Number(process.env.REACT_APP_NETWORK_ID)) {
      return dispatch(openModal(ModalName.switchChain));
    }
    return method();
  };

  useAsync(async () => {
    if (!viewerContract) {
      return;
    }
    try {
      const validatorInfo = await viewerContract.getValidatorInfo(valAddr);
      setValidator(validatorInfo);
    } catch (e) {
      console.log(e);
    }
  }, [viewerContract, refreshMyDelegations]);

  useEffect(() => {
    getValInfo(valAddr).then(res => {
      setValDescInfo(res.description);
    });
  }, [valAddr]);

  const commissionRate = validator?.commissionRate || BigNumber.from(0);
  const commissionRateNumber = commissionRate.toNumber();

  const renderMobileValidatorItem = () => {
    return (
      <div className="mobile-validator-item">
        <div className="avatar">
          <Avatar size={40} icon={<img src={valDescInfo?.details?.icon || userIcon} alt="userIcon" />} />
          <div className="avatar-info">
            <div>{valDescInfo?.moniker}</div>
            <div className="rate">
              {`${commissionRateNumber / RATE_BASE}%`}
              <span className="Commission">
                {" "}
                Commission
                <Tooltip
                  placement="top"
                  title={
                    <div className="toolsDetail">
                      This is the percentage of your staking rewards taken by this validator.
                      <br />
                      <a href={`${helpUrl}`} target="_blank" rel="noreferrer">
                        Learn More
                      </a>
                    </div>
                  }
                  color="#FFFFFF"
                  overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
                >
                  <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
        <div className="statke-info">
          <div className="stake-info-title">
            Your Delegated Stake
            <Tooltip
              placement="top"
              title={
                <div className="toolsDetail">
                  This is your stake currently delegated with this validator. Note that your unbonding stake or
                  withdrawable stake is not included.
                </div>
              }
              color="#FFFFFF"
              overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
            >
              <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
            </Tooltip>
          </div>
          <div className="stake-info-value">{tokens.eq(0) ? "--" : `${formatCelrDecimal(tokens, 6)}`}</div>
        </div>
        <div className="statke-info">
          <div className="stake-info-title">
            Your Unbonding Stake
            <Tooltip
              placement="top"
              title={
                <div className="toolsDetail">
                  This is your stake amount currently in the unbonding process. The unbonding process takes{" "}
                  {getTimeByBlockNum(unbondingBlockNum)}, after which it will become withdrawable.
                </div>
              }
              color="#FFFFFF"
              overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
            >
              <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
            </Tooltip>
          </div>
          <div className="stake-info-value">
            {undelegationTokens.sub(withdrawableUndelegationTokens).eq(0)
              ? "--"
              : `${formatCelrDecimal(undelegationTokens.sub(withdrawableUndelegationTokens), 6)}`}
          </div>
        </div>
        <div className="statke-info">
          <div className="stake-info-title">
            Your Withdrawable Stake
            <Tooltip
              placement="top"
              title={
                <div className="toolsDetail">
                  Withdrawable stake is ready to be claimed on {getNetworkById(chainId).name}.
                  <br />
                  <br />
                  Note: to increase your withdrawable stake, you first need to unbond your stake, which takes{" "}
                  {getTimeByBlockNum(unbondingBlockNum)}.
                </div>
              }
              color="#FFFFFF"
              overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
            >
              <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
            </Tooltip>
          </div>
          <div className="stake-info-value">
            {withdrawableUndelegationTokens.eq(0) ? "--" : `${formatCelrDecimal(withdrawableUndelegationTokens, 6)}`}
          </div>
        </div>
        <div className="mobile-validator-item-btns">
          <Button
            type="text"
            className="extra-button btn-stake"
            onClick={() => {
              clickWithCheckChainId(() => toggleDelegateModalVisible());
            }}
            block
          >
            Stake More
          </Button>
          <Button
            type="text"
            className="extra-button btn-unbond"
            onClick={() => {
              clickWithCheckChainId(() => toggleUnbondModalVisible());
            }}
            block
            disabled={tokens.eq(0)}
          >
            Unbond
          </Button>
          <Button
            block
            type="text"
            className="extra-button btn-withdraw"
            onClick={() => {
              clickWithCheckChainId(() => toggleWithdrawModalVisible());
            }}
            disabled={withdrawableUndelegationTokens.eq(0)}
          >
            Withdraw
          </Button>
        </div>
      </div>
    );
  };

  const renderValidatorItem = () => {
    return (
      <Col span={8} className="block-col">
        <Card className="validator-card">
          <Row>
            <Col span={24}>
              <div className="avatar">
                <Avatar size={56} icon={<img src={valDescInfo?.details?.icon || userIcon} alt="userIcon" />} />
                <div className="avatar-info">
                  <div>{valDescInfo?.moniker}</div>
                  <div className="rate">
                    {`${commissionRateNumber / RATE_BASE}%`}
                    <span className="Commission">
                      {" "}
                      Commission
                      <Tooltip
                        placement="top"
                        title={
                          <div className="toolsDetail">
                            This is the percentage of your staking rewards taken by this validator.
                            <br />
                            <a href={`${helpUrl}`} target="_blank" rel="noreferrer">
                              Learn More
                            </a>
                          </div>
                        }
                        color="#FFFFFF"
                        overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
                      >
                        <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                      </Tooltip>
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={11} style={{ display: "flex", alignItems: "center" }}>
              Your Delegated Stake
              <Tooltip
                placement="topLeft"
                title={
                  <div className="toolsDetail">
                    This is your stake currently delegated with this validator. Note that your unbonding stake or
                    withdrawable stake is not included.
                  </div>
                }
                color="#FFFFFF"
                overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
              >
                <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
              </Tooltip>
            </Col>
            <Col span={13}>{tokens.eq(0) ? "--" : `${formatCelrDecimal(tokens, 6)}`}</Col>
            <Col span={11}>
              Your Unbonding Stake
              <Tooltip
                placement="topLeft"
                title={
                  <div className="toolsDetail">
                    This is your stake amount currently in the unbonding process. The unbonding process takes{" "}
                    {getTimeByBlockNum(unbondingBlockNum)}, after which it will become withdrawable.
                  </div>
                }
                color="#FFFFFF"
                overlayInnerStyle={{ color: "#0A1E42", width: 290 }}
              >
                <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
              </Tooltip>
            </Col>
            <Col span={13}>
              {undelegationTokens.sub(withdrawableUndelegationTokens).eq(0)
                ? "--"
                : `${formatCelrDecimal(undelegationTokens.sub(withdrawableUndelegationTokens), 6)}`}
            </Col>
            <Col span={11}>
              Your Withdrawable Stake
              <Tooltip
                placement="topLeft"
                title={
                  <div className="toolsDetail">
                    Withdrawable stake is ready to be claimed on {getNetworkById(chainId).name}.
                    <br />
                    <br />
                    Note: to increase your withdrawable stake, you first need to unbond your stake, which takes{" "}
                    {getTimeByBlockNum(unbondingBlockNum)}.
                  </div>
                }
                color="#FFFFFF"
                overlayInnerStyle={{ color: "#0A1E42", width: 350 }}
              >
                <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
              </Tooltip>
            </Col>
            <Col span={13}>
              {withdrawableUndelegationTokens.eq(0) ? "--" : `${formatCelrDecimal(withdrawableUndelegationTokens, 6)}`}
            </Col>
          </Row>
          <Row justify="space-between">
            <Col span={7}>
              <Button
                type="text"
                className="extra-button btn-stake"
                onClick={() => {
                  clickWithCheckChainId(() => toggleDelegateModalVisible());
                }}
                block
              >
                Stake More
              </Button>
            </Col>
            <Col span={7}>
              <Button
                type="text"
                className="extra-button btn-unbond"
                onClick={() => {
                  clickWithCheckChainId(() => toggleUnbondModalVisible());
                }}
                block
                disabled={tokens.eq(0)}
              >
                Unbond
              </Button>
            </Col>
            <Col span={8}>
              <Button
                block
                type="text"
                className="extra-button btn-withdraw"
                onClick={() => {
                  clickWithCheckChainId(() => toggleWithdrawModalVisible());
                }}
                disabled={withdrawableUndelegationTokens.eq(0)}
              >
                Withdraw
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };

  return (
    <>
      {isMobile ? renderMobileValidatorItem() : renderValidatorItem()}
      <DelegateModal
        visible={delegateModalVisible}
        onClose={() => {
          toggleDelegateModalVisible();
          dispatch(setRefreshData());
        }}
        validatorInfo={{
          valAddr,
          commissionRate,
          desc: valDescInfo,
        }}
      />
      <UnbondModal
        visible={unbondModalVisible}
        onClose={() => {
          toggleUnbondModalVisible();
          dispatch(setRefreshData());
        }}
        validatorInfo={{
          valAddr,
          commissionRate,
          desc: valDescInfo,
        }}
        delegatorInfo={{
          tokens,
        }}
      />
      <WithdrawModal
        visible={withdrawModalVisible}
        onClose={() => {
          toggleWithdrawModalVisible();
          dispatch(setRefreshData());
        }}
        validatorInfo={{
          valAddr,
          commissionRate,
          desc: valDescInfo,
        }}
        delegatorInfo={{
          withdrawableTokens: withdrawableUndelegationTokens,
        }}
      />
    </>
  );
}
