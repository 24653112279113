import { useState } from "react";
import { Spin } from "antd";
import MyReward from "../newComponents/reward/MyReward";
import FeeShare from "../newComponents/reward/FeeShare";
// import FeeShareIM from "../newComponents/reward/FeeShareIM";
import IMFeeRewards from "../newComponents/reward/IMFeeRewards";
import MyDelegations from "../newComponents/reward/MyDelegations";

export default function Reward(): JSX.Element | null {
  const [loading, setLoading] = useState(false);

  const changeLoading = load => {
    setLoading(load);
  };
  return (
    <Spin spinning={loading}>
      <MyReward changeLoading={changeLoading} />
      <FeeShare />
      {/* <FeeShareIM /> */}
      <IMFeeRewards />
      <MyDelegations />
    </Spin>
  );
}
