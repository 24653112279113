export const partnermainnetNetworks = {
  883: {
    name: "883",
    color: "#3099f2",
    chainId: 883,
    rpcUrl: "http://localhost:8545",
    blockTime: 5000,
    iconUrl: "./noChain.png",
    blockDelay: 15,
    symbol: "",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
    contractAddr: "",
    lqMintTokenSymbolBlackList: [],
  },
  884: {
    name: "884",
    color: "#3099f2",
    chainId: 884,
    rpcUrl: "http://localhost:8547",
    blockTime: 5000,
    iconUrl: "./noChain.png",
    blockDelay: 15,
    symbol: "",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
    contractAddr: "",
    lqMintTokenSymbolBlackList: [],
  },
  localhost: {
    name: "--",
    color: "#666666",
    chainId: 31337,
    rpcUrl: "http://localhost:8545",
    blockTime: 3000,
    iconUrl: "./defaultIcon.svg",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "",
    tokenSymbolList: ["USDT"],
    contractAddr: "",
    lqMintTokenSymbolBlackList: [],
  },
  bscTest: {
    name: "BSC Testnet",
    color: "#7057ff",
    chainId: 97,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    blockTime: 4000,
    iconUrl: "./bsc.png",
    blockDelay: 80,
    symbol: "BNB",
    blockExplorerUrl: "https://testnet.bscscan.com",
    tokenSymbolList: ["CELR", "USDT", "WETH", "DOMI", "MCB", "PSP"],
    contractAddr: "0xf89354F314faF344Abd754924438bA798E306DF2",
    lqMintTokenSymbolBlackList: ["DOMI", "PSP"],
  },
  ropsten: {
    name: "Ropsten Testnet",
    color: "#ff4a8d",
    chainId: 3,
    rpcUrl: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "https://ropsten.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH"],
    contractAddr: "",
    lqMintTokenSymbolBlackList: [],
  },
  goerli: {
    name: "Goerli Testnet",
    color: "#3099f2",
    chainId: 5,
    rpcUrl: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "https://goerli.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH", "TCELR", "USDC", "DOMI", "MCB", "cUSD", "PSP"],
    contractAddr: "0x358234B325EF9eA8115291A8b81b7d33A2Fa762D",
    lqMintTokenSymbolBlackList: ["DOMI", "cUSD", "PSP"],
  },
  mainnet: {
    name: "Ethereum Mainnet",
    color: "#29b6af",
    chainId: 1,
    rpcUrl: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://etherscan.io",
    tokenSymbolList: [
      "USDC",
      "USDT",
      "WETH",
      "BUSD",
      "DODO",
      "LYRA",
      "DOMI",
      "MCB",
      "IMX",
      "oneDODO",
      "OLO",
      "BOBA",
      "XTK",
      "xXTKa",
      "PSP",
    ],
    lqMintTokenSymbolBlackList: ["DOMI", "oneDODO", "OLO", "PSP"],
    contractAddr: "0x5427FEFA711Eff984124bFBB1AB6fbf5E3DA1820",
  },
  bsc: {
    name: "BSC",
    color: "#7057ff",
    chainId: 56,
    rpcUrl: "https://bsc-dataseed.binance.org",
    blockTime: 4000,
    iconUrl: "./bsc.png",
    blockDelay: 8,
    symbol: "BNB",
    blockExplorerUrl: "https://bscscan.com",
    tokenSymbolList: ["USDC", "USDT", "WETH", "BUSD", "DODO", "DOMI", "MCB", "cUSD", "oneDODO", "OLO", "PSP"],
    contractAddr: "0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF",
    lqMintTokenSymbolBlackList: ["DOMI", "cUSD", "oneDODO", "OLO", "PSP"],
  },

  polygon: {
    name: "Polygon (Matic)",
    color: "#3099f2",
    chainId: 137,
    rpcUrl: "https://polygon-rpc.com",
    blockTime: 4000,
    iconUrl: "./polygon.png",
    blockDelay: 80,
    symbol: "MATIC",
    blockExplorerUrl: "https://polygonscan.com",
    tokenSymbolList: ["USDC", "USDT", "WETH", "IMX"],
    contractAddr: "0x88DCDC47D2f83a99CF0000FDF667A468bB958a78",
    lqMintTokenSymbolBlackList: [],
  },
  arbitrum: {
    name: "Arbitrum",
    color: "#ff4a8d",
    chainId: 42161,
    rpcUrl: "https://arb1.arbitrum.io/rpc",
    blockTime: 4000,
    iconUrl: "./arbitrum.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://arbiscan.io",
    tokenSymbolList: ["USDC", "USDT", "WETH", "DODO", "MCB", "IMX", "XTK", "xXTKa"],
    lqMintTokenSymbolBlackList: [],
    contractAddr: "0x1619DE6B6B20eD217a58d00f37B9d47C7663feca",
  },
  Avalanche: {
    name: "Avalanche",
    color: "#3099f2",
    chainId: 43114,
    rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
    blockTime: 5000,
    iconUrl: "./avalanche.png",
    blockDelay: 15,
    symbol: "AVAX",
    blockExplorerUrl: "https://snowtrace.io",
    tokenSymbolList: ["USDC", "USDT", "WETH", "IMX"],
    contractAddr: "0xef3c714c9425a8F3697A9C969Dc1af30ba82e5d4",
    lqMintTokenSymbolBlackList: [],
  },
  Optimism: {
    name: "Optimism",
    color: "#3099f2",
    chainId: 10,
    rpcUrl: "https://mainnet.optimism.io",
    blockTime: 5000,
    iconUrl: "./optimism.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://optimistic.etherscan.io",
    tokenSymbolList: ["USDC", "USDT", "WETH", "LYRA"],
    contractAddr: "0x9D39Fc627A6d9d9F8C831c16995b209548cc3401",
    lqMintTokenSymbolBlackList: [],
  },
  OptimisticKovan: {
    name: "Optimistic Kovan",
    color: "#3099f2",
    chainId: 69,
    rpcUrl: "https://kovan.optimism.io",
    blockTime: 5000,
    iconUrl: "./optimism.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://kovan-optimistic.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH"],
    contractAddr: "0x265B25e22bcd7f10a5bD6E6410F10537Cc7567e8",
    lqMintTokenSymbolBlackList: [],
  },
  Fantom: {
    name: "Fantom",
    color: "#3099f2",
    chainId: 250,
    rpcUrl: "https://rpc.ftm.tools",
    blockTime: 5000,
    iconUrl: "./fantom.png",
    blockDelay: 6,
    symbol: "FTM",
    blockExplorerUrl: "https://ftmscan.com",
    tokenSymbolList: ["USDC", "USDT", "WETH"],
    contractAddr: "0x374B8a9f3eC5eB2D97ECA84Ea27aCa45aa1C57EF",
    lqMintTokenSymbolBlackList: [],
  },
  FantomTestnet: {
    name: "Fantom Testnet",
    color: "#3099f2",
    chainId: 4002,
    rpcUrl: "https://rpc.testnet.fantom.network",
    blockTime: 5000,
    iconUrl: "./fantom.png",
    blockDelay: 6,
    symbol: "FTM",
    blockExplorerUrl: "https://testnet.ftmscan.com",
    tokenSymbolList: ["CELR", "USDT", "TCELR"],
    contractAddr: "0xFA78cBa4ebbf8fE28B4fC1468948F16Fda2752b3",
    lqMintTokenSymbolBlackList: [],
  },

  // Harmony: {
  //   name: "Harmony",
  //   color: "#3099f2",
  //   chainId: 1666600000,
  //   rpcUrl: "https://api.harmony.one",
  //   blockTime: 5000,
  //   iconUrl: "./harmony.png",
  //   blockDelay: 20,
  //   symbol: "ONE",
  //   blockExplorerUrl: "https://explorer.harmony.one",
  //   tokenSymbolList: ["USDC", "WETH"],
  //   contractAddr: "0x78a21c1d3ed53a82d4247b9ee5bf001f4620ceec",
  // lqMintTokenSymbolBlackList:[]
  // },

  // Moonriver: {
  //   name: "Moonriver",
  //   color: "#3099f2",
  //   chainId: 1285,
  //   rpcUrl: "https://rpc.moonriver.moonbeam.network",
  //   blockTime: 5000,
  //   iconUrl: "./moonriver.png",
  //   blockDelay: 20,
  //   symbol: "MOVR",
  //   blockExplorerUrl: "https://moonriver.moonscan.io",
  //   tokenSymbolList: ["USDC"],
  //   // contractAddr: "0x841ce48F9446C8E281D3F1444cB859b4A6D0738C",
  //   contractAddr: "0xe6F4a83eE9f946B86a2ef008dCD872f4a942DB24",
  // lqMintTokenSymbolBlackList:[]
  // },

  BoBa: {
    name: "Boba Network",
    color: "#3099f2",
    chainId: 288,
    rpcUrl: "https://mainnet.boba.network",
    blockTime: 10000,
    iconUrl: "./boba.png",
    blockDelay: 8,
    symbol: "ETH",
    blockExplorerUrl: "https://blockexplorer.boba.network",
    tokenSymbolList: ["USDC", "OLO", "BOBA"],
    contractAddr: "0x841ce48F9446C8E281D3F1444cB859b4A6D0738C",
    lqMintTokenSymbolBlackList: ["OLO"],
  },

  CeloTestnet: {
    name: "Celo Testnet",
    color: "#3099f2",
    chainId: 44787,
    rpcUrl: "https://alfajores-forno.celo-testnet.org",
    blockTime: 10000,
    iconUrl: "./celo.png",
    blockDelay: 8,
    symbol: "CELO",
    blockExplorerUrl: "https://alfajores-blockscout.celo-testnet.org",
    tokenSymbolList: ["USDC", "cUSD"],
    contractAddr: "0x841ce48F9446C8E281D3F1444cB859b4A6D0738C",
    lqMintTokenSymbolBlackList: ["USDC", "cUSD"],
  },

  Celo: {
    name: "Celo",
    color: "#3099f2",
    chainId: 42220,
    rpcUrl: "https://forno.celo.org",
    blockTime: 10000,
    iconUrl: "./celo.png",
    blockDelay: 8,
    symbol: "CELO",
    blockExplorerUrl: "https://explorer.celo.org",
    tokenSymbolList: ["USDC", "USDT", "cUSD"],
    contractAddr: "0xBB7684Cc5408F4DD0921E5c2Cadd547b8f1AD573",
    lqMintTokenSymbolBlackList: ["USDC", "USDT", "cUSD"],
  },
};
