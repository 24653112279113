import { useEffect, useState } from "react";
import { Modal, Button, Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { ethers } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { base64, getAddress, hexlify } from "ethers/lib/utils";
import { convertUSD, formatDecimal, formatBalance } from "celer-web-utils/lib/format";
import { isMobile } from "react-device-detect";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  CBridgeFeeShareInfo,
  withdrawLiquidity,
  queryLiquidityStatus,
  LPType,
  LPHistoryStatus,
  WithdrawDetail,
  getTokenInfo,
  signAgain,
} from "../../api";

import {
  WithdrawReq as WithdrawReqProto,
  WithdrawLq as WithdrawLqProto,
  WithdrawType,
} from "../../proto/sgn/cbridge/v1/tx_pb";
import {
  ClaimStatus,
  Chain,
  WithdrawMethodType,
  WithdrawLiquidityRequest,
  GetTokenInfoRequest,
  SignAgainRequest,
  SignAgainType,
} from "../../proto/gateway/gateway_pb";
// import { LPHistoryStatus } from "../../proto-grpc/sgn/cbridge/v1/query.pb";

import "./feeShareModal.less";
import cImg from "../../assets/images/c-img.png";
import warningImg from "../../assets/images/warning.png";
import loadingImg from "../../assets/images/loading.png";
import successImg from "../../assets/images/success.png";
import { formatCelrWithoutSymbol } from "../../helpers/format";
import getTokenNameByTokenSymbol from "../../helpers/getTokenNameBySymbolAndChainId";
import { setRefreshData } from "../../redux/transferSlice";
import { getNetworkById } from "../../constants/network";
import { LPHistory } from "../../constants/claimType";
import { storageConstants } from "../../constants/const";
import useGasFee from "../../hooks/useGasFee";
import { formatBlockExplorerUrlWithTxHash } from "../../utils/formatUrl";
import getTokenInfoByChainIdAndTokenSymbol from "../../utils/getTokenInfoByChainIdAndTokenSymbol";
import { needToSignAgain } from "../singleChainWithdrawModal";
// let txHash = "";

/* eslint-disable */

interface IProps {
  visible: boolean;
  onClose: () => void;
  feeShareList: Array<CBridgeFeeShareInfo>;
  onClaimSuccess: () => Promise<Array<CBridgeFeeShareInfo>>;
  singleChainToggleMethod: () => void;
  feeSharetokenUsdMap: Map<string, number>;
}

interface SelectedInfo {
  amount: BigNumber;
  chainInfo?: Chain.AsObject;
  symbol: string;
  feeShareAmount: string;
  amountUsd: number;
  tokenName?: string;
  singleChainId?: string;
}
enum Status {
  INIT,
  CLAIM_SINGLE_FEE_SHARE,
  CLAIMING,
  CONFIRM_CLAIM,
  SUCCESS,
  SWITCH_CHAIN,
  INIT_CACHE,
  CONFIRM_CLAIM_CACHE,
  SWITCH_CACHE,
  SUCCESS_CACHE,
}

const getSymbolAndChainId = (denom: string) => {
  const symbol = (denom.match(/CBF-(\S*)(\/)/) || [])[1];
  const chainId = (denom.match(/(\/)(\S*)/) || [])[2];
  return {
    symbol,
    singleChainId: chainId,
  };
};

const FeeShareModal = (props: IProps) => {
  const { visible, onClose, feeShareList, onClaimSuccess, feeSharetokenUsdMap } = props;
  const { chainId, address, signer } = useWeb3Context();
  const {
    contracts: { Bridge },
    transactor,
  } = useContractsContext();
  const dispatch = useAppDispatch();
  const { configs } = useAppSelector(state => state.globalInfo);
  const { chainsList, chainTokenMap } = configs;
  let initStatus = Status.INIT;

  const [feeShareStatus, setFeeShareStatus] = useState<Status>(initStatus);
  const [switchChianId, setSwitchChainId] = useState<number>();
  const targetChainId = Number(process.env.REACT_APP_NETWORK_ID);
  const [selectedInfo, setSelectedInfo] = useState<SelectedInfo>();
  const [tokenList, setTokenList] = useState<Array<SelectedInfo>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [spinning, setSpinning] = useState<boolean>(false);
  const [withdrawDetail, setWithdrawDetail] = useState<WithdrawDetail>();
  const [viewInExploreLink, setViewInExploreLink] = useState<string>("");

  const [totalReward, setTotalReward] = useState(0);

  const { tokenGas, usdGas } = useGasFee();
  const [seqNum, setSqeNum] = useState("");

  let removeStatusInterval;

  useEffect(() => {
    const newWokenList: Array<SelectedInfo> = [];
    let totalRewards = 0;
    feeShareList.forEach(item => {
      const amountBigNumber = BigNumber.from(item.amount);
      const { symbol, singleChainId } = getSymbolAndChainId(item.denom);
      const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(chainTokenMap, Number(singleChainId), symbol);
      const tokenName = getTokenNameByTokenSymbol(tokenInfo?.token?.symbol || "", Number(singleChainId));
      const feeShareAmount = `${formatCelrWithoutSymbol(item.amount, 6, tokenInfo?.token?.decimal)} ${tokenName}`;
      const chainInfo = getChainInfoByChainId(singleChainId);
      const usdPrice = feeSharetokenUsdMap.get(symbol) || 0;
      const amountUsd = Number(formatDecimal(item.amount, 6, tokenInfo?.token?.decimal)) * Number(usdPrice);
      totalRewards += amountUsd;
      newWokenList.push({
        amount: amountBigNumber,
        amountUsd,
        symbol,
        chainInfo,
        feeShareAmount,
        singleChainId,
      });
    });
    const sortedTokenList: SelectedInfo[] = newWokenList.sort((a, b) => {
      if (a?.amountUsd > b?.amountUsd) {
        return -1;
      }
      if (a?.amountUsd < b?.amountUsd) {
        return 1;
      }
      return 0;
    });
    console.log({ newWokenList, sortedTokenList });
    setTokenList(newWokenList);
    setTotalReward(totalRewards);
  }, [feeShareList, feeSharetokenUsdMap]);

  const getChainInfoByChainId = paramChainId => {
    return chainsList.find(item => item.id === Number(paramChainId));
  };

  const closeMethod = async () => {
    onClose();
    setFeeShareStatus(Status.INIT);
    if (switchChianId && switchChianId !== targetChainId) {
      setSwitchChainId(targetChainId);
      dispatch(setRefreshData());
    }
  };

  const claimMethod = async (info: SelectedInfo) => {
    setSelectedInfo(info);
    if (info?.chainInfo?.id !== chainId) {
      setFeeShareStatus(Status.SWITCH_CHAIN);
    } else {
      setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
    }
  };

  const switchChainMethod = async claimChainId => {
    const inId = Number(claimChainId);
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${inId.toString(16)}` }],
      });
      setSwitchChainId(inId);
      setFeeShareStatus(Status.CLAIM_SINGLE_FEE_SHARE);
    } catch (e) {
      console.log("switch fails");
      if (isMobile) {
        onClose();
      }
    }
  };

  const claimSingleMethod = async () => {
    if (!Bridge || !selectedInfo || !signer) {
      return;
    }
    setLoading(true);
    const getTokenInfoRequest = new GetTokenInfoRequest();
    getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
    getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
    const tokenInfo = await getTokenInfo(getTokenInfoRequest);
    // 计算reqid, sig
    const timestamp = Math.floor(Date.now() / 1000);
    const WithdrawLqProtoList: WithdrawLqProto[] = [];
    const withdrawLqProto = new WithdrawLqProto();
    withdrawLqProto.setFromChainId(selectedInfo?.chainInfo?.id || chainId);

    withdrawLqProto.setMaxSlippage(0);
    withdrawLqProto.setTokenAddr(tokenInfo?.toObject().token?.address || "");
    withdrawLqProto.setRatio(0); //100000000  //此处不能传小数。
    WithdrawLqProtoList.push(withdrawLqProto);

    const withdrawReqProto = new WithdrawReqProto();
    withdrawReqProto.setWithdrawsList(WithdrawLqProtoList);
    withdrawReqProto.setExitChainId(selectedInfo?.chainInfo?.id || chainId);

    withdrawReqProto.setReqId(timestamp);
    withdrawReqProto.setWithdrawType(WithdrawType.WITHDRAW_TYPE_CLAIM_FEE_SHARE);
    let sig;
    try {
      // sig = await signer.signMessage(hash);
      sig = await signer.signMessage(ethers.utils.arrayify(ethers.utils.keccak256(withdrawReqProto.serializeBinary())));
      if (sig) {
        setFeeShareStatus(Status.CLAIMING);
      }
    } catch (error) {
      setLoading(false);
      return;
    }
    const bytes = ethers.utils.arrayify(sig);
    const req = new WithdrawLiquidityRequest();
    req.setWithdrawReq(withdrawReqProto.serializeBinary());
    req.setSig(bytes);
    req.setEstimatedReceivedAmt(selectedInfo.amount.toString());
    req.setMethodType(WithdrawMethodType.WD_METHOD_TYPE_STAKING_CLAIM);
    const withdrawResult = await withdrawLiquidity(req);
    const seq_num = withdrawResult.toObject().seqNum.toString() || "";
    setSqeNum(seq_num);
    removeStatusInterval = setInterval(async () => {
      const res = await queryLiquidityStatus({
        seq_num,
        lp_addr: address,
        chain_id: selectedInfo?.chainInfo?.id,
        type: LPType.LP_TYPE_REMOVE,
      });
      if (res?.status) {
        const status = res.status;
        if (status === LPHistoryStatus.LP_FAILED) {
          setLoading(false);
          clearInterval(removeStatusInterval);
        } else if (status === LPHistoryStatus.LP_WAITING_FOR_LP) {
          setFeeShareStatus(Status.CONFIRM_CLAIM);
          const { wd_onchain, sorted_sigs, signers, powers } = res;
          setWithdrawDetail({
            _wdmsg: wd_onchain,
            _sigs: sorted_sigs,
            _signers: signers,
            _powers: powers,
          });
          setLoading(false);
          clearInterval(removeStatusInterval);
        }
      } else {
        setLoading(false);
        clearInterval(removeStatusInterval);
      }
    }, 5000);
  };

  const isRightBridge = () => {
    const bridgetArrd = Bridge?.address;
    const targetBridgeVaultArrd = chainsList?.find(
      item => item.id === Number(selectedInfo?.chainInfo?.id),
    )?.contractAddr;
    if (bridgetArrd && targetBridgeVaultArrd) {
      return getAddress(bridgetArrd) === getAddress(targetBridgeVaultArrd);
    }
    return false;
  };

  const signAgainMethod = async ({ chainId, seqNum, addr }) => {
    const signAgainRequest = new SignAgainRequest();
    signAgainRequest.setType(SignAgainType.SAT_LIQUIDITY);
    signAgainRequest.setUsrAddr(addr);
    signAgainRequest.setChainId(chainId);
    signAgainRequest.setSeqNum(seqNum);
    return signAgain(signAgainRequest);
  };

  const afterSignAgainMthod = async () => {
    if (!Bridge || !transactor || !selectedInfo) {
      return;
    }
    const res = await queryLiquidityStatus({
      seq_num: seqNum,
      lp_addr: address,
      chain_id: selectedInfo?.chainInfo?.id,
      type: LPType.LP_TYPE_REMOVE,
    });
    const wdmsg = base64.decode(res.wd_onchain);
    const signers = res.signers.map(item => {
      const decodeSigners = base64.decode(item);
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    const sigs = res.sorted_sigs.map(item => {
      return base64.decode(item);
    });
    const powers = res.powers.map(item => {
      const decodeNum = base64.decode(item);
      return BigNumber.from(decodeNum);
    });
    let gasLimit;
    gasLimit = await Bridge.estimateGas.withdraw(wdmsg, sigs, signers, powers);
    gasLimit = gasLimit.mul(13).div(10);
    const resultTx = await transactor(Bridge.withdraw(wdmsg, sigs, signers, powers, { gasLimit: gasLimit })).catch(
      e => {
        console.log("catch tx error", e);
        setFeeShareStatus(Status.CONFIRM_CLAIM);
        setLoading(false);
      },
    );
    if (resultTx) {
      const getTokenInfoRequest = new GetTokenInfoRequest();
      getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
      getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
      const tokenInfo = await getTokenInfo(getTokenInfoRequest);
      const newtxStr = JSON.stringify(resultTx);
      const newtx = JSON.parse(newtxStr);
      if (resultTx instanceof Error || newtx.code) {
        console.log("get before error", resultTx);
        // setTransfState(LPHistoryStatus.LP_WAITING_FOR_LP);
        setLoading(false);
      } else {
        const blockTxLink = formatBlockExplorerUrlWithTxHash({
          chainId: selectedInfo.chainInfo?.id || chainId,
          txHash: resultTx.hash,
        });
        const newLPJson: LPHistory = {
          chain: selectedInfo.chainInfo,
          token: tokenInfo?.toObject(),
          amount: selectedInfo.amount.toString(),
          ts: new Date().getTime(),
          blockTxLink,
          status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
          methodType: WithdrawMethodType.WD_METHOD_TYPE_STAKING_CLAIM,
          seqNum: Number(seqNum),
          nonce: resultTx.nonce,
          isLocal: true,
          updateTime: new Date().getTime(),
          txIsFailed: false,
          withdrawId: "",
        };
        const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
        let localLpList: LPHistory[] = [];
        if (localLpListStr) {
          localLpList = JSON.parse(localLpListStr)[address] || [];
        }
        localLpList.unshift(newLPJson);
        const newJson = { [address]: localLpList };
        localStorage.setItem(storageConstants.KEY_LP_LIST, JSON.stringify(newJson));
        setViewInExploreLink(blockTxLink);
        setFeeShareStatus(Status.SUCCESS);
        setLoading(false);
      }
    }
  };

  const confirmClaimMethod = async () => {
    if (!Bridge || !withdrawDetail || !transactor || !selectedInfo) {
      return;
    }
    if (selectedInfo.chainInfo?.id !== chainId) {
      return setFeeShareStatus(Status.SWITCH_CACHE);
    }
    if (!isRightBridge()) {
      console.log("error: wrong Bridge addr");
      return;
    }
    setLoading(true);
    const { _wdmsg, _signers, _sigs, _powers } = withdrawDetail;
    const wdmsg = base64.decode(_wdmsg);
    const signers = _signers.map(item => {
      const decodeSigners = base64.decode(item);
      const hexlifyObj = hexlify(decodeSigners);
      return getAddress(hexlifyObj);
    });
    const sigs = _sigs.map(item => {
      return base64.decode(item);
    });
    const powers = _powers.map(item => {
      const decodeNum = base64.decode(item);
      return BigNumber.from(decodeNum);
    });
    try {
      let gasLimit;
      try {
        gasLimit = await Bridge.estimateGas.withdraw(wdmsg, sigs, signers, powers);
        gasLimit = gasLimit.mul(13).div(10);
        const resultTx = await transactor(Bridge.withdraw(wdmsg, sigs, signers, powers, { gasLimit: gasLimit }));
        if (resultTx) {
          const getTokenInfoRequest = new GetTokenInfoRequest();
          getTokenInfoRequest.setChainId(selectedInfo.chainInfo?.id || chainId);
          getTokenInfoRequest.setTokenSymbol(selectedInfo.symbol);
          const tokenInfo = await getTokenInfo(getTokenInfoRequest);
          const newtxStr = JSON.stringify(resultTx);
          const newtx = JSON.parse(newtxStr);
          if (resultTx instanceof Error || newtx.code) {
            console.log("get before error", resultTx);
            // setTransfState(LPHistoryStatus.LP_WAITING_FOR_LP);
            setLoading(false);
          } else {
            const blockTxLink = formatBlockExplorerUrlWithTxHash({
              chainId: selectedInfo.chainInfo?.id,
              txHash: resultTx.hash,
            });
            const newLPJson: LPHistory = {
              chain: selectedInfo.chainInfo,
              token: tokenInfo?.toObject(),
              amount: selectedInfo.amount.toString(),
              ts: new Date().getTime(),
              blockTxLink,
              status: ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM,
              methodType: WithdrawMethodType.WD_METHOD_TYPE_STAKING_CLAIM,
              seqNum: Number(seqNum),
              nonce: resultTx.nonce,
              isLocal: true,
              updateTime: new Date().getTime(),
              txIsFailed: false,
              withdrawId: "",
            };
            const localLpListStr = localStorage.getItem(storageConstants.KEY_LP_LIST);
            let localLpList: LPHistory[] = [];
            if (localLpListStr) {
              localLpList = JSON.parse(localLpListStr)[address] || [];
            }
            localLpList.unshift(newLPJson);
            const newJson = { [address]: localLpList };
            localStorage.setItem(storageConstants.KEY_LP_LIST, JSON.stringify(newJson));
            setViewInExploreLink(blockTxLink);
            setFeeShareStatus(Status.SUCCESS);
            setLoading(false);
          }
        }
      } catch (e) {
        if (needToSignAgain(e)) {
          const signAgainRes = await signAgainMethod({
            addr: address,
            chainId: selectedInfo.chainInfo?.id || chainId,
            seqNum,
          });
          console.log({ signAgainRes });
          try {
            await afterSignAgainMthod();
          } catch (err) {
            console.log("catch afterSignAgainMthod error", err);
            setFeeShareStatus(Status.CONFIRM_CLAIM);
            setLoading(false);
          }
        } else {
          console.log("catch tx error", e);
          setFeeShareStatus(Status.CONFIRM_CLAIM);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
    } finally {
      //   setLoading(false);
    }
  };

  const claimSuccessMethod = async () => {
    setFeeShareStatus(Status.INIT);
    setSpinning(true);
    try {
      const newFeeShareList = await onClaimSuccess();
      setSpinning(false);
      if (newFeeShareList.length <= 0) {
        closeMethod();
      }
    } catch (e) {
      console.log(e);
      setSpinning(false);
    }
  };

  const contentDom = () => {
    const chainInfo = getChainInfoByChainId(selectedInfo?.chainInfo?.id);
    const tokenInfo = getTokenInfoByChainIdAndTokenSymbol(
      chainTokenMap,
      Number(selectedInfo?.chainInfo?.id),
      selectedInfo?.symbol,
    );
    const feeShareAmount = `${formatCelrWithoutSymbol(selectedInfo?.amount, 6, tokenInfo.token?.decimal)} ${
      tokenInfo.token?.symbol
    }`;
    switch (feeShareStatus) {
      case Status.INIT:
        return (
          <Spin spinning={spinning}>
            <div className="init-block">
              <div className="total-rewards">Total claimable fee rewards: {convertUSD(totalReward)}</div>
              <div className="c-img">
                <img src={cImg} alt="" />
              </div>
              <div className="feeshare-list">
                {tokenList.map(item => {
                  const { amount, amountUsd, symbol, chainInfo, feeShareAmount, singleChainId } = item;
                  if (isMobile) {
                    return (
                      <div className="mobile-item" key={`${singleChainId}-${symbol}`}>
                        <div className="mobile-item-info">
                          <div className="mobile-item-info-icon">
                            <img src={chainInfo?.icon} alt="" />
                          </div>
                          <div className="mobile-item-info-content">
                            <div className="mobile-item-info-chain-name">{chainInfo?.name}</div>
                            <div className="mobile-item-info-amount-title">Claimable Fee Reward</div>
                            <div className="mobile-item-info-amount-token">
                              {feeShareAmount}{" "}
                              <span className="mobile-item-info-amount-usd">({convertUSD(amountUsd)})</span>
                            </div>
                          </div>
                        </div>
                        <Button
                          type="text"
                          block
                          className="mobile-item-btn"
                          onClick={() => {
                            claimMethod({
                              amount,
                              amountUsd,
                              symbol,
                              chainInfo,
                              feeShareAmount,
                            });
                          }}
                        >
                          Claim
                        </Button>
                      </div>
                    );
                  }
                  return (
                    <div className="item" key={`${singleChainId}-${symbol}`}>
                      <div className="chain-info">
                        <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                        <span className="chain-info-name">{chainInfo?.name}</span>
                      </div>
                      <div className="fee-amount">
                        <div className="fee-amount-title">Claimable Fee Reward</div>
                        <div className="fee-amount-token">{feeShareAmount} </div>
                        <div className="fee-amount-usd">({convertUSD(amountUsd)})</div>
                      </div>
                      <div className="btn-block">
                        <Button
                          type="text"
                          block
                          className="claim-btn"
                          onClick={() => {
                            claimMethod({
                              amount,
                              amountUsd,
                              symbol,
                              chainInfo,
                              feeShareAmount,
                            });
                          }}
                        >
                          Claim
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="btn-block-aggregate">
                <Button
                  type="text"
                  block
                  className="claim-btn"
                  onClick={() => {
                    closeMethod();
                    props.singleChainToggleMethod();
                  }}
                >
                  {"Aggregate & Claim"}
                  <Tooltip
                    title={
                      <div>
                        You can aggregate your fee rewards from different chains and claim the rewards on a single
                        chain. Additional fee may apply.
                      </div>
                    }
                    placement="bottom"
                    color="#fff"
                    overlayInnerStyle={{ color: "#000", borderRadius: "8px", textAlign: "center", width: 290 }}
                  >
                    <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6 }} />
                  </Tooltip>
                </Button>
              </div> */}
            </div>
          </Spin>
        );
      case Status.CLAIM_SINGLE_FEE_SHARE:
        const nativeToken = getNetworkById(chainInfo?.id || 1);
        return (
          <div className="single-block">
            <div className="c-img">
              <img src={cImg} alt="" />
            </div>
            <div className="content">
              <div className="feeShare-info">
                <div className="chain-info">
                  <img className="chain-info-icon" src={chainInfo?.icon} alt="" />
                  <span>{selectedInfo?.chainInfo?.name}</span>
                </div>
                <div className="fee-amount">
                  <div className="fee-amount-title">Claimable Fee Reward</div>
                  <div className="fee-amount-token">{feeShareAmount} </div>
                  <div className="fee-amount-usd">({convertUSD(selectedInfo?.amountUsd || 0)})</div>
                </div>
              </div>
              <div className="feeShare-desc">
                Note: You will need to pay
                <span className="gas-fee-price">
                  {" "}
                  {formatBalance(tokenGas, 6, "floor", ",", true)} {nativeToken.symbol} ({convertUSD(usdGas)}) on-chain
                  gas costs
                </span>{" "}
                to claim this fee rewards.
              </div>
              <div className="btn-block">
                <Button type="text" block loading={loading} className="claim-btn" onClick={claimSingleMethod}>
                  Claim
                </Button>
              </div>
            </div>
          </div>
        );
      case Status.SWITCH_CHAIN:
        return (
          <div className="switch-block">
            <div className="warning">
              <div className="img-block">
                <img src={warningImg} alt="" />
              </div>
              <div className="warning-message">
                Please switch to {chainInfo?.name} before claiming your cBridge fee reward.
              </div>
            </div>
            <div className="btn-block">
              <Button
                type="text"
                block
                className="ok-btn"
                onClick={() => {
                  switchChainMethod(selectedInfo?.chainInfo?.id);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        );
      case Status.CLAIMING:
        return (
          <div className="claiming-block">
            <div className="loading-block">
              <img className="loadingimg" src={loadingImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Waiting for Celer SGN Confirmations...</div>
              <div className="sub-title">
                Claim {feeShareAmount} cBridge fee reward from {selectedInfo?.chainInfo?.name}.
              </div>
            </div>
            <div className="desc">
              <div className="desc-info">
                Your request to claim cBridge fee reward is being confirmed by Celer State Guardian Network (SGN), which
                might take a few minutes.
              </div>
            </div>
          </div>
        );
      case Status.CONFIRM_CLAIM:
        return (
          <div className="confirm-block">
            <div className="confirm-message">Please click the button to complete the fee reward claim process.</div>
            <div className="btn-block">
              <Button type="text" block className="confirm-btn" loading={loading} onClick={confirmClaimMethod}>
                Confirm Fee Reward Claim
              </Button>
            </div>
          </div>
        );
      case Status.SUCCESS:
        return (
          <div className="success-block">
            <div className="success-icon">
              <img src={successImg} alt="" />
            </div>
            <div className="title">
              <div className="main-title">Success</div>
              <div className="sub-title">
                Claim {feeShareAmount} cBridge fee reward from {selectedInfo?.chainInfo?.name}
              </div>
            </div>
            <div className="view-in-explorer">
              <a href={viewInExploreLink} target="_blank" rel="noreferrer">
                View in Explorer
              </a>
            </div>
            <div className="desc">Please allow a few minutes before the transaction completes.</div>
            <div className="btn-block">
              <Button type="text" block className="ok-btn" loading={loading} onClick={claimSuccessMethod}>
                OK
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderModalTitle = () => {
    return feeShareStatus === Status.SWITCH_CHAIN ? (
      " "
    ) : (
      <div className="modal-title-block">
        {feeShareStatus === Status.CLAIM_SINGLE_FEE_SHARE ? (
          <div
            className="back"
            onClick={() => {
              setFeeShareStatus(Status.INIT);
            }}
          >
            <LeftOutlined style={{ fontSize: 22, marginLeft: 6 }} />
          </div>
        ) : null}
        <div className="main-title">Claim Fee Rewards</div>
        <div className="sub-title">(pool-based bridge)</div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        title={renderModalTitle()}
        className={feeShareStatus === Status.INIT ? "feeShare-list-modal" : "feeShare-modal"}
        visible={visible}
        onCancel={closeMethod}
        maskClosable={false}
        footer={null}
      >
        {contentDom()}
      </Modal>
    </div>
  );
};

export default FeeShareModal;
