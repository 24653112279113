import { useState, useEffect, useCallback } from "react";
import { Modal, Button, Avatar, Tooltip } from "antd";
import { isMobile } from "react-device-detect";
import { CheckCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { BigNumber } from "ethers";
import { formatUnits, parseEther } from "@ethersproject/units";
import { AddressZero, MaxUint256 } from "@ethersproject/constants";
import { useAsync } from "react-use";
import { useContractsContext } from "../../providers/ContractsContextProvider";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useCustomContractLoader, useTokenBalance } from "../../hooks";
import { ERC20 } from "../../typechain/ERC20";
import { ERC20__factory } from "../../typechain/factories/ERC20__factory";
import { formatDeleteBalance, formatCelrWithoutSymbol } from "../../helpers/format";
import { validFloatRegex } from "../../helpers/regex";
import { getExlporerUrl } from "../../helpers/env";
import { RATE_BASE } from "../../constants";
import userIcon from "../../assets/images/userIcon.png";
import "./index.less";
import TokenInput from "../common/tokenInut";
import lockLoadingPng from "../../assets/images/lockLoading.png";
import lockPng from "../../assets/images/lock.png";
import { setRefreshMyDelegations } from "../../redux/transferSlice";
import tipIcon from "../../assets/images/toolTip.png";

/* eslint-disable */

export interface ITokenInputChangeEvent {
  value: string;
  error?: string;
}

interface IProps {
  visible: boolean;
  onClose: () => void;
  validatorInfo: {
    valAddr: string;
    commissionRate: BigNumber;
    desc?: {
      details: {
        icon: string | undefined;
      };
      moniker: string | undefined;
    };
  };
}

enum DelegateStatus {
  DELEGATE,
  DELEGATING,
  SUCCESS,
  FAILED,
}

const DelegateModal = (props: IProps) => {
  const { visible, onClose, validatorInfo } = props;
  const { provider, address } = useWeb3Context();
  const { contracts, transactor } = useContractsContext();
  const { Staking } = contracts;
  const { celerAddress } = useAppSelector(state => state.globalInfo);

  const tokenContract = useCustomContractLoader(provider, celerAddress || "", ERC20__factory) as ERC20 | undefined;
  const [tokenBalance] = useTokenBalance(tokenContract, address);
  const [allowance, setAllowance] = useState<BigNumber>();

  const commissionRate = validatorInfo.commissionRate.toNumber();
  const minAmount = "1";
  const minAmountNumber = 1;
  const [amount, setAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [delegateStatus, setDelegateStatus] = useState<DelegateStatus>(DelegateStatus.DELEGATE);
  const [delegateTxHash, setDelegateTxHash] = useState<string>("");
  const explorerUrl = getExlporerUrl();
  const dispatch = useAppDispatch();
  const { transferInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;
  const helpUrl = "https://cbridge-docs.celer.network/reference/faq#what-is-the-commission-rate-in-sgn";
  const [needApprove, setNeedApprove] = useState(false);

  useAsync(async () => {
    if (address) {
      setAmount("");
      setDelegateStatus(DelegateStatus.DELEGATE);
    }
  }, [visible]);

  const getAllowance = useCallback(() => {
    if (!tokenContract || !Staking || !address) {
      return;
    }
    tokenContract
      ?.allowance(address, Staking.address || AddressZero)
      .then(result => {
        setAllowance(result);
      })
      .catch(e => {
        console.log(e);
      });
  }, [address, Staking, tokenContract]);

  const delegateTokens = async (valAddr: string, amountWei: BigNumber) => {
    if (!transactor || !Staking) {
      return;
    }
    try {
      const delegateTx = await transactor(Staking?.delegate(valAddr, amountWei));
      setDelegateTxHash(delegateTx.hash);
      setDelegateStatus(DelegateStatus.SUCCESS);
      dispatch(setRefreshMyDelegations(!refreshMyDelegations));
    } catch (e) {
      setDelegateStatus(DelegateStatus.FAILED);
    }
  };

  const approveMethod = async () => {
    if (!transactor || !tokenContract || !celerAddress || !Staking) {
      return;
    }
    setDelegateStatus(DelegateStatus.DELEGATING);
    try {
      const approveTx = await transactor(tokenContract.approve(Staking.address, MaxUint256));
      await approveTx.wait();
      await getAllowance();
      setDelegateStatus(DelegateStatus.DELEGATE);
    } catch (e) {
      setDelegateStatus(DelegateStatus.DELEGATE);
    }
  };

  const delegateMethod = async () => {
    if (!transactor || !tokenContract || !celerAddress || !Staking) {
      return;
    }

    setDelegateStatus(DelegateStatus.DELEGATING);
    const amountWei = parseEther(formatDeleteBalance(amount));
    delegateTokens(validatorInfo.valAddr, amountWei);
  };

  const successMethod = () => {
    setDelegateStatus(DelegateStatus.DELEGATE);
    onClose();
    setAmount("");
  };

  const handleTokenInputChange = (e: ITokenInputChangeEvent) => {
    setErrorMessage(e.error || "");
    setAmount(e.value);
  };

  const setMaxAmount = () => {
    setAmount(formatCelrWithoutSymbol(tokenBalance, 2));
  };

  useEffect(() => {
    let err = "";

    if (amount.length > 0) {
      const amountNum = Number(formatDeleteBalance(amount));
      if (!amountNum) {
        err = "Please enter a valid amount.";
      }
      if (amountNum !== 0) {
        const maxAcountNum = Number(formatUnits(tokenBalance));
        if (amountNum < 0 || !validFloatRegex.test(amount)) {
          err = "Please enter a valid amount.";
        } else if (amountNum < minAmountNumber) {
          err = `The min stake amount is ${minAmountNumber} CELR. Please enter a larger amount.`;
        } else if (amountNum > maxAcountNum) {
          err = `The max stake amount is ${maxAcountNum.toFixed(2)} CELR. Please enter a smaller amount.`;
        }
      }
    }
    setErrorMessage(err);
  }, [amount, tokenBalance]);

  useEffect(() => {
    if (!allowance) {
      getAllowance();
      return;
    }
    if (!validFloatRegex.test(amount)) {
      return;
    }
    if (amount.length > 0) {
      const amountWei = parseEther(formatDeleteBalance(amount));
      if ((amountWei.gt(allowance) || allowance.isZero()) && errorMessage.length == 0) {
        setNeedApprove(true);
      } else {
        setNeedApprove(false);
      }
    } else {
      setNeedApprove(false);
    }
  }, [amount, allowance, errorMessage]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  const contentDom = () => {
    switch (delegateStatus) {
      case DelegateStatus.SUCCESS: {
        return (
          <div className="success">
            <div className="success-bolck">
              <div className="success-icon">
                <CheckCircleFilled style={{ fontSize: "56px", color: "#68DE31" }} />
              </div>
              <div className="success-text">Delegation Completed</div>
              <div className="success-desc">
                Your Celer Tokens are staked successfully on validator {validatorInfo?.desc?.moniker}. Your delegation
                will take 10-20 mins to reflect in "My Rewards".
              </div>
              <div className="success-link">
                <a href={`${explorerUrl}/tx/${delegateTxHash}`} target="_blank" rel="noreferrer">
                  Check on Etherscan
                </a>
              </div>
            </div>
            <Button
              type="primary"
              block
              className="success-btn"
              onClick={() => {
                successMethod();
              }}
            >
              OK
            </Button>
          </div>
        );
      }
      default: {
        return (
          <div className="delegate">
            <div className="form-userInfo">
              <Avatar size={56} icon={<img src={validatorInfo?.desc?.details?.icon || userIcon} alt="userIcon" />} />
              <div className="candidateId">{validatorInfo?.desc?.moniker || "anonymous"}</div>
              {!isMobile && errorMessage ? (
                <div className="error-info">
                  <span>{errorMessage}</span>
                </div>
              ) : (
                <div className="commission-rate">
                  <span className="rate-number">{`${commissionRate / RATE_BASE}% `}</span>
                  <span className="rate">
                    Commission
                    <Tooltip
                      placement="top"
                      title={
                        <div className="toolsDetail">
                          This is the percentage of your staking rewards taken by this validator.
                          <br />
                          <a href={`${helpUrl}`} target="_blank" rel="noreferrer">
                            Learn More
                          </a>
                        </div>
                      }
                      color="#FFFFFF"
                      overlayInnerStyle={{ color: "#0A1E42", width: isMobile ? 200 : 290 }}
                    >
                      <img src={tipIcon} className="withTooltip" alt="tooltip icon" />
                    </Tooltip>
                  </span>
                </div>
              )}
            </div>
            <div className={`btn-group ${delegateStatus === DelegateStatus.DELEGATING ? "delegating" : null}`}>
              <TokenInput
                value={amount}
                onChange={handleTokenInputChange}
                disabled={delegateStatus === DelegateStatus.DELEGATING}
              />
              <div className="symbol-celer">CELR</div>
              {delegateStatus === DelegateStatus.DELEGATING ? (
                <div className="lock-img">
                  <img src={lockPng} alt="" />
                </div>
              ) : null}
              <Button block disabled={delegateStatus === DelegateStatus.DELEGATING} onClick={() => setMaxAmount()}>
                MAX
              </Button>
            </div>
            <div className="delegate-info">
              <div className="block">
                <div className="title">MIN Stake Amount</div>
                <div className="amount">
                  <span className="number">{minAmount}</span>
                  <span className="celr">CELR</span>
                </div>
              </div>
              <div className="block">
                <div className="title">Available Amount</div>
                <div className="amount">
                  <span className="number">{formatCelrWithoutSymbol(tokenBalance, 2)}</span>
                  <span className="celr">CELR</span>
                </div>
              </div>
            </div>
            {isMobile && errorMessage ? (
              <div className="error-info">
                <span>{errorMessage}</span>
              </div>
            ) : null}
            {needApprove ? (
              <Button
                type="primary"
                block
                onClick={approveMethod}
                className={delegateStatus === DelegateStatus.DELEGATING ? "delegating-btn" : "delegate-btn"}
                disabled={
                  !!errorMessage ||
                  !(Number(formatDeleteBalance(amount)) > 0) ||
                  delegateStatus === DelegateStatus.DELEGATING
                }
              >
                {delegateStatus === DelegateStatus.DELEGATING ? (
                  <img src={lockLoadingPng} alt="" />
                ) : (
                  <span>
                    <Tooltip
                      title={
                        <div>
                          You must give SGN smart contracts permission to use your CELR, which is an on-chain tx that
                          consumes gas. You only have to do this once.
                        </div>
                      }
                      placement="bottom"
                      color="#fff"
                      overlayInnerStyle={{
                        color: "#000",
                        borderRadius: "8px",
                        textAlign: "center",
                        width: isMobile ? 200 : 290,
                      }}
                    >
                      <InfoCircleOutlined style={{ fontSize: 13, marginLeft: 6 }} />
                    </Tooltip>{" "}
                    Give permission to stake your CELR
                  </span>
                )}
              </Button>
            ) : (
              <Button
                type="primary"
                block
                onClick={delegateMethod}
                className={delegateStatus === DelegateStatus.DELEGATING ? "delegating-btn" : "delegate-btn"}
                disabled={
                  !!errorMessage ||
                  !(Number(formatDeleteBalance(amount)) > 0) ||
                  delegateStatus === DelegateStatus.DELEGATING
                }
              >
                {delegateStatus === DelegateStatus.DELEGATING ? <img src={lockLoadingPng} alt="" /> : "Delegate"}
              </Button>
            )}
          </div>
        );
      }
    }
  };

  const modalTitleDom = () => {
    return delegateStatus === DelegateStatus.FAILED ? (
      <>
        {/* <div className="transactionRejectDom">
                    <div className="text">
                        Transaction Reject
                    </div>
                    <div className="link" onClick={() => setDelegateStatus(DelegateStatus.DELEGATE)}>
                        Dismiss
                    </div>
                </div>
                Delegate */}
      </>
    ) : (
      "Delegate"
    );
  };

  return (
    <Modal
      title={modalTitleDom()}
      className="delegate-modal"
      width={401}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      {contentDom()}
    </Modal>
  );
};

export default DelegateModal;
