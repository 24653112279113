import { useEffect, useState } from "react";
import { Redirect, Route, Switch, Link, useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import { isMobile } from "react-device-detect";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useWeb3Context } from "../providers/Web3ContextProvider";
import { useConfigContext } from "../providers/ConfigContextProvider";
import { useAppDispatch, useAppSelector } from "../redux/store";
import Dpos from "../views/Dpos";
import Reward from "../views/Reward";
import Header from "../newComponents/Header/index";
import Footer from "../newComponents/Header/Footer";
import ProviderModal from "../newComponents/providerModal";
import SwitchChainModal from "../newComponents/common/SwitchChainModal";
import { closeModal, ModalName } from "../redux/modalSlice";
import {
  save,
  setConfigs,
  setUnbondingBlockNum,
  setBridgeContractAddress,
  setFlowTokenPathConfigs,
} from "../redux/globalInfoSlice";
import { getContractsAddr, getTransferConfigs } from "../api/index";
import { Staking__factory } from "../typechain/factories/Staking__factory";
import useReadOnlyCustomContractLoader from "../hooks/customReadyOnlyContractLoader";
import "./NewApp.less";
import { GetTransferConfigsRequest } from "../proto/gateway/gateway_pb";
import { useWindowWidth } from "../hooks";
import { getFlowTokenPathConfigs } from "../redux/NonEVMAPIs/flowAPIs";

function App(): JSX.Element {
  const location = useLocation();
  const { chainId, signer } = useWeb3Context();
  const { showProviderModal } = useAppSelector(state => state.modal);
  const dispatch = useAppDispatch();
  const { getRpcUrlByChainId } = useConfigContext();
  const { stakingContractAddress, configs } = useAppSelector(state => state.globalInfo);
  const { chainsList } = configs;
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const stakingContract = useReadOnlyCustomContractLoader(jprovider, stakingContractAddress, Staking__factory);
  const [route, setRoute] = useState("");

  useWindowWidth();

  const handleCloseProviderModal = () => {
    dispatch(closeModal(ModalName.provider));
  };

  useEffect(() => {
    const segments = location.pathname.split("/").filter(p => p);
    setRoute(segments[0]);
  }, [location]);

  /**
   * get contract addr by gateway
   */
  useEffect(() => {
    getContractsAddr().then(res => {
      dispatch(
        save({
          viewerContractAddress: res.viewer_contract,
          stakingContractAddress: res.staking_contract,
          stakingRewardContractAddress: res.staking_reward_contract,
          celerAddress: res.celr_contract,
        }),
      );
    });
    const getTransferConfigsRequest = new GetTransferConfigsRequest();
    getTransferConfigs(getTransferConfigsRequest).then(res => {
      if (res) {
        dispatch(setConfigs(res.toObject()));
      }
    });
    getFlowTokenPathConfigs().then(res => {
      if (res) {
        const { FtConfigs } = res;
        dispatch(setFlowTokenPathConfigs(FtConfigs));
      }
    });
  }, [dispatch]);

  useAsync(async () => {
    if (stakingContract) {
      try {
        const periodBlock = await stakingContract.getParamValue(2);
        dispatch(setUnbondingBlockNum(periodBlock.toNumber()));
      } catch (e) {
        console.log(e);
      }
    }
  }, [stakingContract, dispatch]);

  useEffect(() => {
    const contractAddress = chainsList?.find(item => item.id === Number(chainId))?.contractAddr;
    if (contractAddress) {
      dispatch(setBridgeContractAddress({ bridgeContractAddress: contractAddress }));
    }
  }, [chainId, chainsList, dispatch]);

  const renderRouterTab = () => {
    return (
      <div className="router-tab">
        <div className={route === "staking" ? "router-tab-item-selected" : "router-tab-item"}>
          <Link to="/staking">All Validators</Link>
        </div>
        <div className={route === "rewards" ? "router-tab-item-selected" : "router-tab-item"}>
          <Link to="/rewards">My Rewards</Link>
        </div>
      </div>
    );
  };

  return (
    <div className="app">
      <Header />
      <div className="content">
        {isMobile && signer ? renderRouterTab() : null}
        <Switch>
          <Route path="/staking">
            <Dpos />
          </Route>
          <Route path="/rewards">
            <Reward />
          </Route>
          <Redirect from="/" to="/staking" />
        </Switch>
      </div>
      <ProviderModal visible={showProviderModal} onCancel={handleCloseProviderModal} />
      <SwitchChainModal />
      <Footer />
    </div>
  );
}

export default App;
