import _ from "lodash";
import { LPHistory } from "../constants/claimType";
import { ClaimStatus } from "../proto/gateway/gateway_pb";
import { dataClone } from "../helpers/dataClone";
import { storageConstants } from "../constants/const";
import { Historykey } from "../newComponents/reward/History/History";

/* eslint-disable camelcase */
/* eslint-disable no-debugger */

interface MergedLpHistoryResult {
  mergedLpHistory: LPHistory[];
  actionNum: number;
  pendingNum: number;
}
export type MergedLPHistroryResult = [LPHistory[], number, number, boolean];

export const mergeLiquidityHistory = (
  address: string,
  pageToken: number,
  lpHistory: LPHistory[],
  localLpHistoryList: LPHistory[],
  /* eslint-disable-next-line */
  onChainResult: any[],
  pageSize = 6,
  type: Historykey,
): MergedLpHistoryResult => {
  if (pageToken === undefined || !address) {
    return { mergedLpHistory: [], actionNum: 0, pendingNum: 0 };
  }
  let comparePageToken = new Date().getTime();
  if (pageToken !== 0) {
    comparePageToken = pageToken;
  }

  onChainResult?.map((pItem, i) => {
    const localItem = localLpHistoryList[i];
    if (pItem) {
      localItem.txIsFailed = Number(pItem.status) !== 1;
    }
    return pItem;
  });

  const newList = getLPMergeList(lpHistory, localLpHistoryList, comparePageToken, address, type);
  if (newList.length > 0) {
    const arrList: LPHistory[][] = _.chunk(newList, pageSize);
    const nowList = arrList[0];
    const { actionNum, pendingNum } = getLPActionNum(nowList);
    return { mergedLpHistory: nowList, actionNum, pendingNum };
  }
  return { mergedLpHistory: [], actionNum: 0, pendingNum: 0 };
};

const getLPMergeList = (lpList, localLpList, comparePageToken, address, type: Historykey) => {
  const [mergedList, localKeepList] = handleLocalLPList(lpList, localLpList, comparePageToken, type);
  const localKeepHistoryJson = { [address]: localKeepList };
  localStorage.setItem(
    type === "pool_bridge" ? storageConstants.KEY_LP_LIST : storageConstants.KEY_PEG_LP_LIST,
    JSON.stringify(localKeepHistoryJson),
  );
  mergedList.sort((a, b) => Number(b.ts) - Number(a.ts));
  return mergedList;
};

/**
 * 处理本地缓存数据，Gateway是用时间戳做的分页请求
 * @param lpList
 * @param localLpList
 * @param comparePageToken 时间戳，用于分页
 * @returns
 */
const handleLocalLPList = (lpList: LPHistory[], localLpList: LPHistory[], comparePageToken, type: Historykey) => {
  if (!localLpList || localLpList?.length === 0) {
    return [lpList, []];
  }

  const combineList: LPHistory[] = [];
  const copyedLocalHistory: LPHistory[] = dataClone(localLpList);
  const copyedRemoteLpHistory: LPHistory[] = dataClone(lpList);

  // keep the items can't ne clear in local storage
  const localKeepList: LPHistory[] = [];

  if (type === "pool_bridge") {
    copyedRemoteLpHistory.forEach(remoteItem => {
      copyedLocalHistory.forEach(localItem => {
        // resolve the confict data bettween remote and local
        if (Number(remoteItem.seqNum) === Number(localItem.seqNum)) {
          // select which item can be used
          if (remoteItem.status === ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION) {
            combineList.push(localItem);

            const localSeqNumList = localKeepList.map(todoItem => Number(todoItem.seqNum));
            if (!localSeqNumList.includes(Number(localItem.seqNum))) {
              localKeepList.push(localItem);
            }
          } else {
            combineList.push(remoteItem);
          }
        }
      });
    });
  } else if (type === "canonical_bridge") {
    copyedRemoteLpHistory.forEach(remoteItem => {
      copyedLocalHistory.forEach(localItem => {
        // resolve the confict data bettween remote and local
        if (Number(remoteItem.withdrawId) === Number(localItem.withdrawId)) {
          // select which item can be used
          if (remoteItem.status === ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION) {
            combineList.push(localItem);

            const localwithdrawIdList = localKeepList.map(todoItem => Number(todoItem.withdrawId));
            if (!localwithdrawIdList.includes(Number(localItem.withdrawId))) {
              localKeepList.push(localItem);
            }
          } else {
            combineList.push(remoteItem);
          }
        }
      });
    });
  }

  // get the remaining valid local history records
  const remainingLocalHistory: LPHistory[] = [];
  // collect record which not valid in current page

  if (type === "pool_bridge") {
    copyedLocalHistory.forEach(localItem => {
      const seqNumList = combineList.map(it => Number(it.seqNum));
      if (!seqNumList.includes(Number(localItem.seqNum))) {
        localKeepList.push(localItem);

        if (!(Number(localItem.ts) >= Number(comparePageToken))) {
          remainingLocalHistory.push(localItem);
        }
      }
    });
  } else if (type === "canonical_bridge") {
    copyedLocalHistory.forEach(localItem => {
      const withdrawIdList = combineList.map(it => Number(it.withdrawId));
      if (!withdrawIdList.includes(Number(localItem.withdrawId))) {
        localKeepList.push(localItem);

        if (!(Number(localItem.ts) >= Number(comparePageToken))) {
          remainingLocalHistory.push(localItem);
        }
      }
    });
  }

  // get the remaining remote history records
  const remainingRemoteHistory: LPHistory[] = [];
  if (type === "pool_bridge") {
    copyedRemoteLpHistory.forEach(remoteItem => {
      const seqNumList = combineList.map(it => Number(it.seqNum));
      if (!seqNumList.includes(Number(remoteItem.seqNum))) {
        remainingRemoteHistory.push(remoteItem);
      }
    });
  } else if (type === "canonical_bridge") {
    copyedRemoteLpHistory.forEach(remoteItem => {
      const withdrawIdList = combineList.map(it => Number(it.withdrawId));
      if (!withdrawIdList.includes(Number(remoteItem.withdrawId))) {
        remainingRemoteHistory.push(remoteItem);
      }
    });
  }

  // get the merge collection
  const mergedList = [...remainingLocalHistory, ...combineList, ...remainingRemoteHistory];
  return [mergedList, localKeepList];
};

// 判断处于waiting for delegator action 状态，或交易失败，但是处于confirming状态的数据
export const isConfirmToRemoveLq = (lpHistory: LPHistory) => {
  if (lpHistory.status === ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION) {
    return true;
  }
  if (lpHistory.txIsFailed && lpHistory.status === ClaimStatus.CLM_CONFIRMING_FEE_REWARDS_CLAIM) {
    return true;
  }
  return false;
};

export const getLPActionNum = lpList => {
  let actionNum = 0;
  let pendingNum = 0;
  lpList?.forEach(item => {
    if (item.status === ClaimStatus.CLM_WAITING_FOR_DELEGATOR_ACTION || isConfirmToRemoveLq(item)) {
      actionNum += 1;
    }
    if (
      item.status !== ClaimStatus.CLM_COMPLETED &&
      item.status !== ClaimStatus.CLM_FAILED &&
      item.status !== ClaimStatus.CLM_UNKNOWN
    ) {
      pendingNum += 1;
    }
    return item;
  });
  return { actionNum, pendingNum };
};
