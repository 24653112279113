import { createContext, ReactChild, ReactChildren, useCallback, useContext } from "react";
import { getNetworkById } from "../constants/network";
import { useAppSelector } from "../redux/store";

/* eslint-disable*/

interface ConfigContextProps {
  // getTokenByChainAndTokenSymbol: (chainId, tokenSymbol) => any;
  getContractAddrByChainId: (chainId) => any;
  getRpcUrlByChainId: (chainId) => any;
}

interface ConfigContextProviderProps {
  children: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

export const ConfigContext = createContext<ConfigContextProps>({
  // getTokenByChainAndTokenSymbol: () => {},
  getContractAddrByChainId: () => {},
  getRpcUrlByChainId: () => {},
});

export const ConfigContextProvider = ({ children }: ConfigContextProviderProps): JSX.Element => {
  const { globalInfo } = useAppSelector(state => state);
  const { configs } = globalInfo;
  const { chainsList, chainTokenMap } = configs;
  // const getTokenByChainAndTokenSymbol = useCallback(
  //   (chainId, tokenSymbol) => {
  //     const resultToken = chainTokenMap[chainId]?.tokenList?.find(
  //       tokenInfo => tokenInfo?.token?.symbol === tokenSymbol,
  //     );
  //     return resultToken;
  //   },
  //   [configs],
  // );

  const getContractAddrByChainId = useCallback(
    chainId => {
      const list = chainsList.filter(item => item.id === chainId);
      if (list.length > 0 && list[0].contractAddr) {
        const contractAddr = list[0].contractAddr;
        return contractAddr;
      }
    },
    [configs],
  );

  const getRpcUrlByChainId = useCallback(
    chainId => {
      if (getNetworkById(chainId).rpcUrl) {
        const rpcUrl = getNetworkById(chainId).rpcUrl;
        return rpcUrl;
      }
    },
    [configs],
  );

  return (
    <ConfigContext.Provider
      value={{
        // getTokenByChainAndTokenSymbol,
        getContractAddrByChainId,
        getRpcUrlByChainId,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext: () => ConfigContextProps = () => useContext(ConfigContext);
