import { useState } from "react";
import { Card, Row } from "antd";
import { isMobile } from "react-device-detect";
import { useAsync } from "react-use";
import { JsonRpcProvider } from "@ethersproject/providers";
import { useWeb3Context } from "../../providers/Web3ContextProvider";
import { useConfigContext } from "../../providers/ConfigContextProvider";
import useReadOnlyCustomContractLoader from "../../hooks/customReadyOnlyContractLoader";
import { Viewer__factory } from "../../typechain/factories/Viewer__factory";
import { DelegatorInfoType } from "../../helpers/subscribe";
import "./myDelegations.less";
import Validator from "./Validator";
import { useAppSelector } from "../../redux/store";

/* eslint-disable no-debugger */
const MyDelegations = () => {
  const { address } = useWeb3Context();
  const [delegators, setDelegators] = useState<Array<DelegatorInfoType>>();
  const { transferInfo } = useAppSelector(state => state);
  const { refreshMyDelegations } = transferInfo;
  const { getRpcUrlByChainId } = useConfigContext();
  const { viewerContractAddress } = useAppSelector(state => state.globalInfo);
  const rpcUrl = getRpcUrlByChainId(process.env.REACT_APP_NETWORK_ID);
  const jprovider = new JsonRpcProvider(rpcUrl);
  const viewerContract = useReadOnlyCustomContractLoader(jprovider, viewerContractAddress, Viewer__factory);

  useAsync(async () => {
    if (!viewerContract) {
      return;
    }
    try {
      const allDelegatorsInfo = await viewerContract.getDelegatorInfos(address);
      setDelegators(allDelegatorsInfo);
    } catch (e) {
      console.log(e);
    }
  }, [viewerContract, address, refreshMyDelegations]);

  const renderMobileContent = () => {
    return (
      <div className="mobile-myDelegations-content">
        {delegators?.map(delegator => {
          return <Validator key={delegator.valAddr} delegatorInfo={delegator} />;
        })}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <Row>
        {" "}
        {delegators && delegators.length > 0 ? (
          delegators?.map(delegator => {
            return <Validator key={delegator.valAddr} delegatorInfo={delegator} />;
          })
        ) : (
          <div className="renderEmptyDelegates">
            <div className="renderEmptyP">👀</div>
            <div className="renderEmptyP">No Delegation Yet!</div>
          </div>
        )}
      </Row>
    );
  };

  return (
    <Card
      title="My Delegations"
      className="myDelegations"
      style={{ marginTop: "24px", marginBottom: "24px", borderRadius: "21px" }}
    >
      {isMobile ? renderMobileContent() : renderContent()}
    </Card>
  );
};

export default MyDelegations;
